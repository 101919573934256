import React from 'react';
import {
    Breadcrumb,
    Button,
    Card, Carousel,
    Col,
    DatePicker,
    Divider,
    Form,
    Layout,
    notification, Popover,
    Row,
    Select, Skeleton,
    Space,
    Spin, Tag
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/v2/Wrapper";
import {useTranslation} from "react-i18next";
import HotelSelect from "@/components/utils/HotelSelect";
import { ReservationServiceV2 } from "@/services";
import moment from "moment";
import {Link} from "react-router-dom";
import ReservationCreateFormModal
    from "@/views/new-version/reservation/components/ReservationCreateFormModal";

const {Content} = Layout;
const {RangePicker} = DatePicker;
const {Option} = Select;

const SearchReservationPage = () => {
    const [results, setResults] = React.useState({
        data: [],
        loading: false,
        firstLoad: true,
        error: false
    })
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const childCount = Form.useWatch('children', form);
    const [selectedRoom, setSelectedRoom] = React.useState(null);
    const onSearch = async (values) => {
        const hotelId = values.hotel;
        let seats = {
            adults: values.adults,
        }
        if (values.children > 0){
            seats.ages = values.ages;
        }
        values = {
            checkin: values.daterange[0].format('DD.MM.YYYY'),
            checkout: values.daterange[1].format('DD.MM.YYYY'),
            seats: [
                {
                    ...seats
                }
            ]
        }
        setResults({ data: [], loading: true })
        try {
            let {data: response} = await ReservationServiceV2.search(hotelId, values)
            setResults({
                data: response.data,
                loading: false,
                firstLoad: false
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: t("general.error"),
                    description: e.response.data.message
                });
            }
            setResults({ data: [], loading: true, firstLoad: false, error: true })
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.search_availability")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Form form={form} onFinish={onSearch} layout="vertical" initialValues={{
                        adults: 2,
                        children: 0,
                        ages: []
                    }}>
                        <Row gutter={[16,0]}>
                            <Col span={12}>
                                <Form.Item
                                    name="hotel"
                                    label={t("general.hotel")}
                                    rules={[{required: true, message: t("general.choose")}]}
                                >
                                    <HotelSelect
                                        placeholder={t("general.choose")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="daterange"
                                    label={t("general.check_in_out")}
                                    rules={[{ required: true, message: t("general.choose")}]}
                                >
                                    <RangePicker
                                        format="DD.MM.YYYY"
                                        style={{width: '100%'}}
                                        disabledDate={(current) => current && current < moment().startOf('day')}
                                        placeholder={[`${t("general.starting_date")}`, `${t("general.ending_date")}`]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="adults"
                                    label={t("general.adult_count")}
                                    rules={[{required: true, message: t("general.choose")}]}
                                >
                                    <Select>
                                        {[...Array(10)].map((e, i) => {
                                            if(i === 0) return;
                                            return <Option key={i} value={i}>{i}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="children"
                                    label={t("general.child_count")}
                                    rules={[{required: true, message: t("general.choose")}]}
                                >
                                    <Select>
                                        {[...Array(10)].map((e, i) => {
                                            return <Option key={i} value={i}>{i}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16,0]}>
                            {childCount > 0 && [...Array(childCount)].map((e, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Col span={4}>
                                            <Form.Item
                                                name={['ages', i]}
                                                label={t("general.child_age", {count: i + 1})}
                                                rules={[{required: true, message: t("general.choose")}]}
                                            >
                                                <Select>
                                                    {[...Array(18)].map((e, i) => {
                                                        return <Option key={i} value={i}>{i}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </React.Fragment>
                                )
                            })}
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        {t("general.search")}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div className="space-y-3">
                        {results.data.length > 0 && results.data.map((result, i) => {
                            return (
                                <RoomResultCard
                                    key={i}
                                    result={result}
                                    setSelectedRoom={setSelectedRoom}
                                />
                            )
                        })}
                    </div>
                    {results.loading && !results.error && (
                        <Skeleton />
                    )}
                    {(results.data.length === 0 && !results.loading && !results.firstLoad) && (
                        <div className="flex items-center justify-center bg-slate-100 p-4 rounded-lg">
                            Arama kriterlerinize uygun oda bulunamadı.
                        </div>
                    )}
                    {results.error && (
                        <div className="flex items-center justify-center bg-red-100 p-4 rounded-lg font-medium">
                            Bir hata oluştu. Lütfen tekrar deneyin.
                        </div>
                    )}
                </div>
            </Content>
            <ReservationCreateFormModal
                isVisible={selectedRoom !== null}
                onCancel={() => setSelectedRoom(null)}
                selectedRoom={{
                    ...selectedRoom,
                    hotel_id: form.getFieldValue("hotel"),
                }}
                title="Rezervasyon Oluştur"
            />
        </Wrapper>
    );
};

const RoomResultCard = ({ result, setSelectedRoom }) => {
    const { t } = useTranslation();
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card className="rounded-lg">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h1 className="text-lg font-bold">
                                {result?.title}
                            </h1>
                            <p>
                                {result?.description}
                            </p>
                        </Col>
                        {result?.prices.length === 0 && (
                            <Col span={24}>
                                <div className="bg-slate-100 flex items-center justify-center p-4 text-base font-medium">
                                    Uygun Fiyat Bulunamadı
                                </div>
                            </Col>
                        )}
                        {result?.prices.map((price, i) => (
                            <Col span={24} key={i}>
                                <h3 className="mb-3 text-base font-medium">
                                    Kondüsyon: {price?.condition?.adults} Yetişkin, {price?.condition?.children} Çocuk
                                </h3>
                                <div className="flex justify-between items-center bg-slate-100 p-4 rounded-lg">
                                    <div className="flex items-center gap-3">
                                        <span className="text-blue-500 text-lg font-bold">
                                            {price?.total.toLocaleString("tr-TR",{style: 'currency', currency: price?.currency })}
                                        </span>
                                        {price?.discount_percentage > 0 && (
                                            <>
                                                <span className="line-through text-gray-600/70 font-medium">
                                                    {price?.old_total.toLocaleString("tr-TR",{style: 'currency', currency: 'TRY'})}
                                                </span>
                                                <Tag color="green">
                                                    %{price?.discount_percentage}
                                                </Tag>
                                                <Tag color={price?.is_sellable ? "green": "red"}>
                                                    {price?.is_sellable ? "Satışa Açık": "Satışa Kapalı"}
                                                </Tag>
                                            </>
                                        )}
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <Popover placement="topRight" trigger="click" content={() => (
                                            <div className="grid grid-cols-2 gap-2">
                                                {price?.dates?.map((date, i) => (
                                                    <div key={i} className="flex flex-col border p-2 space-y-1">
                                                        <span><strong>Tarih:</strong> {date?.date}</span>
                                                        <span><strong>Gecelik İndirimli Toplam:</strong> {date?.night_total} ₺</span>
                                                        <span><strong>Gecelik Toplam:</strong> {date?.night_total_old} ₺</span>
                                                        <span><strong>Durum:</strong> {date?.is_open_sale ? <Tag color="green">Satışa Açık</Tag>: <Tag color="red">Satışa Kapalı</Tag>}</span>
                                                        {date?.discounts?.length > 0 && date?.discounts?.[0]?.items?.map((item, i) => (
                                                            <span key={i}>
                                                                <strong>{t("general."+item?.type.toLowerCase())}:</strong> &nbsp;
                                                                <Tag color="blue">
                                                                    {item?.discount}
                                                                    {item?.discount_type === "1" ? "%": "₺"} &nbsp;
                                                                    <span className="text-xs">({item?.is_cost_include ? "Mal. Dahil": "Mal. Hariç"})</span>
                                                                </Tag>
                                                            </span>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        )}>
                                            <Button type="primary" htmlType="button" className="rounded-lg">
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </Button>
                                        </Popover>
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            className="rounded-lg"
                                            onClick={() => setSelectedRoom(price)}
                                        >
                                            Oda Seçin
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default SearchReservationPage;