import React, {useEffect, useState} from 'react';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "@/views/new-version/reservation/components/documents/ActionButton";
import {useTranslation} from "react-i18next";
import {Form, notification} from "antd";
import {ReservationServiceV2, HotelServiceV2} from "@services";
import ReservationVoucherModal from "@components/reservations/ReservationVoucherModal";

const SendVoucher = ({ reservation }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [vouchers, setVouchers] = useState({
        data: {},
        loading: true
    })
    const [isVoucherModalVisible, setIsVoucherModalVisible] = React.useState(false);
    const [isReservationVoucherSending, setIsReservationVoucherSending] = React.useState(false);
    const getHotelVouchers = async (hotelId) => {
        setVouchers({ data: [], loading: true })
        try {
            let {data: response} = await HotelServiceV2.getVouchers(hotelId);
            setVouchers({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setVouchers({ data: [], loading: false })
        }
    }
    const onSendVoucher = async (values) => {
        setIsReservationVoucherSending(true)
        try {
            let {data: response} = await ReservationServiceV2.sendVoucher(reservation.uuid, values);
            if (response.success){
                form.resetFields()
                setIsVoucherModalVisible(false)
                notification.success({
                    message: response.message
                });
            }
            setIsReservationVoucherSending(false)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setIsReservationVoucherSending(false)
        }
    }
    useEffect(() => {
       getHotelVouchers(reservation.hotel_id)
    },[])
    return (
        <React.Fragment>
            <ActionButton
                icon={faPaperPlane}
                title={t("general.send_voucher")}
                onClick={() => setIsVoucherModalVisible(true)}
            />
            <ReservationVoucherModal
                title="Müşteriye Rezervasyon Voucher Gönder"
                form={form}
                isVisible={isVoucherModalVisible}
                onCancel={() => setIsVoucherModalVisible(false)}
                onFinish={onSendVoucher}
                loading={isReservationVoucherSending}
                email={reservation?.guests?.[0]?.email}
                translations={vouchers.data}
            />
        </React.Fragment>
    );
};

export default SendVoucher;