import React from 'react';
import {Button, Checkbox, Col, Form, Input, InputNumber, notification, Row} from "antd";
import {useTranslation} from "react-i18next";
import {ReservationDetailService} from "@services";

const { TextArea } = Input;

const CostTab = ({ reservation }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [isReservationCostLoading, setIsReservationCostLoading] = React.useState(false);
    const onSave = async (values) => {
        setIsReservationCostLoading(true)
        try{
            let { data: response } = await ReservationDetailService.updateReservationCost(reservation.uuid, values);
            if(response.success){
                notification.success({
                    message: response.message
                });
                setIsReservationCostLoading(false)
            }
        }catch (e){
            setIsReservationCostLoading(false)
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSetCallCenterSale = async (value) => {
        value = {
            is_call_center_sale: value
        }
        try{
            let { data: response } = await ReservationDetailService.setCallCenterSale(reservation.uuid, value);
            if(response.success){
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    return (
        <div>
            <Form form={form} layout="vertical" onFinish={onSave} initialValues={{
                reservation_cost: reservation?.cost,
                special_commission: reservation?.special_commission,
                cost_note: reservation?.cost_note,
                call_center_sale: reservation?.call_center_sale
            }}>
                <Row gutter={[16,4]}>
                    <Col span={6}>
                        <Form.Item
                            style={{ marginBottom: "6px" }}
                            name="reservation_cost"
                            label={t("general.cost")}
                            rules={[{
                                required:true,
                                message:"Tutar girilmedi!"
                            }]}
                        >
                            <InputNumber style={{width:"100%"}}/>
                        </Form.Item>
                        <p>İndirimli Rez Tutarı: {reservation?.discounted_amount.toLocaleString("tr-TR", {style: "currency", currency: reservation.currency })}</p>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="special_commission"
                            label={t("general.special_commission")}
                        >
                            <InputNumber style={{width:"100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="cost_note"
                            label={t("general.cost_note")}
                        >
                            <TextArea style={{width:"100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t("general.call_center_sale")}
                            valuePropName="checked"
                            name="call_center_sale"
                        >
                            <Checkbox
                                onChange={(e) => onSetCallCenterSale(e.target.checked)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{width:"100%"}} loading={isReservationCostLoading}>
                                {t("general.save")}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default CostTab;