import React from 'react';
import {Col, Row, Timeline} from "antd";
import moment from "moment";

const LogsTab = ({ reservation }) => {
    return (
        <div>
            <Row>
                <Col span={24}>
                    <Timeline>
                        <Timeline.Item>
                            <b>{moment(reservation.date).format("DD.MM.YYYY HH:mm:ss")}</b> <br/>
                            <b>{reservation.created_by}</b> tarafından <b>Rezervasyon</b> oluşturuldu.
                        </Timeline.Item>
                        {reservation?.logs?.map((log,i) => (
                            <Timeline.Item key={i}>
                                <b>{log?.date}</b> <br/>
                                {log?.user ? <b>{log?.user?.first_name + " " + log?.user?.last_name}</b>: <b>Widget</b>} {log?.message}
                            </Timeline.Item>
                        ))}
                    </Timeline>
                </Col>
            </Row>
        </div>
    );
};

export default LogsTab;