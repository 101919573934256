import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, DatePicker, Input, Layout, notification, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {SafeBankCariTransactionService} from "@services";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const SafeBankTransactionsDetail = () => {
    const getColumnSearchProps = (type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }}>
                {type === 'date' ?
                    <div style={{
                        display: 'block'
                    }}>
                        <RangePicker
                            format="DD/MM/YYYY"
                            placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e ? [e] : [])}
                            onPressEnter={() => confirm()}
                            style={{ marginBottom: 8 }}
                        />
                    </div>:
                    <Input
                        placeholder="Ara..."
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                }
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<FontAwesomeIcon icon={faSearch} />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Ara
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            confirm();
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Sıfırla
                    </Button>
                </Space>
            </div>
        ),
    });
    const columns = [
        {
            title: 'İşlem Numarası',
            dataIndex: 'id',
            key: 'id',
            render: (text) => {
                if(text) return text
                return 'Başlangıç Bakiyesi'
            },
        },
        {
            title: 'Kasa & Banka',
            dataIndex: 'safe_bank',
            key: 'safe_bank',
            render: (text) => text && text?.bank_name + ' | ' + text?.title
        },
        {
            title: 'Cari',
            dataIndex: 'cari',
            key: 'cari',
            render: (text) => text && text?.cari?.name + ' | ' + text?.company_title + ' - ' + text?.bill_title
        },
        {
            title: 'İşlem Tipi',
            dataIndex: 'process_type',
            key: 'process_type',
            render: (text) => {
                let type = 'Tahsilat'
                if(text === 2) type = 'Tediye'
                if(text === undefined) type = ''
                return type
            }
        },
        {
            title: 'Tutar',
            dataIndex: 'amount',
            key: 'amount',
            render: (text,record) => text && text + ' ' + record?.currency?.symbol
        },
        {
            title: 'Borç',
            dataIndex: 'amount',
            key: 'debt',
            render: (text,record) => {
                if(record.process_type === 1) return text + ' ' + record?.currency?.symbol
                if(record.process_type === undefined) return ''
                return 0 + ' ' + record?.currency?.symbol
            }
        },
        {
            title: 'Alacak',
            dataIndex: 'amount',
            key: 'credit',
            render: (text,record) => {
                if(record.process_type === 2) return text + ' ' + record?.currency?.symbol
                if(record.process_type === undefined) return ''
                return 0 + ' ' + record?.currency?.symbol
            }
        },
        {
            title: 'Bakiye',
            dataIndex: 'balance',
            key: 'balance',
            render: (text,record) => {
                record.id === 0 ? text = record.balance : text = record.balance + " " + record?.currency?.symbol
                if(text){
                    return text
                }
            }
        },
        {
            title: 'İşlemi Yapan',
            dataIndex: 'user',
            key: 'user',
            render: (text) => text?.full_name
        },
        {
            title: 'İşlem Tarihi',
            dataIndex: 'created_at',
            key: 'created_at',
            ...getColumnSearchProps('date')

        },
        {
            title: 'Açıklama',
            dataIndex: 'description',
            key: 'description',
        }
    ]
    const params = useParams()
    const navigate = useNavigate()
    const queryParameters = new URLSearchParams(window.location.search)
    const currency = queryParameters.get("currency")
    const [loading, setLoading] = useState(false)
    const [safeBankCariTransactions, setSafeBankCariTransactions] = useState({
        data: [],
        meta: {},
        safe_bank: undefined
    })
    const handleFilters = (filters) => {
        if(filters?.created_at){
            filters = {
                ...filters,
                created_at: [
                    moment(filters.created_at[0][0]).format('YYYY-MM-DD'),
                    moment(filters.created_at[0][1]).format('YYYY-MM-DD')
                ]
            }
        }
        return filters
    }
    const getSafeBankCariTransactions = async (page = 1, per_page = 10, filters) => {
        filters = handleFilters(filters)
        if(!currency)
            navigate('/safe-bank-transactions')
        setLoading(true)
        try {
            let {data: response} = await SafeBankCariTransactionService.getBySafeBank(params.safeBankDefinitionId,page, per_page, {
                ...filters,
                currency
            });
            if(response.previous_balance){
                response.data = [
                    {
                        id: 0,
                        balance: response.previous_balance || undefined,
                    },
                    ...response.data
                ]
            }
            setSafeBankCariTransactions({
                data: response.data,
                meta: response.meta,
                safe_bank: response.safe_bank
            })
            setLoading(false)
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getSafeBankCariTransactions().then(() => setLoading(false))
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/safe-bank-transactions">
                        Kasa & Banka Hareketleri
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {safeBankCariTransactions?.safe_bank}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row>
                        <Col span={24}>
                            <Table
                                loading={loading}
                                columns={columns}
                                dataSource={safeBankCariTransactions.data}
                                rowKey={record => record.id}
                                pagination={{
                                    pageSize: safeBankCariTransactions.meta.per_page,
                                    total: safeBankCariTransactions.meta.total,
                                    current: safeBankCariTransactions.meta.current_page,
                                    showSizeChanger: true,
                                }}
                                onChange={(pagination, filters, sorter) => getSafeBankCariTransactions(pagination.current, pagination.pageSize, filters)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default SafeBankTransactionsDetail;