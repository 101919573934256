import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { CurrencyService } from '@services';

const { Option } = Select;

const CurrencySelect = ({ valueKey, ...props }) => {
    const propValueKey = valueKey || 'id';
    const [isLoading, setIsLoading] = useState(false);
    const [currencies, setCurrencies] = useState({
        data: [],
        meta: {}
    });

    useEffect(() => {
        getCurrencies();
    }, []);

    const getCurrencies = async (page = 1, per_page = 10) => {
        try {
            let { data: response } = await CurrencyService.get();
            setCurrencies({
                data: response.data,
            });
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Select
            loading={isLoading}
            {...props}
        >
            {currencies.data.map((currency, index) => <Option key={index} value={currency[propValueKey]}>{currency.currency} | {currency.symbol}</Option>)}
        </Select>
    );
};

export default CurrencySelect;