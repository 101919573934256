import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import useRouteHistory from '@hooks/useRouteHistory';
import { useNavigate } from 'react-router-dom';

import { Authentication } from '@services';
import './login.less';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const { t } = useTranslation()
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { getLatestRoute } = useRouteHistory();

  useEffect(() => {
    if (Authentication.hasToken()) {
      navigate('/');
    }
  }, []);

  const onFinishLogin = async (values) => {
    setLoading(true);
    try {
      let response = await Authentication.login({
        email: values.email,
        password: values.password
      });
      Authentication.setToken(response.data.token, response.data.expire_at);
      navigate(getLatestRoute() || '/');
      setLoading(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-[100dvh] flex items-center justify-center bg-white">
      <div className="mx-auto w-full max-w-3xl rounded-lg p-4">
        <Form form={loginForm} layout="vertical" onFinish={onFinishLogin}>
          <div className="mb-12">
            <h1 className="text-lg md:text-3xl text-blue-500 text-center font-semibold">
              {t('general.welcome_back')}
            </h1>
            <p className="text-sm md:text-base text-center font-medium">
              {t('general.login_for_continue')}
            </p>
          </div>
          <div className="mt-4">
            <Form.Item
                label={t('general.email')}
                name="email"
                rules={[
                    { required: true, message: 'Email alanı zorunludur.' },
                    { type: 'email', message: 'Email formatı yanlış.' },
                ]}
            >
              <Input placeholder="example@rationaltour.com" className="rounded-lg" />
            </Form.Item>
          </div>
          <div className="mt-4">
            <Form.Item
                label={t('general.password')}
                name="password"
                rules={[
                  { required: true, message: 'Şifre alanı zorunludur.' },
                  { min: 6, message: 'Şifreniz minimum 6 karakter olmalıdır.' }
                ]}
            >
              <Input.Password
                  type="password"
                  placeholder="********"
                  className="rounded-lg"
              />
            </Form.Item>
          </div>
          <div className="forgot-password">
            <a href="/forgot-password">{t('general.forgot_password')}</a>
          </div>
          <div className="flex justify-end mt-4">
            <Button type="primary" htmlType="submit" className="rounded-lg flex items-center gap-x-2">
                {loading && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />}
                {t('general.login')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
};

export default Login;