import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Switch, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/v2/Wrapper";
import ContractModalForm from "@/views/new-version/contracts/components/ContractModalForm";
import {ContractService} from "@services";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";

const {Content} = Layout;

const ContractList = () => {
    const params = useParams()
    const { t } = useTranslation()
    const [data, setData] = React.useState({
        data: [],
        loading: true
    })
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <Link to={`/v2/hotels/${params.hotelId}/contract-groups/${params.contractGroupId}/contracts/${text}`}>{text}</Link>
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Room',
            dataIndex: 'room',
            key: 'room',
        },
        {
            title: "Satış Durumu",
            dataIndex: 'open_sale',
            key: 'open_sale',
            render: (text, record) => {
                return <Switch checked={text} unCheckedChildren="Pasif" checkedChildren="Aktif" onChange={(e) => onSaleStatusChange(record.id)}/>
            }
        },
        {
            title: 'Calculating Policy',
            dataIndex: 'calculating_policy',
            key: 'calculating_policy',
            render: (text) => {
                return text === "multiply" ? t("general.multiply") : t("general.fixed_price")
            }
        }
    ]
    const [createContractForm] = Form.useForm()
    const [createContractModalFormVisible, setCreateContractModalFormVisible] = React.useState(false)
    const onSaleStatusChange = async (contractId) => {
        try {
            let {data: response} = await ContractService.demoChangeSaleStatus(params.hotelId, params.contractGroupId, contractId)
            if (response.success){
                notification.success({
                    message: response.message
                })
                getContracts(params.hotelId, params.contractGroupId)
            }
        }catch (e) {
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onContractCreate = async (values) => {
        try {
            let {data: response} = await ContractService.demoCreate(params.hotelId, params.contractGroupId, values)
            if (response.success){
                notification.success({
                    message: response.message
                })
                setCreateContractModalFormVisible(false)
                createContractForm.resetFields()
                getContracts(params.hotelId, params.contractGroupId)
            }
        }catch (e) {
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getContracts = async (hotelId, contractGroupId) => {
        try {
            let {data: response} = await ContractService.demoGet(hotelId, contractGroupId)
            setData({
                data: response.data,
                loading: false
            })
        }catch (e) {
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setData({
                data: [],
                loading: false
            })
        }
    }
    useEffect(() => {
       getContracts(params.hotelId, params.contractGroupId)
    },[])
    return (
        <Wrapper title="Sanal Pos İşlemleri">
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/contract-groups`}>
                            Kontrat Grupları
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Kontrat Grubu
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row>
                        <Col>
                            <Button type="primary" onClick={() => setCreateContractModalFormVisible(true)}>
                                Kontrat Oluştur
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={data.data}
                        loading={data.loading}
                        pagination={false}
                        bordered
                        rowKey="id"
                    />
                </div>
            </Content>
            <ContractModalForm
                hotelId={params.hotelId}
                isVisible={createContractModalFormVisible}
                form={createContractForm}
                title="Kontrat Oluştur"
                onCancel={() => setCreateContractModalFormVisible(false)}
                onFinish={onContractCreate}
            />
        </Wrapper>
    );
};

export default ContractList;