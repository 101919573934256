import React from 'react';
import {Breadcrumb, Layout} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/v2/Wrapper";

const {Content} = Layout;

const Dashboard = () => {
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">

                </div>
            </Content>
        </Wrapper>
    );
};

export default Dashboard;