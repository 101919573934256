import React, {useEffect} from "react";
import Wrapper from "@components/Wrapper";
import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    InputNumber,
    notification,
    Row,
    Select, Space,
    Table,
    Tabs
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {Content} from "antd/es/layout/layout";
import {HotelService, ContractGroupsService, ContractService, ContractQuotaService, PriceChangesService} from "@services";
import moment from "moment";
import StopSaleModalForm from "@components/forecast/StopSaleModalForm";
import OpenSaleModalForm from "@components/forecast/OpenSaleModalForm";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;


function Forecast(){
    const { t } = useTranslation()
    const permissions = useSelector((state) => state.user.user.role.permissions);
    const [quotaForm] = Form.useForm();
    const [stopSaleForm] = Form.useForm();
    const [openSaleForm] = Form.useForm();
    const [quotasForm] = Form.useForm();
    const [priceChangeForm] = Form.useForm();
    const [priceChangesForm] = Form.useForm();
    const [isStopSaleModalVisible, setIsStopSaleModalVisible] = React.useState(false);
    const [isOpenSaleModalVisible, setIsOpenSaleModalVisible] = React.useState(false);
    const [hotels, setHotels] = React.useState({
        data: [],
        loading: true,
    });
    const [contractGroups, setContractGroups] = React.useState({
        data: [],
        loading: true,
    });
    const [contracts, setContracts] = React.useState({
        data: [],
        loading: true,
    });
    const [priceChanges, setPriceChanges] = React.useState({
        data: [],
        columns:[
            {
                title: `${t('general.contract')}`,
                key: 'contract',
                dataIndex: 'contract_name',
                render: (text) => (text)
            },
            {
                title: `${t('general.concepts')}`,
                key: 'concept',
                dataIndex: 'concept',
                render: (text) => (text.concept?.active_language?.name)
            },
            {
                title: `${t('general.per_person')}`,
                key: 'per_person',
                dataIndex: 'per_person',
                render: (text, record) => (
                    <Form.Item>
                        <InputNumber placeholder="Tümüne uygula" onChange={(value) => applyToPriceChanges(value,record.id)} min={0}/>
                    </Form.Item>
                )
            },
            {
                title: 'İndirim/Artış',
                key: 'per_person_discount',
                dataIndex: 'per_person',
                render: (text, record) => (
                    <Form.Item>
                        <InputNumber placeholder="Tümüne uygula" min={0}/>
                    </Form.Item>
                )
            }],
        loading: true,
        invisible: true,
    });
    const [quotas, setQuotas] = React.useState({
        data: [],
        columns:[
            {
                title: `${t('general.contract')}`,
                key: 'contract',
                dataIndex: 'contract_name',
                render: (text) => (text)
            },
            {
                title: `${t('general.concepts')}`,
                key: 'concept',
                dataIndex: 'concept',
                render: (text) => (text.concept?.active_language?.name)
            },
            {
                title: `${t('general.quota')}`,
                key: 'quota',
                dataIndex: 'quota',
                render: (text, record) => (
                    <Form.Item>
                        <InputNumber onChange={(value) => applyToQuotas(value,record.id)} min={0}/>
                    </Form.Item>
                )
            },
            {
                title: `${t('general.min_stay')}`,
                key: 'min_night',
                dataIndex: 'min_night',
                render: (text, record) => (
                    <Form.Item>
                        <InputNumber onChange={(value) => applyToMinNight(value,record.id)} min={0}/>
                    </Form.Item>
                )
            }
        ],
        loading: true,
        invisible: true,
    });
    const [selectedContract, setSelectedContract] = React.useState(null);

    const getHotels = async (page = 1) => {
        try {
            let { data: response } = await HotelService.get(page);
            return response;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getContractGroups = async (id, page = 1, ) => {
        try {
            let { data: contractGroups } = await ContractGroupsService.get(page, id);
            setContractGroups({
                data: contractGroups.data,
                loading: false,
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getContracts = async (id) => {
        try {
            let { data: response } = await ContractService.getByGroupId(id);
            setContracts({
                data: response.data,
                loading: false,
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const applyToQuotas = (value, id) => {
        let array = [];
        const dayLength = quotaForm.getFieldValue('dates')[1].diff(quotaForm.getFieldValue('dates')[0], 'days');
        const startDate = quotaForm.getFieldValue('dates')[0];
        for(let i = 0; i <= dayLength; i++){
            const field = document.getElementById(id.toString()+'_'+moment(startDate).add(i,'days').format('YYYY-MM-DD')+'_quota');
            if (field && !field.disabled && !field.readOnly) {
                array.push({
                    name: [id,moment(startDate).add(i,'days').format('YYYY-MM-DD'), 'quota'],
                    value:value
                });
            }
        }
        quotasForm.setFields(array);
    }
    const applyToMinNight = (value, id) => {
        let array = [];
        const dayLength = quotaForm.getFieldValue('dates')[1].diff(quotaForm.getFieldValue('dates')[0], 'days');
        const startDate = quotaForm.getFieldValue('dates')[0];
        for(let i = 0; i <= dayLength; i++){
            array.push({
                name: [id,moment(startDate).add(i,'days').format('YYYY-MM-DD'),'min_night'],
                value:value
            });
        }
        quotasForm.setFields(array);
    }
    const applyToPriceChanges = (value, id) => {
        let array = [];
        const dayLength = priceChangeForm.getFieldValue('dates')[1].diff(priceChangeForm.getFieldValue('dates')[0], 'days');
        const startDate = priceChangeForm.getFieldValue('dates')[0];
        for(let i = 0; i <= dayLength; i++){
            array.push({
                name: [id,moment(startDate).add(i,'days').format('YYYY-MM-DD'),'per_person'],
                value:value
            });
        }
        priceChangesForm.setFields(array);
    }
    const applyToDiscounts = (value,conceptId,contractId,values) => {
        let contract = values.find((item) => item.id === contractId);
        let concept = contract?.concepts.find((item) => item.id === conceptId);
        let hasPeriod = concept.per_person.filter(per_person => (per_person.period_dates))
        let array = [];
        const dayLength = priceChangeForm.getFieldValue('dates')[1].diff(priceChangeForm.getFieldValue('dates')[0], 'days');
        const startDate = priceChangeForm.getFieldValue('dates')[0];
        for(let i = 0; i <= dayLength; i++){
            let price = hasPeriod.find(item => moment(item.period_dates.start_date).format('YYYY-MM-DD') <= moment(startDate).add(i,'days').format('YYYY-MM-DD') &&
                moment(item.period_dates.end_date).format('YYYY-MM-DD') >= moment(startDate).add(i,'days').format('YYYY-MM-DD'))?.price
            if(!price) continue;
            switch(priceChangesForm.getFieldValue([conceptId,'discount_type'])){
                case 1:
                    if(value > 100 || value < 0) return false;
                    array.push({
                        name: [conceptId,moment(startDate).add(i,'days').format('YYYY-MM-DD'),'per_person'],
                        value:price - price * value / 100
                    });
                    break;
                case 2:
                    array.push({
                        name: [conceptId,moment(startDate).add(i,'days').format('YYYY-MM-DD'),'per_person'],
                        value: price + price * value / 100
                    });
                    break;
                case 3:
                    array.push({
                        name: [conceptId,moment(startDate).add(i,'days').format('YYYY-MM-DD'),'per_person'],
                        value: price + value
                    });
                    break;
                case 4:
                    array.push({
                        name: [conceptId,moment(startDate).add(i,'days').format('YYYY-MM-DD'),'per_person'],
                        value: price - value
                    });
                    break;
                default:
                    if(value > 100 || value < 0) return false;
                    array.push({
                        name: [conceptId,moment(startDate).add(i,'days').format('YYYY-MM-DD'),'per_person'],
                        value:price - price * value / 100
                    });
                    break;
            }
        }
        priceChangesForm.setFields(array);
    }
    const getQuotas = async (values) => {
        let params = {
            start_date: values.dates[0].format('YYYY-MM-DD'),
            end_date: values.dates[1].format('YYYY-MM-DD'),
            contracts: values.contracts
        }
        try {
            let { data: {data: response} } = await ContractQuotaService.getByDate(params);
            let stopSales = [];
            response.map((item) => (
                item?.concepts.map((concept) => (
                    concept.quotas && concept.quotas.map((quota) => {
                        if(quota.quota === 0){
                            stopSales.push({
                                date: quota.date,
                                concept: concept.id
                            })
                        }
                        return quotasForm.setFields([{
                            name: [concept.id,quota.date,'quota'],
                            value: quota.quota
                        },{
                            name: [concept.id,quota.date,'min_night'],
                            value: quota.min_night
                        }])
                    })
                ))
            ));
            return stopSales;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getPriceChanges = async (values) => {
        let params = {
            start_date: values.dates[0].format('YYYY-MM-DD'),
            end_date: values.dates[1].format('YYYY-MM-DD'),
            contracts: values.contracts
        }
        try {
            let { data: {data: response} } = await PriceChangesService.getByDate(params);
            response.map((item) => (
                item?.concepts.map((concept) => (
                    concept.price_changes && concept.price_changes.map((price_change) => (
                        priceChangesForm.setFields([{
                            name: [concept.id,price_change.date,'per_person'],
                            value: price_change.per_person,
                        }])
                    ))
                ))
            ));
            return response;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const generateDates = (dates,type,data,stopSales) => {
        let array = [];
        if (dates && dates.length === 2) {
            let days = dates[1].diff(dates[0], 'days');
            let currentDate = moment(dates[0]);
            for(let i = 0; i <= days; i++){
                array.push({
                    title: currentDate.format('DD.MM.YYYY'),
                    key: currentDate.format('YYYY-MM-DD'),
                    dataIndex: currentDate.format('YYYY-MM-DD'),
                    render: (text, record) => {
                        if(type === 'quota'){
                            return (
                                <div style={{display:"flex",flexDirection:"column",rowGap:"4px"}}>
                                    <Form.Item
                                        noStyle
                                        name={[record.id,moment(dates[0]).add(i,'days').format('YYYY-MM-DD'),'quota']}>
                                        {
                                            stopSales.find((value) => value.concept === record.id && value.date === moment(dates[0]).add(i,'days').format('YYYY-MM-DD')) ?
                                            <InputNumber readOnly disabled placeholder="Kontenjan" min={0} style={{border:"1px dashed red"}} title="Satışa Kapalı"/> :
                                            <InputNumber placeholder="Kontenjan" min={0}/>
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        name={[record.id,moment(dates[0]).add(i,'days').format('YYYY-MM-DD'),'min_night']}>
                                        <InputNumber placeholder="Min. Konaklama" min={0}/>
                                    </Form.Item>
                                </div>
                            )
                        }
                        if(type === 'price'){
                            let contract = data.find((item) => item.concepts.find((concept) => concept.id === record.id));
                            let hasPeriodDate = contract.concepts.find((item) => item.id === record.id).per_person.filter((item) => item.period_dates !== null);
                            let price = hasPeriodDate.find((period) => (moment(dates[0]).add(i,'days').format('YYYY-MM-DD') >= moment(period.period_dates.start_date).format('YYYY-MM-DD')) && (moment(period.period_dates.end_date).format('YYYY-MM-DD') >= moment(dates[0]).add(i,'days').format('YYYY-MM-DD')))?.price;
                            return (
                                <Form.Item
                                    name={[record.id,moment(dates[0]).add(i,'days').format('YYYY-MM-DD'),'per_person']}>
                                    <InputNumber placeholder={price ? price:'Tanımsız'} min={0}/>
                                </Form.Item>
                            )
                        }
                    }
                });
                currentDate = currentDate.add(1, 'days');
            }
        }
        return array;
    }

    const onPriceChangeFinish = async (values) => {
        let array = [];
        Object.keys(values).map((key) => (
            Object.keys(values[key]).map((date) => {
                if(date === "discount_type") return null;
                if(values[key][date]['per_person'] !== undefined) {
                    return array.push({
                        concept_id: key,
                        date: date,
                        per_person: values[key][date]
                    });
                }
                return null;
            })
        ));
        if(array.length <= 0)
            return;
        try {
            let { data: response } = await PriceChangesService.createByContractId(selectedContract, array);
            notification.success({
                message: response.message
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onQuotasFinish = async (values) => {
        let array = [];
        Object.keys(values).map((key) => (
            Object.keys(values[key]).map((date) => {
                if(values[key][date]['quota'] !== undefined) {
                    return array.push({
                        concept_id: key,
                        date: date,
                        quota: values[key][date]
                    });
                }
                return null;
            })
        ));
        try {
            let { data: response } = await ContractQuotaService.createByContractId(selectedContract, array);
            notification.success({
                message: response.message
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onStopSaleFinish = async (values) => {
        values = {
            ...values,
            start_date: values.dates[0].format('YYYY-MM-DD'),
            end_date: values.dates[1].format('YYYY-MM-DD'),
        }
        try {
            let { data: response } = await ContractQuotaService.stopSale(values.contract, values);
            if(response.success){
                notification.success({
                    message: response.message
                });
                setIsStopSaleModalVisible(false);
                stopSaleForm.resetFields();
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onOpenSaleFinish = async (values) => {
        values = {
            ...values,
            start_date: values.dates[0].format('YYYY-MM-DD'),
            end_date: values.dates[1].format('YYYY-MM-DD'),
        }
        try {
            let { data: response } = await ContractQuotaService.openSale(values.contract, values);
            if(response.success){
                notification.success({
                    message: response.message
                });
                setIsOpenSaleModalVisible(false);
                openSaleForm.resetFields();
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const handleQuotaTable = async (values) => {
        quotasForm.resetFields();
         setQuotas(prevState => {
            return {
                ...prevState,
                data: [],
                loading: true,
                columns:[
                    {
                        title: `${t('general.contract')}`,
                        key: 'contract',
                        dataIndex: 'contract_name',
                        render: (text) => (text)
                    },
                    {
                        title: `${t('general.concepts')}`,
                        key: 'concept',
                        dataIndex: 'concept',
                        render: (text) => (text.concept?.active_language?.name)
                    },
                    {
                        title: `${t('general.quota')}`,
                        key: 'quota',
                        dataIndex: 'quota',
                        render: (text, record) => (
                            <Form.Item>
                                <InputNumber placeholder={text} onChange={(value) => applyToQuotas(value,record.id)} min={0}/>
                            </Form.Item>
                        )
                    },
                    {
                        title: `${t('general.min_stay')}`,
                        key: 'min_night',
                        dataIndex: 'min_night',
                        render: (text, record) => (
                            <Form.Item>
                                <InputNumber onChange={(value) => applyToMinNight(value,record.id)} min={0}/>
                            </Form.Item>
                        )
                    }
                ]
            }
        });
         let stopSales = await getQuotas(values);
        let selectedContracts = contracts.data.filter((contract) => values.contracts.includes(contract.id));
        let dates = generateDates(values.dates,'quota',[],stopSales);
        let sourceData = [];
        selectedContracts.map((contract) => (
            contract.concepts.map((concept,i) => (
                sourceData.push({
                    id: concept.id,
                    contract_id: contract.id,
                    contract_name: i === 0 ? contract.title: "",
                    concept: concept,
                    quota: contract?.hotel_room.quota
                })
            ))
        ));
        setQuotas(prevState => {
            return {
                ...prevState,
                data: sourceData,
                loading: false,
                invisible: false,
                columns: [...prevState.columns, ...dates]
            }
        })
    }
    const handlePriceChangeTable = async (values) => {
        priceChangesForm.resetFields();
        let data = await getPriceChanges(values);
        setPriceChanges(prevState => {
            return {
                ...prevState,
                data: [],
                loading: true,
                columns:[
                    {
                        title: `${t('general.contracts')}`,
                        key: 'contract',
                        dataIndex: 'contract_name',
                        render: (text) => (text)
                    },
                    {
                        title: `${t('general.concepts')}`,
                        key: 'concept',
                        dataIndex: 'concept',
                        render: (text) => (text.concept?.active_language?.name)
                    },
                    {
                        title: `${t('general.per_person')}`,
                        key: 'per_person',
                        dataIndex: 'per_person',
                        render: (text, record) => (
                            <Form.Item>
                                <InputNumber placeholder="Tümüne uygula" onChange={(value) => applyToPriceChanges(value,record.id)} min={0}/>
                            </Form.Item>
                        )
                    },
                    {
                        title: `${t('general.discount_increase')}`,
                        key: 'per_person_discount',
                        dataIndex: 'per_person',
                        render: (text, record) => (
                            <>
                                <Form.Item
                                    name={[record.id, 'discount_type']}
                                >
                                    <Select
                                        placeholder="Fiyat türü"
                                        id={record.id + "_discount_type"}
                                    >
                                        <Option value={1}>% İndirim</Option>
                                        <Option value={2}>% Artış</Option>
                                        <Option value={3}>Birim Artış</Option>
                                        <Option value={4}>Birim İndirim</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <InputNumber
                                        onChange={(value) => applyToDiscounts(value,record.id,record.contract_id,data)}
                                        placeholder="Tümüne uygula"
                                        min={0}
                                    />
                                </Form.Item>
                            </>
                        )
                    }
                ]
            }
        });
        let selectedContracts = contracts.data.filter((contract) => values.contracts.includes(contract.id));
        let dates = generateDates(values.dates,'price',data);
        let sourceData = [];
        selectedContracts.map((contract) => (
            contract.concepts.map((concept,i) => (
                sourceData.push({
                    id: concept.id,
                    contract_id: contract.id,
                    contract_name: i === 0 ? contract.title: "",
                    concept: concept,
                    per_person: 0
                })
            ))
        ));
        setPriceChanges(prevState => {
            return {
                ...prevState,
                data: sourceData,
                loading: false,
                invisible: false,
                columns: [...prevState.columns, ...dates]
            }
        })
    }

    useEffect(() => {
        getHotels().then((response) => {
            setHotels({
                data: response.data,
                loading: false,
            });
        })
    },[])

    return(
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.forecast')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Tabs>
                        {permissions.includes('contract.quotas.edit') &&
                            <TabPane tab={t('general.quota')} key="1">
                            <Form layout="vertical" form={quotaForm} onFinish={handleQuotaTable}>
                                <Row gutter={[16,4]}>
                                    <Col span={6}>
                                        <Form.Item
                                            name="hotel"
                                            label={t('general.hotels')}
                                            rules={[{
                                                required: true,
                                                message: 'Lütfen otel seçiniz'
                                            }]}
                                        >
                                            <Select
                                                placeholder="Otel Seçiniz"
                                                onSelect={(id) => {getContractGroups(id);quotaForm.setFieldsValue({contract_group: undefined, contracts: undefined})}}
                                            >
                                                {hotels.data.map((hotel) => (
                                                    <Option key={hotel.id} value={hotel.id}>{hotel.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="contract_group"
                                            label={t('general.contract_groups')}
                                            rules={[{
                                                required: true,
                                                message: 'Lütfen kontrat grubu seçiniz'
                                            }]}
                                        >
                                            <Select
                                                placeholder="Kontrat Grubu Seçiniz"
                                                onSelect={(id) => {getContracts(id);quotaForm.setFieldsValue({contracts:undefined})}}>
                                                {contractGroups.data.map((contractGroup) => (
                                                    <Option key={contractGroup.id} value={contractGroup.id}>{contractGroup.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="contracts"
                                            label={t('general.contracts')}
                                            rules={[{
                                                required: true,
                                                message: 'Lütfen kontrat seçiniz'
                                            }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Kontrat Seçiniz"
                                                onSelect={(id) => {setSelectedContract(id)}}>
                                                {contracts.data.map((contract) => (
                                                    <Option key={contract.id} value={contract.id}>
                                                        <div>
                                                            <span>{contract?.hotel_room?.room_definition?.active_language?.name}</span> <br/>
                                                            <span style={{
                                                                fontSize: "12px",
                                                            }}>({contract.title})</span>
                                                        </div>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="dates"
                                            label={t('general.date_range')}
                                            rules={[{
                                                required: true,
                                                message: 'Lütfen başlangıç tarihi seçiniz'
                                            },{
                                                validator: (rule, value) => {
                                                    if(value && value[0] !== undefined && value[1] !== undefined){
                                                        let diff = moment(value[1]).diff(moment(value[0]), 'days');
                                                        if(diff > 62){
                                                            return Promise.reject('62 günden uzun tarih aralığı seçilemez.');
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }]}
                                        >
                                            <RangePicker
                                                format="DD.MM.YYYY"
                                                style={{width:"100%"}}
                                                onFocus={() => quotaForm.setFieldsValue({dates: [undefined,undefined]})}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Space>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    {t('general.get')}
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" style={{background:"green",borderColor:"green"}} htmlType="button" onClick={() => setIsOpenSaleModalVisible(true)}>
                                                    {t('general.open_sale')}
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="danger" htmlType="button" onClick={() => setIsStopSaleModalVisible(true)}>
                                                    {t('general.stop_sale')}
                                                </Button>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>
                            <Form form={quotasForm} onFinish={onQuotasFinish}>
                               <Row gutter={[16,16]} hidden={quotas.invisible}>
                                   <Col span={24}>
                                       <Table
                                           columns={quotas.columns}
                                           rowKey="id"
                                           dataSource={quotas.data}
                                           loading={quotas.loading}
                                           scroll={{ x: 400 }}
                                           pagination={false}
                                       />
                                   </Col>
                                   <Col span={24}>
                                       <Form.Item>
                                           <Button type="primary" htmlType="submit" style={{width:"100%"}}>Kaydet</Button>
                                       </Form.Item>
                                   </Col>
                               </Row>
                            </Form>
                        </TabPane>
                        }
                        {permissions.includes('contract.price_change.edit') &&
                            <TabPane tab="Fiyat Değişiklikleri" key="2">
                            <Form layout="vertical" form={priceChangeForm} onFinish={handlePriceChangeTable}>
                                <Row gutter={[16,4]}>
                                    <Col span={6}>
                                        <Form.Item
                                            name="hotel"
                                            label={t('general.hotels')}
                                            rules={[{
                                                required: true,
                                                message: 'Lütfen otel seçiniz'
                                            }]}
                                        >
                                            <Select
                                                placeholder="Otel Seçiniz"
                                                onSelect={(id) => getContractGroups(id)}>
                                                {hotels.data.map((hotel) => (
                                                    <Option key={hotel.id} value={hotel.id}>{hotel.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="contract_group"
                                            label={t('general.contract_groups')}
                                            rules={[{
                                                required: true,
                                                message: 'Lütfen kontrat grubu seçiniz'
                                            }]}
                                        >
                                            <Select
                                                placeholder="Kontrat Grubu Seçiniz"
                                                onSelect={(id) => getContracts(id)}>
                                                {contractGroups.data.map((contractGroup) => (
                                                    <Option key={contractGroup.id} value={contractGroup.id}>{contractGroup.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="contracts"
                                            label={t('general.contracts')}
                                            rules={[{
                                                required: true,
                                                message: 'Lütfen kontrat seçiniz'
                                            }]}
                                        >
                                            <Select
                                                placeholder="Kontrat Seçiniz"
                                                mode="multiple"
                                                onSelect={(id) => {setSelectedContract(id)}}>
                                                {contracts.data.map((contract) => (
                                                    <Option key={contract.id} value={contract.id}>{contract.title}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="dates"
                                            label={t('general.date_range')}
                                            rules={[{
                                                required: true,
                                                message: 'Lütfen başlangıç tarihi seçiniz'
                                            },{
                                                validator: (rule, value) => {
                                                    if(value && value[0] !== undefined && value[1] !== undefined){
                                                        let diff = moment(value[1]).diff(moment(value[0]), 'days');
                                                        if(diff > 62){
                                                            return Promise.reject('62 günden uzun tarih aralığı seçilemez.');
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }]}
                                        >
                                            <RangePicker
                                                format="DD.MM.YYYY"
                                                style={{width:"100%"}}
                                                onFocus={() => priceChangeForm.setFieldsValue({dates: [undefined,undefined]})}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                {t('general.get')}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Form form={priceChangesForm} onFinish={onPriceChangeFinish}>
                                <Row gutter={[16,16]} hidden={priceChanges.invisible}>
                                    <Col span={24}>
                                        <Table
                                            columns={priceChanges.columns}
                                            rowKey="id"
                                            dataSource={priceChanges.data}
                                            loading={priceChanges.loading}
                                            scroll={{ x: 400 }}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" style={{width:"100%"}}>Kaydet</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </TabPane>
                        }
                    </Tabs>
                </div>
            </Content>
            <StopSaleModalForm
                title="Satışa Kapat"
                isVisible={isStopSaleModalVisible}
                onCancel={() => {setIsStopSaleModalVisible(false);stopSaleForm.resetFields();}}
                onFinish={onStopSaleFinish}
                form={stopSaleForm}
                hotels={hotels.data}
                contractGroups={contractGroups.data}
                contracts={contracts.data}
                getContractGroups={getContractGroups}
                getContracts={getContracts}
            />
            <OpenSaleModalForm
                title="Satışa Aç"
                isVisible={isOpenSaleModalVisible}
                onCancel={() => {setIsOpenSaleModalVisible(false);openSaleForm.resetFields();}}
                onFinish={onOpenSaleFinish}
                form={openSaleForm}
                hotels={hotels.data}
                contractGroups={contractGroups.data}
                contracts={contracts.data}
                getContractGroups={getContractGroups}
                getContracts={getContracts}
            />
        </Wrapper>
    )
}
export default Forecast;