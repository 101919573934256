import React from "react";
import {Layout, Menu} from "antd";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHotel,
    faMagnifyingGlass,
    faHome,
    faCalendarDays
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const { Sider } = Layout;

const Sidebar = (props) => {
    const { t } = useTranslation()
    const menus = [
        {
            key: "/",
            icon: <FontAwesomeIcon icon={faHome} />,
            label: <Link to="/">v1</Link>,
            permission: []
        },
        {
            key: "/v2/reservations",
            icon: <FontAwesomeIcon icon={faCalendarDays} />,
            label: <Link to="/v2/reservations">{t('general.reservations')}</Link>,
            permission: ['v2.pages.view'],
        },
        {
            key: "/v2/search-availability",
            icon: <FontAwesomeIcon icon={faMagnifyingGlass} />,
            label: <Link to="/v2/search-availability">{t("general.search_availability")}</Link>,
            permission: ['v2.pages.view'],
        },
        {
            key: "/v2/hotels",
            icon: <FontAwesomeIcon icon={faHotel} />,
            label: <Link to="/v2/hotels">{t("general.hotels")}</Link>,
            permission: ['v2.pages.view'],
        }
    ];
    const filterMenusByPermission = (menus, permissions) => {
        return menus.filter(menu => {
            if (menu.permission && menu.permission.length > 0) {
                // Menü izni varsa, kullanıcının izinleriyle eşleşip eşleşmediğini kontrol edin
                return menu.permission.some(permission => permissions.includes(permission));
            } else {
                // Menü izni yoksa veya izin dizisi boşsa, menüyü gösterin
                return true;
            }
        }).map(menu => {
            // Menüde alt menüler varsa, alt menüleri de filtreleyin ve güncelleyin
            if (menu.children && menu.children.length > 0) {
                menu.children = filterMenusByPermission(menu.children, permissions);
            }
            return menu;
        });
    };
    const user = useSelector((state) => state.user);
    return (
        <Sider
            width={200}
            breakpoint="lg"
            collapsedWidth={0}
            trigger={null}
            collapsed={props.onCollapsed}
        >
            <Menu
                mode="inline"
                items={filterMenusByPermission(menus, user.user.role.permissions)}
                theme="dark"
                defaultSelectedKeys={[window.location.pathname]}
            />
        </Sider>
    );
};

export default Sidebar;
