import axios from '../axios';

const ContractGroupsService = {
    async get(page = 1, hotel_id) {
        return await axios.get(`/v1/contract-groups?page=${page}&hotel_id=${hotel_id}`);
    },
    async create(formData) {
        return await axios.post(`/v1/contract-groups`, formData);
    },
    async findById(contractGroupId) {
        return await axios.get(`/v1/contract-groups/${contractGroupId}`);
    },
    async getNationalities(contractGroupId) {
        return await axios.get(`/v1/contract-groups/${contractGroupId}/nationalities`);
    },
    async updateNationalities(contractGroupId, formData) {
        return await axios.post(`/v1/contract-groups/${contractGroupId}/nationalities`, formData);
    },
    async updateCurrency(contractGroupId, formData) {
        return await axios.post(`/v1/contract-groups/${contractGroupId}/currency`, formData);
    },
    async update(contractGroupId, formData) {
        return await axios.post(`/v1/contract-groups/${contractGroupId}`, formData);
    },
    async getById(contractGroupId) {
        return await axios.get(`/v1/contract-groups/${contractGroupId}`);
    },
    async delete(contractGroupId) {
        return await axios.delete(`/v1/contract-groups/${contractGroupId}`);
    },
    async appendCari(formData, contractGroupId) {
        return await axios.post(`/v1/contract-groups/${contractGroupId}/append-cari`, formData);
    },
    async createAdditionalService(formData, contractGroupId) {
        return await axios.post(`/v1/contract-groups/${contractGroupId}/additional-services`, formData);
    },
    async updatePaymentPeriod(contractGroupId, formData) {
        return await axios.post(`/v1/contract-groups/${contractGroupId}/payment-periods`, formData);
    }
};

export default ContractGroupsService;