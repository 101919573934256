import React, {useEffect} from 'react';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "@/views/new-version/reservation/components/documents/ActionButton";
import {useTranslation} from "react-i18next";
import ReservationSaleAgreementModal from "@/components/reservations/ReservationSaleAgreementModal";
import {Form, notification} from "antd";
import {ReservationDetailService, HotelServiceV2} from "@services";

const SendSaleAgreement = ({ reservation }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [saleAgreements, setSaleAgreements] = React.useState({
        data: [],
        loading: true
    })
    const [isSendSaleAgreementModalVisible, setIsSendSaleAgreementModalVisible] = React.useState(false)
    const [isSaleAgreementSending, setIsReservationSaleAgreementSending] = React.useState(false)
    const getHotelSaleAgreements = async (hotelId) => {
        setSaleAgreements({ data: [], loading: true })
        try {
            let {data: response} = await HotelServiceV2.getSaleAgreements(hotelId);
            setSaleAgreements({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setSaleAgreements({ data: [], loading: false })
        }
    }
    const onSendSaleAgreement = async (values) => {
        setIsReservationSaleAgreementSending(true)
        try {
            let {data: response} = await ReservationDetailService.sendReservationSaleAgreement(reservation.uuid, values);
            if (response.success){
                form.resetFields()
                setIsSendSaleAgreementModalVisible(false)
                notification.success({
                    message: response.message
                });
            }
            setIsReservationSaleAgreementSending(false)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setIsReservationSaleAgreementSending(false)
        }
    }
    useEffect(() => {
       getHotelSaleAgreements(reservation.hotel_id)
    },[])
    return (
        <React.Fragment>
            <ActionButton
                icon={faPaperPlane}
                title={t("general.send_sale_agreement")}
                loading={isSaleAgreementSending}
                onClick={() => setIsSendSaleAgreementModalVisible(true)}
            />
            <ReservationSaleAgreementModal
                title="Müşteriye Satış Sözleşmesi Gönder"
                form={form}
                isVisible={isSendSaleAgreementModalVisible}
                onCancel={() => setIsSendSaleAgreementModalVisible(false)}
                onFinish={onSendSaleAgreement}
                loading={isSaleAgreementSending}
                email={reservation?.guests?.[0]?.email}
                translations={saleAgreements.data}
            />
        </React.Fragment>
    );
};

export default SendSaleAgreement;