import React from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Divider, Table} from "antd";

const GeneralTab = ({ reservation = {} }) => {
    const { t } = useTranslation();
    const guestsColumns = [
        {
            title: t("general.type"),
            dataIndex: 'type',
            key: 'type',
            width: 120,
            render: (type) => t(`general.${type}`)
        },
        {
            title: t("general.gender"),
            dataIndex: 'gender',
            key: 'gender',
            width: 120,
            render: (gender) => t(`general.${gender}`)
        },
        {
            title: t("general.full_name"),
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: t("general.birth_date"),
            dataIndex: 'birth_date',
            key: 'birth_date',
            render: (date) => moment(date).format('DD.MM.YYYY')
        },
        {
            title: t("general.phone"),
            dataIndex: 'phone',
            key: 'phone_number',
            render: (phone_number) => phone_number || '-'
        },
        {
            title: t("general.email"),
            dataIndex: 'email',
            key: 'email',
            render: (email) => email || '-'
        },
        {
            title: t("general.identity"),
            dataIndex: 'identity',
            key: 'identity',
            render: (email) => email || '-'
        },
        {
            title: t("general.nationality"),
            dataIndex: 'nationality_label',
            key: 'nationality_label',
            render: (nationality) => nationality || '-'
        }
    ]
    return (
        <div>
            <h3 className="text-xl font-medium mb-4">
                {t("general.details")}
            </h3>
            <div className="grid grid-cols-4 gap-3">
                <DetailCard
                    title={t("general.reservation_no")}
                    content={reservation?.uuid}
                />
                <DetailCard
                    title={t("general.hotel")}
                    content={reservation?.hotel_name}
                />
                <DetailCard
                    title={t("general.room")}
                    content={reservation?.room_name}
                />
                <DetailCard
                    title={t("general.reservation_creator")}
                    content={reservation?.created_by}
                />
                <DetailCard
                    title={t("general.reservation_amount")}
                    content={reservation?.amount?.toLocaleString("tr-TR", { style: 'currency', currency: reservation?.currency })}
                />
                <DetailCard
                    title={t("general.discounted_amount")}
                    content={reservation?.discounted_amount?.toLocaleString("tr-TR", { style: 'currency', currency: reservation?.currency })}
                />
                <DetailCard
                    title={t("general.check_in")}
                    content={moment(reservation?.check_in).format('DD.MM.YYYY')}
                />
                <DetailCard
                    title={t("general.check_out")}
                    content={moment(reservation?.check_out).format('DD.MM.YYYY')}
                />
                <DetailCard
                    title={t("general.nights")}
                    content={moment(reservation?.check_out).diff(moment(reservation?.check_in), 'days')}
                />
                <DetailCard
                    title={t("general.created_at")}
                    content={moment(reservation?.date).format('DD.MM.YYYY HH:mm:ss')}
                />
                <DetailCard
                    title={t("general.payment_status")}
                    content={t(`general.${reservation?.payment_status}`)}
                />
            </div>
            <Divider className="my-6" />
            <h3 className="text-xl font-medium mb-4">
                {t("general.guests")}
            </h3>
            <Table
                columns={guestsColumns}
                dataSource={reservation?.guests}
                pagination={false}
                bordered
                size="small"
                rowKey={(record) => record.id}
            />
            {/*<Row gutter={[16, 16]} style={{"marginBottom":"1rem"}}>
                <Col span={24}>
                    <h4 style={{color:'#40A9FF'}}>
                        {t("general.guest_list")}
                    </h4>
                    <p> {t("general.room")} 1 - {t("general.room_type")} : { reservations?.room_detail?.active_language?.name} : { reservations?.concept?.concept?.active_language?.name}</p>
                    <hr />
                    {reservations?.reservationPeople?.map(item =>{
                        return(
                            <Row key={item.id} justify={'space-between'}>
                                <Col span={6}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <h5>{t("general.full_name")}</h5>
                                        <p style={{overflow:'auto'}}>{item.first_name +' '+ item.last_name} {item?.type === 2 && "(Çocuk)"}</p>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <h5>{t("general.nationality")}</h5>
                                        <p>
                                            {
                                                item?.country_of_birth ?
                                                    item?.country_of_birth?.nationality:
                                                    ""
                                            }
                                        </p>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <h5>{t("general.birth_date")}</h5>
                                        <p>{moment(item.birth_date).format('DD.MM.YYYY')}</p>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <h5>{t("general.contact_info")}</h5>
                                        {item?.phone_number || item?.email ?
                                            <p>{item?.phone_number} | {item?.email}</p>:
                                            <p>-</p>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        )

                    })

                    }

                </Col>
            </Row>*/}
        </div>
    );
};

const DetailCard = ({ title, content}) => {
    return (
        <div className="border-solid border-2 border-slate-300 p-4 rounded-lg flex flex-col hover:bg-slate-100 transition-colors">
            <span className="text-base font-normal">
                {title}
            </span>
            <span className="text-xl font-medium">
               {content}
            </span>
        </div>
    )
}

export default GeneralTab;