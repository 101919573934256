import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useParams} from "react-router-dom";
import {CMConditionService, CMChildAgeService} from "@services";
import CmConditionCreateModalForm from "@components/channel-management/CMConditionCreateModalForm";

const {Content} = Layout;

const CMConditionList = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [conditions, setConditions] = React.useState({
        data: [],
        meta: {},
        hotel: {}
    });
    const [columns, setColumns] = React.useState([
        {
            title: 'Kondüsyon ID',
            dataIndex: 'id',
            key: 'id',
            width: 200,
        },
        {
            title: 'Kondüsyon Adı',
            dataIndex: 'title',
            key: 'title',
            render: (text) => text
        }
    ]);
    const [childAges, setChildAges] = React.useState([]);
    const [form] = Form.useForm();
    const params = useParams()
    const getChildAges = async (hotelId, page) => {
        setIsLoading(true)
        try{
            let {data: response} = await CMChildAgeService.get(hotelId, page);
            setChildAges(response.data);
            let rows = response.data.map((childAge) => {
                return {
                    title: childAge.name,
                    dataIndex: 'child_ages',
                    key: childAge.id,
                    render: (text) => text.find(item => item.child_id === childAge.id)?.number_of_children || 0
                }
            })
            setColumns([
                ...columns,
                ...rows,
            ])
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }
    const getConditions = async (conditionGroupId, hotelId, page) => {
        setIsLoading(true)
        try{
            let {data: response} = await CMConditionService.get(conditionGroupId, hotelId, page);
            setConditions({
                data: response.data,
                meta: response.meta,
                hotel: response.hotel
            });
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const onCreate = async (values) => {
        values = {...values, hotel_id: params.hotelId, condition_group_id: params.conditionGroupId}
        try {
            let response = await CMConditionService.create(params.conditionGroupId, values);
            setConditions({
                ...conditions,
                data: [...conditions.data, response.data.data]
            });
            setIsModalVisible(false);
            form.resetFields();
            notification.success({
                message: response.data.message,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    useEffect(() => {
        getConditions(params.conditionGroupId, params.hotelId);
        getChildAges(params.hotelId, 'all');
    },[params.hotelId, params.conditionGroupId]);
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/channel-management/hotels">
                        Kanal Yönetimi - Tesisler
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/channel-management/hotels/${conditions?.hotel?.id}/condition-groups`}>
                        Kondüsyon Grupları
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {conditions?.hotel?.name ?? '...'} - Kondüsyon Listesi
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" className="mb-10" onClick={() => setIsModalVisible(true)}>Yeni Kondüsyon Ekle</Button>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={conditions.data}
                                loading={isLoading}
                                pagination={{
                                    pageSize: conditions?.meta?.per_page,
                                    total: conditions?.meta?.total,
                                    current: conditions?.meta?.current_page,
                                }}
                                onChange={(pagination) => getConditions(params.conditionGroupId, params.hotelId, pagination.current)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
            <CmConditionCreateModalForm
                isVisible={isModalVisible}
                onFinish={onCreate}
                onCancel={() => setIsModalVisible(false)}
                form={form}
                title="Yeni Kondüsyon Oluştur"
                childAges={childAges}
            />
        </Wrapper>
    );
};

export default CMConditionList;