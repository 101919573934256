import React from 'react';
import {Breadcrumb, Button, Col, Layout, Row, Table, Form, notification, Space, Tooltip, Popconfirm} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import FlightModalForm from "@components/definitions/FlightModalForm";
import { FlightService } from '@services';
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

const {Content} = Layout;

const Flights = () => {
    const [createFlightForm] = Form.useForm();
    const [copyFlightForm] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(true);
    const [flights, setFlights] = React.useState([]);
    const [selectedFlight, setSelectedFlight] = React.useState([]);
    const [isCopyModalVisible, setIsCopyModalVisible] = React.useState(false);
    const [isCreateModalVisible, setIsCreateModalVisible] = React.useState(false);

    const getFlights = async () => {
        try {
            let {data: response} = await FlightService.get();
            setFlights(response.data);
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setIsLoading(false);
        }
    }

    const onCreate = async (values) => {
        values = {
            ...values,
            departure_time: values.departure_time.format('HH:mm:ss'),
            arrival_time: values.arrival_time.format('HH:mm:ss')
        }
        try {
            let {data: response} = await FlightService.create(values);
            if(response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setFlights([...flights, response.data])
                setIsCreateModalVisible(false);
                createFlightForm.resetFields();
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }
    const onDelete = async (id) => {
        try {
            let {data: response} = await FlightService.delete(id);
            if(response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setFlights(flights.filter(flight => flight.id !== id))
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onCopy = (record) => {
        copyFlightForm.setFieldsValue({
            ...record,
            incoming_airport_id: record.incoming_airport.id,
            outgoing_airport_id: record.outgoing_airport.id,
            departure_time: moment(record.departure_time, 'HH:mm:ss'),
            arrival_time: moment(record.arrival_time, 'HH:mm:ss')
        })
        setIsCopyModalVisible(true);
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Kalkış Havalimanı',
            dataIndex: 'incoming_airport',
            key: 'incoming_airport',
            render: (text) => text?.name || <span style={{color:"red"}}>Silinmiş Havalimanı</span>
        },
        {
            title: 'Varış Havalimanı',
            dataIndex: 'outgoing_airport',
            key: 'outgoing_airport',
            render: (text) => text?.name || <span style={{color:"red"}}>Silinmiş Havalimanı</span>
        },
        {
            title: 'Kalkış Saati',
            dataIndex: 'departure_time',
            key: 'departure_time',
        },
        {
            title: 'Varış Saati',
            dataIndex: 'arrival_time',
            key: 'arrival_time',
        },
        {
            title: 'Kapasite',
            dataIndex: 'capacity',
            key: 'capacity',
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (text, record) => (
                <Space direction="horizontal">
                    <Tooltip title="Kopyala">
                        <Button
                            type="primary"
                            htmlType="button"
                            onClick={() => onCopy(record)}
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </Tooltip>
                    <Tooltip>
                        <Popconfirm
                            title="Silmek istediğinize emin misiniz?"
                            onConfirm={() => onDelete(record.id)}
                            okText="Evet"
                            cancelText="İptal"
                        >
                            <Button type="danger" htmlType="button">
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ];

    React.useEffect(() => {
        getFlights()
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Uçuşlar</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]} style={{
                        marginBottom: '10px'
                    }}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={() => setIsCreateModalVisible(true)}>Uçuş Tanımla</Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={flights}
                        pagination={false}
                        loading={isLoading}
                        rowKey={record => record.id}
                    />
                </div>
            </Content>
            <FlightModalForm
                title="Yeni Uçuş Tanımla"
                form={createFlightForm}
                isVisible={isCreateModalVisible}
                onCancel={() => setIsCreateModalVisible(false)}
                onFinish={onCreate}
            />
            <FlightModalForm
                title="Uçuş Kopyala"
                form={copyFlightForm}
                isVisible={isCopyModalVisible}
                onCancel={() => setIsCopyModalVisible(false)}
                onFinish={(values) => onCreate(values).then(() => {
                    setIsCopyModalVisible(false);
                    copyFlightForm.resetFields();
                })}
                data={selectedFlight}
            />
        </Wrapper>
    );
};

export default Flights;