import React from 'react';
import {Col, DatePicker, Divider, Form, Input, Modal, notification, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";
import {ReservationServiceV2} from "@services";

const ReservationCreateFormModal = (props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation()
    const onCreate = async (selectedRoom, values) => {
        try {
            let {data: response} = await ReservationServiceV2.create(selectedRoom.hotel_id, {
                ...values,
                reservation_key: selectedRoom.search_key,
            })
            if (response.success){
                notification.success({
                    message: t("general.success"),
                })
                props.onCancel()
                form.resetFields()
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message,
                })
            }
        }
    }
    return (
        <Modal
            visible={props.isVisible}
            title={props.title}
            closable={false}
            onCancel={props.onCancel}
            onOk={() => form.submit()}
            okText={t("general.save")}
            cancelText={t("general.cancel")}
            width={1000}
        >
            <Form form={form} layout="vertical" onFinish={(values) => onCreate(props.selectedRoom, values)}>
                {[...Array(props.selectedRoom?.condition?.adults)].map((_, index) => (
                    <Row gutter={[16,0]} key={index}>
                        <Col span={24}>
                            <h3>{index + 1}. {t("general.adults")}</h3>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["adults", index, "gender"]}
                                label={t("general.gender")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                                initialValue="Erkek"
                            >
                                <Radio.Group>
                                    <Radio value="Erkek">{t("general.male")}</Radio>
                                    <Radio value="Kadin">{t("general.female")}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["adults", index, "first_name"]}
                                label={t("general.first_name")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t("general.first_name")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["adults", index, "last_name"]}
                                label={t("general.last_name")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t("general.last_name")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["adults", index, "email"]}
                                label={t("general.email")}
                                rules={[
                                    {
                                        required: index === 0,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t("general.email")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["adults", index, "phone"]}
                                label={t("general.phone")}
                                rules={[
                                    {
                                        required: index === 0,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t("general.phone")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["adults", index, "birth_date"]}
                                label={t("general.birth_date")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                            >
                                <DatePicker
                                    placeholder={t("general.birth_date")}
                                    style={{width: "100%"}}
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                ))}
                <Divider/>
                {[...Array(props.selectedRoom?.condition?.children)].map((_, index) => (
                    <Row gutter={[16,0]} key={index}>
                        <Col span={24}>
                            <h3>{index + 1}. {t("general.children")}</h3>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["children", index, "gender"]}
                                label={t("general.gender")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                                initialValue="Erkek"
                            >
                                <Radio.Group>
                                    <Radio value="Erkek">{t("general.male")}</Radio>
                                    <Radio value="Kadin">{t("general.female")}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['children', index, 'first_name']}
                                label={t("general.first_name")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t("general.first_name")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['children', index, 'last_name']}
                                label={t("general.last_name")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t("general.last_name")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['children', index, 'birth_date']}
                                label={t("general.birth_date")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("general.cannot_be_empty"),
                                    }
                                ]}
                            >
                                <DatePicker
                                    placeholder={t("general.birth_date")}
                                    style={{width: "100%"}}
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                ))}
            </Form>
        </Modal>
    );
};

export default ReservationCreateFormModal;