import React, { useEffect, useState } from 'react';
import {
    Layout,
    Breadcrumb,
    Button,
    Table,
    notification,
    Form,
    Space,
    Popconfirm
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faEdit, faTrash, faPlus, faCode } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import WidgetsModalForm from '@components/widgets/WidgetModalForm';
import GenerateCodeModalForm from '@components/widgets/GenerateCodeModalForm';
import { WidgetsService } from '@services';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

let subdomainName = '';
const { Content } = Layout;

const Widgets = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();

    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [generateCodeVisible, setGenerateCodeVisible] = useState(false)
    const [widgets, setWidgets] = useState({});

    const [createForm] = Form.useForm();
    const [createModalVisible, setCreateModalVisible] = useState(false);

    const [selectedPeriod, setSelectedPeriod] = useState({});

    const [editForm] = Form.useForm();
    const [editModalVisible, setEditModalVisible] = useState(false);

    const columns = [
        {
            key: 'index',
            title: '#',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t('general.widget_name')}`,
            dataIndex: 'widget_name'
        },
        {
            title: `${t('general.subdomain_name')}`,
            dataIndex: 'subdomain_name'
        },
        {
            title: 'Hotel id',
            dataIndex: 'hotel_id'
        },
        {
            key: 'actions',
            title: `${t('general.actions')}`,
            render: (text, record) => (
                <Space align="middle">
                    <Button type="primary" onClick={() => onShow(record.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Popconfirm title="Silmek istediğine emin misin?" onConfirm={() => onDelete(record.id)} okText="Sil" cancelText="İptal">
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Popconfirm>
                    <Button type="primary" onClick={() => showWidgetCode(record.subdomain_name)}>
                        <FontAwesomeIcon icon={faCode} />
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        getWidgets();
    }, []);

    const getWidgets = async (page = 1) => {
        try {
            let { data: response } = await WidgetsService.get(page);
            setWidgets(response);
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onCreate = async (values) => {
        try {
            let { data: response } = await WidgetsService.create(values);

            if (response.success) {
                notification.success({
                    message: response.message
                });
                setIsLoadingTable(true);
                getWidgets();
                setCreateModalVisible(false);
                createForm.resetFields();
                navigate(`/widgets`);
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }

            console.log(e);
        }
    }

    const onShow = async (id) => {

        try {
            let { data: response } = await WidgetsService.findById(id);
            let preparedInputs = [
                {
                    name:'widget_name',
                    value: response.data.widget_name,
                },
                {
                    name: 'hotel_id',
                    value: response.data.hotel_id,
                },
                {
                    name: 'subdomain_name',
                    value: response.data.subdomain_name,
                }
            ];

            setSelectedPeriod({
                ...response.data,
                fields: preparedInputs,
            });

            setEditModalVisible(true);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }

            console.log(e);
        }
    };

    const onUpdate = async (values, id) => {
        try {
            let { data: response } = await WidgetsService.update(id, values);

            if (response.success) {
                setIsLoadingTable(true);
                getWidgets();
                setEditModalVisible(false);

                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }

            console.log(e);
        }
    };

    const onDelete = async (id) => {
        try {
            let { data: response } = await WidgetsService.delete(id);

            if (response.success) {
                notification.success({
                    message: response.message
                });
                setIsLoadingTable(true);
                getWidgets();
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }

            console.log(e);
        }
    };

    const showWidgetCode = (id) => {
        setGenerateCodeVisible(true);
        subdomainName = id;
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Periyotlar
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Button type="primary" className="mb-10" onClick={() => setCreateModalVisible(true)}>
                        <FontAwesomeIcon icon={faPlus} className="mr-1" />
                        Yeni Widget Tanımla
                    </Button>

                    <Table
                        columns={columns}
                        dataSource={widgets.data}
                        rowKey="id"
                        loading={isLoadingTable}
                        bordered
                        pagination={{
                            total: widgets.meta?.total,
                            defaultPageSize: widgets.meta?.per_page,
                            onChange: (page, pageSize) => {
                                setIsLoadingTable(true);
                                // getPeriods(page);
                            }
                        }}
                    />
                </div>
            </Content>

            <WidgetsModalForm
                form={createForm}
                isVisible={createModalVisible}
                title="Widget Oluştur"
                onFinish={onCreate}
                onCancel={() => {
                    setCreateModalVisible(false);
                    createForm.resetFields();
                }}
            />

            <WidgetsModalForm
                form={editForm}
                isVisible={editModalVisible}
                title="Widget Düzenle"
                onFinish={onUpdate}
                onCancel={() => {
                    setEditModalVisible(false);
                    editForm.resetFields();
                }}
                data={selectedPeriod}
            />
            <GenerateCodeModalForm
                isVisible={generateCodeVisible}
                title="Widget Kodu"
                subdomain={subdomainName}
                onCancel={() => {
                    setGenerateCodeVisible(false);
                }}
            />
        </Wrapper>
    );
};
export default Widgets;