const convertCurrencyCodeToSymbol = (currencyCode = "TRY") => {
    let formatter = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: currencyCode,
    });
    let parts = formatter.formatToParts(1);
    return parts.find((part) => part.type === 'currency').value || 'N/A';
}

export {
    convertCurrencyCodeToSymbol
}