import React, {useState} from 'react';
import {Breadcrumb, Button, Col, DatePicker, Form, Layout, notification, Row, Space, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useTranslation} from "react-i18next";
import {PaymentTableService} from "@services";
import HotelSelect from "@/components/utils/HotelSelect";

const {Content} = Layout;
const {RangePicker} = DatePicker;

const PaymentTable = () => {
    const { t } = useTranslation();
    const [paymentTable, setPaymentTable] = useState({
        data: [],
        loading: false
    })
    const [searchForm] = Form.useForm();
    const getPaymentTable = async (values) => {
        let start_date = values?.daterange[0].format("YYYY-MM-DD");
        let end_date = values?.daterange[1].format("YYYY-MM-DD");
        let hotel = values.hotels || null;
        setPaymentTable({
            ...paymentTable,
            loading:true
        })
        try {
            let {data: response} = await PaymentTableService.get(start_date, end_date, hotel);
            setPaymentTable({
                data: response.data,
                total_price: response.total_price,
                total_discounted_sale_price: response.total_discounted_sale_price,
                total_deferred_remaining_amount: response.total_deferred_remaining_amount,
                total_cost: response.total_cost,
                total_profit_margin: response.total_profit_margin,
                total: response.total,
                loading:false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: t("general.error"),
                    description: e.response.data.message
                })
            }
            setPaymentTable({
                ...paymentTable,
                loading:false
            })
        }
    }
    const columns = [
        {
            key: "reservation_id",
            title: t("general.reservation_number"),
            dataIndex: "reservation_id",
            render: (text) => <Button type="primary" htmlType="button" target="_blank" href={`/reservation-card/${text}`}>
                {text}
            </Button>
        },
        {
            key: "hotel_name",
            title: t("general.hotel_name"),
            dataIndex: "hotel_name",
        },
        {
            key: "calculation_code",
            title: t("general.payment_type"),
            dataIndex: "calculation_code",
            render: (text, record) => {
                switch (text) {
                    case "payment_on_check_in_date":
                        return <Tag color="volcano">
                            {record.calculation_type}
                            {record.calculation_description && <>
                                <br/>
                                <small>{record.calculation_description}</small>
                            </>}
                        </Tag>
                    case "payment_on_check_out_date":
                        return <Tag color="purple">
                            {record.calculation_type}
                            {record.calculation_description && <>
                                <br/>
                                <small>{record.calculation_description}</small>
                            </>}
                        </Tag>
                    default:
                        return <Tag color="green">
                            {record.calculation_type}
                            {record.calculation_description && <>
                                <br/>
                                <small>{record.calculation_description}</small>
                            </>}
                        </Tag>
                }
            }
        },
        {
            key: "payment_date",
            title: t("general.payment_date"),
            dataIndex: "payment_date"
        },
        {
            key: "payment_rate",
            title: t("general.payment_rate"),
            dataIndex: "payment_rate",
            render: (text, record) => "%" + text
        },
        {
            key: "discounted_sale_price",
            title: t("general.discounted_sale_price"),
            dataIndex: "discounted_sale_price",
            render: (text, record) => text + " " + record.currency
        },
        {
            key: "deferred_remaining_amount",
            title: t("general.remaining_deferred_payment"),
            dataIndex: "deferred_remaining_amount",
            render: (text, record) => text + " " + record.currency
        },
        {
            key: "price",
            title: t("general.amount_to_be_paid"),
            dataIndex: "price",
            render: (text, record) => text.toFixed(2) + " " + record.currency
        },
        {
            key: "cost",
            title: t("general.cost"),
            dataIndex: "cost",
            render: (text, record) => text.toFixed(2) + " " + record.currency
        },
        {
            key: "profit_margin",
            title: t("general.profit_margin"),
            dataIndex: "profit_margin",
            render: (text, record) => text.toFixed(2) + " " + record.currency
        }
    ];
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.payment_table")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Form form={searchForm} layout="vertical" onFinish={(values) => getPaymentTable(values)}>
                        <Row gutter={[16,16]}>
                            <Col span={6}>
                               <Form.Item
                                      label={t("general.date_range")}
                                      name="daterange"
                                      rules={[{
                                          required: true,
                                          message: t("general.select")
                                      }]}
                               >
                                   <RangePicker
                                       format="DD.MM.YYYY"
                                       placeholder={[t("general.start_date"), t("general.end_date")]}
                                       style={{width: "100%"}}
                                   />
                               </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={t("general.hotels")}
                                    name="hotels"
                                >
                                    <HotelSelect
                                        placeholder={t("general.select")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary">
                                        {t("general.search")}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        columns={columns}
                        dataSource={paymentTable.data}
                        loading={paymentTable.loading}
                        footer={() => <div>
                            {paymentTable.total_price?.map((item, index) => <h3 key={index}>
                                {t("general.total_payable_amount")}: {item.total_price} {item.currency}<br/>
                            </h3>)}
                            {paymentTable.total_discounted_sale_price?.map((item, index) => <h3 key={index}>
                                {t("general.total_discounted_sale_price")}: {item.total_discounted_sale_price} {item.currency}<br/>
                            </h3>)}
                            {paymentTable.total_deferred_remaining_amount?.map((item, index) => <h3 key={index}>
                                {t("general.total_deferred_remaining_amount")}: {item.total_deferred_remaining_amount} {item.currency}<br/>
                            </h3>)}
                            {paymentTable.total_cost?.map((item, index) => <h3 key={index}>
                                {t("general.total_cost")}: {item.total_cost} {item.currency}<br/>
                            </h3>)}
                            {paymentTable.total_profit_margin?.map((item, index) => <h3 key={index}>
                                {t("general.total_profit_margin")}: {item.total_profit_margin} {item.currency}<br/>
                            </h3>)}
                            {paymentTable?.total && (
                                <h3>
                                    ({paymentTable?.total} {t("general.payment")})
                                </h3>
                            )}
                        </div>}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default PaymentTable;