import React from 'react';
import {Breadcrumb, Layout} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
const {Content} = Layout;

const LaraBeachExcursionsDashboard = () => {
    const { t } = useTranslation();
    return (
        <Wrapper title="Dashboard">
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.home')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                </div>
            </Content>
        </Wrapper>
    );
};

export default LaraBeachExcursionsDashboard;