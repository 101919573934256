import React, {useEffect} from 'react';
import Wrapper from '@components/Wrapper';
import {Layout, Breadcrumb, Row, Col, Card, notification, Statistic} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import {DashboardService} from "@services";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import LaraBeachExcursionsDashboard from "./LaraBeachExcursionsDashboard";

const { Content } = Layout;

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  const [targets, setTargets] = React.useState({
    data:[],
    isLoading: true
  });
  const [paidPendingReservations, setPaidPendingReservations] = React.useState({
    data:[],
    isLoading: true
  });

  const getDashboard = async () => {
      try {
        let { data: {data: response } } = await DashboardService.get();
        setData(response)
      }catch(e){
        if (e.response) {
          //
        }
      }
  }
  const getTargets = async () => {
    try {
      let {data: response} = await DashboardService.getTargets();
        setTargets({
            data: response.data,
            isLoading: false
        })
    }catch (e) {
      if (e.response) {
        //
      }
    }
  }
  const getPaidPendingReservations = async () => {
    try {
      let {data: response} = await DashboardService.getPaidPendingReservations();
        setPaidPendingReservations({
            data: response.data,
            isLoading: false
        })
    }catch (e) {
      if (e.response) {
        //
      }
    }
  }
  useEffect(() => {
    getDashboard().finally(() => setLoading(false))
    getTargets()
    getPaidPendingReservations()
  },[])
  if (user.user.agency_code === "lara-beach-excursions"){
    return <LaraBeachExcursionsDashboard />
  }
  return (
    <Wrapper title="Dashboard">
      <Content className="m-25">
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('general.home')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="site-layout-background">
          <Row gutter={[16,16]}>
            {targets?.data?.length > 0 && (
                <Col span={24}>
                  <Card title={t('general.user_targets')} loading={targets.isLoading}>
                    <Row gutter={[16,0]}>
                      {targets?.data?.map((target,i) => (
                          <Col span={8} key={i}>
                            <h4 style={{ color: target?.status?.completed ? "#22C55E": "#000000D9" }}>{target?.name} ({target?.ending})</h4>
                            <p style={{ color: target?.status?.completed ? "#22C55E": "#000000D9" }}>{target?.status?.progress}</p>
                          </Col>
                      ))}
                    </Row>
                  </Card>
                </Col>
            )}
            <Col span={8}>
              <Card title={t('general.operation')} style={{aspectRatio:"1/1"}} loading={loading}>
                <p style={{marginBottom:"0.5rem"}}>
                  {t("dashboard.closest_reservations", {count: data?.today_reservations?.count || 0, date: data?.today_reservations?.date || new Date().toLocaleDateString()})}
                </p>
                {data?.today_reservations?.data?.map((res,i) => (
                    <li key={i} style={{marginBottom:"10px"}}>
                      <Link to={`/reservation-card/${res?.uuid}`} target="_blank">{res?.uuid} - {res?.first_name} {res?.last_name}</Link>
                    </li>
                ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card title={t('general.accounting')} style={{aspectRatio:"1/1",overflowY:"scroll"}} loading={loading}>
                <Link to="/deferred-reservations" target="_blank">
                  {t("dashboard.due_reservations", {passed: data?.today_deferred_payments?.passed?.count || 0, upcoming: data?.today_deferred_payments?.upcoming?.count || 0})}
                </Link>
                {data?.today_deferred_payments?.upcoming?.count > 0 && (
                    <div style={{marginTop: "16px"}}>
                      <h3>{t("general.upcoming")}</h3>
                      {data?.today_deferred_payments?.upcoming?.data?.map((res,i) => (
                          <li key={i} style={{marginBottom:"10px"}}>
                            <Link to={`/reservation-card/${res?.reservation_id}`} target="_blank">{res?.reservation_id} - {res?.guest} (<span style={{color:"red"}}>{res?.fall_due}</span> - {res?.check_in}) ({res?.agent}) ({res?.amount} {res?.currency})</Link>
                          </li>
                      ))}
                    </div>
                )}
                {data?.today_deferred_payments?.passed?.count > 0 && (
                    <div style={{marginTop: "16px"}}>
                      <h3>{t("general.passed")}</h3>
                      {data?.today_deferred_payments?.passed?.data?.map((res,i) => (
                          <li key={i} style={{marginBottom:"10px"}}>
                            <Link to={`/reservation-card/${res?.reservation_id}`} target="_blank">{res?.reservation_id} - {res?.guest} (<span style={{color:"red"}}>{res?.fall_due}</span> - {res?.check_in}) ({res?.agent}) ({res?.amount} {res?.currency})</Link>
                          </li>
                      ))}
                    </div>
                )}
              </Card>
            </Col>
            <Col span={8}>
              <Card title={t('general.confirm')} style={{aspectRatio:"1/1",overflowY:"scroll"}} loading={loading}>
                <p style={{marginBottom:"0.5rem"}}>
                  {t("dashboard.pending_confirm", {count: data?.pending_confirm?.count || 0})}
                </p>
                {data?.pending_confirm?.data?.map((res,i) => (
                    <li key={i} style={{marginBottom:"10px"}}>
                      <Link to={`/reservation-card/${res?.uuid}`} target="_blank">{res?.uuid} - {res?.first_name} {res?.last_name}</Link>
                    </li>
                ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card title={`${t('general.exchange_rate')} ${data?.currency_rates?.[0]?.date ? `(${data?.currency_rates?.[0]?.date})`: ''}`}>
                {data?.currency_rates?.[0]?.rates?.map((rate,i) => (
                    <Row gutter={[16,16]} key={i}>
                      <Col span={24}>
                        <Row>
                          <Col span={12}>
                            <Statistic
                                title={`${rate?.name} (${t('general.buy')}`}
                                value={rate?.buying}
                                precision={2}
                                valueStyle={{ color: '#000000D9' }}
                                prefix={rate?.symbol}
                            />
                          </Col>
                          <Col span={12}>
                            <Statistic
                                title={`${rate?.name} (${t('general.sell')}`}
                                value={rate?.selling}
                                precision={2}
                                valueStyle={{ color: '#000000D9' }}
                                prefix={rate?.symbol}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card title={t('general.paid_pending_reservations')} style={{aspectRatio:"1/1",overflowY:"scroll"}} loading={loading}>
                {paidPendingReservations?.data?.map((res,i) => (
                    <li key={i} style={{marginBottom:"10px"}}>
                      <Link to={`/reservation-card/${res?.uuid}`} target="_blank">{res?.uuid} - {res?.guest_name} ({res?.check_in}) ({res?.agent  || "Widget"}) ({res?.amount} {res?.currency})</Link>
                    </li>
                ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card title={t('general.transfers')} style={{aspectRatio:"1/1",overflowY:"scroll"}} loading={loading}>
                <p style={{marginBottom:"0.5rem"}}>
                  {t("dashboard.closest_transfers", {count: data?.closest_transfers?.count || 0})}
                </p>
                {data?.closest_transfers?.data?.map((res,i) => (
                    <li key={i} style={{marginBottom:"10px"}}>
                      <Link to={`/reservation-card/${res?.reservation_uuid}`} target="_blank">{res?.reservation_uuid} - {res?.date} ({res?.type})</Link>
                    </li>
                ))}
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Dashboard;