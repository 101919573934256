import React, {useEffect, useState} from 'react';
import {
    Alert,
    Breadcrumb,
    Button,
    Col,
    Form,
    InputNumber,
    Layout,
    notification,
    Row,
    Select,
    Space,
    Switch,
    Table
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHouse, faMinus} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import { LaraBeachTransferService, LaraBeachPointService, LaraBeachVehicleService } from "@/services";
import TransferModalForm from "@/components/lara-beach-excursions/modals/TransferModalForm";

const {Content} = Layout;

const TransfersPage = () => {
    const { t } = useTranslation()
    const [tableIsLoading, setTableIsLoading] = useState(true)
    const [createForm] = Form.useForm()
    const [editForm] = Form.useForm()
    const [error, setError] = useState({
        message: "",
        row: null
    })
    const [transfers, setTransfers] = useState({
        data: [],
        meta: {},
        loading: true
    })
    const [points, setPoints] = useState({
        data: [],
        loading: true
    })
    const [vehicles, setVehicles] = useState({
        data: [],
        loading: true
    })
    const [publishChanging, setPublishChanging] = useState(false)
    const [selectedTransfer, setSelectedTransfer] = useState({})
    const [isTransferCreateModalVisible, setIsTransferCreateModalVisible] = useState(false)
    const [isTransferEditModalVisible, setIsTransferEditModalVisible] = useState(false)
    const columns = [
        {
            title: "",
            key: "remove",
            width: 50,
            render: (text, record) => <Button type="primary" danger shape="circle" onClick={() => {
                setTransfers(prev => ({
                    ...prev,
                    data: prev.data.filter(item => item.id !== record.id)
                }))
            }}>
                <FontAwesomeIcon icon={faMinus} />
            </Button>
        },
        {
            title: t("general.is_published"),
            dataIndex: "is_published",
            key: "is_published",
            width: 100,
            render: (text, record) => <Switch disabled={record?.notSaved} loading={publishChanging} defaultChecked={record.is_published} onChange={() => onTransferIsPublishedChange(record.id)} />
        },
        {
            title: t("general.pickup_location"),
            dataIndex: "pickup_location",
            key: "pickup_location",
            width: 100,
            render: (text, record) => <Select onChange={(value) => onTransferStartPointChange(record.id, value)} defaultValue={record.start_point_id || undefined} style={{ width: "100%" }} placeholder={t("general.regions")}>
                {points.data.map(point => (
                    <Select.Option key={point.id} value={point.id}>{point.name}</Select.Option>
                ))}
            </Select>
        },
        {
            title: t("general.drop_off_location"),
            dataIndex: "drop_off_location",
            key: "drop_off_location",
            width: 120,
            render: (text, record) => <Select onChange={(value) => onTransferEndPointChange(record.id, value)} defaultValue={record.end_point_id || undefined} style={{ width: "100%" }} placeholder={t("general.regions")}>
                {points.data.map(point => (
                    <Select.Option key={point.id} value={point.id}>{point.name}</Select.Option>
                ))}
            </Select>
        },
        ...vehicles.data.map(vehicle => ({
            title: vehicle.name,
            dataIndex: vehicle.name,
            key: vehicle.name,
            render: (text, record) => <InputNumber
                defaultValue={record.prices?.find(price => price.vehicle_id === vehicle.id)?.price || 0}
                onChange={(value) => onTransferPriceChange(record.id, vehicle.id, value)}
                min={0}
                placeholder={t("general.price")}
                style={{ width: "100%" }}
                addonAfter={<Select defaultValue={record.prices?.find(price => price.vehicle_id === vehicle.id)?.currency_id || "EUR"} style={{ width: 80 }} onChange={(value) => onTransferPriceCurrencyChange(record.id, vehicle.id, value)}>
                    <Select.Option value="EUR">EUR</Select.Option>
                    <Select.Option value="USD">USD</Select.Option>
                    <Select.Option value="TRY">TRY</Select.Option>
                    <Select.Option value="GBP">GBP</Select.Option>
                </Select>}
            />
        })),
    ]
    const addTransfer = () => {
        setTransfers(prev => ({
            ...prev,
            data: prev.data.concat({
                id: Math.floor(Math.random() * 100) + new Date().getTime(),
                start_point_id: null,
                end_point_id: null,
                prices: [],
                notSaved: true
            })
        }))
    }
    const onCreate = async () => {
        let error = false
        let row = null
        transfers.data.map((transfer, index) => {
            if (transfer.start_point_id === transfer.end_point_id){
                error = "Alış ve bırakış noktaları aynı olan transferler bulunuyor. Lütfen kontrol ediniz."
                row = index
            }
            if (transfers.data.filter(item =>
                item.start_point_id === transfer.start_point_id && item.end_point_id === transfer.end_point_id
                || item.start_point_id === transfer.end_point_id && item.end_point_id === transfer.start_point_id
            ).length > 1){
                error = "Aynı alış ve bırakış noktalarına sahip birden fazla transfer bulunuyor. Lütfen kontrol ediniz."
                row = index
            }
        })
        if (error){
            setError({
                message: error,
                row: row
            })
            return
        }
        setError({
            message: "",
            row: null
        })
        let data = transfers.data.map(transfer => {
            let prices = transfer.prices || []
            return {
                start_point_id: transfer.start_point_id,
                end_point_id: transfer.end_point_id,
                prices: prices.map(price => ({
                    vehicle_id: price.vehicle_id,
                    price: price.price,
                    currency_id: price.currency_id || "EUR"
                }))
            }
        })
        try {
            let {data: response} = await LaraBeachTransferService.create({
                transfers: data
            })
            if (response.success){
                notification.success({
                    message: response.message
                })
                getTransfers()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }

    const onTransferIsPublishedChange = async (id) => {
        setPublishChanging(true)
        try {
            let {data: response} = await LaraBeachTransferService.changePublishStatus(id)
            if (response.success){
                notification.success({
                    message: response.message
                })
            }
            setPublishChanging(false)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setPublishChanging(false)
        }
    }
    const onTransferEndPointChange = (transferId, value) => {
        setTransfers(prev => ({
            ...prev,
            data: prev.data.map(transfer => {
                if (transfer.id === transferId) {
                    return {
                        ...transfer,
                        end_point_id: value
                    }
                }
                return transfer
            })}
        ))
    }
    const onTransferStartPointChange = (transferId, value) => {
        setTransfers(prev => ({
            ...prev,
            data: prev.data.map(transfer => {
                if (transfer.id === transferId) {
                    return {
                        ...transfer,
                        start_point_id: value
                    }
                }
                return transfer
            })}
        ))
    }
    const onTransferPriceChange = (transferId, vehicleId, value) => {
        let transfer = transfers.data.find(transfer => transfer.id === transferId)
        let prices = transfer.prices || []
        let priceIndex = prices.findIndex(price => price.vehicle_id === vehicleId)
        if (priceIndex === -1) {
            prices.push({
                vehicle_id: vehicleId,
                price: value
            })
        }else{
            prices[priceIndex].price = value
        }
    }
    const onTransferPriceCurrencyChange = (transferId, vehicleId, value) => {
        let transfer = transfers.data.find(transfer => transfer.id === transferId)
        let prices = transfer.prices || []
        let priceIndex = prices.findIndex(price => price.vehicle_id === vehicleId)
        if (priceIndex === -1) {
            prices.push({
                vehicle_id: vehicleId,
                currency_id: value
            })
        }else{
            prices[priceIndex].currency_id = value
        }
    }
    const getTransfers = async (page = 1, per_page = 10) => {
        setTransfers(prev => ({
            ...prev,
            loading: true
        }))
        try {
            let {data: response} = await LaraBeachTransferService.get(page, per_page)
            setTransfers({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setTransfers(prev => ({
                ...prev,
                loading: false
            }))
        }
    }
    const getPoints = async (page = 1, per_page = "all") => {
        setPoints(prev => ({
            ...prev,
            loading: true
        }))
        try {
            let {data: response} = await LaraBeachPointService.getParents(page, per_page)
            setPoints({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setPoints(prev => ({
                ...prev,
                loading: false
            }))
        }
    }
    const getVehicles = async (page = 1, per_page = "all") => {
        setVehicles(prev => ({
            ...prev,
            loading: true
        }))
        try {
            let {data: response} = await LaraBeachVehicleService.get(page, per_page)
            setVehicles({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setVehicles(prev => ({
                ...prev,
                loading: false
            }))
        }
    }
    useEffect(() => {
        Promise.all([
            getTransfers(),
            getPoints(),
            getVehicles()
        ]).then(() => {
            setTableIsLoading(false)
        });
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.transfers")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]} style={{ marginBottom: "14px" }}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={addTransfer}>
                                {t("general.create")}
                            </Button>
                        </Col>
                        <Col span={24}>
                            {error.message && (
                                <Alert message={`${error.row + 1}. Satır: ${error.message}`} type="warning" />
                            )}
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={transfers.data}
                        rowKey="id"
                        pagination={false}
                        scroll={{ x: 960 }}
                        loading={tableIsLoading}
                    />
                    <Row gutter={[16,16]} style={{ marginBottom: "14px" }}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={onCreate} style={{ width: "100%" }}>
                                {t("general.save")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default TransfersPage;