import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Popconfirm, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import { ReservationProviderService } from '@services';
import ReservationProviderModalForm from "@components/reservation-providers/ReservationProviderModalForm";

const {Content} = Layout;

const ReservationProvidersList = () => {
    const [reservationProviders, setReservationProviders] = useState({
        data: [],
        loading: false,
        meta: {}
    })
    const [createForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const columns = [
        {
            title: 'Adı',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Fatura Ünvanı",
            dataIndex: 'invoice_title',
            key: 'invoice_title',
        },
        {
            title: "Tabela Ünvanı",
            dataIndex: 'sign_title',
            key: 'sign_title',
        },
        {
            title: "Yetkili Adı",
            dataIndex: 'author_name',
            key: 'author_name',
        },
        {
            title: "Yetkili Telefon",
            dataIndex: 'author_phone',
            key: 'author_phone',
        },
        {
            title: "Yetkili Eposta",
            dataIndex: 'author_email',
            key: 'author_email',
        },
        {
            title: "Açıklama",
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: "İşlemler",
            key: "actions",
            render: (text, record) => (
                <Space>
                    <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => onDelete(record.id)} okText="Evet" cancelText="Hayır">
                        <Button type="danger" htmlType="button">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    const onDelete = async (id) => {
        try {
            let {data: response} = await ReservationProviderService.delete(id);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setReservationProviders(prevState => ({
                    ...prevState,
                    data: prevState.data.filter(item => item.id !== id)
                }))
            }
        }catch (e) {
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onSubmit = async (values) => {
        try {
            let {data: response} = await ReservationProviderService.create(values);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setIsModalVisible(false);
                createForm.resetFields();
                setReservationProviders(prevState => ({
                    ...prevState,
                    data: [response.data, ...prevState.data]
                }))
            }
        }catch (e) {
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getReservationProviders = async (page = 1, per_page = 10) => {
        setReservationProviders(prevState => ({
            ...prevState,
            loading: true
        }))
        try {
            let {data: response} = await ReservationProviderService.get(page, per_page);
            setReservationProviders({
                data: response.data,
                loading: false,
                meta: response.meta
            })
        }catch (e) {
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setReservationProviders(prevState => ({
                ...prevState,
                loading: false
            }))
        }
    }
    useEffect(() => {
        getReservationProviders();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Rezervasyon Sağlayıcıları
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={() => setIsModalVisible(true)}>
                                Yeni Sağlayıcı Ekle
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={reservationProviders.data}
                                rowKey={record => record.id}
                                loading={reservationProviders.loading}
                                bordered
                                pagination={{
                                    total: reservationProviders.meta?.total,
                                    defaultPageSize: reservationProviders.meta?.per_page,
                                    onChange: (page, pageSize) => getReservationProviders(page, pageSize)
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
            <ReservationProviderModalForm
                title="Yeni Rezervasyon Sağlayıcı Ekle"
                form={createForm}
                isVisible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onFinish={onSubmit}
            />
        </Wrapper>
    );
};

export default ReservationProvidersList;