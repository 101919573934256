import React from 'react';
import {Button, notification, Popover, Space} from "antd";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import { ReservationServiceV2 } from "@services";
import ActionButton from "@/views/new-version/reservation/components/documents/ActionButton";
import {useTranslation} from "react-i18next";

const VoucherDownload = ({ reservation }) => {
    const { t } = useTranslation();
    const [voucherLoading, setVoucherLoading] = React.useState(false);
    const getReservationVoucher = async (lang) => {
        setVoucherLoading(true)
        try {
            let response = await ReservationServiceV2.downloadVoucher(
                reservation.uuid,
                lang
            );
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            let fileName = `${reservation?.guests?.[0]?.full_name}_${reservation?.uuid}.pdf`
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            setVoucherLoading(false)
            return response.data;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message,
                });
            }
            setVoucherLoading(false)
        }
    }
    return (
        <Popover
            content={() => (
                <Space>
                    <Button htmlType="button" type="primary" onClick={() => getReservationVoucher("tr")}>TR</Button>
                    <Button htmlType="button" type="primary" onClick={() => getReservationVoucher("en")}>EN</Button>
                    <Button htmlType="button" type="primary" onClick={() => getReservationVoucher("de")}>DE</Button>
                    <Button htmlType="button" type="primary" onClick={() => getReservationVoucher("ru")}>RU</Button>
                </Space>
            )}
            trigger="click"
        >
            <ActionButton
                icon={faDownload}
                title={t("general.voucher")}
                loading={voucherLoading}
            />
        </Popover>
    );
};

export default VoucherDownload;