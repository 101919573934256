import React, {useEffect} from 'react';
import {Button, Col, Form, InputNumber, notification, Row, Select, Switch, Table, Tag} from "antd";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {SafeBankDefinitionService, ReservationPaymentsService} from "@services";

const {Option} = Select;

const PaymentLinksTab = ({ reservation }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [selectedVirtualPos, setSelectedVirtualPos] = React.useState(null);
    const [isBalanceCompletion, setIsBalanceCompletion] = React.useState(false);
    const [paymentLink, setPaymentLink] = React.useState(null);
    const [reservationPayments, setReservationPayments] = React.useState({
        data: [],
        loading: false
    });
    const [paymentLinks, setPaymentLinks] = React.useState({
        data: [],
        loading: false
    });
    const [virtualPoses, setVirtualPoses] = React.useState({
        data: [],
        loading: false
    });
    const onCreate = async (values) => {
        try{
            let { data: response } = await ReservationPaymentsService.createPaymentLink(reservation.uuid, values);
            if(response.success){
                setPaymentLink(response.data)
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSelectVirtualPos = (value) => {
        setSelectedVirtualPos(value);
        form.setFieldsValue({
            currency:undefined
        })
    }
    const getReservationPayments = async () => {
        setReservationPayments({ data: [], loading: true, error: false })
        try {
            let {data: response} = await ReservationPaymentsService.getByReservation(reservation.uuid)
            setReservationPayments({
                data: response.data,
                loading: false,
                error: false,
            })
        }catch (e){
            setReservationPayments({
                data: [],
                loading: false,
                error: true,
            })
        }
    }
    const getReservationPaymentLinks = async () => {
        setPaymentLinks({ data: [], loading: true });
        try{
            let {data: response} = await ReservationPaymentsService.getPaymentLink(reservation.uuid);
            setPaymentLinks({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            setPaymentLinks({
                data: [],
                loading: false
            })
        }
    }
    const paymentLinkLogsColumns = [
        {
            title: 'Kart Numarası',
            key:'card_number',
            dataIndex: 'card_number',
            render:(text) => text ?? "Tanımsız"
        },
        {
            title: 'Banka Mesajı',
            key:'message',
            dataIndex: 'message',
            render:(text) => text ?? "Tanımsız"
        },
        {
            title: 'Müşteri Ip Adresi',
            key:'ip',
            dataIndex: 'ip',
            render:(text) => text ?? "Tanımsız"
        },
        {
            title: 'Durum',
            key:'status',
            dataIndex: 'status',
            render:(text) => {
                if(text == 1){
                    return <Tag color="yellow">Bekleniyor</Tag>
                }
                if(text == 2){
                    return <Tag color="green">Başarılı</Tag>
                }
                return <Tag color="red">Başarısız</Tag>
            }
        },
        {
            title: 'İşlem Tarihi',
            key:'created_at',
            dataIndex: 'created_at',
            render:(text) => moment(text).format("DD.MM.YYYY HH:mm:ss")
        }
    ];
    const columns = [
        {
            title: `${t("general.link")}`,
            key: "url",
            dataIndex: "url",
            render: (text) => <a href={text} target="_blank" rel="noreferrer">Bağlantı</a>
        },
        {
            title: `${t("general.status")}`,
            key:'status',
            dataIndex: 'status',
            render:(text) => {
                let status = "Unknown"
                if (text === "pending"){
                    status = "Bekliyor"
                    return <Tag color="yellow">{status}</Tag>
                }
                if(text === "expired"){
                    status = "Zaman Aşımı"
                    return <Tag color="red">{status}</Tag>
                }
                if(text === "fail"){
                    status = "Başarısız"
                    return <Tag color="red">{status}</Tag>
                }
                if(text === "success"){
                    status = "Ödeme Alındı"
                    return <Tag color="green">{status}</Tag>
                }
                return <Tag color="yellow">{status}</Tag>
            }
        },
        {
            title: `${t("general.agent")}`,
            key:'user',
            dataIndex: 'created_by',
        },
        {
            title: `${t("general.amount")}`,
            key:'amount',
            dataIndex: 'amount',
            render:(text,record) => text.toLocaleString("tr-TR", { style: 'currency', currency: record.currency?.code })
        },
        {
            title: `${t("general.bank")}`,
            key:'bank',
            dataIndex: 'bank_name',
        },
        {
            title: `${t("general.installment")}`,
            key:'installments',
            dataIndex: 'installments',
            render:(text) => text ?? "Peşin"
        },
        {
            title: `${t("general.is_balance_completion")}`,
            key:'is_balance_completion',
            dataIndex: 'is_balance_completion',
            render:(text) => text ? <Tag color="green">Evet</Tag> : <Tag color="red">Hayır</Tag>
        },
        {
            title: `${t("general.balance_completion_payment")}`,
            key:'reservation_payment',
            dataIndex: 'reservation_payment',
            render:(text) => text?.name ?? "N/A"
        },
        {
            title: `${t("general.created_date")}`,
            key:'created_at',
            dataIndex: 'created_at',
            render:(text) => text
        },
        {
            title: `${t("general.language")}`,
            key:'locale',
            dataIndex: 'locale',
            render:(text) => t(`general.${text}`)
        },
        /*{
            title: `${t("general.actions")}`,
            key:'actions',
            render:(text,record) => (
                <Space>
                    <Popconfirm title="Bu işlemi sadece müşteriden ödemesini tamamladığına dair onay aldığınızda gerçekleştiriniz!" onConfirm={() => onCheckStatusPayment(record.id)}>
                        <Button type="primary" htmlType="button">
                            <FontAwesomeIcon icon={faRefresh}/>
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }*/
    ]
    useEffect(() => {
        const getSafeBankVirtualPoses = async () => {
            setVirtualPoses({ data: [], loading: true });
            try {
                let {data: response} = await SafeBankDefinitionService.getByReservationId(reservation.uuid, 7);
                setVirtualPoses({
                    data: response.data,
                    loading: false
                })
            } catch (e) {
                setVirtualPoses({
                    data: [],
                    loading: false
                })
            }
        }
        getSafeBankVirtualPoses();
        getReservationPayments();
        getReservationPaymentLinks();
    },[])
    return (
        <div>
            <Form form={form} layout="vertical" onFinish={onCreate}>
                <Row gutter={[16,4]}>
                    <Col span={5}>
                        <Form.Item
                            label={t("general.virtual_pos")}
                            name="virtual_pos_id"
                            rules={[{
                                required:true,
                                message:"Sanal pos seçilmedi!"
                            }]}
                        >
                            <Select
                                onSelect={onSelectVirtualPos}
                            >
                                {virtualPoses.data?.map((safeBank)=>(
                                    <Option key={safeBank.id} value={safeBank.id}>{safeBank.bank_name} - ({safeBank.title})</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="currency"
                            label={t("general.currencies")}
                            rules={[{
                                required:true,
                                message:"Para birimi seçilmedi!"
                            }]}
                        >
                            <Select placeholder="Lütfen para birimi seçiniz.">
                                {virtualPoses.data?.find((pos) => pos.id === selectedVirtualPos)?.currencies?.map((currency) => (
                                    <Option key={currency.id} value={currency.currency.id}>{currency.currency.code}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label={t("general.payment_amount")}
                            name="amount"
                            rules={[{
                                required:true,
                                message:"Ödeme miktarı giriniz!"
                            }]}
                        >
                            <InputNumber
                                placeholder="Ödeme miktarı giriniz."
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                min={0}
                                style={{width:"100%"}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label={t("general.installment")}
                            name="installment"
                        >
                            <InputNumber
                                min={2}
                                style={{width:"100%"}}
                                placeholder="Peşin için boş bırakınız."
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label={t("general.language")}
                            name="locale"
                        >
                            <Select
                                placeholder={t("general.choose")}
                            >
                                <Option value="tr">{t("general.turkish")}</Option>
                                <Option value="en">{t("general.english")}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label={t("general.is_balance_completion")}
                            name="is_balance_completion"
                            valuePropName="checked"
                        >
                            <Switch onChange={(value) => setIsBalanceCompletion(value)}/>
                        </Form.Item>
                    </Col>
                    {isBalanceCompletion && (
                        <Col span={3}>
                            <Form.Item
                                label={t("general.balance_completion_payment")}
                                name="reservation_payment_id"
                                rules={[{
                                    required:true,
                                    message:"Rezervasyon ödemesi seçilmedi!"
                                }]}
                            >
                                <Select>
                                    {reservationPayments.data?.map((payment) => {
                                        if(payment.approved === 1 && payment?.payment_method_id === 4){
                                            return(
                                                <Option key={payment.id} value={payment.id}>{payment?.account_label} <br/>
                                                    <span style={{fontSize:"12px"}}>({payment?.amount?.toLocaleString("tr-TR", { style: 'currency', currency: reservation?.currency })})</span> - <span style={{fontSize:"12px"}}>({moment(payment?.fall_due).format('DD.MM.YYYY')})</span>
                                                </Option>
                                            )
                                        }
                                        return null;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={24}>
                        <Button type="primary" htmlType="submit" style={{width:"100%"}}>
                            {t("general.create_payment_link")}
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Row gutter={[16,16]} className="my-6">
                <Col span={24}>
                    <Button type="secondary" style={{width:"100%"}} onClick={() => {navigator.clipboard.writeText(paymentLink).then(function (){
                        notification.success({
                            message:"Bağlantı kopyalandı!"
                        });
                    })}}>{paymentLink && paymentLink}</Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={paymentLinks.data}
                loading={paymentLinks.loading}
                bordered
                pagination={false}
                rowKey={(record) => record.id}
                expandable={{
                    expandedRowRender: (record) => (
                        <Table
                            title={() => <h4>Ödeme İşlemleri</h4>}
                            columns={paymentLinkLogsColumns}
                            dataSource={record?.transaction_logs}
                            rowKey={(record) => record.id}
                            pagination={false}
                            bordered
                        />
                    ),
                    rowExpandable: (record) => record?.transaction_logs?.length > 0,
                }}
            />
        </div>
    );
};

export default PaymentLinksTab;