import React from 'react';
import {Button, Checkbox, Dropdown, Menu, Popconfirm, Select, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faBars, faEdit, faList, faLock, faLockOpen, faRefresh, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const {Option} = Select;

const ReservationTableActions = ({
    data = {},
    getReservationById = () => {},
    onDeleteReservation = () => {},
    onListReservation = () => {},
    user = {},
    permissions = [],
    onCancelReservation = () => {},
    sendMailOnCancel = false,
    setSendMailOnCancel = () => {},
    onChangeLockReservation = () => {},
    onRestoreReservation = () => {},
    restoreCancelReservationStatus = 1,
    setRestoreCancelReservationStatus = () => {},
}) => {
    const { t } = useTranslation()
    const items = [
        {
            key: 'edit',
            content: t("general.edit"),
            title: t("general.edit"),
            icon: <FontAwesomeIcon icon={faEdit} style={{ color: "#1890FF" }}/>,
            props: {
                onClick: getReservationById,
            },
            disabled: false,
            visible: true,
        },
        /*{
            key: 'list-reservation',
            title: user?.is_super_admin && !data?.cl ? t("general.list"): "Bu işlem için yetkiniz bulunmuyor.",
            content: t("general.list"),
            confirmation: {
                title: "Rezervasyon kullanıcılar tarafından görüntülenmeye başlanacaktır. Emin misiniz?",
                onConfirm: onListReservation,
            },
            icon: <FontAwesomeIcon icon={faList} style={{ color: "#1890FF" }}/>,
            props: {},
            disabled: false,
            visible: user?.is_super_admin && !data?.cl,
        },
        {
            key: 'delete',
            title: permissions.includes('reservation.delete') ? t("general.delete"): "Bu işlem için yetkiniz bulunmuyor.",
            content: t("general.delete"),
            confirmation: {
                title: "Silmek istediğinize emin misiniz?",
                onConfirm: onDeleteReservation,
            },
            icon: <FontAwesomeIcon icon={faTrash} style={{ color: "#EF4444" }}/>,
            props: {},
            disabled: !permissions.includes('reservation.delete'),
            visible: true,
        },
        {
            key: 'cancel/restore',
            title: user?.is_super_admin ? t("general.cancel")+"/"+t("general.restore"): "Bu işlem için yetkiniz bulunmuyor.",
            content: data.status !== 6 ? t("general.cancel"): t("general.restore"),
            confirmation: {
                title: data.status !== 6 ? (
                    <span>
                        Rezervasyonu iptal etmek istediğinize emin misiniz?.<br/>
                        İptal E-postası gönderilsin mi?
                        <Checkbox checked={sendMailOnCancel} onChange={() => setSendMailOnCancel(!sendMailOnCancel)} style={{ marginLeft: 10 }}/>
                    </span>
                ) : (
                    <span>
                        Hangi durum'da güncellenecek?
                        <Select
                            placeholder="Rezervasyon durumu seçiniz."
                            defaultValue={restoreCancelReservationStatus}
                            style={{ width: "100%", marginTop: 10 }}
                            onChange={(e) => setRestoreCancelReservationStatus(e)}
                        >
                            <Option key={1} value={1}>{t("general.confirm_waiting_to_send")}</Option>
                            <Option key={2} value={2}>{t("general.confirm_pendings")}</Option>
                            <Option key={3} value={3}>{t("general.confirmed")}</Option>
                            <Option key={4} value={4}>{t("general.confirm_cancelled")}</Option>
                            <Option key={6} value={6}>{t("general.reservation_cancelled")}</Option>
                        </Select>
                    </span>
                ),
                onConfirm: data.status !== 6 ? onCancelReservation : onRestoreReservation,
            },
            icon: <FontAwesomeIcon icon={data.status !== 6 ? faBan: faRefresh} style={{ color: data.status !== 6 ? "#EF4444": "#22C55E" }}/>,
            props: {},
            disabled: !user?.is_super_admin,
            visible: true,
        },*/
        {
            key: 'lock',
            title: permissions.includes('reservation.lock.update') ? t("general.lock")+"/"+t("general.unlock"): "Bu işlem için yetkiniz bulunmuyor.",
            content: !data?.locked ? t("general.lock"): t("general.unlock"),
            confirmation: {
                title: "Kilit durumunu değiştirmek istediğinize emin misiniz?",
                onConfirm: onChangeLockReservation,
            },
            icon: <FontAwesomeIcon icon={!data?.locked ? faLock : faLockOpen} style={{ color: !data?.locked ? "#EF4444": "#22C55E" }}/>,
            props: {},
            disabled: !permissions.includes('reservation.lock.update'),
            visible: true,
        }
    ];
    return (
        <Dropdown
            overlay={
                <Menu>
                    {items.filter((n) => n.visible === true).map((item) => (
                        <Menu.Item key={item.key} disabled={item.disabled} {...item.props}>
                            {item.confirmation ? (
                                <Tooltip title={item.title} mouseLeaveDelay={0}>
                                    <Popconfirm
                                        title={item.confirmation.title}
                                        onConfirm={item.confirmation.onConfirm}
                                        okText="Evet"
                                        cancelText="Hayır"
                                        style={{ width: "100%", height: "100%" }}
                                        disabled={item.disabled}
                                    >
                                        <Space
                                            direction="horizontal"
                                            style={{ fontWeight: 500, padding: "6px 12px", width: "100%", height: "100%"}}
                                        >
                                            {item.icon}
                                            {item.content}
                                        </Space>
                                    </Popconfirm>
                                </Tooltip>
                            ) : (
                                <Tooltip title={item.title} mouseLeaveDelay={0}>
                                    <Space
                                        direction="horizontal"
                                        style={{ fontWeight: 500, padding: "6px 12px" }}
                                    >
                                        {item.icon}
                                        {item.content}
                                    </Space>
                                </Tooltip>
                            )}
                        </Menu.Item>
                    ))}
                </Menu>
            }
            trigger={['click']}
        >
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}>
                <Button type="primary" htmlType="button" style={{
                    margin: "auto 0",
                }}>
                    <FontAwesomeIcon icon={faBars} />
                </Button>
            </div>
        </Dropdown>
    );
};

export default ReservationTableActions;