import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Input,
  Layout,
  Select,
  Space,
  DatePicker,
  notification,
  Checkbox,
  Row,
  Table,
  Col,
  Skeleton,
} from "antd";
import Wrapper from "@components/Wrapper";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HotelService, ReservationService } from "../../services";
import {useTranslation} from "react-i18next";



const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Option } = Select;

function LoginExit() {
  const { t } = useTranslation()
  const [hotelNames, setHotels] = useState([]);
  const [hotelDistricts, setHotelDistricts] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [tableFilter, setTableFilter] = useState({
    sRT: "",
    dataSource: null,
    nameSearch: "",
  });
  const [reservations, setReservations] = useState([]);
  const columns = [
    {
      className:"table-padding text-center",
      key: "reservation_number",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.reservation_no")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "id",
      render: (text, record) => (
        <h1 type="primary" style={{ fontSize: "12px" }} block="true">
          {record.uuid}
        </h1>
      ),
    },
    {
      className:"table-padding text-center",
      key: "hotel_name",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.hotel")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "hotels",
      render: (text, record) => (
        <h1 style={{ fontSize: "12px" }}>{text.name}</h1>
      ),
    },
    {
      className:"table-padding text-center",
      key: "full_name",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.full_name")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "full_name",
      render: (text, record) => <h1 style={{ fontSize: "12px" }}>{text}</h1>,
    },
    {
      className:"table-padding text-center",
      key: "phone_number",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.contact_number")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "phone_number",
      render: (text, record) => <h1 style={{ fontSize: "12px" }}>{text}</h1>,
    },
    {
      className:"table-padding text-center",
      key: "email",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.email")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "email",
      render: (text, record) => <h1 style={{ fontSize: "12px" }}>{text}</h1>,
    },
    {
      className:"table-padding text-center",
      key: "check_in",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.check_in")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "check_in",
      render: (text, record) => <h1 style={{ fontSize: "12px" }}>{text}</h1>,
    },
    {
      className:"table-padding text-center",
      key: "check_out",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.check_out")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "check_out",
      render: (text, record) => <h1 style={{ fontSize: "12px" }}>{text}</h1>,
    },
    {
      className:"table-padding text-center",
      key: "adult_number",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.adult_count")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "adult_number",
      render: (text, record) => <h1 style={{ fontSize: "12px" }}>{text}</h1>,
    },
    {
      className:"table-padding text-center",
      key: "child_number",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.child_count")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "child_number",
      render: (text, record) => <h1 style={{ fontSize: "12px" }}>{text}</h1>,
    },
    {
      className:"table-padding text-center",
      key: "first_name" + "last_name",
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "#1890ff",
            textAlign:"center"
          }}
        >
          {t("general.agent")}
          <Col>
            <Input allowClear />
          </Col>
        </div>
      ),
      dataIndex: "users",
      render: (text, record) => (
        <h1 style={{ fontSize: "12px" }}>
          {text?.first_name} {text?.last_name}
        </h1>
      ),
    },
  ];
  useEffect(() => {
    getHotels();
    getReservations();
  }, []);

  const getHotels = async () => {
    try {
      let {
        data: { data: hotels },
      } = await HotelService.get();
      let hotelDistrict = [];
      hotels.forEach((hotel) => {
        hotelDistrict.push(hotel.district);
      });
      const hotelDistricts = [...new Set(hotelDistrict)];
      setHotelDistricts(hotelDistricts);
      setHotels(hotels);
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  };
  const getReservations = async () => {
    try {
      let { data: response } = await ReservationService.get();
      setReservations(response.data);
      setTableFilter({
        sRT: "",
        dataSource: response.data,
        nameSearch: "",
      });
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  };

  return (
    <>
      <Wrapper>
        <Content className="m-25">
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHouse} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {t("general.login_exit_list")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background">
            {isLoadingTable && <Skeleton />}
            {!isLoadingTable && (
              <Row  gutter={[16,16]} style={{marginBottom:"30px"}}>
                <Col span={10}>
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      format="DD.MM.YYYY"
                      placeholder={[`${t("general.start_date")}`, `${t("general.end_date")}`]}
                    />
                  </Space>
                  <button className="button">{t("general.search")}</button>
                </Col>
                <Col span={10}>
                    <Select
                      onSearch={(nameSearch) =>
                        setTableFilter({
                          dataSource: reservations.filter((reservation) =>
                            reservation.id.toString().includes(nameSearch)
                          ),
                        })
                      }
                      mode="multiple"
                      placeholder={t("general.hotel")}
                      style={{ width: "300px", textAlign: "center" }}
                    >
                      {hotelNames.map((item) => (
                        <Option value={item.id} key={`description${item.id}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <button className="button">
                      {t("general.search")}
                    </button>
                </Col>
                <Col span={4}>
                  <Checkbox.Group>
                    <Row>
                      <Col>
                        <Checkbox value="giris">{t("general.check_in")}</Checkbox>
                      </Col>
                      <Col>
                        <Checkbox value="cikis">{t("general.check_out")}</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Col>
              </Row>
            )}
            <Col>
              {!isLoadingTable && (
                <Table
                  columns={columns}
                  scroll={{ x: 1300, y: 800 }}
                  dataSource={tableFilter.dataSource}
                  rowKey="id"
                  loading={isLoadingTable}
                  bordered={true}
                  pagination={{
                    pageSize: 10,
                  }}
                />
              )}
            </Col>
          </div>
        </Content>
      </Wrapper>
    </>
  );
}
export default LoginExit;
