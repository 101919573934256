import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, message, notification, Row, Space, Table, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faHouse, faPercentage, faShapes} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/v2/Wrapper";
import {useTranslation} from "react-i18next";
import {ContractGroupsService, CountriesService} from "@services";
import {Link, useParams} from "react-router-dom";
import ContractGroupsModalForm from "@/components/contracts/ContractGroupsModalForm";
import ContractGroupNationalityFormModal from "@/views/new-version/contract-groups/components/ContractGroupNationalityFormModal";
import ContractGroupCopyFormModal
    from "@/views/new-version/contract-groups/components/ContractGroupCopyFormModal";
import ContractGroupCurrencyFormModal
    from "@/views/new-version/contract-groups/components/ContractGroupCurrencyFormModal";

const { Content } = Layout;

const ContractGroupList = () => {
    const [countries, setCountries] = useState({
        data: [],
        loading: false,
    });
    const [createForm] = Form.useForm();
    const params = useParams()
    const { t } = useTranslation();
    const columns = [
        {
            title: t('general.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('general.name'),
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => <Link to={`/v2/hotels/${params.hotelId}/contract-groups/${record.id}/contracts`}>{value}</Link>
        },
        {
            title: t('general.actions'),
            key: 'actions',
            render: (value, record) => (
                <Space size="small">
                    <ContractGroupNationalityFormModal
                        contractGroupId={record.id}
                        nationalities={record.nationalities}
                        countries={countries}
                    />
                    <ContractGroupCopyFormModal
                        hotelId={params.hotelId}
                        contractGroupId={record.id}
                    />
                    <ContractGroupCurrencyFormModal
                        hotelId={params.hotelId}
                        contractGroupId={record.id}
                        currency_id={record.currency_id}
                    />
                    <Tooltip title={t("general.commissions")}>
                        <Link to={`/v2/hotels/${params.hotelId}/contract-groups/${record.id}/commissions`}>
                            <Button type="primary" htmlType="button">
                                <FontAwesomeIcon icon={faPercentage} />
                            </Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title={t("general.widgets")}>
                        <Button type="primary" htmlType="button" disabled={true}>
                            <FontAwesomeIcon icon={faShapes} />
                        </Button>
                    </Tooltip>
                    <Tooltip title={t("general.payment_methods")}>
                        <Button type="primary" htmlType="button" disabled={true}>
                            <FontAwesomeIcon icon={faCreditCard} />
                        </Button>
                    </Tooltip>
                </Space>
            )
        }
    ];
    const [data, setData] = useState({
        data: [],
        loading: false,
        meta: {}
    });
    const [createContractGroupModalFormVisible, setCreateContractGroupModalFormVisible] = useState(false);

    const getContractGroupList = async (page = 1) => {
        setData({ ...data, loading: true });
        try {
            let {data: response} = await ContractGroupsService.get(page, params.hotelId);
            setData({
                data: response.data,
                loading: false,
                meta: response.meta
            });
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
            setData({ ...data, loading: true });
        }
    }
    const onCreate = async (values) => {
        values = {
            ...values,
            hotel_id: params.hotelId
        }
        try {
            let {data: response} = await ContractGroupsService.create(values);
            if (response.success){
                message.success(response.message);
                setCreateContractGroupModalFormVisible(false);
                getContractGroupList();
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getCountries = async () => {
        setCountries({ loading: true, data: [] });
        try {
            let {data: response} = await CountriesService.get();
            setCountries({
                data: response.data,
                loading: false,
            })
        }catch (e){
            //
        }
    }
    useEffect(() => {
        getCountries()
        getContractGroupList();
        // eslint-disable-next-line
    }, []);
    return (
        <Wrapper title="Sanal Pos İşlemleri">
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Kontrat Grupları
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row>
                        <Col>
                            <Button type="primary" onClick={() => setCreateContractGroupModalFormVisible(true)}>
                                Kontrat Grubu Oluştur
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={data.data}
                        loading={data.loading}
                        pagination={false}
                        bordered
                        rowKey="id"
                    />
                </div>
            </Content>
            <ContractGroupsModalForm
                isVisible={createContractGroupModalFormVisible}
                title={t('general.new_contract_group')}
                form={createForm}
                onFinish={onCreate}
                onCancel={() => setCreateContractGroupModalFormVisible(false)}
            />
        </Wrapper>
    );
};

export default ContractGroupList;