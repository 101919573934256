import React from 'react';
import {Alert, Button, Col, Form, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";
import PeriodSelect from "@/components/utils/PeriodSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

const EditPeriodModalForm = (props) => {
    const [form] = Form.useForm()
    const [isVisible, setIsVisible] = React.useState(false)
    const { t } = useTranslation()
    const onCancel = () => {
        setIsVisible(false)
        props.onCancel && props.onCancel()
    }
    const onFinish = (values) => {
        setIsVisible(false)
        props.onFinish && props.onFinish(values)
    }
    return (
        <>
            <Button type="primary" htmlType="button" onClick={() => setIsVisible(true)} icon={<FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 8 }}/>}>
                Periyot Aktar
            </Button>
            <Modal visible={isVisible} title={props.title} closable={false} onCancel={onCancel} onOk={() => form.submit()}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="period" label={t("general.period")}>
                                <PeriodSelect />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Alert
                                message="Dikkat!"
                                description={
                                    <p>
                                        Kontrat'a periyot aktarma işlemi sonrası geçmiş periyot için tanımlanmış <strong>fiyatlandırmalar</strong> ve <strong>kondüsyonlar</strong> kaldırılacak, ardından kontrat satışa kapatılacaktır.
                                        &nbsp; <br/><br/> <p style={{ fontWeight: 500 }}>(Yeni periyot tarihleriniz için fiyat tanımlaması tamamlandıktan sonra dilerseniz kontratı tekrar satışa açabilirsiniz.)</p>
                                    </p>
                                }
                                type="warning"
                                showIcon
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default EditPeriodModalForm;