import React, { useEffect, useState } from 'react';
import {Layout, Breadcrumb, Table, notification, Space, Button, Form} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse} from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import Wrapper from '@components/Wrapper';
import { ExternalContractGroupService } from "@/services";
import {useTranslation} from "react-i18next";
import ContractGroupModalForm from "@/views/external/contracts/components/contract-groups/ContractGroupModalForm";

const { Content } = Layout;

const ExternalContractGroups = () => {
    const { t } = useTranslation()
    const [createForm] = Form.useForm();
    const params = useParams();
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [conditionGroups, setConditionGroups] = useState({});
    const columns = [
        {
            title: '#',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t('general.contract_group_name')}`,
            dataIndex: 'title',
            render: (text, record) => (
                <Link to={`${record.id}/contracts`}>{record.name}</Link>
            )
        },
        {
            title: `${t('general.actions')}`,
            render: () => (
                <Space align="middle">
                </Space>
            )
        }
    ];

    useEffect(() => {
        getContractGroups();
    }, []);

    const getContractGroups = async () => {
        try {
            let { data: contractGroups } = await ExternalContractGroupService.get(params.hotelId, params.page);
            setConditionGroups(contractGroups);
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onCreate = async (values) => {
        try {
            let { data: response } = await ExternalContractGroupService.create(params.hotelId, values);
            if (response.success){
                notification.success({
                    message: response.message
                });
                getContractGroups();
                setIsCreateModalVisible(false);
                createForm.resetFields();
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/contract-hotels">
                        {t('general.hotels')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/contract-hotels/${params.hotelId}/contract-groups`}>
                        {t('general.contract_groups')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Button type="primary" htmlType="button" onClick={() => setIsCreateModalVisible(true)}>
                        {t('general.add')}
                    </Button>
                    <Table
                        loading={isLoadingTable}
                        columns={columns}
                        dataSource={conditionGroups.data}
                        rowKey="id"
                        bordered
                    />
                </div>
                <ContractGroupModalForm
                    isVisible={isCreateModalVisible}
                    title={t('general.contract_group')}
                    onFinish={onCreate}
                    form={createForm}
                    onCancel={() => {
                        setIsCreateModalVisible(false);
                    }}
                />
            </Content>
        </Wrapper>
    )
};

export default ExternalContractGroups;
