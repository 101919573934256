import React from 'react';
import {Col, DatePicker, Form, Input, InputNumber, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";

const ContractCommissionModalForm = ({ isVisible, form, onCancel, onFinish, title, contractId }) => {
    const { t } = useTranslation();
    return (
        <Modal visible={isVisible} title={title} closable={false} onOk={() => form.submit()} onCancel={onCancel}>
            <Form form={form} layout="vertical" onFinish={(values) => onFinish(contractId, values)}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            label={t("general.start_date")}
                            name="start_date"
                            rules={[{ required: true, message: "Bu alan zorunludur" }]}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                format="DD.MM.YYYY"
                                placeholder={t("general.start_date")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t("general.end_date")}
                            name="end_date"
                            rules={[
                                { required: true, message: "Bu alan zorunludur" },
                                {
                                    message: t("general.end_date_must_be_after_start_date"),
                                    validator: (rule, value) => {
                                        if (value && form.getFieldValue("start_date") && value.isBefore(form.getFieldValue("start_date"))) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                format="DD.MM.YYYY"
                                placeholder={t("general.end_date")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t("general.commission")}
                            name="name"
                            rules={[{ required: true, message: "Bu alan zorunludur" }]}
                        >
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder={t("general.commission")}
                                addonBefore="%"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ContractCommissionModalForm;