import React from 'react';
import {Breadcrumb, Card, Col, Layout, Row} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faLayerGroup, faTag} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/v2/Wrapper";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";

const {Content} = Layout;

const Discounts = () => {
    const params = useParams()
    const { t } = useTranslation()
    const DISCOUNTS = [
        {
            id: "action_discounts",
            label: "general.action_discounts",
            href: `/v2/hotels/${params.hotelId}/action-discounts`,
            icon: faTag
        },
        {
            id: "date_discounts",
            label: "general.night_discounts",
            href: `/v2/hotels/${params.hotelId}/date-discounts`,
            icon: faTag
        },
        {
            id: "day_discounts",
            label: "general.day_discounts",
            href: `/v2/hotels/${params.hotelId}/day-discounts`,
            icon: faTag
        },
        {
            id: "early_reservation_discounts",
            label: "general.early_reservation_discounts",
            href: `/v2/hotels/${params.hotelId}/early-reservation-discounts`,
            icon: faTag
        },
        {
            id: "mobile_discounts",
            label: "general.mobile_discounts",
            href: `/v2/hotels/${params.hotelId}/mobile-discounts`,
            icon: faTag
        },
        {
            id: "discount_groups",
            label: "general.discount_groups",
            href: `/v2/hotels/${params.hotelId}/discount-groups`,
            icon: faLayerGroup
        },
        {
            id: "all_discounts",
            label: "general.all_discounts",
            href: `/v2/hotels/${params.hotelId}/all-discounts`,
            icon: faLayerGroup
        }
    ]
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.discounts")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        {DISCOUNTS.map(discount => (
                            <Col span={6} key={discount.id}>
                                <Link to={discount.href}>
                                    <Card
                                        hoverable={true}
                                        style={{
                                            width: "100%",
                                            height: "100px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <FontAwesomeIcon icon={discount.icon} style={{
                                                fontSize: "30px",
                                                color: "#1890ff"
                                            }}/>
                                            <span style={{ fontSize: "18px", fontWeight: 500 }}>{t(discount.label)}</span>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default Discounts;