import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Layout,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Table
} from "antd";
import Wrapper from "@components/Wrapper";
import { CurrencyService, PaymentMethodsService, SafeBankDefinitionService, BankService, CurrentDeficitService } from '@services';
import {faHouse, faReceipt, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HotelSelect from "@components/utils/HotelSelect";
import SafeBankAppendCariModalForm from "@components/safe-bank/SafeBankAppendCariModalForm";
import {useTranslation} from "react-i18next";

const { Content } = Layout
const { Option } = Select

function SafeBank() {
    const { t } = useTranslation()
    const [safeBankForm] = Form.useForm();
    const [safeBankAppendCariForm] = Form.useForm();
    const [paymentMethods, setPaymentMethods] = useState({
        error: false,
        loading: true,
        data: []
    })
    const [banks, setBanks] = useState({
        error: false,
        loading: true,
        data: []
    })
    const [currencies, setCurrencies] = useState({
        error: false,
        loading: true,
        data: []
    })
    const [safeBankDefinitions, setSafeBankDefinitions] = useState({
        error: false,
        loading: true,
        data: [],
        meta: {}
    })
    const [selectPaymentMethod, setSelectedPaymentMethod] = useState()
    const [selectedBank, setSelectedBank] = useState(null)
    const [selectedSafeBank, setSelectedSafeBank] = useState(null)
    const [isAppendCariModalVisible, setIsAppendCariModalVisible] = useState(false)
    const [currentDeficit, setCurrentDeficit] = React.useState({
        data: [],
        meta: {}
    });
    const [isCariLoading, setIsCariLoading] = React.useState(true);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t('general.hotel')}`,
            dataIndex: 'hotel',
            render: (text, record, index) => {
                return record?.hotel?.name ? record?.hotel?.name : 'Tüm Oteller'
            }
        },
        {
            title: `${t('general.payment_method')}`,
            dataIndex: 'paymentMethod',
            render: (text) => text.name
        },
        {
            title: `${t('general.currency')}`,
            dataIndex: 'currencies',
            render: (text) => text.map((currency, index) => {
                return <span key={index}>{currency.currency.currency}{text.length-1 !== index ? ", ":""}</span>
            })
        },
        {
            title: `${t('general.bank')}`,
            dataIndex: 'bank_name',
            render: (text) => text
        },
        {
            title: `${t('general.title')}`,
            dataIndex: 'title',
            render: (text) => text
        },
        {
            title: `${t('general.iban')}`,
            dataIndex: 'iban',
            render: (text) => text
        },
        {
            title: `${t('general.swift_code')}`,
            dataIndex: 'swift_code',
            render: (text) => text
        },
        {
            title: `${t('general.full_name')}`,
            dataIndex: 'full_name',
            render: (text) => text
        },
        {
            title: `${t('general.actions')}`,
            dataIndex: 'actions',
            render: (text, record) => (
                <Space>
                    <Popconfirm title={t('general.are_you_sure_delete')} cancelText={t('general.cancel')} okText={t('general.delete')} onConfirm={() => onDelete(record.id)}>
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="button" onClick={() => {setSelectedSafeBank(record);setIsAppendCariModalVisible(true)}}>
                        <FontAwesomeIcon icon={faReceipt} />
                    </Button>
                </Space>
            )
        }
    ]

    const getSafeBankDefinitions = async (page = 1, pageSize = 10) => {
        try {
            let { data: safeBank } = await SafeBankDefinitionService.get(page, pageSize);
            setSafeBankDefinitions(prevState => ({
                ...prevState,
                data: safeBank?.data,
                loading: false,
                meta: safeBank?.meta
            }))
        } catch (e) {
            setSafeBankDefinitions(prevState => ({
                ...prevState,
                error: true,
                loading: false
            }))
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onDelete = async (id) => {
        try {
            let { data: response } = await SafeBankDefinitionService.delete(id);
            if (response.success) {
                getSafeBankDefinitions()
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSubmit = async (values) => {
        setSafeBankDefinitions(prevState => ({
            ...prevState,
            loading: true
        }))
        try {
            let { data: response } = await SafeBankDefinitionService.create(values);
            if (response.success) {
                getSafeBankDefinitions()
                safeBankForm.resetFields()
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onAppendCariFinish = async (values, safeBankId) => {
        try {
            let { data: response } = await CurrentDeficitService.appendCari(values, safeBankId);
            if (response.success) {
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getCurrentDeficit = async (page = 1, per_page= 10, filters = {}, sorter) => {
        setIsCariLoading(true)
        try {
            let {data: response} = await CurrentDeficitService.get(page, per_page, filters);
            setCurrentDeficit({
                data: [
                    ...currentDeficit.data,
                    ...response.data
                ],
                meta: response.meta
            });
            setIsCariLoading(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const onScroll = (e) => {
        const { target } = e;
        if (! isCariLoading && currentDeficit.meta.last_page > currentDeficit.meta.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
            setIsCariLoading(true);
            getCurrentDeficit(currentDeficit.meta.current_page + 1);
        }
    };

    useEffect(() => {
        const getCurrencies = async () => {
            try {
                let { data: { data: currency } } = await CurrencyService.get();
                setCurrencies(prevState => ({
                    ...prevState,
                    data: currency,
                    loading: false
                }))
            } catch (e) {
                setCurrencies(prevState => ({
                    ...prevState,
                    error: true,
                    loading: false
                }))
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        }
        const getPaymentMethods = async () => {
            try {
                let { data: { data: payments } } = await PaymentMethodsService.get();
                setPaymentMethods(prevState => ({
                    ...prevState,
                    data: payments,
                    loading: false
                }))
            } catch (e) {
                setPaymentMethods(prevState => ({
                    ...prevState,
                    error: true,
                    loading: false
                }))
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        }

        const getBanks = async () => {
            try {
                let { data: { data: banks } } = await BankService.get();
                setBanks(prevState => ({
                    ...prevState,
                    data: banks,
                    loading: false
                }))
            } catch (e) {
                setBanks(prevState => ({
                    ...prevState,
                    error: true,
                    loading: false
                }))
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        }
        getPaymentMethods()
        getCurrencies()
        getSafeBankDefinitions()
        getBanks()
        getCurrentDeficit()
    }, [])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.safe_bank_definition')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Form form={safeBankForm} onFinish={onSubmit} layout="vertical">
                        <Row gutter={[16, 16]}>
                            <Col span={4}>
                                <Form.Item
                                    name="hotel_id"
                                    tooltip="Otel seçimi yapılmaz ise tüm otellerde kullanılacaktır."
                                    label={t('general.hotel')}
                                    rules={[{
                                        required: true,
                                        message: "Otel seçimi yapılmalı!"
                                    }]}
                                >
                                    <HotelSelect nullable />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="payment_method_id"
                                    label={t('general.payment_method')}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Ödeme yöntemi seçilmeli!"
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        onSelect={(id) => setSelectedPaymentMethod(id)}
                                        loading={paymentMethods.loading}
                                        placeholder="Ödeme yöntemi seçiniz!"
                                        filterOption={(input, option) =>
                                            (option?.children ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    >
                                        {paymentMethods.data?.map((method) => (
                                            <Option value={method.id} key={method.id}>{method.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {selectPaymentMethod === 7 &&
                                <Col span={4}>
                                    <Form.Item
                                        name="bank_id"
                                        label={t('general.bank')}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Banka seçilmeli!"
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            loading={banks.loading}
                                            placeholder="Banka seçiniz!"
                                            filterOption={(input, option) =>
                                                (option?.children ?? '')
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                            onSelect={(id) => setSelectedBank(id)}
                                        >
                                            {banks.data?.map((bank) => (
                                                <Option key={bank.id} value={bank.id}>{bank.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }
                            <Col span={selectPaymentMethod === 7 ? 4 : 8}>
                                <Form.Item
                                    name="currencies"
                                    label={t('general.currency')}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Para birimi seçilmeli!"
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        loading={currencies.loading}
                                        placeholder="Para birimi seçiniz!"
                                        filterOption={(input, option) =>
                                            (option?.children ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    >
                                        {currencies.data?.map((currency) => (
                                            <Option key={currency.id} value={currency.id}>{currency.currency}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="bank_name"
                                    label={t('general.bank')}
                                    rules={[{
                                        required: true,
                                        message: "Ödeme noktası girilmedi!"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="title"
                                    label={t('general.title')}
                                    rules={[{
                                        required: true,
                                        message: "Başlık girilmedi!"
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="cari"
                                    label={t('general.cari')}
                                    rules={[{required: true, message: 'Cari alanı boş bırakılamaz!'}]}
                                >
                                    <Select loading={isCariLoading} dropdownRender={menu => <div onScrollCapture={onScroll}>{menu}</div>} placeholder="Cari Seçiniz">
                                        {currentDeficit?.data?.map((item,index) => (
                                            <Option value={item.id} key={index}>{item?.cari?.role} ({item?.company_title} - {item?.authorized_person})</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {(selectPaymentMethod === 7 && !selectedBank === false) &&
                                JSON.parse(banks.data?.find(bank => bank.id === selectedBank)?.required_fields)?.map((field,index) => (
                                    <Col span={8} key={index}>
                                        <Form.Item
                                            name={['bank_fields', field.name]}
                                            label={field.label}
                                            tooltip={field.tooltip}
                                            rules={field.rules}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                ))
                            }
                            {selectPaymentMethod === 1 &&
                                <>
                                    <Col span={6}>
                                        <Form.Item
                                            name="iban"
                                            label={t('general.iban')}
                                            rules={[{
                                                required: true,
                                                message: "Iban girilmedi!"
                                            }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name="swift_code"
                                            label={t('general.swift_code')}
                                            tooltip="TRY dışındaki hesaplar için swift kodu girilmelidir."
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name="full_name"
                                            label={t('general.full_name')}
                                            rules={[{
                                                required: true,
                                                message: "Ad ve soyad girilmedi!"
                                            }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={safeBankDefinitions.loading}>
                                        {t('general.create')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        columns={columns}
                        dataSource={safeBankDefinitions?.data}
                        loading={safeBankDefinitions.loading}
                        rowKey="id"
                        pagination={{
                            pageSize: safeBankDefinitions?.meta?.per_page,
                            total: safeBankDefinitions?.meta?.total,
                            current: safeBankDefinitions?.meta?.current_page,
                            showSizeChanger: true,
                            onChange: (page, pageSize) => getSafeBankDefinitions(page, pageSize),
                        }}
                    />
                </div>
            </Content>
            <SafeBankAppendCariModalForm
                isVisible={isAppendCariModalVisible}
                title={t('general.assign_cari')}
                onFinish={onAppendCariFinish}
                form={safeBankAppendCariForm}
                onCancel={() => {
                    setIsAppendCariModalVisible(false);
                    safeBankAppendCariForm.resetFields();
                }}
                selectedSafeBank={selectedSafeBank}
                currentDeficit={currentDeficit}
                isCariLoading={isCariLoading}
                onScroll={onScroll}
            />
        </Wrapper>
    )
}
export default SafeBank