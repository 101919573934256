import React, { useState, useEffect } from 'react';
import {
  Layout,
  Breadcrumb,
  Popconfirm,
  Button,
  Form,
  Table,
  Space,
  notification,
  Row,
  Col,
  DatePicker
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faTrash, faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { PeriodDateService } from '@services';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {useTranslation} from "react-i18next";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const PeriodDate = () => {
  const { t } = useTranslation();
  const {periodId} = useParams();

  const [periods, setPeriods] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  const [createForm] = Form.useForm();
  
  const columns = [
    {
      key: 'index',
      title: '#',
      render: (text, record, index) => index + 1
    },
    {
      key: 'start_date',
      title: `${t('general.start_date')}`,
      dataIndex: 'start_date',
      render:(text)=>moment.isMoment(text) ? moment(text).format('DD/MM/YYYY'):text
    },
    {
      key: 'end_date',
      title: `${t('general.end_date')}`,
      dataIndex: 'end_date',
      render:(text)=>moment.isMoment(text) ? moment(text).format('DD/MM/YYYY'):text
    },
    {
      key: 'actions',
      title: `${t('general.actions')}`,
      render: (text, record) => (
        <Space align="middle">
          <Popconfirm title={t("general.are_you_sure_delete")} onConfirm={() => onDelete(record.id)} okText={t("general.delete")} cancelText={t("general.cancel")}>
            <Button type="danger">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  useEffect(() => {
    getPeriodDates();
  }, []);
  
  const getPeriodDates = async () => {
    try {
      let { data: response } = await PeriodDateService.get(periodId);
      setPeriods(response.data);
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  const onCreate = async (values) => {
    values = {
      start_date: values.date[0].format('YYYY-MM-DD'),
      end_date: values.date[1].format('YYYY-MM-DD'),
    }
    try {
      let { data: response } = await PeriodDateService.create(periodId,values);
        if (response.success) {
          notification.success({
            message: response.message
          });
          setIsLoadingTable(true);
          getPeriodDates();
          createForm.resetFields();
        }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
      console.log(e);
    }
  };
  const onDelete = async (id) => {
    try {
      let { data: response } = await PeriodDateService.delete(periodId, id);
      if (response.success) {
        notification.success({
          message: response.message
        });
        setIsLoadingTable(true);
        getPeriodDates();
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
      console.log(e);
    }
  }
  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/periods">
            Periyotlar
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Periyot Tarihleri
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="site-layout-background">
          <Form onFinish={onCreate}>
            <Row gutter={[16,16]} justify="start">
              <Col>
                <Form.Item name="date" label={t("general.date_range")}>
                  <RangePicker
                      format="DD/MM/YYYY"
                      placeholder={[`${t("general.start_date")}`, `${t("general.end_date")}`]}
                      disabledDate={(currentDate) => {
                        return currentDate.isBefore(moment())
                      }}
                      style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  <FontAwesomeIcon icon={faSave} className="mr-1" />
                  {t("general.add")}
                </Button>
              </Col>
            </Row>
          </Form>
          <Table loading={isLoadingTable} columns={columns} dataSource={periods} rowKey="id"/>
        </div>
      </Content>
    </Wrapper>
  );
};

export default PeriodDate;