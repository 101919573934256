import React from 'react';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "@/views/new-version/reservation/components/documents/ActionButton";
import {useTranslation} from "react-i18next";
import {Form, notification} from "antd";
import {ReservationServiceV2} from "@services";
import ReservationProformaSendModal from "@components/reservations/ReservationProformaSendModal";

const SendProforma = ({ reservation }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isProformaModalVisible, setIsProformaModalVisible] = React.useState(false);
    const [isProformaSending, setIsProformaSending] = React.useState(false);
    const onSendReservationProforma = async (values) => {
        setIsProformaSending(true)
        try{
            let { data: response } = await ReservationServiceV2.sendProforma(reservation.uuid, values);
            if(response.success){
                form.resetFields()
                setIsProformaModalVisible(false)
                notification.success({
                    message: response.message
                });
            }
            setIsProformaSending(false)
        }catch (e){
            setIsProformaSending(false)
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    return (
        <React.Fragment>
            <ActionButton
                icon={faPaperPlane}
                title={t("general.send_proforma")}
                onClick={() => setIsProformaModalVisible(true)}
                loading={isProformaSending}
            />
            <ReservationProformaSendModal
                title="Rezervasyon Proforma Gönder"
                form={form}
                isVisible={isProformaModalVisible}
                onCancel={() => setIsProformaModalVisible(false)}
                onFinish={onSendReservationProforma}
                loading={isProformaSending}
            />
        </React.Fragment>
    );
};

export default SendProforma;