import React from 'react';
import {
    Breadcrumb, Button, Form,
    Layout,
    notification,
    Space,
    Table, Tooltip
} from "antd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useTranslation} from "react-i18next";
import {ExternalOperatorService, ExternalBankService} from "@services";
import InstallmentCreateModalForm from "./components/InstallmentCreateModalForm";

const {Content} = Layout;

const PoolOperatorList = () => {
    const [installmentForm] = Form.useForm();
    const [installmentCreateModalFormVisible, setInstallmentCreateModalFormVisible] = React.useState(false);
    const [selectedOperator, setSelectedOperator] = React.useState(null);
    const { t } = useTranslation()
    const [banks, setBanks] = React.useState({
        data: [],
        loading: true
    });
    const [operators, setOperators] = React.useState({
        data: [],
        meta: {},
        loading: true,
        error: false
    });
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 100,
            key: 'id',
        },
        {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Operator',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: "İşlemler",
            key: 'action',
            render: (text, record) => (
                <Space align="middle">
                    <Tooltip title="Taksit Seçenekleri">
                        <Button htmlType="button" type="primary" onClick={() => getOperator(record.id)}>
                            <FontAwesomeIcon icon={faCreditCard} />
                        </Button>
                    </Tooltip>
                </Space>
            ),

        }
    ];

    const getOperators = async (page = 1, per_page = 10) => {
        setOperators({
            ...operators,
            loading: true
        })
        try {
            let {data: response} = await ExternalOperatorService.get(page, per_page);
            setOperators({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setOperators({
                ...operators,
                loading: false
            })
        }
    }

    const getOperator = async (id) => {
        try {
            let {data: response} = await ExternalOperatorService.find(id);
            setSelectedOperator(response.data)
            installmentForm.setFieldsValue({
                installment_options: response.data.installments
            })
            setInstallmentCreateModalFormVisible(true)
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getBanks = async (page = 1, per_page = 10) => {
        setBanks({
            ...banks,
            loading: true
        })
        try {
            let {data: response} = await ExternalBankService.all();
            setBanks({
                data: response.data,
                loading: false
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setBanks({
                ...banks,
                loading: false
            })
        }
    }

    const onCreate = async (values, id) => {
        try {
            let {data: response} = await ExternalOperatorService.installmentCreate(values, id);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                installmentForm.resetFields()
                setInstallmentCreateModalFormVisible(false)
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }


    React.useEffect(() => {
        getOperators();
        getBanks();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Operators
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={operators.data}
                        loading={operators.loading}
                        rowKey={record => record.id}
                        pagination={{
                            total: operators.meta.total,
                            current: operators.meta.current_page,
                            pageSize: operators.meta.per_page,
                        }}
                        onChange={(pagination) => getOperators(pagination.current, pagination.pageSize)}
                    />
                </div>
                <InstallmentCreateModalForm
                    form={installmentForm}
                    isVisible={installmentCreateModalFormVisible}
                    title="Taksit Seçenekleri"
                    onCancel={() => setInstallmentCreateModalFormVisible(false)}
                    onFinish={(values, id) => onCreate(values, id)}
                    banks={banks}
                    data={selectedOperator}
                />
            </Content>
        </Wrapper>
    );
};

export default PoolOperatorList;