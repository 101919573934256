import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Form, Input, Layout, notification, Row, Select, Space, Table, Tag} from "antd";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/v2/Wrapper";
import {useTranslation} from "react-i18next";
import {DiscountServiceV2, ContractGroupsService, ContractService} from "@services";
import moment from "moment/moment";

const {Content} = Layout;
const {Option} = Select;

const AllDiscountsList = () => {
    const [searchForm] = Form.useForm();
    const params = useParams();
    const {t} = useTranslation();
    const [discounts, setDiscounts] = useState({
        data: [],
        meta: {},
        loading: false
    });
    const [contractGroups, setContractGroups] = useState({
        data: [],
        meta: {},
        loading: false
    });
    const [contracts, setContracts] = useState({
        data: [],
        meta: {},
        loading: false
    });
    const onSearch = (values) => {
        getDiscounts(1, 10, values);
    };
    const getContractGroups = async (page = 1) => {
        setContractGroups({ ...contractGroups, loading: true });
        try {
            let {data: response} = await ContractGroupsService.get(page, params.hotelId);
            setContractGroups({
                data: response.data,
                loading: false,
                meta: response.meta
            });
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
            setContractGroups({ ...contractGroups, loading: true });
        }
    }
    const getContracts = async (contractGroupId, page = 1) => {
        if (!contractGroupId){
            setContracts({ ...contracts, data: [] });
            searchForm.setFieldsValue({ contract_id: undefined });
            return;
        }
        setContracts({ ...contracts, loading: true });
        try {
            let {data: response} = await ContractService.demoGet(params.hotelId, contractGroupId, page);
            setContracts({
                data: response.data,
                loading: false,
                meta: response.meta
            });
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
            setContracts({ ...contracts, loading: true });
        }
    }
    const getDiscounts = async (page, limit, filters) => {
        setDiscounts({ ...discounts, loading: true });
        try {
            let {data: response} = await DiscountServiceV2.allDiscounts(params.hotelId, page, limit, filters);
            setDiscounts({
                data: response.data,
                meta: response.meta,
                loading: false
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
            setDiscounts({ ...discounts, loading: false });
        }
    }
    const columns = [
        {
            title: t("general.discount_name"),
            dataIndex: 'discount_name',
            key: 'discount_name',
            render: (text) => t(`general.${text.toLowerCase()}`)
        },
        {
            title: t("general.contract"),
            dataIndex: 'contract_name',
            key: 'contract_name',
        },
        {
            title: t("general.discount"),
            dataIndex: 'discount',
            key: 'discount',
            render: (text, record) => {
                if (record.discount_value_type === "1" || record.discount_type === "1"){
                    return `${text}%`
                }
                if (record.discount_value_type === "2" || record.discount_type === "2"){
                    return `${text}`
                }
            }
        },
        {
            title: t("general.is_cost_include"),
            dataIndex: 'is_cost_include',
            key: 'is_cost_include',
            render: (text) => {
                if (text === 1){
                    return <Tag color="green">Evet</Tag>
                }
                if (text === 0){
                    return <Tag color="red">Hayır</Tag>
                }
            }
        },
        {
            title: t("general.details"),
            dataIndex: 'details',
            key: 'details',
            render: (text, record) => {
                if (record.discount_name === "EARLY_RESERVATION_DISCOUNT"){
                    return <EarlyReservationDiscountDetails data={record} />
                }
                if (record.discount_name === "DATE_DISCOUNT"){
                    return <DateDiscountDetails data={record} />
                }
                if (record.discount_name === "MOBILE_DISCOUNT") {
                    return <MobileDiscountDetails data={record}/>
                }
                if (record.discount_name === "DAY_DISCOUNT") {
                    return <DayDiscountDetails data={record}/>
                }
                if (record.discount_name === "ACTION_DISCOUNT"){
                    return <ActionDiscountDetails data={record} />
                }
            }
        }
    ]
    useEffect(() => {
        getContractGroups();
        getDiscounts(1, 10, searchForm.getFieldsValue());
    },[]);
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/discounts`}>
                            {t("general.discounts")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.all_discounts")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Filters
                        onSearch={onSearch}
                        form={searchForm}
                        contractGroups={contractGroups}
                        contracts={contracts}
                        getContracts={(contractGroupId) => getContracts(contractGroupId)}
                    />
                    <Table
                        columns={columns}
                        dataSource={discounts.data}
                        loading={discounts.loading}
                        pagination={false}
                        rowKey="uuid"
                        scroll={{ x: 768 }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

const Filters = ({ form, onSearch, contractGroups, getContracts, contracts }) => {
    const { t } = useTranslation();
    const contractGroup = Form.useWatch("contract_group_id", form);
    return (
        <Form layout="vertical" form={form} onFinish={onSearch} initialValues={{
            status: 1
        }}>
            <Row gutter={[16,16]} style={{ marginBottom: 16 }}>
                <Col span={6}>
                    <Form.Item
                        name="status"
                        label="Durum"
                    >
                        <Select allowClear={false}>
                            <Option value={1}>Aktif</Option>
                            <Option value={0}>Pasif</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="contract_group_id"
                        label="Kontrat Grubu"
                    >
                        <Select
                            allowClear={true}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => getContracts(value)}
                            placeholder={t("general.contract_group")}
                        >
                            {contractGroups.data.map(group => (
                                <Option key={group.id} value={group.id}>{group.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="contract_id"
                        label="Kontrat"
                    >
                        <Select
                            allowClear={true}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={t("general.contract")}
                            disabled={!contractGroup}
                        >
                            {contracts.data.map(contract => (
                                <Option key={contract.id} value={contract.id}>{contract.title}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Button style={{ width: "100%" }} type="primary" htmlType="button" onClick={() => form.submit()}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

const ActionDiscountDetails = ({data}) => {
    return (
        <Space direction="vertical">
            <div>
                <span>
                    <b>Geçerlilik Tarihi</b>: {moment(data.validity_start_date).format("DD.MM.YYYY")} - {moment(data.validity_end_date).format("DD.MM.YYYY")}
                </span>
            </div>
            <div>
                <span>
                    <b>Konaklama Tarihi</b>: {moment(data.stay_start_date).format("DD.MM.YYYY")} - {moment(data.stay_end_date).format("DD.MM.YYYY")}
                </span>
            </div>
        </Space>
    )
}

const DayDiscountDetails = ({data}) => {
    const { t } = useTranslation();
    const days = JSON.parse(data.days) || []
    const dates = JSON.parse(data.dates) || []
    return (
        <Space direction="vertical">
            <div>
                <span>
                    <b>Geçerlilik Tarihi</b>: {moment(data.validity_start_date).format("DD.MM.YYYY")} - {moment(data.validity_end_date).format("DD.MM.YYYY")}
                </span>
            </div>
            <div>
                {days.length > 0 ? (
                    <span>
                        <b>Günler:</b>&nbsp; {days.map((day, index) => (
                        <Tag key={index} color="blue">
                            {t("general."+ moment().day(day).format("dddd").toLowerCase())}
                        </Tag>
                    ))}
                    </span>
                ): (
                    <span>
                        <b>Tarihler</b>: &nbsp; {dates.map((date, index) => (
                        <Tag key={index} color="blue">
                            {moment(date).format("DD.MM.YYYY")}
                        </Tag>
                    ))}
                    </span>
                )}
            </div>
        </Space>
    )
}

const MobileDiscountDetails = ({data}) => {
    return (
        <Space direction="vertical">
            <div>
                <b>Başlangıç Bitiş Tarihi</b>: {moment(data.start_date).format("DD.MM.YYYY")} - {moment(data.end_date).format("DD.MM.YYYY")}
            </div>
        </Space>
    )
}

const DateDiscountDetails = ({data}) => {
    return (
        <Space direction="vertical">
            <div>
                <b>Başlangıç Bitiş Tarihi</b>: {moment(data.start_date).format("DD.MM.YYYY")} - {moment(data.end_date).format("DD.MM.YYYY")}
            </div>
            <div>
                <b>Min-Max Gece</b>: {data.min_night} - {data.max_night}
            </div>
        </Space>
    )
}

const EarlyReservationDiscountDetails = ({data}) => {
    return (
        <Space direction="vertical">
            <div>
                {data?.checkin_day ? (
                    <span>
                        <b>Girişten Öncesi Gün Sayısı</b>: {data.checkin_day}
                    </span>
                ): (
                    <span>
                        <b>Konaklama Tarihi</b>: {moment(data?.stay_start_date).format("DD.MM.YYYY")} - {moment(data.stay_end_date).format("DD.MM.YYYY")}
                    </span>
                )}
            </div>
            <div>
                <span>
                    <b>Geçerlilik Tarihi</b>: {moment(data.validity_start_date).format("DD.MM.YYYY")} - {moment(data.validity_end_date).format("DD.MM.YYYY")}
                </span>
            </div>
        </Space>
    )
}

export default AllDiscountsList;