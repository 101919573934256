import React, {useEffect} from "react";
import {
    Breadcrumb,
    Button,
    Col, Collapse,
    DatePicker,
    Form, Input,
    InputNumber,
    Layout,
    notification, Popconfirm,
    Row,
    Select, Space, Switch,
    Table,
    Tag
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Wrapper from "@components/Wrapper";
import {faEdit, faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import {HotelService} from "@services"
import {ContractGroupsService,ContractService,ContractDateDiscountsService} from "@services";
import {useParams} from "react-router-dom";
import moment from "moment";
import ContractDateDiscountModalForm from "../../components/contracts/ContractDateDiscountModalForm";
import {useTranslation} from "react-i18next";

const {Content} = Layout
const {Option} = Select
const {RangePicker} = DatePicker
const {Panel} = Collapse

function NightDiscounts(){
    const { t } = useTranslation()
    const params = useParams()
    const [discountForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const [filterForm] = Form.useForm();
    const [isTableLoading,setIsTableLoading] = React.useState(true)
    const [isEditModalVisible,setIsEditModalVisible] = React.useState(false)
    const [nightDiscounts,setNightDiscounts] = React.useState([])
    const [nightDiscount,setNightDiscount] = React.useState({})
    const [hotels,setHotels] = React.useState([])
    const [rooms,setRooms] = React.useState([])
    const [contractGroups,setContractGroups] = React.useState([])

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t('general.status')}`,
            key: 'status',
            dataIndex: 'status',
            render: (text, record) => {
                if(record.status === 1){
                    return <Tag color="green">Aktif</Tag>
                }
                if(record.status === 2){
                    return <Tag color="red">Pasif</Tag>
                }
            }
        },
        {
            title: `${t('general.hotel')}`,
            dataIndex: 'contract',
            render: (text) => (
                <Space>
                    <div style={{fontSize:"13px"}}>{text?.hotel?.name}</div>
                </Space>
            )
        },
        {
            title: `${t('general.room')}`,
            dataIndex: 'contract',
            render: (text, record) => (
                <Space>
                    <div style={{fontSize:"13px"}}>{record?.contract?.hotel_room?.room_definition?.active_language.name}</div>
                </Space>
            )
        },
        {
            title: `${t('general.start_date')}`,
            dataIndex: 'start_date',
            render: (text, record, index) => moment(record.start_date).format('DD.MM.YYYY')
        },
        {
            title: `${t('general.end_date')}`,
            dataIndex: 'end_date',
            render: (text, record, index) => moment(record.end_date).format('DD.MM.YYYY')
        },
        {
            title: `${t('general.min_night')}`,
            dataIndex: 'min_night',
            render: (text, record, index) => record.min_night
        },
        {
            title: `${t('general.max_night')}`,
            dataIndex: 'max_night',
            render: (text, record, index) => record.max_night
        },
        {
            title: `${t('general.discount')}`,
            render: (text, record) => {
                if(record.discount_value_type === "1"){
                    return `%${record.discount}`
                }
                if(record.discount_value_type === "2"){
                    return `${record.discount} Birim`
                }
            }
        },
        {
            title: `${t('general.is_cost_include')}`,
            dataIndex: 'is_cost_include',
            render: (text) => text ? <Tag color="green">{t('general.yes')}</Tag> : <Tag color="red">{t('general.no')}</Tag>
        },
        {
            title: `${t('general.actions')}`,
            key: 'actions',
            render: (text, record) => (
                <Space align="middle">
                    <Button type="primary" htmlType="button" onClick={() => getNightDiscount(record.id)}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                    <Popconfirm title="Silmek istediğine emin misin?" onConfirm={() => onDelete(record.id)} okText="Sil" cancelText="İptal">
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]
    const getContractGroups = async (hotelId) => {
        if(hotelId === null){
            setContractGroups([])
            setRooms([])
            return discountForm.setFieldsValue({rooms:undefined,contract_group:undefined})
        }
        try {
            let { data: {data:contractGroups} } = await ContractGroupsService.get(params.page,hotelId);
            setContractGroups(contractGroups);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getRooms = async (contractGroupId) => {
        if(contractGroupId === null){
            setRooms([])
            return discountForm.setFieldsValue({rooms:undefined})
        }
        try {
            let { data: {data:rooms} } = await ContractService.getByGroupId(contractGroupId);
            console.log(rooms)
            setRooms(rooms);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const onSubmit = async (values) =>{
        values = {
            start_date:moment(values.date_range[0]).format('YYYY-MM-DD'),
            end_date:moment(values.date_range[1]).format('YYYY-MM-DD'),
            ...values
        }
        try {
            let { data: response } = await ContractDateDiscountsService.create(values);
            if(response.success){
                getNightDiscounts()
                notification.success({
                    message: response.message
                });
            }
            discountForm.setFieldsValue({min_night:undefined,max_night:undefined,date_range:undefined,discount:undefined,rooms:undefined});
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getNightDiscounts = async (params = {}) => {
        try {
            let { data: {data: discounts} } = await ContractDateDiscountsService.getAllByAgency(params);
            setNightDiscounts(discounts);
            setIsTableLoading(false)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getNightDiscount = async (id) => {
        try {
            let { data: { data: discount } } = await ContractDateDiscountsService.getById(id);
            editForm.setFieldsValue({
                ...discount,
                date_range:[moment(discount.start_date),moment(discount.end_date)],
                discount_value_type: parseInt(discount.discount_value_type),
            });
            setNightDiscount(discount);
            setIsEditModalVisible(true);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onDelete = async (contractDateDiscountId) => {
        try {
            let {data: response} = await ContractDateDiscountsService.delete(contractDateDiscountId);
            if (response.success) {
                notification.success({
                    message: response.message
                });
                setIsTableLoading(true);
                getNightDiscounts();
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
        }
    };
    const onUpdate = async (values, id) => {
        values = {
            ...values,
            start_date:moment(values.date_range[0]).format('YYYY-MM-DD'),
            end_date:moment(values.date_range[1]).format('YYYY-MM-DD'),
            is_cost_include:values.is_cost_include ? 1 : 0,
            rooms: [nightDiscount?.contract?.id],
            id: id
        }
        try {
            let {data: response} = await ContractDateDiscountsService.update(id, values);
            if (response.success) {
                notification.success({
                    message: response.message
                });
                setIsTableLoading(true);
                setIsEditModalVisible(false);
                getNightDiscounts();
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
        }
    };
    const onFilter = (values) => {
        setIsTableLoading(true);
        let params = {
            "hotel_name": values.hotel_name ?? '',
            "status": values.status ?? 1,
        }
        getNightDiscounts(params);
    }
    useEffect(()=>{
        const getHotels = async (page = 1) => {
            try {
                let { data: {data:response} } = await HotelService.get(page);
                setHotels(response);
            } catch (e) {
                console.log(e);
            }
        };
        getHotels()
        getNightDiscounts()
    },[])
    return(
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.night_discounts')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Form form={discountForm} layout="vertical" onFinish={onSubmit}>
                        <Row gutter={[16,16]}>
                            <Col span={8}>
                                <Form.Item
                                    name="date_range"
                                    label={t('general.date_range')}
                                    rules={[
                                        {
                                            required:true,
                                            message:"Başlangıç ve bitiş tarihi seçilmelidir!"
                                        }
                                    ]}
                                >
                                    <RangePicker
                                        format="DD.MM.YYYY"
                                        placeholder={[`${t('general.start_date')}`, `${t('general.end_date')}`]}
                                        disabledDate={(currentDate) => {
                                            return currentDate.isBefore(moment().subtract(1,'days'))
                                        }}
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="min_night"
                                    label={t('general.min_night')}
                                    rules={[
                                        {
                                            required:true,
                                            message:"Min. gece sayısı seçilmelidir!"
                                        }
                                    ]}
                                >
                                    <InputNumber style={{width:"100%"}} min={1}/>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="max_night"
                                    label={t('general.max_night')}
                                    rules={[
                                        {
                                            required:true,
                                            message:"Maks. gece sayısı seçilmelidir!"
                                        }
                                    ]}
                                >
                                    <InputNumber style={{width:"100%"}} min={1}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="discount"
                                    label={t('general.discount')}
                                    rules={[
                                        {
                                            required:true,
                                            message:"Maks. gece sayısı seçilmelidir!"
                                        }
                                    ]}
                                >
                                    <InputNumber addonBefore={
                                        <Form.Item
                                            name="discount_value_type"
                                            noStyle={true}
                                            rules={[
                                                {
                                                    required:true,
                                                    message:"İndirim tipi seçilmelidir!"
                                                }
                                            ]}
                                            initialValue={1}
                                        >
                                            <Select>
                                                <Option value={1}>{t('general.percent')}</Option>
                                                <Option value={2}>{t('general.unit')}</Option>
                                            </Select>
                                        </Form.Item>
                                    } min={1} style={{width:"100%"}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                            <Col span={6}>
                                <Form.Item
                                    name="hotel"
                                    label={t('general.hotels')}
                                    rules={[{required:true,message:"Hotel seçimi yapılmalıdır!"}]}>
                                    <Select style={{width:"100%"}} onChange={(e) => getContractGroups(e)} placeholder={t("general.choose")}>
                                        <Option>Seçiniz</Option>
                                        {hotels?.map((hotel,index)=>(
                                            <Option key={index} value={hotel.id}>{hotel.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="contract_group"
                                    label={t('general.contract_groups')}
                                    rules={[{required:true,message:"Kontrat grubu seçimi yapılmalıdır!"}]}
                                >
                                    <Select style={{width:"100%"}} onChange={(e) => getRooms(e)} disabled={!contractGroups.length} placeholder={t("general.choose")}>
                                        <Option>Seçiniz</Option>
                                        {contractGroups?.map((contractGroup,index)=>(
                                            <Option key={index} value={contractGroup.id}>{contractGroup.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="rooms"
                                    label={t('general.rooms')}
                                    rules={[{required:true,message:"Min. 1 oda seçilmelidir!"}]}>
                                    <Select mode="multiple" style={{width:"100%"}} disabled={!rooms.length} placeholder={t("general.choose")}>
                                        {rooms?.map((room,index)=>(
                                            <Option key={index} value={room.id}>{room.hotel_room.room_definition.active_language.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="is_cost_include"
                                    label={t('general.is_cost_include')}
                                    valuePropName="checked"
                                    initialValue="true"
                                >
                                    <Switch defaultChecked />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{width:"100%"}}>
                                        {t('general.save')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row style={{marginBottom:"16px"}}>
                        <Collapse size="small" style={{width:"100%"}}>
                            <Panel header={t('general.filter')} key="1">
                                <Form form={filterForm} layout="vertical" onFinish={onFilter}>
                                    <Row gutter={[16,0]}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={t('general.status')}
                                                name="status"
                                                initialValue=""
                                            >
                                                <Select>
                                                    <Option key={0} value="">{t('general.all')}</Option>
                                                    <Option key={1} value={1}>{t('general.active')}</Option>
                                                    <Option key={2} value={0}>{t('general.passive')}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={t('general.hotel_name')}
                                                name="hotel_name"
                                            >
                                                <Input placeholder={t('general.hotel_name')} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    {t('general.filter')}
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Panel>
                        </Collapse>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={nightDiscounts}
                        loading={isTableLoading}
                        rowKey={record => record.id}
                    />
                </div>
            </Content>
            <ContractDateDiscountModalForm
                title="Gece İndirimi Düzenle"
                form={editForm}
                isVisible={isEditModalVisible}
                data={nightDiscount}
                onCancel={() => setIsEditModalVisible(false)}
                onFinish={onUpdate}
            />
        </Wrapper>
    )
}
export default NightDiscounts