import React from 'react';
import {Breadcrumb, Table, Layout, notification, Row, Col, Form, DatePicker, Button, Select, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import {ProfitReportService} from "@services";
import HotelSelect from "@/components/utils/HotelSelect";
import CurrencySelect from "@/components/utils/CurrencySelect";
import moment from "moment";
import {Link} from "react-router-dom";

const {Content} = Layout;
const {RangePicker} = DatePicker;
const {Option} = Select;

const ProfitReport = () => {
    const { t } = useTranslation()
    const [data, setData] = React.useState({
        data: [],
        loading: true,
        total_profit: 0,
        total_cost: 0,
        total_fee: 0,
        total_discounted_fee: 0,
        total_paid_fee: 0,
        total_stayed: 0,
        total_will_stay: 0,
        total_deferred_amount: 0,
    });
    const [form] = Form.useForm();
    const columns = [
        {
            title: t("general.reservation_number"),
            dataIndex: "id",
            key: "id",
            render: (text) => <Link to={`/reservation-card/${text}`}>{text}</Link>
        },
        {
            title: t("general.hotel_name"),
            dataIndex: "hotel",
            key: "hotel_name",
        },
        {
            title: t("general.check_in"),
            dataIndex: "check_in",
            key: "check_in",
            render: (text) => moment(text).format("DD.MM.YYYY"),
        },
        {
            title: t("general.check_out"),
            dataIndex: "check_out",
            key: "check_out",
            render: (text) => moment(text).format("DD.MM.YYYY"),
        },
        {
            title: t("general.cost"),
            dataIndex: "cost",
            key: "cost",
            render: (text, record) => text + " " +record?.currency
        },
        {
            title: t("general.total_amount"),
            dataIndex: "total_fee",
            key: "total_fee",
            render: (text, record) => text + " " +record?.currency
        },
        {
            title: t("general.discounted_amount"),
            dataIndex: "discounted_fee",
            key: "discounted_fee",
            render: (text, record) => text + " " +record?.currency
        },
        {
            title: t("general.paid_amount"),
            dataIndex: "paid_fee",
            key: "paid_fee",
            render: (text, record) => text + " " +record?.currency
        },
        {
            title: t("general.profit"),
            dataIndex: "profit",
            key: "profit",
            render: (text, record) => text + " " +record?.currency
        },,
        {
            title: t("general.deferred_will_pay_amount"),
            dataIndex: "deferred_payment_amount",
            key: "deferred_payment_amount",
            render: (text, record) => text + " " + record?.currency
        },
        {
            title: t("general.stay_status"),
            dataIndex: "is_stayed",
            key: "is_stayed",
            render: (text) => text ? <Tag color="green">{t("general.stayed")}</Tag> : <Tag color="red">{t("general.will_stay")}</Tag>
        }

    ];
    const getReport = async (values) => {
        values = {
            ...values,
            start_date: values.start_date[0].format("YYYY-MM-DD"),
            end_date: values.start_date[1].format("YYYY-MM-DD"),
            status: values.status ? values.status : "all",
        }
        try {
            let {data: response} = await ProfitReportService.get(values);
            setData({
                data: response.data,
                loading: false,
                total_profit: response?.total_profit,
                total_cost: response?.total_cost,
                total_fee: response?.total_fee,
                total_discounted_fee: response?.total_discounted_fee,
                total_paid_fee: response?.total_paid_fee,
                total_stayed: response?.total_stayed,
                total_will_stay: response?.total_will_stay,
                total_deferred_amount: response?.total_deferred_amount,
            });
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message,
                })
            }
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.profit")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={form} onFinish={getReport} layout="vertical">
                        <Row gutter={[16,16]}>
                            <Col span={6}>
                                <Form.Item
                                    name="hotel_id"
                                    label={t("general.hotel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("general.required")
                                        }
                                    ]}
                                >
                                    <HotelSelect />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="currency_id"
                                    label={t("general.currency")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("general.required")
                                        }
                                    ]}
                                >
                                    <CurrencySelect />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="start_date"
                                    label={t("general.stay_date")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("general.required")
                                        }
                                    ]}
                                >
                                    <RangePicker
                                        format="DD.MM.YYYY"
                                        placeholder={[t("general.start_date"), t("general.end_date")]}
                                        style={{width: "100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="status"
                                    label={t("general.status")}
                                >
                                    <Select defaultValue="all" placeholder={t("general.select")}>
                                        <Option value="all">Tümü</Option>
                                        <Option value="stayed">Konaklamış</Option>
                                        <Option value="will_stay">Konaklayacak</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item
                                    label="&nbsp;"
                                >
                                    <Button htmlType="submit" type="primary">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Table
                            columns={columns}
                            dataSource={data.data}
                            pagination={false}
                            scroll={{
                                x: 2000,
                                y: 500,
                            }}
                            bordered
                            summary={() => (
                                <Table.Summary fixed>
                                    {data.data.length > 0 && (
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={4}>
                                                {data.total_cost} {data.data[0]?.currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={5}>
                                                {data.total_fee} {data.data[0]?.currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={6}>
                                                {data.total_discounted_fee} {data.data[0]?.currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={7}>
                                                {data.total_paid_fee} {data.data[0]?.currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={8}>
                                                {data.total_profit} {data.data[0]?.currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={9}>
                                                {data.total_deferred_amount} {data.data[0]?.currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={10}>
                                                {t("general.stayed")}: {data.total_stayed} <br/>
                                                {t("general.will_stay")}: {data.total_will_stay}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    )}
                                </Table.Summary>
                            )}
                        />
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default ProfitReport;