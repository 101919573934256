import React, {useEffect, useMemo, useState} from 'react';
import {
    Alert,
    Button,
    Divider,
    Form,
    Input,
    InputNumber,
    notification,
    Popover,
    Select,
    Space,
    Table,
    Tooltip
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarDay,
    faCheck,
    faDoorOpen,
    faEdit,
    faExclamation,
    faMinus,
    faPlus, faXmark
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {useParams} from "react-router-dom";
import {ContractService} from "@services";
import ImportPeriodModalForm from "@/views/new-version/contracts/components/ImportPeriodModalForm";

const {Option} = Select;

const fixedQuotaRow = {
    id: "QUOTAS",
    code: "QUOTAS",
    adult: null,
    ages: [],
    child: null,
    prices: {}
}

const FixedContractEditor = ({ getDaysInRange, contract, conditions, periods, availabilities, childAges, onEditPeriod, getContract, onSave }) => {
    const params = useParams();
    const [newForm] = Form.useForm();
    const newContract = Form.useWatch('contract', newForm) || {};
    const newAvailabilities = Form.useWatch('availabilities', newForm) || {};
    const newPeriods = Form.useWatch('periods', newForm) || [];
    const newConditions = Form.useWatch('conditions', newForm) || [];
    const newChildAges = Form.useWatch('child_ages', newForm) || [];
    const [mounted, setMounted] = useState(false);

    const onEditFinish = async (values) => {
        values = {
            prices: newConditions,
            availability: newAvailabilities,
            periods: newPeriods,
        }
        await onSave(values);
    }

    useEffect(() => {
        newForm.setFieldsValue({
            conditions: conditions,
            availabilities: availabilities,
            child_ages: childAges,
            periods: periods,
            contract: contract,
        })
        setMounted(true)
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Form form={newForm} onFinish={onEditFinish} layout="vertical" initialValues={{
                conditions: conditions,
                availabilities: availabilities,
                child_ages: childAges,
                periods: periods,
                contract: contract,
            }}>
                <Form.Item name="contract" hidden noStyle><Input/></Form.Item>
                <Form.Item name="conditions" hidden noStyle><Input/></Form.Item>
                <Form.Item name="availabilities" hidden noStyle><Input/></Form.Item>
                <Form.Item name="child_ages" hidden noStyle><Input/></Form.Item>
                <Form.Item name="periods" hidden noStyle><Input/></Form.Item>
            </Form>
            {mounted && (
                <FixedTable
                    form={newForm}
                    hotelId={params.hotelId}
                    contractGroupId={params.contractGroupId}
                    childAges={newChildAges}
                    periods={newPeriods}
                    conditions={newConditions}
                    getDaysInRange={getDaysInRange}
                    onEditPeriod={onEditPeriod}
                    availabilities={newAvailabilities}
                    getContract={() => getContract(params.hotelId, params.contractGroupId, contract.id)}
                    contract={newContract}
                />
            )}
        </>
    )
};

const FixedTable = ({ periods, conditions, childAges, availabilities, contract, form, getDaysInRange, onEditPeriod, getContract, hotelId, contractGroupId }) => {
    const dataSource = [
        fixedQuotaRow,
        ...conditions
    ]
    const addRow = () => {
        let nextId = conditions[conditions.length - 1].id + 1;

        form.setFieldsValue({
            conditions: [
                ...conditions,
                {
                    id: nextId,
                    code: priceCode(1, 0, []),
                    adult: 1,
                    child: 0,
                    ages: [],
                    prices: []
                }
            ]
        });
    }
    const removeRow = (id) => {
        const newData = conditions.filter((item) => item.id !== id);
        form.setFieldsValue({
            conditions: newData
        });
    }
    const changeStatusPeriod = (id) => {
        const newData = periods.map((period) => {
            if (period.id === id) {
                period.is_daily = !period.is_daily;
            }
            return period;
        });
        form.setFieldsValue({
            periods: newData
        });
    }
    const isRemoveButtonDisabled = dataSource.length === 2;
    const priceCode = (adult, child, ages) => {
        let string = `${adult}A`;
        if (ages.length > 0){
            ages.forEach((age) => {
                string += `-${age}C`
            })
        }
        return string;
    }
    const sharedOnCell = (record, index) => {
        if ((index === 0 && record.code === "QUOTAS")) {
            return { colSpan: 0 };
        }

        return {};
    };
    const onAdultChange = (value, record) => {
        form.setFieldsValue({
            conditions: conditions.map((item) => {
                if (item.id === record.id) {
                    return {
                        ...item,
                        adult: value,
                        code: priceCode(value, item.child, item.ages),
                    }
                }
                return item
            })
        })
    }
    const onChildChange = (value, record) => {
        form.setFieldsValue({
            conditions: conditions.map((item) => {
                if (item.id === record.id) {
                    return {
                        ...item,
                        child: value,
                        ages: Array.from({length: value}, (_, index) => childAges[0]?.id),
                        code: priceCode(item.adult, value, Array.from({length: value}, (_, index) => childAges[0]?.id)),
                    }
                }
                return item
            })
        })
    }
    const onChildAgeChange = (value, record, index) => {
        form.setFieldsValue({
            conditions: conditions.map((item) => {
                if (item.id === record.id) {
                    const ages = item.ages.map((age, ageIndex) => {
                        if (ageIndex === index) {
                            return value
                        }
                        return age
                    })
                    return {
                        ...item,
                        ages: ages,
                        code: priceCode(item.adult, item.child, ages),
                    }
                }
                return item
            })
        })
    }
    const isPeriodsValid = () => {
        return periods?.length > 0;
    }
    const isPricesValid = () => {
        return conditions?.some(item => Object.keys(item.prices || {}).length > 0);
    }
    const columns = [
        {
            title: <Tooltip title="Satır Ekle">
                <Button
                    type="dashed"
                    onClick={addRow}
                    icon={<FontAwesomeIcon icon={faPlus} style={{
                        fontSize: '10px',
                        marginLeft: '1px',
                    }}/>}
                    style={{
                        width: '14px',
                        height: '14px',
                        padding: 0,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto'
                    }}
                />
            </Tooltip>,
            dataIndex: 'key',
            key: 'key',
            width: 50,
            onCell: (_, index) => ({
                colSpan: (index) > 0 ? 1 : 5,
            }),
            render: (value, record, index) => {
                if (record.code === "QUOTAS"){
                    return <p style={{ margin: 0, textAlign: "center", fontWeight: 500 }}>
                        Kontenjan
                    </p>
                }
                return (
                    <>
                        <Button disabled={!!isRemoveButtonDisabled} type="danger" onClick={() => removeRow(record.id)} style={{
                            width: '14px',
                            height: '14px',
                            padding: 0,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto'
                        }}>
                            <FontAwesomeIcon icon={faMinus}  style={{
                                fontSize: '10px',
                            }}/>
                        </Button>
                    </>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 40,
            onCell: sharedOnCell,
            render: (value, record, index) => {
                const priceCode = record.code;
                const isDuplicated = dataSource.filter((item) => item.code === priceCode).length > 1;
                return (
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        {isDuplicated ? (
                            <FontAwesomeIcon icon={faExclamation} style={{ color: 'red' }} />
                        ): (
                            <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
                        )}
                    </div>
                )
            }
        },
        {
            title: 'Adult',
            dataIndex: 'adult',
            key: 'adult',
            width: 80,
            onCell: sharedOnCell,
            className: (record) => record.adult > 2 ? 'background-red-500' : 'background-red-500',
            render: (adult, record, index) => {
                return (
                    <InputNumber
                        style={{width: '100%'}}
                        value={adult}
                        onChange={(value) => onAdultChange(value, record)}
                    />
                )
            }
        },
        {
            title: 'Child',
            dataIndex: 'child',
            key: 'child',
            width: 80,
            onCell: sharedOnCell,
            render: (child, record, index) => {
                return (
                    <InputNumber
                        value={child}
                        style={{width: '100%'}}
                        onChange={(value) => onChildChange(value, record)}
                    />
                )
            }
        },
        {
            title: 'Ages',
            dataIndex: 'ages',
            key: 'ages',
            onCell: sharedOnCell,
            render: (ages, record, index) => {
                return (
                    <Space direction="horizontal">
                        {ages?.map((item, index) => (
                            <Select key={index} value={item} onChange={(value) => onChildAgeChange(value, record, index)}>
                                {childAges?.map((age) => (
                                    <Option key={age.id} value={age.id}>
                                        {age.name}
                                    </Option>
                                ))}
                            </Select>
                        ))}
                    </Space>
                )
            }
        },
        ...periods.map((period) => {
            let isSingle = period.is_daily === true;
            return {
                title: <PeriodSwitch period={period} isSingle={isSingle} changeStatusPeriod={changeStatusPeriod} onEditPeriod={onEditPeriod} />,
                className: "text-left",
                width: "auto",
                render: (row) => (
                    <EditCell
                        row={row}
                        conditions={conditions}
                        period={period}
                        availabilities={availabilities}
                        childAges={childAges}
                        form={form}
                    />
                ),
                children: isSingle ? getDaysInRange(period.start, period.end)?.map((periodDay) => ({
                    title: moment(periodDay).format('DD.MM.YYYY'),
                    dataIndex: "prices",
                    key: periodDay,
                    width: 280,
                    render: (row, record) => (
                        <EditCell
                            row={record}
                            periodDay={periodDay}
                            conditions={conditions}
                            availabilities={availabilities}
                            childAges={childAges}
                            form={form}
                        />
                    ),
                })): []
            }
        })
    ]
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
            size="small"
            title={() => (
                <TableHeader
                    isPeriodsValid={isPeriodsValid}
                    isPricesValid={isPricesValid}
                    periods={periods}
                    conditions={conditions}
                    hotelId={hotelId}
                    contractGroupId={contractGroupId}
                    getContract={getContract}
                    contract={contract}
                />
            )}
            footer={() => (
                <TableFooter
                    onSaveContract={() => form.submit()}
                    isValid={isPricesValid() && isPeriodsValid()}
                />
            )}
            rowKey={(record) => record.id}
            scroll={{ x: 'max-content' }}
        />
    )
}

const EditCell = ({ row, period, periodDay, availabilities, form, conditions }) => {
    const isDaily = Boolean(periodDay); // Günlük mü yoksa çoklu tarih mi
    const [quota, setQuota] = useState(isDaily ? availabilities?.[periodDay]?.quota || 0 : availabilities?.[period?.start]?.quota || 0);
    const [minStay, setMinStay] = useState(isDaily ? availabilities?.[periodDay]?.min_stay || 0 : availabilities?.[period?.start]?.min_stay || 0);

    const onQuotaSave = () => {
        const updatedAvailabilities = { ...availabilities };
        if (isDaily) {
            // Günlük işlem
            updatedAvailabilities[periodDay] = {
                ...(availabilities[periodDay] || {}),
                quota: quota
            };
        } else {
            // Çoklu tarih işlem
            let currentDate = moment(period.start);
            const endDate = moment(period.end);
            while (currentDate.isSameOrBefore(endDate)) {
                const dateKey = currentDate.format('YYYY-MM-DD');
                updatedAvailabilities[dateKey] = {
                    ...(availabilities[dateKey] || {}),
                    quota: quota
                };
                currentDate.add(1, 'day');
            }
        }
        form.setFieldsValue({ availabilities: updatedAvailabilities });
    };

    const onMinStaySave = () => {
        const updatedAvailabilities = { ...availabilities };
        if (isDaily) {
            // Günlük işlem
            updatedAvailabilities[periodDay] = {
                ...(availabilities[periodDay] || {}),
                min_stay: minStay
            };
        } else {
            // Çoklu tarih işlem
            let currentDate = moment(period.start);
            const endDate = moment(period.end);
            while (currentDate.isSameOrBefore(endDate)) {
                const dateKey = currentDate.format('YYYY-MM-DD');
                updatedAvailabilities[dateKey] = {
                    ...(availabilities[dateKey] || {}),
                    min_stay: minStay
                };
                currentDate.add(1, 'day');
            }
        }
        form.setFieldsValue({ availabilities: updatedAvailabilities });
    };

    useEffect(() => {
        setQuota(isDaily ? availabilities?.[periodDay]?.quota || 0 : availabilities?.[period?.start]?.quota || 0);
        setMinStay(isDaily ? availabilities?.[periodDay]?.min_stay || 0 : availabilities?.[period?.start]?.min_stay || 0);
    },[periodDay, period, availabilities, form, conditions])

    if (row.code === "QUOTAS") {
        return (
            <Space direction="horizontal">
                <InputNumber
                    addonBefore={(
                        <Tooltip title="Kontenjan">
                            <FontAwesomeIcon icon={faDoorOpen} style={{ opacity: "0.5", fontSize: "12px" }}/>
                        </Tooltip>
                    )}
                    min={0}
                    value={quota}
                    onChange={(value) => setQuota(value)}
                    onBlur={onQuotaSave}
                    style={{ width: "100%" }}
                />
                <InputNumber
                    addonBefore={<Tooltip title="Min. Konaklama">
                        <FontAwesomeIcon icon={faCalendarDay} style={{ opacity: "0.5", fontSize: "12px" }}/>
                    </Tooltip>}
                    value={minStay}
                    min={0}
                    style={{ width: "100%" }}
                    onBlur={onMinStaySave}
                    onChange={(value) => setMinStay(value)}
                />
            </Space>
        );
    }
    /*return isDaily
        ? <PriceInputsDate isDaily={isDaily} row={row} ages={row.ages || []} prices={row.prices || []} periodDay={periodDay} form={form} conditions={conditions} />
        : <PriceInputs isDaily={isDaily} row={row} ages={row.ages || []} prices={row.prices || []} period={period} form={form} conditions={conditions} />;*/
    return <PriceInputsUnified prices={row.prices || []} ages={row.ages || []} isSingleDay={isDaily} row={row} period={period} periodDay={periodDay} form={form} conditions={conditions} />;
};

const PeriodSwitch = ({ period, onEditPeriod, isSingle = false, changeStatusPeriod }) => {
    return (
        <Space direction="horizontal">
            <Tooltip title={isSingle ? "Periyodu Gizle": "Periyodu Göster"}>
                <Button type="link" style={{ padding: 0 }} onClick={() => changeStatusPeriod(period.id)}>
                    {moment(period.start).format('DD.MM.YYYY')} <br/> {moment(period.end).format('DD.MM.YYYY')}
                </Button>
            </Tooltip>
            <Tooltip title="Geliştirme Sürecinde" role="update-period">
                <Button onClick={() => /*onEditPeriod(period)*/ () => {}} shape="circle" size="small" type="dashed" icon={<FontAwesomeIcon icon={faEdit} width={10} height={10} className="ant-btn-link" />} />
            </Tooltip>
        </Space>
    )
}

const TableFooter = ({ onSaveContract = () => {}, isValid }) => {
    return (
        <Space style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0"
        }}>
            <span></span>
            <span></span>
            <Button type="primary" htmlType="button" onClick={onSaveContract} disabled={!isValid}>
                Kontratı Kaydet
            </Button>
        </Space>
    )
}

const TableHeader = ({ contract, hotelId, contractGroupId, getContract, isPeriodsValid, isPricesValid }) => {
    const onPeriodImport = async (values) => {
        try {
            let {data: response} = await ContractService.demoImportPeriod(hotelId, contractGroupId, contract.id, values)
            if (response.success){
                notification.success({
                    message: response.message
                })
                getContract(hotelId, contractGroupId, contract.id)
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    return (
        <Space direction="horizontal" style={{ width: "100%", "justifyContent": "space-between" }}>
            <div>
                <span style={{ fontSize: "18px", fontWeight: 500 }}>{contract?.title}</span> <br/>
                <span style={{ fontSize: "14px", fontWeight: 400 }}>{contract?.room?.name && `(${contract?.room?.name})`}</span>
            </div>
            <div>
                <Space direction="vertical" align="center">
                    <Popover content={
                        isPeriodsValid() ? (
                            <h4>Periyot tanımlamalarınız tamamlanmış durumda.</h4>
                        ): (
                            <h4>Periyot tanımı yapıldıktan sonra, periyot tarihlerini kontratınıza eklemeniz gerekmektedir.</h4>
                        )
                    }>
                        <Button shape="circle" htmlType="button" type="dashed" icon={
                            isPeriodsValid() > 0 ? <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />: <FontAwesomeIcon icon={faXmark} style={{ color: "red" }} />
                        } />
                    </Popover>
                    <h4>Periyot</h4>
                </Space>
                <Divider type="vertical" />
                <Space direction="vertical" align="center">
                    <Popover content={
                        <>
                            {isPricesValid() ? (
                                <h4>Fiyat tanımlamalarınız tamamlanmış durumda.</h4>
                            ): (
                                <h4>Fiyat tanımlamalarınız eksik. Lütfen fiyat tanımlamalarınızı yapınız.</h4>
                            )}

                            <Alert message={<>0 <strong>(sıfır)</strong> olarak tanımlanan fiyatlar <strong>STOP</strong> olarak işlenecektir.</>} type="warning" />
                        </>
                    }>
                        <Button shape="circle" htmlType="button" type="dashed" icon={
                            isPricesValid() > 0 ? <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />: <FontAwesomeIcon icon={faXmark} style={{ color: "red" }} />
                        } />
                    </Popover>
                    <h4>Fiyat</h4>
                </Space>
            </div>
            <ImportPeriodModalForm
                title="Periyot Aktar"
                onFinish={(values) => onPeriodImport(values)}
            />
        </Space>
    )
}

const PriceInputsUnified = React.memo(({form, ages, prices, period, periodDay, row, conditions, isSingleDay = false }) => {
    const initialAdultPrice = useMemo(
        () => prices.find((price) => price.date === (isSingleDay ? periodDay : period.start))?.price || 0,
        [prices, periodDay, period, isSingleDay]
    );

    const initialChildPrices = useMemo(
        () =>
            ages.map(
                (age, index) =>
                    prices.find((price) => price.date === (isSingleDay ? periodDay : period.start))?.[`childPrice${index}_${age}`] || 0
            ),
        [ages, prices, periodDay, period, isSingleDay]
    );

    const [adultPrice, setAdultPrice] = useState(initialAdultPrice);
    const [childPrices, setChildPrices] = useState(initialChildPrices);

    const onPriceSave = (type = "adult") => {
        const updatedPrices = [...prices];
        const updateLogic = (dateKey) => {
            const existingPriceIndex = updatedPrices.findIndex((item) => item.date === dateKey);
            if (existingPriceIndex !== -1) {
                if (type === "adult") {
                    updatedPrices[existingPriceIndex] = {
                        ...updatedPrices[existingPriceIndex],
                        price: adultPrice,
                    };
                } else {
                    ages.forEach((age, index) => {
                        updatedPrices[existingPriceIndex][`childPrice${index}_${age}`] = childPrices[index];
                    });
                }
            } else {
                const newPrice = { date: dateKey };
                if (type === "adult") {
                    newPrice.price = adultPrice;
                } else {
                    ages.forEach((age, index) => {
                        newPrice[`childPrice${index}_${age}`] = childPrices[index];
                    });
                }
                updatedPrices.push(newPrice);
            }
        };

        if (isSingleDay) {
            updateLogic(periodDay);
        } else {
            let currentDate = moment(period.start);
            const endDate = moment(period.end);
            while (currentDate.isSameOrBefore(endDate)) {
                const dateKey = currentDate.format('YYYY-MM-DD');
                updateLogic(dateKey);
                currentDate.add(1, 'day');
            }
        }

        form.setFieldsValue({
            conditions: conditions.map((condition) => {
                if (condition.code === row.code) {
                    return {
                        ...condition,
                        prices: updatedPrices,
                    };
                }
                return condition;
            }),
        });
    };

    const handleInputChange = (value, index = null) => {
        if (index === null) {
            setAdultPrice(value);
        } else {
            setChildPrices((prevPrices) => {
                const updatedPrices = [...prevPrices];
                updatedPrices[index] = value;
                return updatedPrices;
            });
        }
    };

    useEffect(() => {
        setAdultPrice(initialAdultPrice);
        setChildPrices(initialChildPrices);
    }, [prices, ages, periodDay, period, row, conditions, form, isSingleDay]);

    return (
        <div style={{ width: "100%", display: "flex", gap: "0.6rem" }}>
            <div style={{ position: "relative" }} className="input-head-label" data-label="Yetişkin Fiyatı">
                <InputNumber
                    value={adultPrice}
                    min={0}
                    onBlur={() => onPriceSave("adult")}
                    onChange={(value) => setAdultPrice(value)}
                    style={{ fontSize: "12px" }}
                    className="hide-input-arrow"
                />
            </div>
            {ages && ages.length > 0 && ages.map((age, index) => {
                return (
                    <div key={index} style={{ position: "relative" }} className="input-head-label" data-label={`${index + 1}. Çocuk Fiyatı`}>
                        <InputNumber
                            key={index}
                            value={childPrices[index] || 0}
                            min={0}
                            style={{ width: "100%", maxWidth: "100px", fontSize: "12px" }}
                            onBlur={() => onPriceSave("child")}
                            onChange={(value) => handleInputChange(value, index)}
                            className="hide-input-arrow"
                        />
                    </div>
                );
            })}
        </div>
    );
});

export default FixedContractEditor;


/*
* Aşağıdaki componentler optimize edilerek tek bir componentte birleştirildi. İhtiyaç olursa ayrı ayrı componentler olarak kullanılabilir.
* */

/*const DailyEditCell = ({ row, periodDay, availabilities, form, conditions }) => {
    const [quota, setQuota] = useState(availabilities?.[periodDay]?.quota || 0);
    const [minStay, setMinStay] = useState(availabilities?.[periodDay]?.min_stay || 0);
    const onQuotaSave = () => {
        const updatedAvailabilities = { ...availabilities };
        updatedAvailabilities[periodDay] = {
            ...(availabilities[periodDay] || {}),
            quota: quota
        };
        form.setFieldsValue({
            availabilities: updatedAvailabilities
        });
    }
    const onMinStaySave = () => {
        const updatedAvailabilities = { ...availabilities };
        updatedAvailabilities[periodDay] = {
            ...(availabilities[periodDay] || {}),
            min_stay: minStay
        };
        form.setFieldsValue({
            availabilities: updatedAvailabilities
        });
    }
    if (row.code === "QUOTAS"){
        return <Space direction="horizontal">
            <InputNumber
                addonBefore={<Tooltip title="Kontenjan">
                    <FontAwesomeIcon icon={faDoorOpen} style={{ opacity: "0.5", fontSize: "12px" }}/>
                </Tooltip>}
                value={quota}
                min={0}
                style={{ width: "100%" }}
                onBlur={onQuotaSave}
                onChange={(value) => setQuota(value)}
            />
            <InputNumber
                addonBefore={<Tooltip title="Min. Konaklama">
                    <FontAwesomeIcon icon={faCalendarDay} style={{ opacity: "0.5", fontSize: "12px" }}/>
                </Tooltip>}
                value={minStay}
                min={0}
                onBlur={onMinStaySave}
                style={{ width: "100%" }}
                onChange={(value) => setMinStay(value)}
            />
        </Space>
    }
    return <PriceInputsDate row={row} ages={row.ages || []} prices={row.prices || []} periodDay={periodDay} form={form} conditions={conditions} />
}

const PeriodEditCell = ({ row, period, availabilities, form, conditions }) => {
    const [quota, setQuota] = useState(availabilities?.[period.start]?.quota || 0);
    const [minStay, setMinStay] = useState(availabilities?.[period.start]?.min_stay || 0);
    const onQuotaSave = () => {
        const updatedAvailabilities = { ...availabilities };
        let currentDate = moment(period.start);
        const endDate = moment(period.end);
        while (currentDate.isSameOrBefore(endDate)) {
            const dateKey = currentDate.format('YYYY-MM-DD');
            updatedAvailabilities[dateKey] = {
                ...(availabilities[dateKey] || {}),
                quota: quota
            };
            currentDate.add(1, 'day');
        }
        form.setFieldsValue({
            availabilities: updatedAvailabilities
        });
    }
    const onMinStaySave = () => {
        const updatedAvailabilities = { ...availabilities };
        let currentDate = moment(period.start);
        const endDate = moment(period.end);
        while (currentDate.isSameOrBefore(endDate)) {
            const dateKey = currentDate.format('YYYY-MM-DD');
            updatedAvailabilities[dateKey] = {
                ...(availabilities[dateKey] || {}),
                min_stay: minStay
            };
            currentDate.add(1, 'day');
        }
        form.setFieldsValue({
            availabilities: updatedAvailabilities
        });
    }
    if (row.code === "QUOTAS"){
        return (
            <Space direction="horizontal">
                <InputNumber
                    addonBefore={(
                        <Tooltip title="Kontenjan">
                            <FontAwesomeIcon icon={faDoorOpen} style={{ opacity: "0.5", fontSize: "12px" }}/>
                        </Tooltip>
                    )}
                    min={0}
                    value={quota}
                    onChange={(value) => setQuota(value)}
                    onBlur={onQuotaSave}
                    style={{ width: "100%" }}
                />
                <InputNumber
                    addonBefore={<Tooltip title="Min. Konaklama">
                        <FontAwesomeIcon icon={faCalendarDay} style={{ opacity: "0.5", fontSize: "12px" }}/>
                    </Tooltip>}
                    value={minStay}
                    min={0}
                    style={{ width: "100%" }}
                    onBlur={onMinStaySave}
                    onChange={(value) => setMinStay(value)}
                />
            </Space>
        )
    }
    return <PriceInputs row={row} ages={row.ages || []} prices={row.prices || []} period={period} form={form} conditions={conditions} />
}*/

/*const PriceInputsDate = React.memo(({ prices, ages, periodDay, row, conditions, form }) => {
    const initialAdultPrice = useMemo(
        () => prices.find((price) => price.date === periodDay)?.price || 0,
        [prices, periodDay]
    );

    const initialChildPrices = useMemo(
        () =>
            ages.map(
                (age, index) =>
                    prices.find((price) => price.date === periodDay)?.[`childPrice${index}_${age}`] || 0
            ),
        [ages, prices, periodDay]
    );
    const [adultPrice, setAdultPrice] = useState(initialAdultPrice);
    const [childPrices, setChildPrices] = useState(initialChildPrices);
    const onPriceSave = (type = "adult") => {
        if (type === "adult") {
            const updatedPrices = [...prices];
            const existingPriceIndex = updatedPrices.findIndex((item) => item.date === periodDay);
            if (existingPriceIndex !== -1) {
                updatedPrices[existingPriceIndex] = {
                    ...updatedPrices[existingPriceIndex],
                    price: adultPrice,
                };
            } else {
                updatedPrices.push({
                    date: periodDay,
                    price: adultPrice,
                });
            }

            form.setFieldsValue({
                conditions: conditions.map((condition) => {
                    if (condition.code === row.code) {
                        return {
                            ...condition,
                            prices: updatedPrices
                        }
                    }
                    return condition
                })
            });
        }
        if (type === "child") {
            const updatedPrices = [...prices];
            const existingPriceIndex = updatedPrices.findIndex((item) => item.date === periodDay);
            if (existingPriceIndex !== -1) {
                ages.forEach((age, index) => {
                    updatedPrices[existingPriceIndex][`childPrice${index}_${age}`] = childPrices[index];
                });
            } else {
                const newPrice = {
                    date: periodDay,
                };
                ages.forEach((age, index) => {
                    newPrice[`childPrice${index}_${age}`] = childPrices[index];
                });
                updatedPrices.push(newPrice);
            }

            form.setFieldsValue({
                conditions: conditions.map((condition) => {
                    if (condition.code === row.code) {
                        return {
                            ...condition,
                            prices: updatedPrices
                        }
                    }
                    return condition
                })
            });
        }
    };

    const handleInputChange = (value, index = null) => {
        if (index === null) {
            setAdultPrice(value);
        } else {
            setChildPrices((prevPrices) => {
                const updatedPrices = [...prevPrices];
                updatedPrices[index] = value;
                return updatedPrices;
            });
        }
    };

    useEffect(() => {
        setAdultPrice(initialAdultPrice);
        setChildPrices(initialChildPrices);
    }, [prices, ages, periodDay, row, conditions, form])
    return (
        <div style={{ width: "100%", display: "flex", gap: "0.6rem"}}>
           <div style={{ position: "relative" }} className="input-head-label" data-label="Yetişkin Fiyatı">
                <InputNumber
                    value={adultPrice}
                    min={0}
                    onChange={(value) => setAdultPrice(value)}
                    style={{ fontSize: "12px" }}
                    className="hide-input-arrow"
                    onBlur={() => onPriceSave("adult")}
                />
            </div>

            {ages && ages.length > 0 && ages.map((age, index) => {
                return (
                    <div key={index} style={{ position: "relative" }} className="input-head-label" data-label={`${index + 1}. Çocuk Fiyatı`}>
                        <InputNumber
                            key={index}
                            value={childPrices[index] || 0}
                            min={0}
                            onBlur={() => onPriceSave("child")}
                            style={{ width: "100%", maxWidth: "100px", fontSize: "12px" }}
                            onChange={(value) => handleInputChange(value, index)}
                        />
                    </div>
                )
            })}
        </div>
    )
})

const PriceInputs = React.memo(({ form, ages, prices, period, row, conditions }) => {
    const initialAdultPrice = useMemo(
        () => prices.find((price) => price.date === period.start)?.price || 0,
        [prices, period.start]
    );

    const initialChildPrices = useMemo(
        () =>
            ages.map(
                (age, index) =>
                    prices.find((price) => price.date === period.start)?.[`childPrice${index}_${age}`] || 0
            ),
        [ages, prices, period.start]
    );

    const [adultPrice, setAdultPrice] = useState(initialAdultPrice);
    const [childPrices, setChildPrices] = useState(initialChildPrices);

    const onPriceSave = (type = "adult") => {
        if (type === "adult") {
            const updatedPrices = [...prices];
            let currentDate = moment(period.start);
            const endDate = moment(period.end);
            while (currentDate.isSameOrBefore(endDate)) {
                const dateKey = currentDate.format('YYYY-MM-DD');
                const existingPriceIndex = updatedPrices.findIndex((item) => item.date === dateKey);
                if (existingPriceIndex !== -1) {
                    updatedPrices[existingPriceIndex] = {
                        ...updatedPrices[existingPriceIndex],
                        price: adultPrice,
                    };
                } else {
                    updatedPrices.push({
                        date: dateKey,
                        price: adultPrice,
                    });
                }
                currentDate.add(1, 'day');
            }
            form.setFieldsValue({
                conditions: conditions.map((condition) => {
                    if (condition.code === row.code) {
                        return {
                            ...condition,
                            prices: updatedPrices
                        }
                    }
                    return condition
                })
            });
        }
        if (type === "child") {
            const updatedPrices = [...prices];
            let currentDate = moment(period.start);
            const endDate = moment(period.end);
            while (currentDate.isSameOrBefore(endDate)) {
                const dateKey = currentDate.format('YYYY-MM-DD');
                const existingPriceIndex = updatedPrices.findIndex((item) => item.date === dateKey);
                if (existingPriceIndex !== -1) {
                    ages.forEach((age, index) => {
                        updatedPrices[existingPriceIndex][`childPrice${index}_${age}`] = childPrices[index];
                    });
                } else {
                    const newPrice = {
                        date: dateKey,
                    };
                    ages.forEach((age, index) => {
                        newPrice[`childPrice${index}_${age}`] = childPrices[index];
                    });
                    updatedPrices.push(newPrice);
                }
                currentDate.add(1, 'day');
            }
            form.setFieldsValue({
                conditions: conditions.map((condition) => {
                    if (condition.code === row.code) {
                        return {
                            ...condition,
                            prices: updatedPrices
                        }
                    }
                    return condition
                })
            });
        }
    };

    const handleInputChange = (value, index = null) => {
        if (index === null) {
            setAdultPrice(value);
        } else {
            setChildPrices((prevPrices) => {
                const updatedPrices = [...prevPrices];
                updatedPrices[index] = value;
                return updatedPrices;
            });
        }
    };

    useEffect(() => {
        setAdultPrice(initialAdultPrice);
        setChildPrices(initialChildPrices);
    }, [prices, ages, period, row, conditions, form])

    return (
        <div style={{ width: "100%", display: "flex", gap: "0.6rem"}}>
            <div style={{ position: "relative" }} className="input-head-label" data-label="Yetişkin Fiyatı">
                <InputNumber
                    value={adultPrice}
                    min={0}
                    onBlur={() => onPriceSave("adult")}
                    onChange={(value) => setAdultPrice(value)}
                    style={{ fontSize: "12px" }}
                    className="hide-input-arrow"
                />
            </div>
            {ages && ages.length > 0 && ages.map((age, index) => {
                return (
                    <div key={index} style={{ position: "relative" }} className="input-head-label" data-label={`${index + 1}. Çocuk Fiyatı`}>
                        <InputNumber
                            key={index}
                            value={childPrices[index] || 0}
                            min={0}
                            style={{ width: "100%", maxWidth: "100px", fontSize: "12px" }}
                            onBlur={() => onPriceSave("child")}
                            onChange={(value) => handleInputChange(value, index)}
                            className="hide-input-arrow"
                        />
                    </div>
                )
            })}
        </div>
    )
})*/