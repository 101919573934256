import React, {useEffect, useState} from 'react';
import {Breadcrumb, Card, Col, Layout, Row, DatePicker, Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from '@components/Wrapper';
import { UserCallService } from '@services';

const { RangePicker } = DatePicker;
const {Content} = Layout;

const UserCallReport = () => {
    const [date, setDate] = useState(null);
    const [data, setData] = useState({
        data: [],
        isLoading: false
    });

    const getData = async () => {
        let values = {
            start_date: date[0] || null,
            end_date: date[1] || null
        }
        setData(prevState => ({...prevState, isLoading: true}));
        try {
            const {data: response} = await UserCallService.report(values);
            setData({
                data: response,
                isLoading: false,
            });
            console.log(response)
        } catch (error) {
            console.error(error);
            setData(prevState => ({...prevState, isLoading: false}));
        }
    }

    useEffect(() => {
        getData();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Agent Çağrı Raporu
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row>
                        <RangePicker
                            onChange={(date, dateString) => setDate(dateString)}
                            style={{marginBottom: "20px"}}
                            placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                            format="DD.MM.YYYY"
                        />
                        <Button
                            htmlType="button"
                            onClick={() => getData()}
                        >
                            Raporla
                        </Button>
                    </Row>
                    <Row gutter={[16,16]}>
                        {data?.data?.this_month && (
                            <Col span={12}>
                                <Card
                                    title={`${data?.data?.this_month?.date?.name} | ${data?.data?.this_month?.date?.start} - ${data?.data?.this_month?.date?.end}`}
                                >
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid #e8e8e8",
                                        padding: "10px",
                                        marginBottom: "40px"
                                    }}>
                                        {data?.data?.this_month?.data?.length === 0 && <p>Veri Bulunamadı</p>}
                                        {data?.data?.this_month?.data?.map((item,key) => (
                                            <div key={key}>
                                                <p>{item?.user?.name} - {item?.total} Çağrı</p>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </Col>
                        )}
                        {data?.data?.last_month && (
                            <Col span={12}>
                            <Card
                                title={`${data?.data?.last_month?.date?.name} | ${data?.data?.last_month?.date?.start} - ${data?.data?.last_month?.date?.end}`}
                            >
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    {data?.data?.last_month?.data?.length === 0 && <p>Veri Bulunamadı</p>}
                                    {data?.data?.last_month?.data?.map((item,key) => (
                                        <div key={key}>
                                            <p>{item?.user?.name} - {item?.total} Çağrı</p>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                        )}
                        {data?.data?.this_year && (
                            <Col span={12}>
                            <Card
                                title={`${data?.data?.this_year?.date?.name} | ${data?.data?.this_year?.date?.start} - ${data?.data?.this_year?.date?.end}`}
                            >
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    {data?.data?.this_year?.data?.length === 0 && <p>Veri Bulunamadı</p>}
                                    {data?.data?.this_year?.data?.map((item,key) => (
                                        <div key={key}>
                                            <p>{item?.user?.name} - {item?.total} Çağrı</p>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                        )}
                        {data?.data?.custom_date && (
                            <Col span={24}>
                            <Card
                                title={`${data?.data?.custom_date?.date?.name} | ${data?.data?.custom_date?.date?.start} - ${data?.data?.custom_date?.date?.end}`}
                            >
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    {data?.data?.custom_date?.data?.length === 0 && <p>Veri Bulunamadı</p>}
                                    {data?.data?.custom_date?.data?.map((item,key) => (
                                        <div key={key}>
                                            <p>{item?.user?.name} - {item?.total} Çağrı</p>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                        )}
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default UserCallReport;