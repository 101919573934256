import React, {useEffect} from 'react';
import {Breadcrumb, Button, Form, Layout, notification, Spin, Steps} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/v2/Wrapper";
import {useTranslation} from "react-i18next";
import HotelCreateGeneralStep from "@/views/new-version/hotels/components/HotelCreateGeneralStep";
import HotelCreateAgePolicyStep from "@/views/new-version/hotels/components/HotelCreateAgePolicyStep";
import HotelCreatePhotosStep from "@/views/new-version/hotels/components/HotelCreatePhotosStep";
import HotelCreateRoomsStep from "@/views/new-version/hotels/components/HotelCreateRoomsStep";
import HotelCreateRoomAttributes from "@/views/new-version/hotels/components/HotelCreateRoomAttributes";
import {HotelServiceV2} from "@services";
import {Link, useParams} from "react-router-dom";

const { Content } = Layout;
const { Step } = Steps;

const HotelUpdatePage = () => {
    const params = useParams()
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [current, setCurrent] = React.useState(0);
    const next = () => setCurrent(current + 1);
    const prev = () => setCurrent(current - 1);
    const [form] = Form.useForm();
    const steps = [
        {
            id: 0,
            title: 'Otel Bilgileri',
            content: <HotelCreateGeneralStep form={form} />,
            fields: ["hotel_name", "hotel_languages", "hotel_location", "hotel_google_maps", "translations"],
        },
        {
            id: 1,
            title: 'Çocuk Yaşı Politikası',
            content: <HotelCreateAgePolicyStep form={form} />,
            code: "child_age_policy",
            fields: ["child_age_policy"]
        },
        {
            id: 2,
            title: 'Otel Fotoğrafları',
            content: <HotelCreatePhotosStep form={form} />,
            code: "hotel_photos",
            fields: ["hotel_photos"]
        },
        {
            id: 3,
            title: 'Oda Tanımları',
            content: <HotelCreateRoomsStep form={form} />,
            code: "hotel_rooms",
            fields: ["hotel_rooms"]
        },
        {
            id: 4,
            title: 'Oda Olanakları',
            content: <HotelCreateRoomAttributes form={form} />,
            code: "hotel_rooms",
            fields: ["hotel_rooms"]
        }
    ];
    const onHandleSubmit = () => {
        form.validateFields().then(() => {
            form.submit()
        }).catch(errors => {
            let fieldErrors = errors.errorFields
            fieldErrors = fieldErrors.filter((error) => error.errors.length > 0 || error.warnings.length > 0)
            if (fieldErrors.length > 0) {
                let step = steps.find((step) => step.fields.includes(fieldErrors[0].name[0])).id
                setCurrent(step)
            }
        })
    }
    const onUpdate = async (values) => {
        try {
            let {data: response} = await HotelServiceV2.update(params.hotelId, values)
            if (response.success){
                notification.success({
                    message: t("general.success"),
                    description: response.message
                })
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: t("general.error"),
                    description: e.response.data.message
                })
                if (e.response.data.errors){
                    e.response.data.errors.forEach((error) => {
                        form.setFields([{name: error.field, errors: [error.message]}])
                    })
                }
            }
        }
    }
    const getHotel = async (id) => {
        setIsLoading(true)
        try {
            let {data: response} = await HotelServiceV2.show(id)
            form.setFieldsValue(response)
            setIsLoading(false)
        }catch (e){
            if (e.response){
                notification.error({
                    message: t("general.error"),
                    description: e.response.data.message
                })
            }
            setIsLoading(false)
        }
    }
    useEffect(() => {
       getHotel(params.hotelId)
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.update_hotel")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                {isLoading ? <div className="site-layout-background">
                    <Spin style={{
                        display: "block",
                        margin: "0 auto",
                        marginTop: "50px",
                        marginBottom: "50px"
                    }}/>
                </div>: (
                    <div className="site-layout-background">
                        <Steps current={current} type="navigation" onChange={(selected) => setCurrent(selected)}>
                            {steps.map((item) => (
                                <Step key={item.id} title={item.title} />
                            ))}
                        </Steps>
                        <Form form={form} layout="vertical" style={{ padding: "16px 0" }} onFinish={(values) => onUpdate(values)} initialValues={{
                            general: {currency_id: "EUR", duration_type: "hours"}
                        }}>
                            {steps.map((item) => (
                                <div key={item.id} style={{ display: current === item.id ? 'block' : 'none' }}>
                                    {item.content}
                                </div>
                            ))}
                        </Form>
                        <div
                            style={{
                                marginTop: 24,
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {current > 0 && (
                                <Button
                                    style={{
                                        margin: '0 8px',
                                    }}
                                    onClick={() => prev()}
                                >
                                    Geri
                                </Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={() => next()}>
                                    İleri
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type="primary" onClick={() => onHandleSubmit()}>
                                    Kaydet
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </Content>
        </Wrapper>
    );
};

export default HotelUpdatePage;