import React, {useEffect} from 'react';
import {Breadcrumb, Col, Layout, notification, Row, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {CMHotelService} from "@services";
import {Link} from "react-router-dom";

const {Content} = Layout;

const CmContractHotelList = () => {
    const columns = [
        {
            title: 'Otel ID',
            dataIndex: 'id',
            key: 'id',
            width: 200,
        },
        {
            title: 'Otel Adı',
            dataIndex: 'name',
            key: 'name',
            width: "50%",
            render: (text, record) => <Link to={`${record.id}/contract-groups`}>{text}</Link>
        }
    ];
    const [isLoading, setIsLoading] = React.useState(true);
    const [hotels, setHotels] = React.useState({
        data: [],
        meta: {}
    });

    const getHotels = async (page) => {
        setIsLoading(true)
        try{
            let {data: response} = await CMHotelService.get(page);
            setHotels({
                data: response.data,
                meta: response.meta
            });
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    useEffect(() => {
        getHotels();
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Kanal Yönetimi - Kontrat Otelleri
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={hotels.data}
                                loading={isLoading}
                                pagination={{
                                    pageSize: hotels?.meta?.per_page,
                                    total: hotels?.meta?.total,
                                    current: hotels?.meta?.current_page,
                                }}
                                onChange={(pagination) => getHotels(pagination.current)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default CmContractHotelList;