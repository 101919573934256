import React, { useState, useEffect } from 'react';
import {
    Modal,
    Upload,
    Row,
    Col,
    Button,
    message,
    Drawer,
    Image,
    Space,
    Card,
    notification,
    InputNumber,
    Form,
    Input,
    Tabs,
    Breadcrumb,
    Layout, Table, Popconfirm, Select, DatePicker, Switch
} from 'antd';
import {Link, useParams} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouse,
    faPerson,
    faPeopleGroup,
    faSave,
    faClipboardCheck,
    faUpload,
    faPlus, faEdit, faTrash, faShapes, faGear
} from '@fortawesome/free-solid-svg-icons';
import { HotelInfoService, Authentication, WebLanguageService, HotelService } from '@services';
import Wrapper from '@components/Wrapper';
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import {useSelector} from "react-redux";

const {TabPane} = Tabs
const {Content} = Layout
const {Option} = Select

const HotelSettings = () => {
    const user = useSelector((state) => state.user.user);
    const params = useParams();
    const [settingsModalForm] = Form.useForm();
    const [footerMenusForm] = Form.useForm();
    const [isFooterMenusLoading,setIsFooterMenusLoading] = useState(true);
    const [hotelDetails,setHotelDetails] = useState({})
    const [webLanguages,setWebLanguages] = useState([])
    const [hotel,setHotel] = useState({})
    const [hotelSocialMedia,setHotelSocialMedia] = useState({})
    const [footerMenus,setFooterMenus] = useState({})
    const columns = [
        {
            title: '#',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Başlık',
            dataIndex: "title",
            render: (text, record) => text
        },
        {
            title: 'İçerik',
            dataIndex: "content",
            render: (text, record) => text
        },
        {
            title: 'İşlemler',
            render: (text, record) => (
                <Space align="middle">
                    <Popconfirm title="Silmek istediğine emin misin?" okText="Sil" cancelText="İptal" onConfirm={() => onDeleteFooterMenu(record.id)}>
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const onCreate = async (values) => {
        values = {
            "phone_number": values.phone_number ?? null,
            "whatsapp_number": values.whatsapp_number ?? null,
            "address": values.otel_location ?? null,
            "footer": values.otel_footer ?? null,
            "header": values.otel_header ?? null,
            "show_slider": values.show_slider ?? 0,
            "is_only_male_allowed": values.is_only_male_allowed ?? 0,
            "auto_send_confirm": values.auto_send_confirm ?? 0,
            "show_total_price_in_reservation": values.show_total_price_in_reservation ?? 0,
            "confirm_email": values.confirm_email ?? null,
            "whatsapp_status" :{
                "days_of_week": values.days_of_week,
                "start_time": values.start_time && moment(values.start_time).format('HH:mm:ss'),
                "end_time": values.end_time && moment(values.end_time).format('HH:mm:ss'),
            }
        }
        user?.is_super_admin && (values = {...values,auto_process_reservations:values.auto_process_reservations ?? 0})
        try {
            let { data: response } = await HotelInfoService.create(params.hotelId,values);
            getWhatsappStatus();
            getHotelDetails();
            notification.success({
                message: response.message
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onCreateFooterMenu = async (values) => {
        try {
            let { data: response } = await HotelInfoService.createFooterMenu(params.hotelId,values);
            getFooterMenus();
            notification.success({
                message: response.message
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onDeleteFooterMenu = async (id) => {
        try {
            let { data: response } = await HotelInfoService.deleteFooterMenu(params.hotelId, id);
            getFooterMenus();
            notification.success({
                message: response.message
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getHotelDetails = async () => {
        try {
            let { data: response } = await HotelInfoService.getHotelDetails(params.hotelId);
            setHotelDetails(response.data);
            settingsModalForm.setFieldsValue({
                ...response?.data?.[0],
                phone_number:response?.data?.[0]?.phone_number?.slice(1),
                whatsapp_number:response?.data?.[0]?.whatsapp_number?.slice(1),
                otel_location:response?.data?.[0]?.address,
                otel_header:response?.data?.[0]?.header,
                otel_footer:response?.data?.[0]?.footer,
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getHotelSocialMedia = async () => {
        try {
            let { data: response } = await HotelInfoService.getHotelSocialMedia(params.hotelId);
            setHotelSocialMedia(response.data);
            console.log(response.data.filter(value => value.social_id == 1)[0])
            settingsModalForm.setFieldsValue({
                twitter:response.data.filter(value => value.social_id == 1)[0].account,
                instagram:response.data.filter(value => value.social_id == 2)[0].account,
                facebook:response.data.filter(value => value.social_id == 4)[0].account,
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getFooterMenus = async () => {
        try {
            let { data: response } = await HotelInfoService.getFooterMenus(params.hotelId);
            setFooterMenus(response);
            setIsFooterMenusLoading(false)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getWhatsappStatus = async () => {
        try {
            let { data: response } = await HotelInfoService.getWhatsappStatus(params.hotelId);
            let days = [];
            if(response.data.length > 0){
                response.data.map((value, index) => {
                    days.push(value.day_of_week)
                })
                settingsModalForm.setFieldsValue({
                    days_of_week: days,
                    start_time: moment(response.data[0].start_time, 'HH:mm:ss'),
                    end_time: moment(response.data[0].end_time, 'HH:mm:ss'),
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getWebLanguages = async () => {
        try {
            let { data: { data: response } } = await WebLanguageService.getHotelLanguage(params.hotelId);
            setWebLanguages(response);
        } catch (e) {
            console.log(e);
        }
    };
    const getHotel = async (hotelId) => {
        try {
            let { data: { data: response } } = await HotelService.findById(hotelId);
            setHotel(response)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    useEffect(()=>{
        getHotelDetails()
        getHotelSocialMedia()
        getFooterMenus()
        getWhatsappStatus()
        getWebLanguages()
        getHotel(params.hotelId)
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/hotels">
                        Oteller
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {hotel?.name} Ayarlar
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                        }}
                        size="large"
                    >
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Genel Bilgiler" key="1">
                                    <Form form={settingsModalForm} onFinish={(values) => onCreate(values)} layout={'vertical'}>
                                        <Row gutter={16}>
                                            <Col span={6}>
                                                <Form.Item name={"phone_number"} label={"Telefon Numarası:"}>
                                                    <InputNumber addonBefore="+" placeholder={'Örn: 905554442222'} style={{width:"100%"}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item name={"whatsapp_number"} label={"Whatsapp Numarası:"}>
                                                    <InputNumber addonBefore="+" placeholder={'Örn: 905554442222'} style={{width:"100%"}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name={"days_of_week"} label={"Whatsapp Aktif Günleri:"}>
                                                    <Select mode="multiple" placeholder="Lütfen gün seçimi yapınız.">
                                                        <Option value="0">Pazartesi</Option>
                                                        <Option value="1">Salı</Option>
                                                        <Option value="2">Çarşamba</Option>
                                                        <Option value="3">Perşembe</Option>
                                                        <Option value="4">Cuma</Option>
                                                        <Option value="5">Cumartesi</Option>
                                                        <Option value="6">Pazar</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name={"start_time"} label={"Whatsapp Hizmet Bşl. Saati:"}>
                                                    <DatePicker picker="time" style={{width:"100%"}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name={"end_time"} label={"Whatsapp Hizmet Btş. Saati:"}>
                                                    <DatePicker picker="time" style={{width:"100%"}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item name={"otel_location"} label={"Adres Bilgisi:"}>
                                                    <TextArea rows={2} placeholder={'Adres Bilgisi Giriniz'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item name={"otel_header"} label={"Header Kodları:"}>
                                                    <TextArea rows={2} placeholder={'Header kısmına eklenecek kodları giriniz.'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item name={"otel_footer"} label={"Footer Kodları:"}>
                                                    <TextArea rows={2} placeholder={'Footer kısmına eklenecek kodları giriniz.'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={3}>
                                                <Form.Item name={"show_slider"} valuePropName="checked" tooltip="Otel resimlerinin bulunduğu slider kullanıcı ziyaret ettiğinde gösterilsin." label={"Slider Başlangıçta Gösterilsin:"}>
                                                    <Switch/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={3}>
                                                <Form.Item name={"is_only_male_allowed"} valuePropName="checked" tooltip="Tek bay ziyaretçiler otomatik reddedilsin mi?" label={"Tekli Bay Konaklama Kabul Edilmesin:"}>
                                                    <Switch/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={3}>
                                                <Form.Item
                                                    name={"show_total_price_in_reservation"}
                                                    valuePropName="checked"
                                                    tooltip="Rezervasyon formunda satış fiyatı gösterilsin mi? Pasif ise maliyet gösterilecektir."
                                                    label={"Rezervasyon Formuna Satış Fiyatı İşlensin:"}
                                                >
                                                    <Switch/>
                                                </Form.Item>
                                            </Col>
                                            {user?.is_super_admin && (
                                                <Col span={3}>
                                                    <Form.Item
                                                        name="auto_process_reservations"
                                                        valuePropName="checked"
                                                        tooltip="Rezervasyon otomatik olarak işlensin mi? (Pasif ise rezervasyon onay bekleyecektir)"
                                                        label="Rezervasyonlar otomatik olarak işlensin:"
                                                    >
                                                        <Switch/>
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            <Col span={3}>
                                                <Form.Item
                                                    name={"auto_send_confirm"}
                                                    valuePropName="checked"
                                                    tooltip="Otomatik Confirm'e Epostası Gönderilsin mi?"
                                                    label={"Otomatik Confirme Epostası Gönderilsin:"}
                                                >
                                                    <Switch/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    name="confirm_email"
                                                    label="Confirme Epostası"
                                                    rules={[
                                                        { type: 'email' , message: 'Lütfen geçerli bir eposta adresi giriniz.'},
                                                    ]}
                                                >
                                                    <Input placeholder="E-posta adresi giriniz."/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label={"Hotel Logosu:"}>
                                                    <Upload
                                                        action={`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL}/v1/hotel-logo/${params.hotelId}`}
                                                        headers={{
                                                            Authorization: `Bearer ${Authentication.getToken()}`
                                                        }}
                                                        method={'POST'}
                                                        beforeUpload={(file) => {
                                                            const isImage = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
                                                            if (!isImage) {
                                                                message.error(`${file.name} adlı dosya bir resim değildir!`);
                                                            }
                                                            return isImage || Upload.LIST_IGNORE;
                                                        }}
                                                        listType="picture"
                                                        maxCount={1}
                                                    >
                                                        <Button icon={<FontAwesomeIcon icon={faUpload} style={{marginRight:"8px"}}/>}>Logo Yükle</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item>
                                                    <Button htmlType="submit" type="primary" style={{width:"100%"}}>Genel Bilgileri Kaydet</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </TabPane>
                                <TabPane tab="Footer Menü Ekle" key="2">
                                    <Col span={24}>
                                        <Form layout="vertical" form={footerMenusForm} onFinish={(values) => onCreateFooterMenu(values)}>
                                            <Tabs>
                                                {webLanguages.map((language, index) => (
                                                    <TabPane tab={language.web_language.name} key={index}>
                                                        <Row gutter={[16]}>
                                                            <Col span={24}>
                                                                <Form.Item name={['footer',language.web_language['iso_639-1'],'title']} label="Menü Başlığı" rules={[{
                                                                    required: true,
                                                                    message: 'Lütfen menü başlığı giriniz.'
                                                                }]}>
                                                                    <Input placeholder={'Menü Başlığı'}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item name={['footer',language.web_language['iso_639-1'],'content']} label="Menü İçeriği" rules={[{
                                                                    required: true,
                                                                    message: 'Lütfen menü içeriği giriniz.'
                                                                }]}>
                                                                    <TextArea placeholder={'Menü İçeriği'}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item>
                                                                    <Button type="primary" htmlType="submit" style={{width:"100%"}}>Footer Menüsü Kaydet</Button>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                ))}
                                            </Tabs>
                                        </Form>
                                        <Table
                                            loading={isFooterMenusLoading}
                                            dataSource={footerMenus.data}
                                            columns={columns}
                                            pagination={false}
                                            rowKey={record => record.id}
                                        >
                                        </Table>
                                    </Col>
                                </TabPane>
                                <TabPane tab="Sosyal Medya Ekle" key="3">
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Form.Item name={"instagram"} label={"Instagram Adresiniz:"}>
                                                <Input style={{width:"100%"}} addonBefore="https://instagram.com/" placeholder={'Instagram kullanıcı adınızı giriniz'}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={"twitter"} label={"Twitter Adresiniz:"}>
                                                <Input style={{width:"100%"}} addonBefore="https://twitter.com/" placeholder={'Twitter kullanıcı adınızı giriniz'}/>
                                            </Form.Item>
                                        </Col>
                                        {/*<Col span={24}>
                                    <Form.Item name={"youtube"} label={"Youtube Adresiniz:"}>
                                        <Input style={{width:"100%"}} addonBefore="https://www.youtube.com/c/" placeholder={'Youtube kullanıcı adınızı giriniz'}/>
                                    </Form.Item>
                                </Col>*/}
                                        <Col span={24}>
                                            <Form.Item name={"facebook"} label={"Facebook Adresiniz:"}>
                                                <Input style={{width:"100%"}} addonBefore="https://facebook.com/" placeholder={'Facebook kullanıcı adınızı giriniz'}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                    </Space>

                </div>
            </Content>
        </Wrapper>
    );
};

export default HotelSettings;