import React from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Image,
    Layout,
    notification,
    Row,
    Select,
    Tabs,
    Switch, Radio, Upload
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ExternalHotelService, ExternalPropertyService,ExternalPopularFacilityService, ExternalDescriptionService} from "@services";
import {Link, useParams} from "react-router-dom";


const {Content} = Layout;
const {Option} = Select;
const {TextArea} = Input;

const ExternalHotels = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const [isLogoUploading, setIsLogoUploading] = React.useState(false);

    const [properties, setProperties] = React.useState({
        data: [],
        loading: true,
    });
    const [descriptions, setDescriptions] = React.useState({
        data: [],
        loading: true,
    });
    const [popularFacilities, setPopularFacilities] = React.useState({
        data: [],
        loading: true,
    });
    const [hotel, setHotel] = React.useState({
        data: {},
        loading: true,
        error: false
    });

    const [isBannerLoading, setIsBannerLoading] = React.useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = React.useState([]);

    const handleInitialValues = (data) => {
        form.setFieldsValue({
            name: data.name,
            description: data.description,
            details: {
                ages_policy: data.details.ages_policy,
                checkin_policy: data.details.checkin_policy,
                checkout_policy: data.details.checkout_policy,
                quality: data.details.quality,
            },
            properties: data.properties.map((item) => item.id),
            descriptions: data.descriptions.map((item) => item.id),
            popular_facilities: data.facilities.map((item) => item.id),
            fileInput: data.image,
        })
    }

    const getHotel = async (id) => {
        if (!id) return;
        try {
            let {data: response} = await ExternalHotelService.show(id);
            setHotel({
                ...hotel,
                data: response.data,
                loading: false,
            })
            handleInitialValues(response.data);
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getAllProperties = async () => {
        try {
            let {data: response} = await ExternalPropertyService.get();
            setProperties({
                data: response.data,
                loading: false,
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getAllPopularFacilities = async () => {
        try {
            let {data: response} = await ExternalPopularFacilityService.get();
            setPopularFacilities({
                data: response.data,
                loading: false,
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getAllDescriptions = async () => {
        try {
            let {data: response} = await ExternalDescriptionService.get();
            setDescriptions({
                data: response.data,
                loading: false,
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onEdit = async (values) => {
        try {
            let {data: response} = await ExternalHotelService.update(values, params.id);
            notification.success({
                message: 'Başarılı',
                description: response.message
            })
            getHotel(params.id);
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onSetBanner = async (id) => {
        try {
            let {data: response} = await ExternalHotelService.setBanner(params.id, id);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                getHotel(params.id);
            }
        }catch (e){
            if(e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onSubmitImage = async (values) => {
        const formData = new FormData();
        formData.append("image", values);
        try {
            let {data: response} = await ExternalHotelService.uploadImage(params.id, formData);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                await getHotel(params.id);
            }
        }catch (e){
            if(e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onRemoveImage = async (imageId) => {
        try {
            let {data: response} = await ExternalHotelService.removeImage(params.id,imageId);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })

                setIsDeleteLoading([...isDeleteLoading.filter(item => item !== imageId)]);
                await getHotel(params.id);
            }
        }catch (e){
            if(e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onSubmitLogo = async (values) => {
        try {
            let formData = new FormData();
            formData.append("logo", values);
            setIsLogoUploading(true);
            let {data: response} = await ExternalHotelService.uploadLogo(params.id, formData);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setIsLogoUploading(false);
                getHotel(params.id);
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setIsLogoUploading(false);
        }
    }

    React.useEffect(() => {
       getHotel(params.id);
    },[params.id])

    React.useEffect(() => {
        getAllProperties();
        getAllDescriptions();
        getAllPopularFacilities();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/external-management/hotels">
                        Dış Kanal Yönetimi (Oteller)
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Otel Detayı
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {hotel.data?.name}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form layout="vertical" form={form} onFinish={(values) => onEdit(values)}>
                        <Tabs>
                            <Tabs.TabPane tab="Genel Bilgiler" key="general">
                                <Row gutter={[16,16]}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="name"
                                            label="Otel Adı"
                                            rules={[{
                                                required: true,
                                                message: 'Otel adı zorunludur'
                                            }]}
                                        >
                                            <Input placeholder="Otel Adı" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="description"
                                            label="Otel Açıklaması"
                                            rules={[{
                                                required: true,
                                                message: 'Otel açıklaması zorunludur'
                                            }]}
                                        >
                                            <TextArea
                                                rows={6}
                                                placeholder="Otel açıklaması"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'ages_policy']}
                                            label="Yaş Politikası"
                                        >
                                            <Input placeholder="Yaş politikası" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'checkin_policy']}
                                            label="Giriş Politikası"
                                        >
                                            <Input placeholder="Giriş politikası" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'checkout_policy']}
                                            label="Çıkış Politikası"
                                        >
                                            <Input placeholder="Çıkış politikası" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'quality']}
                                            label="Otel Kalitesi"
                                        >
                                            <InputNumber style={{
                                                width: '100%'
                                            }} placeholder="Otel kalitesi" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'isComplete']}
                                            label="Tamamlanmış"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                checkedChildren="Evet"
                                                unCheckedChildren="Hayır"
                                                onChange={
                                                    (value) => {
                                                        form.setFieldsValue({
                                                            details: {
                                                                isComplete: value
                                                            }
                                                        })
                                                    }
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="logo"
                                            label="Logo"
                                        >
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                beforeUpload={(file) => {
                                                    onSubmitLogo(file);
                                                    return false;
                                                }}
                                            >
                                                {hotel?.data?.logo ? (
                                                    <img
                                                        src={hotel?.data?.logo}
                                                        alt="avatar"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                ) : (
                                                    <button
                                                        style={{
                                                            border: 0,
                                                            background: 'none',
                                                        }}
                                                        type="button"
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} />
                                                        <div
                                                            style={{
                                                                marginTop: 8,
                                                            }}
                                                        >
                                                            Yükle
                                                        </div>
                                                    </button>
                                                )}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Button type="primary" htmlType="submit" style={{
                                    width: '100%',
                                    marginTop: '10px'
                                }}>
                                    Bilgileri Kaydet
                                </Button>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Özellikler" key="features">
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['properties']}
                                            label="Otel Özellikleri"
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Otel özellikleri"
                                            >
                                                {properties.data.map((item) => (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['descriptions']}
                                            label="Otel Hatırlatmaları"
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Otel hatırlatmaları"
                                            >
                                                {descriptions.data.map((item) => (
                                                    <Option key={item.id} value={item.id}>{item.content}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['popular_facilities']}
                                            label="Otel Popüler Özellikleri"
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Otel popüler özellikleri"
                                            >
                                                {popularFacilities.data.map((item) => (
                                                    <Option key={item.id} value={item.id}>{item.facility}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Button type="primary" htmlType="submit" style={{
                                    width: '100%',
                                    marginTop: '10px'
                                }}>
                                    Bilgileri Kaydet
                                </Button>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Fotoğraflar" key="images">

                                <Row gutter={[16,16]}>
                                    <div style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '16px',
                                    }}>
                                        <Image.PreviewGroup>
                                            {hotel.data?.images?.map((item, key) => (
                                                <div key={key}>
                                                    <div key={key-1} style={{ position: 'relative' }}>
                                                    <Image
                                                        style={{
                                                            objectFit: 'cover',
                                                        }}
                                                        width={150}
                                                        height={150}
                                                        src={item?.url}

                                                    />
                                                        <Button
                                                            type="danger"
                                                            icon={<FontAwesomeIcon  icon={faTrash} />}
                                                            loading={isDeleteLoading.includes(item.id)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '5px',
                                                                right: '5px',
                                                                width: '30px',
                                                                height: '30px',
                                                            }}
                                                            onClick={
                                                            () => {
                                                                onRemoveImage(item.id);
                                                                setIsDeleteLoading([...isDeleteLoading, item.id]);
                                                            }
                                                        }
                                                        />
                                                    </div>
                                                    <div style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        marginTop: "5px"
                                                    }}>
                                                        <Radio
                                                            disabled={isBannerLoading}
                                                            checked={item.is_banner}
                                                            onClick={() => onSetBanner(item.id)}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                            <Input
                                                type="file"
                                                id="fileInput"
                                                onChange={(e) => {
                                                    onSubmitImage(e.target.files[0])
                                                }}
                                                accept="image/*"
                                                name="fileInput"
                                                hidden
                                                style={{
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'contain',
                                                    width: '150px',
                                                    height: '150px',
                                                    cursor: 'pointer',
                                                    outline: 'none',
                                                    display: 'none',
                                                    border: 'none',
                                                }}
                                            />
                                            <label htmlFor="fileInput" style={{
                                                background: 'url(' + process.env.REACT_APP_PROD_API_URL+"/plus.jpg" + ') no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundSize: 'contain',
                                                width: '150px',
                                                height: '150px',
                                                cursor: 'pointer',
                                                outline: 'none',
                                                border: 'none',
                                            }} />
                                        </Image.PreviewGroup>

                                    </div>
                                </Row>
                            </Tabs.TabPane>
                        </Tabs>
                    </Form>
                </div>
            </Content>
        </Wrapper>
    );
};

export default ExternalHotels;