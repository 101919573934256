import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Layout, notification, Popconfirm, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/v2/Wrapper";
import {useTranslation} from "react-i18next";
import {DiscountServiceV2} from "@services";
import {Link, useParams} from "react-router-dom";
import DiscountGroupModalForm
    from "@/views/new-version/discounts/discount-groups/components/DiscountGroupModalForm";

const {Content} = Layout;

const DiscountGroupList = () => {
    const params = useParams()
    const [createForm] = Form.useForm()
    const { t } = useTranslation()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [discountGroups, setDiscountGroups] = useState({
        data: [],
        loading: true
    })
    const columns = [
        {
            title: t("general.name"),
            dataIndex: "name",
            key: "name",
            render: (text, record) => <Link to={`/v2/hotels/${params.hotelId}/discount-groups/${record.id}`}>{text}</Link>
        },
        {
            title: t("general.actions"),
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
                <Space direction="horizontal">
                    <Popconfirm title="Silmek istediğinize emin misiniz?" cancelText="İptal" onCancel="Sil" onConfirm={() => onDelete(record.id)}>
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]
    const getDiscountGroups = async () => {
        setDiscountGroups({ ...discountGroups, loading: true })
        try {
            let {data: response} = await DiscountServiceV2.getGroups(params.hotelId)
            setDiscountGroups({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setDiscountGroups({ ...discountGroups, loading: false })
        }
    }
    const onCreate = async (formValues) => {
        try {
            let {data: response} = await DiscountServiceV2.createGroup(params.hotelId, formValues)
            if(response.success){
                notification.success({
                    message: response.message
                })
                getDiscountGroups()
                setIsModalVisible(false)
                createForm.resetFields()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onDelete = async (id) => {
        try {
            let {data: response} = await DiscountServiceV2.deleteGroup(params.hotelId, id)
            if(response.success){
                notification.success({
                    message: response.message
                })
                getDiscountGroups()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
       getDiscountGroups()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/discounts`}>
                            {t("general.discounts")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.discount_groups")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row style={{ marginBottom: "12px" }}>
                        <Button htmlType="button" type="primary" onClick={() => setIsModalVisible(true)}>
                            {t("general.create")}
                        </Button>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={discountGroups.data}
                        loading={discountGroups.loading}
                        pagination={false}
                        bordered
                    />
                </div>
            </Content>
            <DiscountGroupModalForm
                title={t("general.create_discount_group")}
                isVisible={isModalVisible}
                onFinish={onCreate}
                form={createForm}
                onCancel={() => {
                    setIsModalVisible(false)
                    createForm.resetFields()
                }}
            />
        </Wrapper>
    );
};

export default DiscountGroupList;