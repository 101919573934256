import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Space, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsProgress, faHouse, faUsers} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import {TargetService} from "@services";
import TargetDefinitionModalForm from "@/views/targets/components/TargetDefinitionModalForm";
import TargetUsersModalForm from "@/views/targets/components/TargetUsersModalForm";
import {Link} from "react-router-dom";

const {Content} = Layout;

const Targets = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [usersModalVisible, setUsersModalVisible] = useState(false);
    const [selectedTarget, setSelectedTarget] = useState(null);
    const columns = [
        {
            key: 'id',
            title: "Id",
            dataIndex: 'id',
        },
        {
            key: 'title',
            title: t('general.title'),
            dataIndex: 'title',
        },
        {
            key: 'start_date',
            title: t('general.start_date'),
            dataIndex: 'start_date',
        },
        {
            key: 'end_date',
            title: t('general.end_date'),
            dataIndex: 'end_date',
        },
        {
            key: 'details',
            title: t('general.details'),
            dataIndex: 'details',
            render: (text, record) => (
                <>
                    {record.details?.map((detail, index) => (
                        <p key={index}><strong>{detail?.title}</strong>: {detail?.value}</p>
                    ))}
                </>
            )
        },
        {
            key: 'status',
            title: t('general.status'),
            dataIndex: 'status',
            render: (text, record) => text === "expired" ? <Tag color="red">{t("general.expired")}</Tag>: <Tag color="green">{t("general.active")}</Tag>
        },
        {
            key: 'actions',
            title: t('general.actions'),
            dataIndex: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" htmlType="button" onClick={() => getTargetUsers(record.id)}>
                        <FontAwesomeIcon icon={faUsers} />
                    </Button>
                    <Button type="primary" htmlType="button">
                        <Link to={`/targets/${record.id}`}>
                            <FontAwesomeIcon icon={faBarsProgress} />
                        </Link>
                    </Button>
                </Space>
            )
        }
    ];
    const getTargetUsers = async (id) => {
        setSelectedTarget(data.data.find(target => target.id === id));
        setUsersModalVisible(true);
    }

    const [data, setData] = useState({
        data: [],
        loading: true
    })
    const getTargets = async (page = 1, per_page = 10) => {
        try {
            let {data: response} = await TargetService.get(page, per_page);
            setData({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: t('general.error'),
                    description: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getTargets();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.targets')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={() => setCreateModalVisible(true)}>
                                {t('general.create')}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={data.data}
                        loading={data.loading}
                        rowKey="id"
                    />
                </div>
            </Content>
            <TargetDefinitionModalForm
                title={t('general.create')}
                isVisible={createModalVisible}
                form={form}
                onFinish={() => {
                    setCreateModalVisible(false);
                    form.resetFields();
                    getTargets();
                }}
                onCancel={() => {
                    setCreateModalVisible(false);
                    form.resetFields();
                }}
            />
            <TargetUsersModalForm
                title={t('general.users')}
                isVisible={usersModalVisible}
                selectedTarget={selectedTarget}
                onFinish={() => {
                    setUsersModalVisible(false);
                    setSelectedTarget(null);
                    getTargets();
                }}
                onCancel={() => {
                    setUsersModalVisible(false);
                    setSelectedTarget(null);
                }}
            />
        </Wrapper>
    );
};

export default Targets;