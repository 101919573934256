import React, {useEffect} from 'react';
import {Button, Form, Modal, notification, Select, Tooltip, Tree, TreeSelect} from "antd";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {ContractGroupsService} from "@services";

const { Option } = Select;

const ContractNationalityFormModal = ({ contractGroupId, countries, nationalities }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isVisible, setIsVisible] = React.useState(false);
    const onFinish = async (values, contractGroupId) => {
        try {
            let {data: response} = await ContractGroupsService.updateNationalities(contractGroupId, values);
            if (response.success){
                notification.success({
                    message: t("general.success"),
                    description: response.message
                })
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: t("general.error"),
                    description: e.response.data.message
                })
            }
        }
    }
    return (
        <>
            <Tooltip title={t("general.nationalities")}>
                <Button type="primary" onClick={() => setIsVisible(true)}>
                    <FontAwesomeIcon icon={faGlobe} />
                </Button>
            </Tooltip>
            <Modal visible={isVisible} title="Kontrat Grubu Uyrukları" closable={false} onOk={() => form.submit()} onCancel={() => setIsVisible(false)}>
                <Form form={form} layout="vertical" onFinish={(values) => onFinish(values, contractGroupId)} initialValues={{
                    nationalities: nationalities
                }}>
                    <Form.Item
                        label="Uyruk"
                        name="nationalities"
                    >
                        <TreeSelect
                            maxTagCount="responsive"
                            placeholder="Tüm uyruklar için boş bırakınız."
                            treeDefaultExpandAll
                            treeCheckable
                            multiple
                            filterTreeNode={(input, node) => {
                                return node.title.toLowerCase().includes(input.toLowerCase());
                            }}
                            treeData={[
                                {
                                    title: t("general.continents"),
                                    value: 'continents',
                                    children: countries.data.map((country) => {
                                        return {
                                            title: country.nationality,
                                            value: country.alpha_2_code,
                                            key: country.alpha_2_code,
                                        }
                                    })
                                },
                            ]}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ContractNationalityFormModal;