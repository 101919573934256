import axios from '../../axios';

const HotelService = {
    async create(formData) {
        return await axios.post(`/v2/dashboard/hotels`, formData);
    },
    async update(id, formData) {
        return await axios.post(`/v2/dashboard/hotels/${id}`, formData);
    },
    async show(id) {
        return await axios.get(`/v2/dashboard/hotels/${id}`);
    },
    async getVouchers(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/documents/vouchers`);
    },
    async getFactSheets(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/documents/fact-sheets`);
    },
    async getSaleAgreements(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/documents/sale-agreements`);
    }
}

export default HotelService;