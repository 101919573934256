import React, {useEffect} from 'react';
import {
    Modal,
    Form,
    Input,
    Tabs,
    Row,
    Col,
    DatePicker,
    Button,
    Radio,
    Select,
    InputNumber,
    notification, Popconfirm
} from 'antd';
import moment from 'moment';
import UserSelect from "@/components/utils/UserSelect";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {ReservationService} from "@services";
import {convertCurrencyCodeToSymbol} from "@/utils/helper";

const { TabPane } = Tabs;
const { Option } = Select;

const ReservationModalForm = (props) => {
    const {
        reservation = {},
        title = "N/A",
        isVisible = false,
        onCancel = () => {},
        countries = [],
        getReservations = () => {},
    } = props;


    const [guestForm] = Form.useForm();
    const [reservationForm] = Form.useForm();
    const user = useSelector((state) => state.user.user);
    const [isReservationNumberUpdating, setIsReservationNumberUpdating] = React.useState(false)
    const { t } = useTranslation()
    const permissions = useSelector((state) => state.user.user.role.permissions);
    const onFinishReservationEdit = async (values, id) => {
        values = {
            ...values,
            created_at: values.created_at.format("YYYY-MM-DD HH:mm:ss"),
            check_in: values.check_in.format("YYYY-MM-DD"),
            check_out: values.check_out.format("YYYY-MM-DD"),
        }
        try {
            let { data: response } = await ReservationService.updateReservation(id,values);
            if(response.success){
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onFinishGuestEdit = async (values, id) => {
        values = {
            adults: values.adults.map((adult) => {
                return {
                    ...adult,
                    birth_date: adult.birth_date ? adult.birth_date.format("YYYY-MM-DD") : null,
                    passport_date: adult.passport_date ? adult.passport_date.format("YYYY-MM-DD") : null,
                }
            }),
            children: values.children.map((child) => {
                return {
                    ...child,
                    birth_date: child.birth_date ? child.birth_date.format("YYYY-MM-DD") : null,
                }
            })
        }
        try {
            let { data: response } = await ReservationService.updateGuests(id,values);
            if(response.success){
                await getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onUpdateReservationNumber = async (id) => {
        setIsReservationNumberUpdating(true)
        try {
            let {data: response} = await ReservationService.updateReservationNumber(id)
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message,
                })
                setIsReservationNumberUpdating(false)
                props.getReservations()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message,
                })
            }
            setIsReservationNumberUpdating(false)
        }
    }
    useEffect(() => {
        reservationForm.setFields([
            { name:"check_in", value: moment(reservation?.check_in) },
            { name:"check_out", value: moment(reservation?.check_out) },
            { name:"created_at", value: moment(reservation?.date) },
            { name:"total_fee", value: reservation?.amount },
            { name:"reservation_provider", value: reservation?.created_by_id ?? 0}
        ])
        guestForm.setFields([
            {
                name:"adults",
                value: reservation?.guests?.filter((guest) => guest.type === "adult").map((guest) => {
                    return {
                        gender: guest.gender === "male" ? "Erkek" : "Kadin",
                        first_name: guest.full_name.split(" ")[0],
                        last_name: guest.full_name.split(" ")[1],
                        birth_date: moment(guest.birth_date),
                        email: guest.email,
                        phone_number: guest.phone,
                        country_of_birth: guest.nationality_id,
                        identity: guest.identity,
                    }
                })
            },
            {
                name:"children",
                value: reservation?.guests?.filter((guest) => guest.type === "child").map((guest) => {
                    return {
                        gender: guest.gender === "male" ? "Erkek" : "Kadin",
                        first_name: guest.full_name.split(" ")[0],
                        last_name: guest.full_name.split(" ")[1],
                        birth_date: moment(guest.birth_date),
                    }
                })
            }
        ])
    },[reservation])
    return (
        <Modal
            visible={isVisible}
            title={title}
            closable={false}
            onOk={onCancel}
            onCancel={onCancel}
            okText="Kapat"
            cancelButtonProps={{ style: { display: 'none' } }}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)'}}
            footer={[
                <>
                    {user?.is_super_admin && (
                        <Popconfirm title={t("general.are_you_sure")} disabled={isReservationNumberUpdating} onConfirm={() => onUpdateReservationNumber(props.reservation?.uuid)}>
                            <Button key="back" type="primary" loading={isReservationNumberUpdating}>
                                {t("general.assign_an_updated_reservation_number")}
                            </Button>
                        </Popconfirm>
                    )}
                </>,
                <Button key="back" type="primary" onClick={onCancel}>
                    Kapat
                </Button>,
            ]}
        >
            <Tabs>
                <TabPane key="1" tab="Rezervasyon Bilgileri">
                    <Form form={reservationForm} layout="vertical" onFinish={(values) => onFinishReservationEdit(values, reservation?.uuid)}>
                        <Row gutter={[16,4]}>
                            <Col span={12}>
                                <Form.Item label="Rezervasyon Tarihi" name="created_at">
                                    <DatePicker
                                        format="DD.MM.YYYY HH:mm:ss"
                                        style={{width:"100%"}}
                                        showTime
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Giriş Tarihi" name="check_in">
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Çıkış Tarihi" name="check_out">
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            {permissions.includes('reservation.total_fee.edit') && (
                                <Col span={12}>
                                    <Form.Item label="Toplam Ücret" name="total_fee">
                                        <InputNumber addonAfter={convertCurrencyCodeToSymbol(reservation?.currency)} style={{width:"100%"}} min={0}/>
                                    </Form.Item>
                                </Col>
                            )}
                            {permissions.includes('reservation.provider.edit') && (
                                <Col span={12}>
                                    <Form.Item label="Rezervasyonu Oluşturan" name="reservation_provider">
                                        <UserSelect allowClear placeholder="Kullanıcı seçiniz" initial={props.reservation.users?.id ?? 0}>
                                            <Option value={0}>Widget</Option>
                                            {props.reservation.users && (
                                                <Option value={props.reservation.users.id}>{props.reservation?.users?.full_name}</Option>
                                            )}
                                        </UserSelect>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" style={{width:"100%"}}>Rezervasyon Bilgilerini Kaydet</Button>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane key="2" tab="Müşteri Bilgileri">
                    <Form form={guestForm} layout="vertical" onFinish={(values) => onFinishGuestEdit(values, props.reservation?.uuid)}>
                        <Tabs>
                            <TabPane tab="Yetişkin Bilgileri" key="1" forceRender={true}>
                                <Form.List
                                    name={['adults']}
                                    rules={[{
                                        validator: async (_, mails) => {
                                            if (!mails || mails.length < 1) {
                                                return Promise.reject(new Error('En az bir yetişkin girmelisiniz.!'));
                                            }
                                        }
                                    }]}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <div style={{ position: "relative" }} key={field.key}>
                                                    <Form.Item
                                                        label={<h4>{index + 1}. Yetişkin</h4>}
                                                        required={false}
                                                    >
                                                        <Row gutter={[16,0]}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[field.name, 'gender']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Cinsiyet"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Cinsiyet seçiniz!"
                                                                    }]}
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value="Erkek">Erkek</Radio>
                                                                        <Radio value="Kadin">Kadın</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name, 'first_name']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Ad"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Ad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Ad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'last_name']}
                                                                    label="Soyad"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Soyad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Soyad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'birth_date']}
                                                                    label="Doğum Tarihi"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Doğum tarihi giriniz!"
                                                                    }]}
                                                                >
                                                                    <DatePicker
                                                                        format="DD.MM.YYYY"
                                                                        style={{width:"100%"}}
                                                                        placeholder="Doğum Tarihi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'email']} label="E-Posta Adresi">
                                                                    <Input
                                                                        placeholder="E-Posta Adresi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'phone_number']} label="Telefon Numarası">
                                                                    <Input
                                                                        placeholder="Telefon Numarası"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'identity']} label="Kimlik Numarası">
                                                                    <Input
                                                                        placeholder="Kimlik Numarası"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'country_of_birth']} label="Uyruk">
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Uyruk"
                                                                        filterOption={(input, option) =>
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {countries?.map((country, index) => (
                                                                            <Option key={index} value={country.num_code}>{country.nationality}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                    <Button type="dashed" size="small" style={{
                                                        position: 'absolute',
                                                        right: '0',
                                                        top: '0',
                                                    }} onClick={() => remove(field.name)}>
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </Button>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    icon={<FontAwesomeIcon icon={faPlus}/>}
                                                >
                                                    Yetişkin Ekle
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </TabPane>
                            <TabPane tab="Çocuk Bilgileri" key="2" forceRender={true}>
                                <Form.List
                                    name={['children']}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <div style={{ position: "relative" }} key={field.key}>
                                                    <Form.Item
                                                        label={<h4>{index + 1}. Çocuk</h4>}
                                                        required={false}
                                                    >
                                                        <Row gutter={[16,0]}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[field.name, 'gender']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Cinsiyet"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Cinsiyet giriniz!"
                                                                    }]}
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value="Erkek">Erkek</Radio>
                                                                        <Radio value="Kadin">Kadın</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name, 'first_name']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Ad"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Ad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Ad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'last_name']}
                                                                    label="Soyad"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Soyad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Soyad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'birth_date']}
                                                                    label="Doğum Tarihi"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Doğum Tarihi giriniz!"
                                                                    }]}
                                                                >
                                                                    <DatePicker
                                                                        format="DD.MM.YYYY"
                                                                        style={{width:"100%"}}
                                                                        placeholder="Doğum Tarihi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                    <Button type="dashed" size="small" style={{
                                                        position: 'absolute',
                                                        right: '0',
                                                        top: '0',
                                                    }} onClick={() => remove(field.name)}>
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </Button>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    icon={<FontAwesomeIcon icon={faPlus}/>}
                                                >
                                                    Çocuk Ekle
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </TabPane>
                        </Tabs>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" style={{width:"100%"}}>Rezervasyon Bilgilerini Kaydet</Button>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default ReservationModalForm;