import React, { useEffect, useState } from 'react';
import { Layout, Grid } from 'antd';
import Sidebar from '@components/v2/Sidebar';
import Navbar from '@components/v2/Navbar';
import {Helmet} from "react-helmet";

const { useBreakpoint } = Grid;

const Wrapper = (props) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const { lg } = useBreakpoint();

    useEffect(() => {
        if (typeof lg !== 'undefined') {
            setIsSidebarCollapsed(! lg);
        }
    }, [lg]);

    const onToggleSiderbar = (e) => {
        setIsSidebarCollapsed(! isSidebarCollapsed);
    };

    return (
        <Layout>
            <Helmet>
                <title>Rational Tour {props.title ? `- ${props.title}`:""}</title>
            </Helmet>
            <Navbar onToggleSiderbar={onToggleSiderbar} />
            <Layout>
                <Sidebar onCollapsed={isSidebarCollapsed} />
                {props.children}
            </Layout>
        </Layout>
    );
};

export default Wrapper;