import React, {useEffect} from 'react';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ActionButton from "@/views/new-version/reservation/components/documents/ActionButton";
import ReservationFactSheetModal from "@/components/reservations/ReservationFactSheetModal";
import {Form, notification} from "antd";
import {ReservationDetailService, HotelServiceV2} from "@services";

const SendFactSheet = ({ reservation }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [factSheets, setFactSheets] = React.useState({
        data: [],
        loading: true
    })
    const [isFactSheetModalVisible, setIsFactSheetModalVisible] = React.useState(false)
    const [isFactSheetSending, setIsFactSheetSending] = React.useState(false)
    const getHotelFactSheets = async (hotelId) => {
        setFactSheets({ data: [], loading: true })
        try {
            let {data: response} = await HotelServiceV2.getFactSheets(hotelId);
            setFactSheets({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setFactSheets({ data: [], loading: false })
        }
    }
    const onSendFactSheet = async (values) => {
        setIsFactSheetSending(true)
        try {
            let {data: response} = await ReservationDetailService.sendReservationFactSheet(reservation.uuid, values);
            if (response.success){
                form.resetFields()
                setIsFactSheetModalVisible(false)
                notification.success({
                    message: response.message
                });
            }
            setIsFactSheetSending(false)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setIsFactSheetSending(false)
        }
    }
    useEffect(() =>{
        getHotelFactSheets(reservation.hotel_id)
    },[])
    return (
        <React.Fragment>
            <ActionButton
                icon={faPaperPlane}
                title={t("general.send_fact_sheet")}
                onClick={() => setIsFactSheetModalVisible(true)}
                loading={isFactSheetSending}
            />
            <ReservationFactSheetModal
                title="Müşteriye Fact Sheet Gönder"
                form={form}
                isVisible={isFactSheetModalVisible}
                onCancel={() => setIsFactSheetModalVisible(false)}
                onFinish={onSendFactSheet}
                loading={isFactSheetSending}
                email={reservation?.guests?.[0]?.email}
                translations={factSheets.data}
            />
        </React.Fragment>
    );
};

export default SendFactSheet;