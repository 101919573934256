import React, {useEffect} from 'react';
import {Breadcrumb, Col, Form, Layout, notification, Row, Table, DatePicker, Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faSave} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useParams} from "react-router-dom";
import {CMPeriodDatesService} from "@services";

const {Content} = Layout;
const {RangePicker} = DatePicker;

const CmPeriodDateList = () => {
    const columns = [
        {
            title: 'Rezervasyon Giriş Tarihi',
            dataIndex: 'start_date',
            key: 'start_date'
        },
        {
            title: 'Rezervasyon Çıkış Tarihi',
            dataIndex: 'end_date',
            key: 'end_date'
        }
    ];
    const [isLoading, setIsLoading] = React.useState(true);
    const [periodDates, setPeriodDates] = React.useState({
        data: [],
        meta: {},
        hotel: {}
    });
    const [form] = Form.useForm();
    const params = useParams()
    const getPeriodDates = async (periodId, hotelId, page) => {
        setIsLoading(true)
        try{
            let {data: response} = await CMPeriodDatesService.get(periodId, hotelId, page);
            setPeriodDates({
                data: response.data,
                meta: response.meta,
                hotel: response.hotel
            });
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const onCreate = async (values) => {
        values = {...values, hotel_id: params.hotelId, period_id: params.periodId, start_date: values.date_range[0].format('YYYY-MM-DD'), end_date: values.date_range[1].format('YYYY-MM-DD')}
        try {
            let response = await CMPeriodDatesService.create(params.periodId, values);
            setPeriodDates({
                ...periodDates,
                data: [...periodDates.data, response.data.data]
            });
            form.resetFields();
            notification.success({
                message: response.data.message,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    useEffect(() => {
        getPeriodDates(params.periodId, params.hotelId);
    },[params.hotelId, params.periodId])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/channel-management/hotels">
                        Kanal Yönetimi - Tesisler
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {periodDates?.hotel?.name ?? '...'} - Periyot Tarihleri Listesi
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Form layout="vertical" onFinish={onCreate} form={form}>
                                <Row gutter={[16,4]}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Rezervasyon Tarih Aralığı"
                                            name="date_range"
                                            rules={[{ required: true, message: 'Lütfen rezervasyon tarih aralığını giriniz!' }]}
                                        >
                                            <RangePicker
                                                placeholder={['Rezervasyon Giriş Tarihi', 'Rezervasyon Çıkış Tarihi']}
                                                format="DD.MM.YYYY"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16,4]}>
                                    <Col span={24}>
                                        <Button htmlType="submit" style={{ width:"100%" }} icon={<FontAwesomeIcon style={{ marginRight: "10px"}} icon={faSave}/>} type="primary">Kaydet</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={periodDates.data}
                                loading={isLoading}
                                pagination={{
                                    pageSize: periodDates?.meta?.per_page,
                                    total: periodDates?.meta?.total,
                                    current: periodDates?.meta?.current_page,
                                }}
                                onChange={(pagination) => getPeriodDates(params.periodId, params.hotelId, pagination.current)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default CmPeriodDateList;