import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Layout,
  Select,
  Row,
  Col,
  Button,
  Space,
  Form,
  notification,
  Table, Input, Popconfirm,
} from "antd";
import Wrapper from "@components/Wrapper";
import {faEdit, faHouse, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CurrentDeficitService, CariService } from "../../services";
import CurrentDeficitModalForm from "@components/current-deficit/CurrentDeficitModalForm";
import {useTranslation} from "react-i18next";

const { Content } = Layout;

function CurrentDeficit() {
  const { t } = useTranslation()
  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [cari, setCaris] = useState([]);
  const [currentDeficit, setCurrentDeficit] = useState({
    data: [],
    meta: {},
    filters: {},
    params: {},
  });
  const [selectedCurrentDeficit, setSelectedCurrentDeficit] = useState(null);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const getColumnSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }}>
          <Input
              placeholder="Ara..."
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
                type="primary"
                onClick={() => confirm()}
                icon={<FontAwesomeIcon icon={faSearch} />}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Ara
            </Button>
            <Button
                onClick={() => {
                  clearFilters();
                  confirm();
                }}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Sıfırla
            </Button>
          </Space>
        </div>
    ),
  });
  const columns = [
    {
      key: "cari_type",
      title: `${t('general.cari_type')}`,
      dataIndex: "cari",
      filters: currentDeficit.filters?.caris,
      render: (text) => text.name,
    },
    {
      key: "currency",
      title: `${t('general.currency')}`,
      dataIndex: "currency",
      filters: currentDeficit.filters?.currencies,
      render: (text) => `${text.currency} | ${text.symbol}`,
    },
    {
      key: "company_title",
      title: `${t('general.sign_title')}`,
      dataIndex: "company_title",
      ...getColumnSearchProps(),
    },
    {
      key: "bill_title",
      title: `${t('general.invoice_title')}`,
      dataIndex: "bill_title",
      ...getColumnSearchProps(),
    },
    {
      key: "address",
      title: `${t('general.address')}`,
      dataIndex: "address",
      ...getColumnSearchProps(),
    },
    {
      key: "tax_administration",
      title: `${t('general.tax_administration')}`,
      dataIndex: "tax_administration",
      ...getColumnSearchProps(),
    },
    {
      key: "tax_number",
      title: `${t('general.tax_number')}`,
      dataIndex: "tax_number",
      ...getColumnSearchProps(),
    },
    {
      key: "authorized_person",
      title: `${t('general.authorized_person')}`,
      dataIndex: "authorized_person",
      ...getColumnSearchProps(),
    },
    {
      key: "authorized_phone",
      title: `${t('general.authorized_phone')}`,
      dataIndex: "authorized_phone",
      ...getColumnSearchProps(),
    },
    {
      key: "authorized_email",
      title: `${t('general.authorized_email')}`,
      dataIndex: "authorized_email",
      ...getColumnSearchProps(),
    },
    {
      key: "actions",
      title:`${t('general.actions')}`,
      render: (text,record) => (
        <Space align="middle">
          <Button type="primary" onClick={() => onEdit(record.id)}>
            <FontAwesomeIcon icon={faEdit}/>
          </Button>
          <Popconfirm title={t('general.are_you_sure_delete')} onConfirm={() => onDelete(record.id)}>
            <Button type="danger">
              <FontAwesomeIcon icon={faTrash}/>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    getCurrentDeficit(pagination.current, pagination.pageSize, filters, sorter);
  }

  const getCurrentDeficit = async (page = 1, per_page= 10, filters = {}, sorter) => {
    setIsLoadingTable(true)
    try {
      let {data: response} = await CurrentDeficitService.get(page, per_page, filters);
      setCurrentDeficit({
        data: response.data,
        meta: response.meta,
        filters: response.filters,
      });
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  }

  const onDelete = async (id) => {
    try {
      let {data: response} = await CurrentDeficitService.delete(id);
      if (response.success) {
        getCurrentDeficit();
        notification.success({
          message: response.message,
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  }

  const onEdit = async (id) => {
    try {
      let {data: response} = await CurrentDeficitService.getByCariId(id);
      setSelectedCurrentDeficit(response.data);
      editForm.setFieldsValue({
        ...response.data,
        currency: response.data.currency.id,
      });
      setIsModalVisible(true);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  }

  const onUpdate = async (values, id) => {
    try {
      let {data: response} = await CurrentDeficitService.update(values, id);
      if(response.success){
        setIsModalVisible(false);
        getCurrentDeficit();
        notification.success({
          message: response.message,
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  }

  const onCreate = async (values) => {
    setIsLoadingTable(true)
    try {
      let {data: response} = await CurrentDeficitService.create(values);
      if(response.success){
        setIsCreateModalVisible(false);
        getCurrentDeficit();
        notification.success({
          message: response.message,
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
        setIsLoadingTable(false)
      }
    }
  }

  useEffect(() => {
    const getCaris = async () => {
      try {
        let {data: response} = await CariService.get();
        setCaris(response.data);
      } catch (e) {
        if (e.response) {
          notification.error({
            message: e.response.data.message,
          });
        }
      }
    }
    getCaris();
    getCurrentDeficit()
  },[])

  return (
    <>
      <Wrapper>
        <Content className="m-25">
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHouse} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {t('general.caris')}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background">
            <Table
                columns={columns}
                scroll={{ x: 1300, y: 800 }}
                dataSource={currentDeficit.data}
                rowKey="id"
                loading={isLoadingTable}
                bordered={true}
                onChange={handleTableChange}
                title={() => (
                    <Space size={16}>
                      <Button
                          type="primary"
                          icon={<FontAwesomeIcon icon={faPlus} />}
                          onClick={() => setIsCreateModalVisible(true)}
                      >
                        {t('general.create_new')}
                      </Button>
                    </Space>
                )}
                pagination={{
                  total: currentDeficit.meta.total,
                  pageSize: currentDeficit.meta.per_page,
                  current: currentDeficit.meta.current_page,
                  showSizeChanger: true,
                }}
            />
          </div>
        </Content>
        <CurrentDeficitModalForm
            type="create"
            isVisible={isCreateModalVisible}
            form={createForm}
            title={t('general.create_new')}
            onCancel={() => {
              setIsCreateModalVisible(false);
              createForm.resetFields();
            }}
            onFinish={onCreate}
            caris={cari}
        />
        <CurrentDeficitModalForm
            type="edit"
            isVisible={isModalVisible}
            form={editForm}
            title={t('general.edit')}
            onCancel={() => {
              setIsModalVisible(false);
              editForm.resetFields();
            }}
            onFinish={onUpdate}
            caris={cari}
            data={selectedCurrentDeficit}
        />
      </Wrapper>
    </>
  );
}
export default CurrentDeficit;
