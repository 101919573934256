import React, { useEffect, useState } from 'react';
import {Layout, Breadcrumb, Button, Table, notification, Form, Space, Popconfirm, Tooltip} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouse,
    faEdit,
    faTrash,
    faPlus,
    faLanguage,
    faPercent, faBriefcase, faEye, faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Wrapper from '@components/Wrapper';
import { ContractService, ContractCommissionService, AdditionalService } from '@services';
import ContractGroupsModalForm from "../../components/contracts/ContractGroupsModalForm";
import ContractGroupsService from "../../services/contracts/ContractGroups.service";
import contractGroupsService from "../../services/contracts/ContractGroups.service";
import ContractNationalityModalForm from "../../components/contracts/ContractNationalityModalForm";
import ContractGroupLanguageModalForm from '../../components/contracts/ContractGroupLanguageModalForm';
import CountriesService from "../../services/countries/Countries.service";
import ContractGroupLanguage from '../../services/contract_group_languages/ContractGroupLanguage.service';
import ContractCommissionModalForm from "@components/contracts/ContractCommissionModalForm";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import ContractCurrencyModalForm from "../../components/contracts/ContractCurrencyModalForm";
import CurrenciesService from "../../services/currencies/Currencies.service";
import {useSelector} from "react-redux";
import ContractGroupCariModalForm from "@components/contracts/ContractGroupCariModalForm";
import ContractAdditionalServiceModalForm from "@components/contracts/ContractAdditionalServiceModalForm";
import moment from "moment";
import {useTranslation} from "react-i18next";
import ContractGroupPaymentPeriodModalForm from "@components/contracts/ContractGroupPaymentPeriodModalForm";

const { Content } = Layout;

const ContractGroups = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const params = useParams();
    const [isDebtWorks, setIsDebtWorks] = useState(false);
    const permissions = useSelector((state) => state.user.user.role.permissions);

    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [conditionGroups, setConditionGroups] = useState({});
    const [contracts, setContracts] = useState([]);
    const [isContractCommissionModalVisible, setIsContractCommissionModalVisible] = useState(false)
    const [isContractCurrencyModalVisible, setIsContractCurrencyModalVisible] = useState(false)
    const [isCariEditModalVisible, setIsCariEditModalVisible] = useState(false)
    const [isAdditionalServiceModalVisible, setIsAdditionalServiceModalVisible] = useState(false)
    const [additionalServices, setAdditionalServices] = useState({
        data: [],
        loading:true
    })

    const [isWebLanguageModalVisible, setIsWebLanguageModalVisible] = useState(false);
    const [isPaymentPeriodModalVisible, setIsPaymentPeriodModalVisible] = useState(false);
    const [createLanguageModalForm] = Form.useForm();
    const [contractCommissionForm] = Form.useForm();
    const [contractCurrencyForm] = Form.useForm();
    const [cariEditModalForm] = Form.useForm();
    const [additionalServiceForm] = Form.useForm();
    const columns = [
        {
            title: '#',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t('general.contract_group_name')}`,
            dataIndex: 'title',
            render: (text, record) => (
                <Link to={`/contract-hotels/${params.hotelId}/contract-groups/${record.id}/contracts`}>{record.name}</Link>
            )
        },
        {
            title: `${t('general.actions')}`,
            render: (text, record) => (
                <Space align="middle">
                    <Tooltip title={!permissions.includes('contract.group.edit') ? 'Bu işlem için yetkiniz bulunmuyor.': 'Düzenle'}>
                        <Button type="primary" disabled={!permissions.includes('contract.group.edit')} onClick={() => {
                            setSelectedContractGroup1(record.id);
                            onEdit(record.id)
                        }}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Tooltip>
                    <Button type="primary" onClick={() => { setSelectedContractGroup(record.id); setIsNationalityCreateModalVisible(true) }}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Tooltip title={!permissions.includes('contract.group.delete') ? 'Bu işlem için yetkiniz bulunmuyor.':'Sil'}>
                        <Popconfirm disabled={!permissions.includes('contract.group.delete')} title="Silmek istediğine emin misin?" onConfirm={() => onDelete(record.id)} okText="Sil" cancelText="İptal">
                            <Button type="danger" disabled={!permissions.includes('contract.group.delete')}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                    <Button type="danger" onClick={() => onHideContractGroup(record.id)}>
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                    <Button type="primary" onClick={() => { setIsWebLanguageModalVisible(true); setSelectedContractGroup1(record.id); }}>
                        <FontAwesomeIcon icon={faLanguage} />
                    </Button>
                    <Button type="primary" href={`/contract-hotels/${params.hotelId}/contract-groups/${record.id}/commissions`}>
                        <FontAwesomeIcon icon={faPercent} />
                    </Button>
                    <Button type="primary" onClick={() => { setSelectedContractGroup(record.id); getCurrency(record.id) }}>
                        <FontAwesomeIcon icon={faDollarSign} />
                    </Button>
                    <Button type="primary" onClick={() => { setSelectedContractGroup1(record.id);onCariEdit(record.id) }}>
                        <FontAwesomeIcon icon={faDollarSign} />
                    </Button>
                    <Button type="primary" onClick={() => getContractAdditionalServices(record.id)}>
                        <FontAwesomeIcon icon={faBriefcase} />
                    </Button>
                    <Tooltip title={t("general.payment_periods")}>
                        <Button type="primary" htmlType="button" onClick={() => {
                            setSelectedContractGroup1(record)
                            setIsPaymentPeriodModalVisible(true)
                        }}>
                            <FontAwesomeIcon icon={faMoneyBill} />
                        </Button>
                    </Tooltip>
                </Space>
            )
        }
    ];

    /* create modal form states */
    const [createModalForm] = Form.useForm();
    const [createNationalityModalForm] = Form.useForm();
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isCreateNationalityModalVisible, setIsNationalityCreateModalVisible] = useState(false);

    /* edit modal form states */
    const [editModalForm] = Form.useForm();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedConditionGroup, setSelectedConditionGroup] = useState({});
    const [selectedContractGroup, setSelectedContractGroup] = useState();
    const [selectedContractGroups, setSelectedContractGroups] = useState();
    const [selectedContractGroup1, setSelectedContractGroup1] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [selectedCurrencyId, setCurrencyForContractGroup] = useState();

    useEffect(() => {
        getContractGroups();
        getCurrencies();
        getAdditionalServices();
    }, []);



    const getContracts = async (contractGroupId) => {
        try {
            let { data: contracts } = await ContractService.getByGroupId(contractGroupId);
            setContracts(contracts);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onHideContractGroup = async (id) => {
        try {
            let { data: response } = await ContractService.hideContractGroup(id);
            if (response.success){
                getContractGroups();
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getCurrency = async (contractGroupId) => {
        try {
            let data = CurrenciesService.getByGroupId(contractGroupId).then(response => {
                setCurrencyForContractGroup(response.data);
                setIsContractCurrencyModalVisible(true);
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getCurrencies = async () => {
        try {
            let { data: currencies } = await CurrenciesService.get();
            setSelectedCurrency(currencies);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getContractGroups = async () => {
        try {
            let { data: contractGroups } = await contractGroupsService.get(params.page, params.hotelId);
            setConditionGroups(contractGroups);
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onCreate = async (values) => {
        values = {
            ...values,
            hotel_id: params.hotelId
        }
        try {
            let { data: response } = await ContractGroupsService.create(values);

            if (response.success) {
                setIsLoadingTable(true);
                setIsCreateModalVisible(false);
                createModalForm.resetFields();
                getContractGroups();

                notification.success({
                    message: response.message
                });

                navigate(`/contract-hotels/${params.hotelId}/contract-groups`);
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }

            console.log(e);
        }
    };
    const onCreateContractLanguage = async (values) => {
        try {
            let { data: response } = await ContractGroupLanguage.create(values, selectedContractGroup1);

            if (response.success) {
                setIsCreateModalVisible(false);
                getContractGroups();

                notification.success({
                    message: response.message
                });

                navigate(`/contract-hotels/${params.hotelId}/contract-groups`);
            }
        } catch (e) {
            if (e.response) {
                console.log(e.response.data.message)
                notification.error({
                    message: e.response.data.message
                });
            }

            console.log(e);
        }
    };

    const onEdit = async (id) => {
        try {
            let { data: {data: response } } = await ContractGroupsService.findById(id);
            editModalForm.setFieldsValue({
                name: response.name,
                debt_details: response.debt_details?.map((item) => {
                    return {
                        "debt_value": item.debt_value,
                        "debt_start_date": moment(item.debt_start_date),
                        "debt_end_date": moment(item.debt_end_date),
                    }
                }),
            });
            setIsDebtWorks(response.debt_details.length > 0)
            setIsEditModalVisible(true);
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onCariEdit = async (id) => {
        try {
            let { data: {data: response } } = await ContractGroupsService.findById(id);
            if(response.cari){
                cariEditModalForm.setFieldsValue({
                    cari: response?.cari?.cari?.name + " | " + response?.cari?.company_title + " - " + response?.cari?.bill_title,
                });
            }
            let {data: currency} = await CurrenciesService.getByGroupId(id)
            setCurrencyForContractGroup(currency);
            setIsCariEditModalVisible(true);
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onCreateContractCari = async (values) => {
        try {
            let { data: response } = await ContractGroupsService.appendCari(values, selectedContractGroup1);
            if(response.success){
                cariEditModalForm.resetFields();
                setIsCariEditModalVisible(false);
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }

    const onUpdate = async (values) => {
        values = {
            ...values,
            debt_details: values?.debt_details?.map((item) => {
                return {
                    ...item,
                    debt_start_date: item.debt_start_date.format("YYYY-MM-DD"),
                    debt_end_date: item.debt_end_date.format("YYYY-MM-DD"),
                }
            })
        }
        try {
            let { data: response } = await ContractGroupsService.update(selectedContractGroup1, values);
            if (response.success) {
                setIsLoadingTable(true);
                getContractGroups();
                setIsEditModalVisible(false);
                editModalForm.resetFields();

                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }

        }
    };

    const onDelete = async (id) => {
        try {
            let { data: response } = await contractGroupsService.delete(id);

            if (response.success) {
                getContractGroups();
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onCreateNationalty = async (values) => {
        values = { ...values, contract_group_id: selectedContractGroup }
        try {
            let { data: response } = await CountriesService.create(values);

            if (response.success) {
                setIsNationalityCreateModalVisible(false)
                getContractGroups();
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }

            console.log(e);
        }
    };
    const onCreateContractCommission = async (values, contractGroupId) => {
        values = {
            ...values,
            contract_group_id: contractGroupId,
            commissions: values?.commissions?.map((commission) => {
                return {
                    ...commission,
                    start_date: commission.date_range[0].format("YYYY-MM-DD"),
                    end_date: commission.date_range[1].format("YYYY-MM-DD"),
                }
            })
        }
        try {
            let { data: response } = await ContractCommissionService.create(values);
            if (response.success) {
                setIsContractCommissionModalVisible(false)
                /*getContractGroups();*/
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
            console.log(e);
        }
    }
    const clearCommissions = async (contractGroupId) => {
        let values = {
            contracts: [],
            contract_group_id: contractGroupId,
            commissions: [],
        }
        try {
            let { data: response } = await ContractCommissionService.create(values);
            if (response.success) {
                setIsContractCommissionModalVisible(false)
                /*getContractGroups();*/
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
            console.log(e);
        }
    }
    const onCreateContractCurrencies = async (values) => {
        values = {
            contract_group_id: selectedContractGroup,
            ...values
        }
        try {
            let { data: response } = await CurrenciesService.create(values);
            if (response.success) {
                setIsContractCurrencyModalVisible(false)
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
            console.log(e);
        }
    }
    const onCreateContractAdditionalService = async (values,contractId) => {
        try {
            let { data: response } = await ContractGroupsService.createAdditionalService(values,contractId);
            if(response.success){
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getContractAdditionalServices = async (contractGroupId) => {
        try {
            let { data: response } = await ContractGroupsService.findById(contractGroupId);
            response.data?.additional_services?.map((service,i) => {
                additionalServiceForm.setFields([{name: ['additional_services',i,'id'],value: service?.additional_service?.id}])
                additionalServiceForm.setFields([{name: ['additional_services',i,'price'],value: service?.price}])
            })

            setSelectedContractGroups(response);
            setIsAdditionalServiceModalVisible(true);
        }catch (e){
            console.log(e)
        }
    }

    const getAdditionalServices = async (contractId) => {
        try {
            let { data: response } = await AdditionalService.get("all");
            setAdditionalServices({
                data: response.data,
                loading: false
            })
        }catch (e){
            console.log(e)
        }
    }

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/contract-hotels">
                        {t('general.hotels')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/contract-hotels/${params.hotelId}/contract-groups`}>
                        {t('general.contract_groups')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Tooltip title={!permissions.includes('contract.group.create') ? 'Bu işlem için yetkiniz bulunmuyor.': 'Yeni Oluştur'}>
                        <Button type="primary" disabled={!permissions.includes('contract.group.create')} className="mb-10" onClick={() => setIsCreateModalVisible(true)}>
                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                            {t('general.new_contract_group')}
                        </Button>
                    </Tooltip>

                    <Table
                        loading={isLoadingTable}
                        columns={columns}
                        dataSource={conditionGroups.data}
                        rowKey="id"
                        bordered
                    >
                    </Table>
                </div>
            </Content>

            <ContractGroupsModalForm
                isVisible={isCreateModalVisible}
                title={t('general.new_contract_group')}
                form={createModalForm}
                onFinish={onCreate}
                onCancel={() => setIsCreateModalVisible(false)}
                setIsDebtWorks={setIsDebtWorks}
                isDebtWorks={isDebtWorks}
            />

            <ContractGroupsModalForm
                isVisible={isEditModalVisible}
                title={t('general.edit_contract_group')}
                form={editModalForm}
                onFinish={onUpdate}
                onCancel={() => setIsEditModalVisible(false)}
                data={selectedConditionGroup}
                setIsDebtWorks={setIsDebtWorks}
                isDebtWorks={isDebtWorks}
            />
            <ContractNationalityModalForm
                isVisible={isCreateNationalityModalVisible}
                title={t('general.nationality')}
                form={createNationalityModalForm}
                onFinish={onCreateNationalty}
                contract_group_id={selectedContractGroup}
                onCancel={() => setIsNationalityCreateModalVisible(false)}
            />
            <ContractGroupLanguageModalForm
                isVisible={isWebLanguageModalVisible}
                title={t('general.languages')}
                form={createLanguageModalForm}
                onFinish={onCreateContractLanguage}
                contract_group_id={selectedContractGroup1}
                hotelId={params.hotelId}
                onCancel={() => setIsWebLanguageModalVisible(false)}
            />
            <ContractCommissionModalForm
                isVisible={isContractCommissionModalVisible}
                onCancel={() => { setIsContractCommissionModalVisible(false) }}
                title={t('general.contract_commissions')}
                form={contractCommissionForm}
                onFinish={onCreateContractCommission}
                contract_group_id={selectedContractGroup}
                contracts={contracts}
                clearComissions={clearCommissions}
                commissions={conditionGroups?.data?.filter((group) => group.id === selectedContractGroup)[0]}
            />
            <ContractCurrencyModalForm
                isVisible={isContractCurrencyModalVisible}
                onCancel={() => { setIsContractCurrencyModalVisible(false) }}
                title={t('general.contract_currencies')}
                currency_id={selectedCurrencyId}
                contract_group_id={selectedContractGroup}
                form={contractCurrencyForm}
                onFinish={onCreateContractCurrencies}
                currencies={selectedCurrency}
            />
            <ContractGroupCariModalForm
                isVisible={isCariEditModalVisible}
                onCancel={() => { setIsCariEditModalVisible(false); cariEditModalForm.resetFields() }}
                title={t('general.assign_cari')}
                form={cariEditModalForm}
                onFinish={onCreateContractCari}
                currency={selectedCurrencyId}
            />
            <ContractAdditionalServiceModalForm
                isVisible={isAdditionalServiceModalVisible}
                title={t('general.add_additional_service')}
                form={additionalServiceForm}
                onFinish={onCreateContractAdditionalService}
                onCancel={() => {setIsAdditionalServiceModalVisible(false);additionalServiceForm.resetFields();}}
                selectedContractGroup={selectedContractGroups}
                additionalServices={additionalServices.data}
            />
            <ContractGroupPaymentPeriodModalForm
                title={t("general.payment_periods")}
                contractGroup={selectedContractGroup1}
                onSuccess={getContractGroups}
                visible={isPaymentPeriodModalVisible}
                onCancel={() => setIsPaymentPeriodModalVisible(false)}
            />
        </Wrapper>
    )
};

export default ContractGroups;