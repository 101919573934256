import React, {useState} from 'react';
import {Breadcrumb, Button, Col, DatePicker, Layout, notification, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useTranslation} from "react-i18next";
import { ProgressPaymentService } from "@services"
import moment from "moment";

const {Content} = Layout;
const { RangePicker } = DatePicker;
const ProgressPayment = () => {
    const { t } = useTranslation()
    const columns = [
        {
            key: 'reservation_id',
            title: t("general.reservation_no"),
            dataIndex: 'uuid',
        },
        {
            key: 'hotel',
            title: t("general.hotel"),
            dataIndex: 'hotel',
        },
        {
            key: 'guest',
            title: t("general.guest"),
            dataIndex: 'guest',
        },
        {
            key: 'check_date',
            title: t("general.check_in_out"),
            dataIndex: 'check_date',
        },
        {
            key: 'total_price',
            title: t("general.reservation_amount"),
            dataIndex: 'total_price',
            render: (text, record) => text + " " + record.currency
        },
        {
            key: 'discounted_fee',
            title: t("general.discount_reservation_amount"),
            dataIndex: 'discounted_fee',
            render: (text, record) => text + " " + record.currency
        },
        {
            key: 'cost',
            title: t("general.cost"),
            dataIndex: 'cost',
            render: (text, record) => text + " " + record.currency
        },
        {
            key: 'profit',
            title: t("general.progress_payment"),
            dataIndex: 'profit',
            render: (text, record) => text + " " + record.currency
        },,
        {
            key: 'percentage_profit',
            title: t("general.percentage_profit"),
            dataIndex: 'percentage_profit',
            render: (text, record) => text
        },
        {
            key: 'created_at',
            title: t("general.reservation_date"),
            dataIndex: 'created_at',
        }
    ];
    const [dateRange, setDateRange] = useState([])
    const [isExporting, setIsExporting] = useState(false)
    const [data, setData] = useState({
        data:[],
        loading: false,
        meta:{}
    })
    const getData = async (page = 1, pageSize = 10) => {
        if (!dateRange){
            notification.error({
                message: t("general.error"),
            })
        }
        setData({
            ...data,
            loading: true
        })
        try {
            let {data: response} = await ProgressPaymentService.get({
                start_date: dateRange[0].format("YYYY-MM-DD"),
                end_date: dateRange[1].format("YYYY-MM-DD"),
                page: page,
                pageSize: pageSize
            })
            setData({
                data: response.data,
                loading: false,
                meta: response.meta,
                total: {
                    total_fee: response?.total_fee || 0,
                    cost: response?.cost || 0,
                    discounted_total: response?.discounted_total || 0,
                    profit: response?.profit || 0,
                    currency: response?.data?.length > 0 ? response?.data[0]?.currency : "N/A"
                }
            })
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message,
                })
            }
            setData({
                ...data,
                loading: false
            })
        }
    }
    const onExport = async () => {
        if (!dateRange){
            notification.error({
                message: t("general.error"),
            })
        }
        setIsExporting(true)
        try {
            let response = await ProgressPaymentService.export({
                start_date: dateRange[0].format("YYYY-MM-DD"),
                end_date: dateRange[1].format("YYYY-MM-DD"),
            })
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'hak_edis_'+dateRange[0].format('YYYY_MM_DD')+'_'+dateRange[1].format('YYYY_MM_DD')+'.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            setIsExporting(false)
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message,
                })
            }
            setIsExporting(false)
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.progress_payment")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row>
                        <Col span={24}>
                            <Space>
                                <RangePicker
                                    format="DD.MM.YYYY"
                                    placeholder={[t("general.start_date"), t("general.end_date")]}
                                    onChange={(date, dateString) => setDateRange(date)}
                                />
                                <Button htmlType="button" onClick={() => getData()}>
                                    Search
                                </Button>
                                <Button type="primary" htmlType="button" onClick={() => onExport()} disabled={dateRange.length <= 0 || isExporting}>
                                    Excel
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={data.data}
                        pagination={{
                            total: data.meta?.total || 0,
                            pageSize: data.meta?.per_page || 10,
                            current: data.meta?.current_page || 1,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "50", "100", "200", "500", "1000"],
                            onChange: (page, pageSize) => {
                                getData(page,pageSize)
                            }
                        }}
                        rowKey={(record) => record.uuid}
                        footer={() => (
                            <>
                                {data?.data?.length > 0 && (
                                    <Space direction="horizontal">
                                        <Space direction="horizontal">
                                            <span style={{fontWeight: "bold"}}>{t("general.total_reservation_price")}:</span>
                                            <span>{data?.total?.total_fee ? data?.total?.total_fee?.toFixed(2): ""} {data?.total?.currency}</span>
                                        </Space>
                                        <Space direction="horizontal">
                                            <span style={{fontWeight: "bold"}}>{t("general.total_cost")}:</span>
                                            <span>{data?.total?.cost ? data?.total?.cost?.toFixed(2): ""} {data?.total?.currency}</span>
                                        </Space>
                                        <Space direction="horizontal">
                                            <span style={{fontWeight: "bold"}}>{t("general.total_discounted_price")}:</span>
                                            <span>{data?.total?.discounted_total ? data?.total?.discounted_total?.toFixed(2): ""} {data?.total?.currency}</span>
                                        </Space>
                                        <Space direction="horizontal">
                                            <span style={{fontWeight: "bold"}}>{t("general.total_progress_payment")}:</span>
                                            <span>{data?.total?.profit ? data?.total?.profit?.toFixed(2): ""} {data?.total?.currency}</span>
                                        </Space>
                                    </Space>
                                )}
                            </>
                        )}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default ProgressPayment;