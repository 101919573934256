import React, {useEffect} from 'react';
import {Col, Form, Input, Modal, notification, Radio, Row, Select} from "antd";
import {ContractGroupsService, ContractService} from "@services";
import {useParams} from "react-router-dom";
const {Option} = Select;
const DiscountGroupModalForm = (props) => {
    const params = useParams()
    const [contractGroups, setContractGroups] = React.useState({
        data: [],
        loading: true,
    });
    const [contracts, setContracts] = React.useState({
        data: [],
        loading: true,
    });
    const getContractGroups = async (page = 1) => {
        setContractGroups({ ...contractGroups, loading: true });
        try {
            let {data: response} = await ContractGroupsService.get(page, params.hotelId);
            setContractGroups({
                data: response.data,
                loading: false,
                meta: response.meta
            });
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
            setContractGroups({ ...contractGroups, loading: true });
        }
    }
    const getContracts = async (contractGroupId, page = 1) => {
        props.form.setFieldsValue({ contract_id: undefined });
        setContracts({ ...contracts, loading: true, data: [] });
        try {
            let {data: response} = await ContractService.demoGet(params.hotelId, contractGroupId, page);
            setContracts({
                data: response.data,
                loading: false,
                meta: response.meta
            });
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
            setContracts({ ...contracts, loading: true });
        }
    }
    useEffect(() => {
        getContractGroups();
    },[])
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={() => props.onCancel()}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values)}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Grup Adı"
                            rules={[{required: true, message: "Name is required"}]}
                        >
                            <Input placeholder="Grup Adı" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="contract_group_id"
                            label="Kontrat Grubu Adı"
                            rules={[{required: true, message: "Kontrat grubu seçiniz"}]}
                        >
                            <Select
                                showSearch
                                placeholder="Kontrat grubu seçiniz"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => getContracts(value)}
                            >
                                {contractGroups.data.map((contractGroup) => (
                                    <Option key={contractGroup.id} value={contractGroup.id}>
                                        {contractGroup.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="contract_id"
                            label="Kontrat Adı"
                            rules={[{required: true, message: "Kontrat seçiniz"}]}
                        >
                            <Select
                                showSearch
                                placeholder="Kontrat seçiniz"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {contracts.data.map((contractGroup) => (
                                    <Option key={contractGroup.id} value={contractGroup.id}>
                                        {contractGroup.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="consecutive"
                            label="Tür"
                            initialValue="1"
                            rules={[{required: true, message: "Tür seçiniz"}]}
                        >
                            <Radio.Group>
                                <Radio value="0">Sıralı Hesaplama</Radio>
                                <Radio value="1">Toplu Hesaplama</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default DiscountGroupModalForm;