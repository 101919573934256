import React, {useEffect} from 'react';
import {Breadcrumb, Layout, notification, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {CMReservationService} from '@services';

const {Content} = Layout;


const CmReservations = () => {
    const [reservations, setReservations] = React.useState({
        data: [],
        isLoading: true,
        isError: false
    })
    const columns = [
        {
            title: 'Rezervasyon No',
            dataIndex: 'uuid',
            key: 'uuid',
            render: (text) => <strong>{text}</strong>,
        },
        {
            title: 'Otel',
            dataIndex: 'hotel',
            key: 'hotel',
            render: (text) => text.name
        },
        {
            title: 'Oda Tipi',
            dataIndex: 'room',
            key: 'room',
            render: (text) => text.name
        },
        {
            title: 'Müşteri Adı',
            dataIndex: 'customers',
            key: 'customers',
            render: (text) => text[0].full_name
        },
        {
            title: 'Giriş Tarihi',
            dataIndex: 'check_in',
            key: 'check_in',
        },
        {
            title: 'Çıkış Tarihi',
            dataIndex: 'check_out',
            key: 'check_out',
        },
        {
            title: 'Tutar',
            dataIndex: 'total_price',
            key: 'total_price',
        },
        {
            title: 'Rezervasyon Tarihi',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'İşlemler',
            dataIndex: 'actions',
            key: 'actions',
        }
    ];

    const getReservations = async () => {
        try {
            let {data: response} = await CMReservationService.get();
            setReservations({
                data: response.data,
                isLoading: false,
                isError: false
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    useEffect(() => {
        getReservations()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Kanal Yönetimi - Reservasyonlar
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={reservations.data}
                        pagination={false}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default CmReservations;