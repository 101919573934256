import React from 'react';
import {Button, Form, Modal, notification, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContractGroupsService} from "@services";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons/faDollarSign";
import CurrencySelect from "@/components/utils/CurrencySelect";

const ContractGroupCurrencyFormModal = ({ contractGroupId, currency_id }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isVisible, setIsVisible] = React.useState(false);
    const onFinish = async (values, contractGroupId) => {
        try {
            let {data: response} = await ContractGroupsService.updateCurrency(contractGroupId, values);
            if (response.success){
                notification.success({
                    message: t("general.success"),
                    description: response.message
                })
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: t("general.error"),
                    description: e.response.data.message
                })
            }
        }
    }
    return (
        <>
            <Tooltip title={t("general.currency")}>
                <Button type="primary" onClick={() => setIsVisible(true)}>
                    <FontAwesomeIcon icon={faDollarSign} />
                </Button>
            </Tooltip>
            <Modal visible={isVisible} title="Kontrat Grubu Para Birimi" closable={false} onOk={() => form.submit()} onCancel={() => setIsVisible(false)}>
                <Form form={form} layout="vertical" onFinish={(values) => onFinish(values, contractGroupId)} initialValues={{
                    currency_id: currency_id
                }}>
                    <Form.Item
                        label="Para Birimi"
                        name="currency_id"
                    >
                        <CurrencySelect
                            valueKey="code"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ContractGroupCurrencyFormModal;