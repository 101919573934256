import React from 'react';
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "@/views/new-version/reservation/components/documents/ActionButton";
import {notification} from "antd";
import {ReservationServiceV2} from "@services";
import {useTranslation} from "react-i18next";

const ReceiptDownload = ({ reservation }) => {
    const { t } = useTranslation();
    const [reservationReceiptLoading, setReservationReceiptLoading] = React.useState(false);
    const getReservationReceipt = async () => {
        setReservationReceiptLoading(true)
        try {
            let response = await ReservationServiceV2.downloadReceipt(
                reservation.uuid
            );
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = reservation.uuid + '_form.pdf';
            a.click();
            window.URL.revokeObjectURL(url);
            setReservationReceiptLoading(false)
            return response.data;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message,
                });
            }
            setReservationReceiptLoading(false)
        }
    }
    return (
        <ActionButton
            icon={faDownload}
            title={t("general.reservation_receipt")}
            loading={reservationReceiptLoading}
            onClick={getReservationReceipt}
        />
    );
};

export default ReceiptDownload;