import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, notification, Skeleton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import { ReservationDetailService } from "@services";
import { useParams } from "react-router-dom";

const { Content } = Layout;

const Voucher = () => {
  const [voucher, setVoucher] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/reservation-card/${params.reservationId}`}>Rezervasyon Kartı</Breadcrumb.Item>
          <Breadcrumb.Item>Rezervasyon Voucher</Breadcrumb.Item>
        </Breadcrumb>

        <div className="site-layout-background">

        </div>
      </Content>
    </Wrapper>
  );
};

export default Voucher;
