import React, {useEffect, useMemo, useState} from 'react';
import {Breadcrumb, Form, Input, Layout, notification, Spin, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/v2/Wrapper";
import {Link, useParams} from "react-router-dom";
import {ContractService} from "@services";
import moment from "moment/moment";
import EditPeriodModalForm from "@/views/new-version/contracts/components/EditPeriodModalForm";
import FixedContractEditor from "@/views/new-version/contracts/components/FixedContractEditor";
import MultiplyContractEditor from "@/views/new-version/contracts/components/MultiplyContractEditor";
import {useTranslation} from "react-i18next";

const {Content} = Layout;

const HOTEL_ID = 30;

const fixedQuotaRow = {
    id: "QUOTAS",
    adult: null,
    ages: [],
    child: null,
    prices: {}
}
const fixedPerPersonRow = {
    id: "PER-PERSON",
    adult: null,
    ages: [],
    child: null,
    prices: {}
}

const EDITOR_COMPONENTS = {
    "multiply": MultiplyContractEditor,
    "fixed": FixedContractEditor
}

const ContractEdit = () => {
    const { t } = useTranslation();
    const [periodEditForm] = Form.useForm();
    const [form] = Form.useForm();
    const contract = Form.useWatch('contract', form) || {};
    const conditions = Form.useWatch('conditions', form) || [];
    const periods = Form.useWatch('periods', form) || [];
    const availabilities = Form.useWatch('availabilities', form) || {};
    const childAges = Form.useWatch('child_ages', form) || [];
    const [isLoading, setIsLoading] = React.useState(true);
    const [isEditPeriodModalVisible, setIsEditPeriodModalVisible] = React.useState(false);
    const params = useParams();
    const [selectedPeriod, setSelectedPeriod] = React.useState({});
    const changeStatusPeriod = (id) => {
        const newData = periods.map((period) => {
            if (period.id === id) {
                period.is_daily = !period.is_daily;
            }
            return period;
        });
        form.setFieldsValue({
            periods: newData
        });
    }
    const onEditPeriod = (period) => {
        setSelectedPeriod(period);
        periodEditForm.setFieldsValue({
            period: [moment(period.start), moment(period.end)]
        })
        setIsEditPeriodModalVisible(true);
    }
    const onUpdatePeriod = (values, id) => {
        const newData = periods.map((period) => {
            if (period.id === id) {
                period.start = values.period[0].format('YYYY-MM-DD');
                period.end = values.period[1].format('YYYY-MM-DD');
            }
            return period;
        });
        form.setFieldsValue({
            periods: newData
        });
        setIsEditPeriodModalVisible(false);
    }
    const getDaysInRange = useMemo(() => (start, end) => {
        let startDate = moment(start);
        let endDate = moment(end);
        let dates = [];

        while (startDate <= endDate) {
            dates.push(startDate.format('YYYY-MM-DD'));
            startDate = startDate.add(1, 'days');
        }

        return dates;
    }, []);
    const getContract = async (hotelId, contractGroupId, contractId) => {
        setIsLoading(true)
        try {
            let {data: response} = await ContractService.demoShow(hotelId, contractGroupId, contractId)
            form.setFieldsValue({
                contract: {
                    id: response.data.contract.id,
                    title: response.data.contract.title,
                    room: response.data.contract.room,
                    calculating_policy: response.data.contract.calculating_policy || "multiply",
                },
                availabilities: response.data.availability,
                conditions: response.data.contract.conditions,
                periods: response.data.periods,
                child_ages: response.data.child_ages
            })
            setIsLoading(false)
        }catch (e) {
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setIsLoading(false)
        }
    }
    const onSave = async (values) => {
        setIsLoading(true)
        try {
            let {data: response} = await ContractService.demoSave(params.hotelId, params.contractGroupId, params.contractId, values)
            if (response.success){
                notification.success({
                    message: response.message
                })
            }
            await getContract(params.hotelId, params.contractGroupId, params.contractId)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setIsLoading(false)
        }
    }

    const EditorComponent = (props) => {
        let Component = EDITOR_COMPONENTS[props.contract.calculating_policy];
        return <Component {...props} />
    }

    useEffect(() => {
        getContract(params.hotelId, params.contractGroupId, params.contractId)
        // eslint-disable-next-line
    },[])
    return (
        <Wrapper title="Sanal Pos İşlemleri">
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/contract-groups`}>
                            {t("general.contract_groups")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/contract-groups/${params.contractGroupId}/contracts`}>
                            {t("general.contract_group")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {form.getFieldValue('contract')?.title}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={form}>
                        <Form.Item name="contract" hidden noStyle><Input/></Form.Item>
                        <Form.Item name="conditions" hidden noStyle><Input/></Form.Item>
                        <Form.Item name="availabilities" hidden noStyle><Input/></Form.Item>
                        <Form.Item name="child_ages" hidden noStyle><Input/></Form.Item>
                        <Form.Item name="periods" hidden noStyle><Input/></Form.Item>
                    </Form>
                    {isLoading && <Loading/>}

                    {!isLoading && (
                        <EditorComponent
                            form={form}
                            contract={contract}
                            conditions={conditions}
                            onSave={onSave}
                            periods={periods}
                            availabilities={availabilities}
                            childAges={childAges}
                            getDaysInRange={getDaysInRange}
                            changeStatusPeriod={changeStatusPeriod}
                            getContract={getContract}
                        />
                    )}
                </div>
            </Content>
            <EditPeriodModalForm
                isVisible={isEditPeriodModalVisible}
                onCancel={() => setIsEditPeriodModalVisible(false)}
                title="Periyot Düzenle"
                form={periodEditForm}
                data={selectedPeriod}
                onFinish={(values, id) => onUpdatePeriod(values, id)}
            />
        </Wrapper>
    );
};

const Loading = () => {
    return (
        <div>
            <h1 style={{ fontSize: 24, textAlign: "center" }}>
                Kontratınız işleniyor lütfen bekleyiniz...
            </h1>
            <Spin
                style={{
                    display: 'block',
                    margin: 'auto',
                    marginTop: '20px',
                    marginBottom: '20px'
                }}
            />
        </div>
    )
}

export default ContractEdit;