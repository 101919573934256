import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Layout, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from '@components/Wrapper';
import { UserCallService } from '@services';
import UserCallModalForm from "@components/user-call/UserCallModalForm";

const {Content} = Layout;

const UserCallList = () => {
    const [createForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const columns = [
        {
            title: "#",
            dataIndex: 'id',
            key: 'id',
            render: (text) => text
        },
        {
            title: "Agent",
            dataIndex: 'user',
            key: 'user',
            render: (text) => text?.first_name + ' ' + text?.last_name
        },
        {
            title: "Tarih",
            dataIndex: 'date',
            key: 'date',
            render: (text) => text
        },
        {
            title: "Çağrı Sayısı",
            dataIndex: 'call_count',
            key: 'call_count',
            render: (text) => text
        }
    ]
    const [calls, setCalls] = useState({
        data: [],
        isLoading: true,
        meta: {},
    });

    const getAgentCalls = async (page = 1, pageSize = 10) => {
        setCalls(prevState => ({...prevState, isLoading: true}));
        try {
            const {data: response} = await UserCallService.get(page, pageSize);
            setCalls({
                data: response.data,
                isLoading: false,
                meta: response.meta,
            });
            console.log(response)
        } catch (error) {
            console.error(error);
            setCalls(prevState => ({...prevState, isLoading: false}));
        }
    }

    const onSubmit = async (values) => {
        values = {
            ...values,
            start_date: values.start_date.format('YYYY-MM-DD'),
            end_date: values.end_date.format('YYYY-MM-DD')
        }
        try {
            const {data: response} = await UserCallService.create(values);
            if (response.success){
                getAgentCalls();
                setIsModalVisible(false);
                createForm.resetFields();
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAgentCalls();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Agent Çağrıları
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Button htmlType="button" type="primary" onClick={() => setIsModalVisible(true)}>
                        Yeni Çağrı Ekle
                    </Button>
                    <Table
                        columns={columns}
                        dataSource={calls.data}
                        rowKey={record => record.id}
                        loading={calls.isLoading}
                        bordered
                        pagination={{
                            total: calls.meta?.total,
                            defaultPageSize: calls.meta?.per_page,
                            onChange: (page, pageSize) => getAgentCalls(page, pageSize)
                        }}
                    />
                </div>
            </Content>
            <UserCallModalForm
                title="Yeni Çağrı Ekle"
                form={createForm}
                isVisible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onFinish={onSubmit}
            />
        </Wrapper>
    );
};

export default UserCallList;