import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, Input, notification, Row, Skeleton} from "antd";
import {ReservationNotesService} from "@services";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const { TextArea } = Input;

const NotesTab = ({ reservation }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const params = useParams();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const user = useSelector((state) => state.user.user);
    const onSave = async (values) => {
        try {
            let {data: response} = await ReservationNotesService.create(params.reservationId, values);
            if (response.message) {
                notification.success({
                    message: response.message
                })
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getReservationNotes = async () => {
        setLoading(true)
        try {
            let {data: response} = await ReservationNotesService.get(params.reservationId);
            setError(false)
            setLoading(false)
            form.setFieldsValue({
                guest_note: response.data.guest_note,
                hotel_reservation_note: response.data.hotel_reservation_note,
                operation_note: response.data.operation_note,
                reception_note: response.data.reception_note,
                agent_note: response.data.agent_note,
                agency_note: response.data.agency_note,
            })
        }catch (e){
            setError(true)
            setLoading(false)
        }
    }
    useEffect(() => {
        getReservationNotes()
    },[])
    if (loading) {
        return <Skeleton />
    }
    if (error) {
        return (
            <Alert
                message={t("general.error")}
                description={t("general.error_occurred_try_again")}
                type="error"
                showIcon
            />
        )
    }
    return (
        <Form form={form} onFinish={onSave}>
            <Row gutter={[16, 16]} style={{"marginBottom":"1rem"}}>
                <Col span={12}>
                    <Card title={t("general.guest_note")} style={{backgroundColor:'#B1D8B7'}}>
                        <Form.Item name="guest_note">
                            <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                        </Form.Item>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={t("general.hotel_reservation_note")} style={{backgroundColor:'#93c5fd'}}>
                        <Form.Item name="hotel_reservation_note">
                            <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{"marginBottom":"1rem"}}>
                <Col span={12}>
                    <Card title={t("general.operation_note")} style={{backgroundColor:'#FFFEA8'}}>
                        <Form.Item name="operation_note">
                            <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                        </Form.Item>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title={t("general.reception_note")} style={{backgroundColor:'#FFC171'}}>
                        <Form.Item name="reception_note">
                            <TextArea rows={4} style={{"marginBottom":"1rem",height:150}}/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{"marginBottom":"1rem"}}>
                {(user?.is_super_admin || user?.id === reservation?.created_by_id) && (
                    <Col span={12}>
                        <Card title={t("general.agent_note")} style={{backgroundColor:'#b27fb2'}}>
                            <Form.Item name="agent_note">
                                <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                            </Form.Item>
                        </Card>
                    </Col>
                )}
                <Col span={12}>
                    <Card title={t("general.agency_note")} style={{backgroundColor:'#e36200'}}>
                        <Form.Item name="agency_note">
                            <TextArea rows={4} style={{"marginBottom":"1rem",height:150}} />
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button htmlType="submit" style={{backgroundColor:'#04AA6D',color:'white', borderRadius:20,border:'none'}}>KAYDET</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default NotesTab;