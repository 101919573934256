import React, { useEffect, useState } from 'react';
import {
    Layout,
    Breadcrumb,
    Button,
    Table,
    notification,
    Space,
    Tag,
    Collapse,
    Skeleton,
    Row,
    Col,
    Input,
    Form, Tooltip, DatePicker, Select, Popconfirm, Checkbox
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouse,
    faEdit,
    faTrash,
    faSearch,
    faBan,
    faPlaneSlash,
    faList,
    faLock, faLockOpen, faFileExcel
} from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { ReservationService, ContractService, ReservationDetailService, CountriesService } from '@services';
import ReservationModalForm from "@components/reservations/ReservationModalForm";
import moment from "moment";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ReservationsTableActions from "@/views/reservations/components/reservations-table-actions";

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Reservations = () => {
    const { t } = useTranslation();
    const permissions = useSelector((state) => state.user.user.role.permissions);
    const user = useSelector((state) => state.user.user);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [sendMailOnCancel, setSendMailOnCancel] = useState(false);
    const [restoreCancelReservationStatus, setRestoreCancelReservationStatus] = useState(1);
    const [isReservationModalFormVisible, setIsReservationModalFormVisible] = useState(false);
    const [reservations, setReservations] = useState({
        data: [],
        isLoading: true,
        meta: {},
    });
    const [searchParams, setSearchParams] = useState({
        hotel_name: "",
        room_name: "",
        agent_name: "",
        uuid: "",
        check_in: "",
        check_out: "",
        guest_name: "",
        reservation_date: "",
        status: "",
        currencies: [242,251,90,249],
        only_checkin: false,
        only_checkout: false,
    });
    const [countries, setCountries] = useState([]);
    const [reservation, setReservation] = useState([]);
    const [contractConcepts, setContractConcepts] = useState([]);
    const [reservationEditForm] = Form.useForm();
    const [guestEditForm] = Form.useForm();
    const [reservationSearchForm] = Form.useForm();
    const columns = [
        {
            key: "reservation_number",
            title: <span style={{color:"#1890ff",fontSize:"12px",textAlign:"center"}}>{t("general.reservation_no")}</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'reservation_number',
            render: (text, record) => {
                let reservationNumber = record.uuid
                let hasDeferredPayment = false
                let totalPayment = 0
                record?.payments?.length > 0 && record?.payments?.map((payment) => {
                    if(payment?.safe_bank?.payment_method_id === 4 && payment?.approved === 1){
                        let amount = 0;
                        payment?.balance_completions?.map((item) => {
                            amount = amount + parseInt(item.amount)
                        })
                        if (amount !== payment?.amount){
                            hasDeferredPayment = true
                        }
                    }
                    if(payment.approved !== 1){
                        return null
                    }
                    totalPayment = totalPayment + payment.amount
                })
                if(record.status === 6){
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#6b7280", borderColor: "#6b7280" }}
                        block='true'
                        href={`/reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                        
                    </Button>
                }
                if(hasDeferredPayment){
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#be185d", borderColor: "#be185d" }}
                        block='true'
                        href={`/reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>

                    </Button>
                }
                if (totalPayment === 0) {
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#ef4444", borderColor: "#ef4444" }}
                        block='true'
                        href={`/reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                        
                    </Button>
                }
                if (totalPayment > 0 && totalPayment < record.total_fee) {
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px" }}
                        block='true'
                        href={`/reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                    </Button>
                }
                if (totalPayment >= record.total_fee) {
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#22c55e", borderColor: "#22c55e" }}
                        block='true'
                        href={`/reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                        
                    </Button>
                }
            }
        },
        {
            key: 'reservation_provider',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.reservation_creator")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'reservation_provider',
            render: (text, record) => {
                if(record?.users){
                    return <Tooltip title={record.users.role.name}><h1 style={{ 'fontSize': '12px',textAlign:"center"}}>{record.users.first_name} {record.users.last_name}</h1></Tooltip>
                }
                return <Tooltip title="Rezervasyonu paymyres üzerinden müşteri kendisi oluşturmuştur."><h1 style={{ 'fontSize': '12px',textAlign:"center"}}>Widget</h1></Tooltip>
            }
        },
        {
            key: 'hotel_name',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.hotel")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'hotels',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text.name}</h1>
        },
        {
            key: 'room_name',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.room_name")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'room_detail',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text?.active_language?.name}</h1>
        },
        {
            key: 'room_concept',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.concept")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'concept_detail',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text?.active_language?.name}</h1>
        },
        {
            key: 'full_name',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.full_name")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'full_name',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'check_in',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.check_in")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'check_in',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'check_out',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.check_out")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'check_out',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'locked',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.lock_status")}
            </span>,
            dataIndex: 'locked',
            render: (text) => <div style={{
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyItems: "center"
            }}>
                <div style={{
                    margin: "0 auto",
                    color: text ? "red" : "green"
                }}>
                    {text ? (<FontAwesomeIcon icon={faLock}/>) : (<FontAwesomeIcon icon={faLockOpen}/>)}
                </div>
            </div>
        },
        {
            key: 'total_fee',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.reservation_amount")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'total_fee',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'total_fee_discount',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff" }}>
                {t("general.discount_reservation_amount")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'total_fee',
            render: (text, record) => {
                let discounts = 0;
                record?.payments?.map((item) => {
                    if(item?.safe_bank?.payment_method_id === 6){
                        return discounts += item?.amount
                    }
                })
                return <h1 style={{ "fontSize": "12px" }}>{text - discounts} {record?.currency_id?.symbol}</h1>
            }
        },
        {
            key: 'reservation_create_date',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.reservation_date")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'created_at',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'title',
            title: <span style={{ "fontSize": "12px",textAlign: "center", color: "#1890ff",}}>
                {t("general.status")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'payments',
            render: (text, record) => {
                let status = t("general.confirm_waiting_to_send")
                if(record.status === 0){
                    status = t("general.pre_reservation")
                    return <Tooltip title="Rezervasyon bilgileri girildi, ödeme işlemi sağlanamdı!"><Tag color="blue" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if(record.status === 2){
                    status = t("general.confirm_pendings")
                    return <Tooltip title="Otel'e confirme işlemi için mail gönderildi, cevap bekleniyor."><Tag color="orange" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if(record.status === 3){
                    status = t("general.confirmed")
                    return <Tooltip title="Otel işlemi onayladı."><Tag color="green" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if (record.status === 4) {
                    status = t("general.confirm_cancelled")
                    return <Tooltip title="Otel işlemi reddetti."><Tag color="red" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if (record.status === 5) {
                    status = "Confirm'e Gönderilecek"
                    return <Tooltip title="Otel'e confirme işlemi için mail gönderilmedi."><Tag color="magenta" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if (record.status === 6) {
                    status = t("general.reservation_cancelled")
                    return <Tooltip title="Rezervasyon iptal edildi."><Tag color="red" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                return <Tooltip title="Otel'e confirme işlemi için mail gönderilecek."><Tag color="yellow" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
            }
        },
        {
            key: 'title',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.payment")}
            </span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500"}
                }
            },
            dataIndex: 'payments',
            render: (text, record) => {
                let totalPayment = 0
                text.length > 0 && text.map((payment) => {
                    if(payment.approved !== 1){
                        return null
                    }
                    totalPayment = totalPayment + payment.amount
                })
                if (totalPayment === 0) {
                    return (<Tag color="red">Ödenmedi</Tag>)
                }
                if (totalPayment > 0 && totalPayment < record.total_fee) {
                    return (<Tag color="cyan">Kısmi Ödeme</Tag>)
                }
                if (totalPayment >= record.total_fee) {
                    return (<Tag color="green">Ödendi</Tag>)
                }
            }
        },
        {
            key: 'actions',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.actions")}
            </span>,
            fixed: 'right',
            render: (text, record) =>
                <ReservationsTableActions
                    data={record}
                    getReservationById={() => getReservationById(record.uuid)}
                    onListReservation={() => onListReservation(record.uuid)}
                    sendMailOnCancel={sendMailOnCancel}
                    setSendMailOnCancel={setSendMailOnCancel}
                    onCancelReservation={() => onCancelReservation(record.uuid)}
                    onDeleteReservation={() => onDeleteReservation(record.uuid)}
                    onChangeLockReservation={() => onChangeLockReservation(record.uuid)}
                    onRestoreReservation={() => onRestoreReservation(record.uuid)}
                    restoreCancelReservationStatus={restoreCancelReservationStatus}
                    setRestoreCancelReservationStatus={setRestoreCancelReservationStatus}
                    user={user}
                    permissions={permissions}
                />
        }
    ];

    useEffect(() => {
        getReservations()
        getCountries()
    }, []);

    const getContracts = async (contractId) => {
        try {
            let { data: response } = await ContractService.getContractConceptsById(contractId);
            setContractConcepts(response.data)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getReservationById = async (id) => {
        try {
            let { data: response } = await ReservationDetailService.getById(id);
            setReservation(response.data)
            getContracts(response.data.contract_id)
            setIsReservationModalFormVisible(true)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onDeleteReservation = async (id) => {
        try {
            let { data: response } = await ReservationService.delete(id);
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onListReservation = async (id) => {
        try {
            let { data: response } = await ReservationService.listReservation(id);
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onCancelReservation = async (id) => {

        try {
            let { data: response } = await ReservationService.cancel(id,{
                sendMail: sendMailOnCancel
            });
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onRestoreReservation = async (id) => {
        try {
            let { data: response } = await ReservationService.restoreCancel(id, restoreCancelReservationStatus);
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onChangeLockReservation = async (id) => {
        try {
            let { data: response } = await ReservationService.changeLock(id);
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onFinishReservationEdit = async (values,id) => {
        values = {
            ...values,
            check_in: moment(values.check_in).format("YYYY-MM-DD"),
            check_out: moment(values.check_out).format("YYYY-MM-DD"),
            created_at: moment(values.created_at).format("YYYY-MM-DD HH:mm:ss"),
        }
        try {
            let { data: response } = await ReservationService.updateReservation(id,values);
            if(response.success){
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getCountries = async () => {
        try {
            let {data: {data: response}} = await CountriesService.get();
            setCountries(response);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onFinishGuestEdit = async (values,id) => {
        values = {
            adults: [
                ...values.adults.map((adult) => {
                    return {
                        ...adult,
                        birth_date: moment(adult.birth_date).format("YYYY-MM-DD"),
                        passport_date: adult.passport_date ? moment(adult.passport_date).format("YYYY-MM-DD") : null,
                    }
                })
            ],
            children: [
                ...values.children.map((child) => {
                    return {
                        ...child,
                        birth_date: moment(child.birth_date).format("YYYY-MM-DD"),
                    }
                })
            ]
        }
        try {
            let { data: response } = await ReservationService.updateGuests(id,values);
            if(response.success){
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onExport = async () => {
        setIsExporting(true);
        try {
            let response = await ReservationService.export(searchParams);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'reservasyonlar.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            setIsExporting(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            setIsExporting(false);
        }
    }

    const getReservations = async (page = 1, pageSize = 10 ) => {
        try {
            let { data: response } = await ReservationService.get(page, pageSize, searchParams);
            setReservations(prevState => (
                {
                    ...prevState,
                    data: response.data,
                    meta: response.meta,
                    isLoading: false
                }
            ));
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.reservations")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={reservationSearchForm} onFinish={() => getReservations()} layout="vertical">
                        <Row gutter={[16,0]}>
                            <Col span={4}>
                                <Form.Item label={t("general.reservation_creator")}>
                                    <Input placeholder={t("general.reservation_creator")} onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            agent_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.reservation_number")}>
                                    <Input placeholder={t("general.reservation_number")} onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            uuid: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.hotel_name")}>
                                    <Input placeholder={t("general.hotel_name")} onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            hotel_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.room_name")}>
                                    <Input placeholder={t("general.room_name")} onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            room_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.guest_name")}>
                                    <Input placeholder="Ziyaretçi adını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            guest_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.reservation_date")}>
                                    <RangePicker
                                        style={{width:"100%"}}
                                        format="DD.MM.YYYY"
                                        placeholder={[t("general.start_date"), t("general.end_date")]}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                reservation_date: e ? [
                                                    moment(e[0]).format("YYYY-MM-DD"),
                                                    moment(e[1]).format("YYYY-MM-DD")
                                                ] : []
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.check_in_out")}>
                                    <RangePicker
                                        style={{width:"100%"}}
                                        format="DD.MM.YYYY"
                                        placeholder={["Giriş tarihi", "Çıkış tarihi"]}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                check_in: e ? moment(e[0]).format("YYYY-MM-DD"): "",
                                                check_out: e ? moment(e[1]).format("YYYY-MM-DD"): ""
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.status")}>
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        placeholder="Rezervasyon durumu seçiniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            status: e ? e : ""
                                        }
                                    })}>
                                        <Option key={1} value={1}>{t("general.confirm_waiting_to_send")}</Option>
                                        <Option key={2} value={2}>{t("general.confirm_pendings")}</Option>
                                        <Option key={3} value={3}>{t("general.confirmed")}</Option>
                                        <Option key={4} value={4}>{t("general.confirm_cancelled")}</Option>
                                        <Option key={6} value={6}>{t("general.reservation_cancelled")}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16,0]}>
                            <Col span={6}>
                                <Form.Item label={t("general.currencies")}>
                                    <Checkbox.Group
                                        defaultValue={[242,251,90,249]}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                currencies: e
                                            }
                                        })}
                                    >
                                        <Checkbox value={251}>USD</Checkbox>
                                        <Checkbox value={242}>TRY</Checkbox>
                                        <Checkbox value={90}>EURO</Checkbox>
                                        <Checkbox value={249}>GBP</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={t("general.date_type")}
                                >
                                    <Checkbox.Group
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                only_checkin: e.includes("only_checkin"),
                                                only_checkout: e.includes("only_checkout")
                                            }
                                        })}
                                    >
                                        <Checkbox value="only_checkin">{t("general.only_check_in")}</Checkbox>
                                        <Checkbox value="only_checkout">{t("general.only_check_out")}</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{display:"flex",justifyContent:"flex-end",marginBottom:"14px"}}>
                                <Space>
                                    <div className="background-red-500" style={{width:"20px",height:"20px",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                    <span className="ml-2" style={{fontSize:"12px"}}>
                                        {t("general.pay_at_hotel")}
                                    </span>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[16,0]}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button style={{width:"100%"}} type="primary" htmlType="submit" onClick={() => setIsLoadingTable(true)}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div style={{
                        display:"flex",
                        justifyContent:"space-between",
                        marginBottom:"10px"
                    }}>
                        <Space direction="vertical">
                            <Row gutter={[0,8]}>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#6b7280",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.canceled_reservations")}</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#ef4444",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.unpaid_reservations")}</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#1890FF",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.partially_paid_reservations")}</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#22c55e",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.paid_reservations")}</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#be185d",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.due_reservations")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Space>
                        <Space direction="horizontal">
                            {permissions.includes('reservation.export') && (
                                <Tooltip
                                    title="Excel">
                                    <Button
                                        type="primary"
                                        htmlType="button"
                                        icon={<FontAwesomeIcon icon={faFileExcel} />}
                                        onClick={onExport}
                                        loading={isExporting}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip
                                placement="topLeft"
                                title="Tamamlanmamış Rezervasyonlar">
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    href="/temp-reservations"
                                    icon={<FontAwesomeIcon icon={faPlaneSlash} />}
                                />
                            </Tooltip>
                        </Space>
                    </div>
                    <Table
                        columns={columns}
                        scroll={{ x: 2000, y: "100%" }}
                        dataSource={reservations.data}
                        rowKey={record => record.uuid}
                        loading={isLoadingTable}
                        bordered
                        size="small"
                        pagination={{
                            total: reservations.meta?.total,
                            defaultPageSize: reservations.meta?.per_page,
                            onChange: (page, pageSize) => {
                                setIsLoadingTable(true)
                                getReservations(page, pageSize)
                            }
                        }}
                    />
                </div>
            </Content>
            <ReservationModalForm
                title="Rezervasyon Düzenle"
                isVisible={isReservationModalFormVisible}
                onCancel={() => setIsReservationModalFormVisible(false)}
                onFinishReservationEdit={onFinishReservationEdit}
                onFinishGuestEdit={onFinishGuestEdit}
                reservation={reservation}
                reservationForm={reservationEditForm}
                guestForm={guestEditForm}
                countries={countries}
                contractConcepts={contractConcepts}
                getReservations={getReservations}
            />
        </Wrapper>
    );
};

export default Reservations;