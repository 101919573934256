import React, {useEffect, useState} from 'react';
import {Breadcrumb, Col, Descriptions, Layout, List, Pagination, Row, Statistic} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useParams} from "react-router-dom";
import { HotelStatsService } from '@services';
import moment from 'moment';
const {Content} = Layout;

const HotelStats = () => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const getHotelStats = async (hotelId, page) => {
        try {
            let { data: response  } = await HotelStatsService.get(hotelId, page);
            setData(response);
        }catch (e){
            console.log(e);
        }
    }
    useEffect(() => {
        getHotelStats(params.hotelId, page)
            .finally(() => setIsLoading(false));
    },[params.hotelId, page])
    return (
        <Wrapper title="Hotels">
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/hotels">
                        Oteller
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        İstatistikler
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={8}>
                            <Statistic title="Son 7 Gün Ziyaretçi Sayısı" value={data?.meta?.stats?.last7days} />
                        </Col>
                        <Col span={8}>
                            <Statistic title={`${data?.meta?.stats?.last_day?.date ?? ''} Ziyaretçi Sayısı`} value={data?.meta?.stats?.last_day?.total} />
                        </Col>
                        <Col span={8}>
                            <Statistic title="Son 7 Gün Tamamlanmamış Rezervasyonlar" value={data?.meta?.stats?.uncompleted_reservations} />
                        </Col>
                        <Col span={24}>
                            <List
                                header={<div>İşlenmiş Son Ziyaretçiler | Günlük İşleme Zamanı 20:00 GMT+03:00</div>}
                                loading={isLoading}
                                style={{height:"600px",overflowY:"auto"}}
                                bordered
                                dataSource={data.data}
                                renderItem={(item) => (
                                    <List.Item>
                                        <Descriptions title={`${item.country} - ${item.city} ${item.zip_code}`}>
                                            <Descriptions.Item label="Yapılan Arama">{item.search_params.lang.toUpperCase()} | {item.search_params.adults} Yetişkin {item.search_params.children} Çocuk</Descriptions.Item>
                                            <Descriptions.Item label="Giriş Çıkış Tarihi">{item.search_params.checkin} / {item.search_params.checkout}</Descriptions.Item>
                                            <Descriptions.Item label="Arama Tarihi">{item.date} (Europe/Istanbul) <br/> {item.user_date} ({item.timezone})</Descriptions.Item>
                                        </Descriptions>
                                    </List.Item>
                                )}
                            />
                            <div style={{display:"flex",justifyContent:"flex-end",marginTop:"16px"}}>
                                <Pagination
                                    defaultCurrent={data?.meta?.current_page}
                                    total={data?.meta?.total}
                                    onChange={(page) => setPage(page)}
                                    showTotal={(total) => `Toplam ${total}`}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default HotelStats;