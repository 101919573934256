import React from 'react';
import {Breadcrumb, Button, Col, Layout, Row, Table, Form, notification, Space, Tooltip, Popconfirm} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import CityModalForm from "@components/definitions/CityModalForm";
import { CityService } from '@services';

const {Content} = Layout;

const Cities = () => {
    const [createCityForm] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(true);
    const [cities, setCities] = React.useState([]);
    const [isCreateModalVisible, setIsCreateModalVisible] = React.useState(false);

    const getCountries = async () => {
        try {
            let {data: response} = await CityService.getPaginate();
            setCities(response.data);
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setIsLoading(false);
        }
    }

    const onCreate = async (values) => {
        try {
            let {data: response} = await CityService.create(values);
            if(response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setCities([...cities, response.data])
                setIsCreateModalVisible(false);
                createCityForm.resetFields();
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }
    const onDelete = async (id) => {
        try {
            let {data: response} = await CityService.delete(id);
            if(response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setCities(cities.filter(country => country.id !== id))
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Bölge',
            dataIndex: 'main_region',
            key: 'main_region',
            render: (text, record) => text?.name ?? <span style={{color:"red"}}>Silinmiş Ülke</span>
        },
        {
            title: 'Şehir Adı',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Alternatif Adlar',
            dataIndex: 'alternative_names',
            key: 'alternative_names',
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (text, record) => (
                <Space>
                    <Tooltip>

                        <Popconfirm
                            title="Silmek istediğinize emin misiniz?"
                            onConfirm={() => onDelete(record.id)}
                            okText="Evet"
                            cancelText="İptal"
                        >
                            <Button type="danger" htmlType="button">
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ];

    React.useEffect(() => {
        getCountries()
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Ülkeler</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]} style={{
                        marginBottom: '10px'
                    }}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={() => setIsCreateModalVisible(true)}>Şehir Tanımla</Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={cities}
                        pagination={false}
                        loading={isLoading}
                        rowKey={record => record.id}
                    />
                </div>
            </Content>
            <CityModalForm
                title="Yeni Şehir Tanımla"
                form={createCityForm}
                isVisible={isCreateModalVisible}
                onCancel={() => setIsCreateModalVisible(false)}
                onFinish={onCreate}
            />
        </Wrapper>
    );
};

export default Cities;