import React, { useEffect, useState } from 'react';
import { Layout, Breadcrumb, Button, Table, notification, Form, Space, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import Wrapper from '@components/Wrapper';
import { ConditionGroupService } from '@services';
import ConditionGroupModalForm from '@components/conditions/ConditionGroupModalForm';
import {useTranslation} from "react-i18next";

const { Content } = Layout;

const ConditionGroup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [conditionGroups, setConditionGroups] = useState({});
  const columns = [
    {
      title: '#',
      render: (text, record, index) => index + 1
    },
    {
      title: `${t('general.condition')}`,
      dataIndex: 'title',
      render: (text, record) => (
          <Link to={`/condition-groups/${record.id}`}>{record.title}</Link>
      )
    },
    {
      title: `${t('general.actions')}`,
      render: (text, record) => (
          <Space align="middle">
            <Button type="primary" onClick={() => onEdit(record.id)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>

            <Popconfirm title={t('general.are_you_sure_delete')} onConfirm={() => onDelete(record.id)} okText={t('general.delete')} cancelText={t('general.cancel')}>
              <Button type="danger">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Popconfirm>
          </Space>
      )
    }
  ];

  /* create modal form states */
  const [createModalForm] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  /* edit modal form states */
  const [editModalForm] = Form.useForm();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedConditionGroup, setSelectedConditionGroup] = useState({});

  useEffect(() => {
    getConditionGroups();
  }, []);

  const getConditionGroups = async () => {
    try {
      let { data: conditionGroups } = await ConditionGroupService.get();

      setConditionGroups(conditionGroups);
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  const onCreate = async (values) => {
    try {
      let { data: response } = await ConditionGroupService.create(values);

      if (response.success) {
        setIsLoadingTable(true);
        setIsCreateModalVisible(false);
        createModalForm.resetFields();
        getConditionGroups();

        notification.success({
          message: response.message
        });

        navigate(`/condition-groups/${response.data.id}`);
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }

      console.log(e);
    }
  };

  const onEdit = async (id) => {
    try {
      let { data: conditionGroup } = await ConditionGroupService.findById(id);

      if (conditionGroup.success) {
        let preparedInputs = [
          {
            name: 'title',
            value: conditionGroup.data.title
          }
        ];

        setSelectedConditionGroup({
          ...conditionGroup.data,
          fields: preparedInputs
        });
        setIsEditModalVisible(true);
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }
    }
  };

  const onUpdate = async (values, conditionId) => {
    try {
      let { data: response } = await ConditionGroupService.update(conditionId, values);

      if (response.success) {
        setIsLoadingTable(true);
        getConditionGroups();
        setIsEditModalVisible(false);
        editModalForm.resetFields();

        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  const onDelete = async (id) => {
    try {
      let { data: response } = await ConditionGroupService.delete(id);

      if (response.success) {
        getConditionGroups();

        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  return (
      <Wrapper>
        <Content className="m-25">
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHouse} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {t('general.condition_groups')}
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className="site-layout-background">
            <Button type="primary" className="mb-10" onClick={() => setIsCreateModalVisible(true)}>
              <FontAwesomeIcon icon={faPlus} className="mr-1" />
              {t('general.create_new_condition_group')}
            </Button>

            <Table
                loading={isLoadingTable}
                columns={columns}
                dataSource={conditionGroups.data}
                rowKey="id"
                bordered
                childrenColumnName="antdChildren"
            >
            </Table>
          </div>
        </Content>

        <ConditionGroupModalForm
            isVisible={isCreateModalVisible}
            title={t('general.create_new_condition_group')}
            form={createModalForm}
            onFinish={onCreate}
            onCancel={() => setIsCreateModalVisible(false)}
        />

        <ConditionGroupModalForm
            isVisible={isEditModalVisible}
            title={t('general.edit_condition_group')}
            form={editModalForm}
            onFinish={onUpdate}
            onCancel={() => setIsEditModalVisible(false)}
            data={selectedConditionGroup}
        />
      </Wrapper>
  )
};

export default ConditionGroup;