import axios from '../../axios';

const DiscountsService = {
    /* General */

    async allDiscounts(hotelId, page, limit, filters) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/discounts`,{
            params: {
                page,
                per_page: limit,
                ...filters
            }
        });
    },

    /* Group Discounts */
    async getGroups(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/discounts/group`);
    },
    async getGroup(hotelId, groupId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/discounts/group/${groupId}`);
    },
    async deleteGroup(hotelId, groupId) {
        return await axios.delete(`/v2/dashboard/hotels/${hotelId}/discounts/group/${groupId}`);
    },
    async createGroup(hotelId, data) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/discounts/group`, data);
    },
    async appendDiscountGroup(hotelId, groupId, data) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/discounts/group/${groupId}/append`, data);
    },


    /* Action Discounts */
    async getActionDiscounts(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/discounts/actionDiscounts`);
    },
    async createActionDiscount(hotelId, data) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/discounts/actionDiscounts`, data);
    },
    async deleteActionDiscount(hotelId, id) {
        return await axios.delete(`/v2/dashboard/hotels/${hotelId}/discounts/actionDiscounts/${id}`);
    },

    /* Date Discounts */
    async getDateDiscounts(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/discounts/dateDiscounts`);
    },
    async createDateDiscount(hotelId, data) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/discounts/dateDiscounts`, data);
    },
    async deleteDateDiscount(hotelId, id) {
        return await axios.delete(`/v2/dashboard/hotels/${hotelId}/discounts/dateDiscounts/${id}`);
    },

    /* Early Reservation Discounts */
    async getEarlyReservationDiscounts(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/discounts/earlyReservationDiscounts`);
    },
    async createEarlyReservationDiscount(hotelId, data) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/discounts/earlyReservationDiscounts`, data);
    },
    async deleteEarlyReservationDiscount(hotelId, id) {
        return await axios.delete(`/v2/dashboard/hotels/${hotelId}/discounts/earlyReservationDiscounts/${id}`);
    },

    /* Day Discounts */
    async getDayDiscounts(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/discounts/dayDiscounts`);
    },
    async createDayDiscount(hotelId, data) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/discounts/dayDiscounts`, data);
    },
    async deleteDayDiscount(hotelId, id) {
        return await axios.delete(`/v2/dashboard/hotels/${hotelId}/discounts/dayDiscounts/${id}`);
    },

    /* Mobile Discounts */
    async getMobileDiscounts(hotelId) {
        return await axios.get(`/v2/dashboard/hotels/${hotelId}/discounts/mobileDiscounts`);
    },
    async createMobileDiscount(hotelId, data) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/discounts/mobileDiscounts`, data);
    },
    async deleteMobileDiscount(hotelId, id) {
        return await axios.delete(`/v2/dashboard/hotels/${hotelId}/discounts/mobileDiscounts/${id}`);
    },
}

export default DiscountsService;