import React, {useState} from 'react';
import {
    Alert,
    Button,
    Popconfirm,
    Popover,
    Space,
    Table,
    Tag,
    Tooltip,
    Upload,
    message,
    notification,
    Form
} from "antd";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faCheck, faEye, faMoneyBillWave, faSave, faTrash, faUpload} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ReservationPaymentTransferModalForm from "@components/reservations/ReservationPaymentTransferModalForm";
import {Authentication, ReservationPaymentsService} from "@services";
import ReservationPaymentBalanceCompletionModalForm from "@components/reservations/ReservationPaymentBalanceCompletionModalForm";
const PaymentsTable = ({ payments = [], loading, error, reservation, getReservationPayments = async () => {}, paymentMethods, getSafeBankDefinitions, safeBankDefinitions }) => {
    const { t } = useTranslation();
    const [reservationPaymentBalanceCompletionForm] = Form.useForm();
    const [showPaymentBalanceCompletionModal, setShowPaymentBalanceCompletionModal] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const permissions = useSelector((state) => state.user.user.role.permissions);
    const user = useSelector((state) => state.user.user);
    const onDeleteReservationPaymentDocument = async (paymentId, documentId) => {
        try {
            let {data: response} = await ReservationPaymentsService.deleteDocument(paymentId, documentId);
            if(response.success){
                notification.success({
                    message: response.message
                });
                await getReservationPayments()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onDeleteReservationPayment = async (paymentId) => {
        try{
            let { data: response } = await ReservationPaymentsService.delete(paymentId);
            if(response.success){
                notification.success({
                    message: response.message
                });
                await getReservationPayments()
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onHideReservationPayment = async (paymentId) => {
        try{
            let { data: response } = await ReservationPaymentsService.changeInvoiceVisibility(paymentId);
            if(response.success){
                await getReservationPayments()
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onApprovedReservationPayment = async (paymentId, approved) => {
        let values = {
            approved:approved ? 1:0
        }
        try{
            let { data: response } = await ReservationPaymentsService.approve(paymentId,values);
            if(response.success){
                if(response.success){
                    notification.success({
                        message: response.message
                    });
                    await getReservationPayments()
                }
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onBalanceCompletion = (paymentId) => {
        setSelectedPayment(paymentId)
        setShowPaymentBalanceCompletionModal(true)
    }
    const onAddPaymentBalanceCompletion = async (values) => {
        try{
            let {data: response} = await ReservationPaymentsService.createBalanceCompletion(reservation.uuid, selectedPayment, values);
            if(response.success){
                notification.success({
                    message: response.message
                });
                await getReservationPayments()
                setShowPaymentBalanceCompletionModal(false)
                reservationPaymentBalanceCompletionForm.resetFields()
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onBalanceCompletionDelete = async (balanceCompletionId, paymentId) => {
        try{
            let {data: response} = await ReservationPaymentsService.deleteBalanceCompletion(reservation.uuid, paymentId, balanceCompletionId);
            if(response.success){
                notification.success({
                    message: response.message
                });
                await getReservationPayments()
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const reservationPaymentCompletionsColumns = [
        {
            title: 'Ödeme Yeri',
            key:'safe_bank',
            dataIndex: 'safe_bank',
            render:(text) => text?.title
        },
        {
            title: 'Tutar',
            key:'amount',
            dataIndex: 'amount',
            render:(text,record) => text
        },
        {
            title: 'Açıklama',
            key:'description',
            dataIndex: 'description',
            render:(text) => text
        },
        {
            title: 'İşlemi Yapan',
            key:'agent',
            dataIndex: 'agent',
            render:(text) => text?.full_name ?? "Otomatik Sistem Onayı"
        },
        {
            title: 'İşlem Tarihi',
            key:'created_at',
            dataIndex: 'created_at',
            render:(text) => text
        },
        {
            title: 'İşlemler',
            key:'actions',
            dataIndex: 'actions',
            render:(text,record) => (
                <Space>
                    {record?.safe_bank?.payment_method_id !== 7 &&
                        <Tooltip>
                            <Popconfirm
                                title="Silmek istediğinize emin misiniz?"
                                onConfirm={() => onBalanceCompletionDelete(record.id, record.reservation_payment_id)}
                            >
                                <Button type="danger" htmlType="button">
                                    <FontAwesomeIcon icon={faTrash}/>
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    }
                </Space>
            )
        }
    ];
    const columns = [
        {
            title: '#',
            key:'id',
            dataIndex: 'id',
            render: (text,record,index) => index + 1
        },
        {
            title: `${t("general.payment_type")}`,
            key:'safe_bank',
            dataIndex: "payment_method_label",
        },
        {
            title: `${t("general.payment_type")}`,
            key:"bank_name",
            dataIndex: "account_label",
        },
        {
            title: `${t("general.amount")}`,
            key:"amount",
            dataIndex: "amount",
            render:(text) => text.toLocaleString("tr-TR", { style: 'currency', currency: reservation?.currency })
        },
        {
            title: `${t("general.agent")}`,
            key:"agent",
            dataIndex: "created_by",
        },
        {
            title:`${t("general.due_date")}`,
            key:"fall_due",
            dataIndex: "fall_due",
            render:(text) => text ? moment(text).format('DD.MM.YYYY'): "-"
        },
        {
            title:`${t("general.process_date")}`,
            key:"process_date",
            dataIndex: "process_date",
            render:(text) => moment(text).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            title:`${t("general.approver")}`,
            key: "approved_by",
            dataIndex: "approved_by",
            render:(text,record) => {
                if(!text && record?.approved_at){
                    return t("general.system")
                }
                return text ? text: "-"
            }
        },
        {
            title:`${t("general.approval_date")}`,
            key:"approval_date",
            dataIndex: "approved_at",
            render:(text) => text ? moment(text).format('DD.MM.YYYY HH:mm:ss'): "-"
        },
        {
            title:`${t("general.description")}`,
            key:"description",
            dataIndex: "description",
            render:(text) => text
        },
        {
            title:`${t("general.approval_status")}`,
            key:"approved",
            dataIndex: "approved",
            render:(text) => {
                let message = "Bekleniyor"
                if(text === 1){
                    message = "Onaylandı"
                    return( <Tag color="green">{message}</Tag>)
                }
                if(text === 2){
                    message = "İptal Edildi"
                    return( <Tooltip title="Tüm ücret müşteriye iade edilmiştir."><Tag color="red">{message}</Tag></Tooltip>)
                }
                if(text === 0){
                    message = "Reddedildi"
                    return( <Tag color="red">{message}</Tag>)
                }
                return( <Tag>{message}</Tag>)
            }
        },
        {
            title: `${t("general.status")}`,
            key:"status",
            dataIndex: "status",
            render:(text,record) => {
                let message = "Ödenmedi"
                let totalBalance = 0;
                record?.balance_completions?.map((item) => {
                    totalBalance += item.amount
                })
                if(record.approved === 1 && record?.payment_method_id !== 4){
                    message = "Ödendi"
                    return <Tag color="green">{message}</Tag>
                }
                if(record.approved === 1 && record?.payment_method_id === 4 && record?.amount <= totalBalance){
                    message = "Ödendi"
                    return <Tag color="green">{message}</Tag>
                }
                return <Tag color="red">{message}</Tag>
            }
        },
        {
            title: `${t("general.documents")}`,
            key:"files",
            dataIndex: "files",
            render:(text,record) => <div style={{
                display:"grid",
                gridTemplateColumns:"repeat(2,1fr)",
                gap:"10px"
            }}>
                {record?.documents?.map((item, key) => (
                    <Popover
                        key={key}
                        content={<Space>
                            <a key={key} href={`https://${item?.url}`} target="_blank" rel="noreferrer" style={{
                                whiteSpace:"nowrap",
                            }}>
                                <Button htmlType="button" type="primary" icon={<FontAwesomeIcon icon={faSave}/>}/>
                            </a>
                            <Button type="danger" htmlType="button" onClick={() => onDeleteReservationPaymentDocument(record.id, item.id)} icon={<FontAwesomeIcon icon={faTrash}/>}/>
                        </Space>}
                        title="İşlemler"
                        trigger="click"
                    >
                        <span className="text-blue-500 whitespace-nowrap cursor-pointer">
                            Dosya {key + 1}
                        </span>
                    </Popover>
                ))}
                {record?.documents?.length === 0 && "-"}
            </div>
        },
        {
            title: `${t("general.actions")}`,
            key:"actions",
            render:(text,record) => (
                <Space>
                    <Popconfirm
                        title="Silmek istediğinize emin misiniz?"
                        onConfirm={() => onDeleteReservationPayment(record.id)}
                        okText="Sil"
                        cancelText="İptal"
                    >
                        <Button type="danger" htmlType="button">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title={`Ödeme rezervasyon fişinde ${record?.is_visible_on_invoice ? "gizlenecektir.": "gösterilecektir."}`}
                        onConfirm={() => onHideReservationPayment(record.id)}
                        okText="Tamam"
                        cancelText="İptal"
                    >
                        <Button style={{
                            backgroundColor: record?.is_visible_on_invoice ? "#b7eb8f": "#ff4d4f",
                            color: record?.is_visible_on_invoice ? "#389e0d": "#fff",
                        }}>
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </Popconfirm>
                    {record.approved === null &&
                        <>
                            <Popconfirm
                                title="Onaylamak istediğinize emin misiniz?"
                                onConfirm={() => onApprovedReservationPayment(record.id,true)}
                                okText="Onayla"
                                cancelText="İptal"
                            >
                                <Button type="primary" htmlType="button">
                                    <FontAwesomeIcon icon={faCheck}/>
                                </Button>
                            </Popconfirm>
                            <Popconfirm
                                title="Reddetmek istediğinize emin misiniz?"
                                onConfirm={() => onApprovedReservationPayment(record.id,false)}
                                okText="Reddet"
                                cancelText="İptal"
                            >
                                <Button type="danger" htmlType="button">
                                    <FontAwesomeIcon icon={faBan}/>
                                </Button>
                            </Popconfirm>
                        </>
                    }
                    {record.approved === 1 && record?.payment_method_id === 4 &&
                        <>
                            <Tooltip
                                title={!permissions.includes('reservation.payment.balance.create') ? "Bu işlem için yetkiniz bulunmuyor.": "Bakiye Tamamlama Ödemesi"}
                                placement="left"
                            >
                                <Button type="primary" disabled={!permissions.includes('reservation.payment.balance.create')} onClick={() => onBalanceCompletion(record.id)}>
                                    <FontAwesomeIcon icon={faMoneyBillWave}/>
                                </Button>
                            </Tooltip>
                        </>
                    }
                    {(record.approved === 1 && record?.payment_method_id === 7 && user?.is_super_admin) &&
                        <ReservationPaymentTransferModalForm
                            title={t("general.virman")}
                            payment={record}
                            onSuccess={getReservationPayments}
                            isDisabled={!user?.is_super_admin}
                        />
                    }
                    <Tooltip title="Belge Yükle">
                        <Upload
                            name="file"
                            multiple={true}
                            action={`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL}/v1/reservation-payments/${record.id}/upload-document`}
                            headers={{
                                Authorization: `Bearer ${Authentication.getToken()}`
                            }}
                            showUploadList={false}
                            onChange={(info) => {
                                if (info.file.status === 'done') {
                                    message.success(`${info.file.name} dosyası başarıyla yüklendi.`);
                                    getReservationPayments();
                                } else if (info.file.status === 'error') {
                                    message.error(`${info.file.name} dosyası yüklenirken bir hata oluştu.`);
                                }
                            }}
                        >
                            <Button type="primary" htmlType="button">
                                <FontAwesomeIcon icon={faUpload} />
                            </Button>
                        </Upload>
                    </Tooltip>
                </Space>
            )
        }
    ]
    if (error) {
        return (
            <Alert
                message={t("general.error")}
                description={t("general.error_occurred_try_again")}
                type="error"
                showIcon
            />
        )
    }
    return (
        <>
            <Table
                columns={columns}
                dataSource={payments}
                loading={loading}
                /*title={() => <TotalPrice/>}*/
                bordered
                rowKey={(record) => record.id}
                scroll={{ x: 768 }}
                expandable={{
                    expandedRowRender: (record) => (
                        <Table
                            title={() => <h4>{t("general.balance_completion_payments")}</h4>}
                            columns={reservationPaymentCompletionsColumns}
                            dataSource={record?.balance_completions}
                            rowKey={(record) => record.id}
                            pagination={false}
                            bordered
                        />
                    ),
                    defaultExpandAllRows: true,
                    rowExpandable: (record) => record?.balance_completions?.length > 0,
                }}
            />
            <ReservationPaymentBalanceCompletionModalForm
                title="Bakiye Tamamlama Ödemesi Ekle"
                form={reservationPaymentBalanceCompletionForm}
                isVisible={showPaymentBalanceCompletionModal}
                onCancel={() => setShowPaymentBalanceCompletionModal(false)}
                onFinish={onAddPaymentBalanceCompletion}
                selectedPayment={selectedPayment}
                paymentMethods={paymentMethods || []}
                getSafeBankDefinitions={getSafeBankDefinitions}
                safeBankDefinitions={safeBankDefinitions || []}
            />
        </>
    );
};

export default PaymentsTable;