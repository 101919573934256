import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import { LaraBeachSettingsService, LaraBeachPointService } from "@/services";
import PointModalForm from "@/components/lara-beach-excursions/modals/PointModalForm";
import {Link} from "react-router-dom";

const {Content} = Layout;

const PointsPage = () => {
    const { t } = useTranslation()
    const [supportedLanguages, setSupportedLanguages] = useState([])
    const [createForm] = Form.useForm()
    const [editForm] = Form.useForm()
    const [points, setPoints] = useState({
        data: [],
        meta: {},
        loading: true
    })
    const [selectedPoint, setSelectedPoint] = useState({})
    const [isPointCreateModalVisible, setIsPointCreateModalVisible] = useState(false)
    const [isPointEditModalVisible, setIsPointEditModalVisible] = useState(false)
    const columns = [
        {
            title: t("general.id"),
            dataIndex: "id",
            key: "id"
        },
        {
            title: t("general.name"),
            dataIndex: "name",
            key: "name",
            render: (text, record) => <Link to={`/lara-beach-excursions/points/${record.id}`}>{text}</Link>
        },
        {
            title: t("general.actions"),
            key: "actions",
            render: (text, record) => (
                <Space direction="horizontal">
                    <Button htmlType="button" type="primary" onClick={() => onEdit(record.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </Space>
            )
        }
    ]
    const onCreate = async (values) => {
        try {
            let {data: response} = await LaraBeachPointService.create(values)
            if (response.success){
                notification.success({
                    message: response.message
                })
                setIsPointCreateModalVisible(false)
                createForm.resetFields()
                await getPoints()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getSupportedLanguages = async () => {
        try {
            let {data: response} = await LaraBeachSettingsService.show('supported_languages')
            setSupportedLanguages(response.data.value.split(',') || [])
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getPoints = async (page = 1, per_page = 10) => {
        setPoints(prev => ({
            ...prev,
            loading: true
        }))
        try {
            let {data: response} = await LaraBeachPointService.getParents(page, per_page)
            setPoints({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setPoints(prev => ({
                ...prev,
                loading: false
            }))
        }
    }
    const onUpdate = async (values, id) => {
        try {
            let {data: response} = await LaraBeachPointService.update(id, values)
            if (response.success){
                notification.success({
                    message: response.message
                })
                setIsPointEditModalVisible(false)
                editForm.resetFields()
                await getPoints()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onEdit = async (id) => {
        try {
            let {data: response} = await LaraBeachPointService.find(id)
            response?.data?.translations?.map((translation) => {
                editForm.setFields([
                    {
                        name: ['points', translation.language, 'name'],
                        value: translation.name
                    }
                ]);
            })
            setIsPointEditModalVisible(true)
            setSelectedPoint(response.data)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getSupportedLanguages()
        getPoints()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.points")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={() => setIsPointCreateModalVisible(true)}>
                                {t("general.create")}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={points.data}
                        rowKey="id"
                        pagination={{
                            current: points.meta.current_page,
                            pageSize: points.meta.per_page,
                            total: points.meta.total,
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                getPoints(page, pageSize)
                            },
                        }}
                        loading={points.loading}
                    />
                </div>
            </Content>
            <PointModalForm
                title={t("general.create")}
                isVisible={isPointCreateModalVisible}
                onCancel={() => {
                    setIsPointCreateModalVisible(false)
                    createForm.resetFields()
                }}
                form={createForm}
                supportedLanguages={supportedLanguages}
                onFinish={onCreate}
            />
            <PointModalForm
                title={t("general.edit")}
                isVisible={isPointEditModalVisible}
                onCancel={() => {
                    setIsPointEditModalVisible(false)
                    editForm.resetFields()
                }}
                form={editForm}
                supportedLanguages={supportedLanguages}
                onFinish={onUpdate}
                data={selectedPoint}
            />
        </Wrapper>
    );
};

export default PointsPage;