import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber, notification,
    Row,
    Select, Skeleton,
} from "antd";
import moment from "moment";
import {useTranslation} from "react-i18next";
import PaymentsTable from "@/views/new-version/reservation/components/PaymentsTable";
import {ReservationPaymentsService, PaymentMethodsService, SafeBankDefinitionService} from "@services";

const {Option} = Select;
const {TextArea} = Input;

const PaymentsTab = ({ reservation }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [payments, setPayments] = useState({
        data: [],
        loading: true,
        error: false,
    });
    const [paymentMethods, setPaymentMethods] = useState({
        data: [],
        loading: false,
    });
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [safeBankDefinitions, setSafeBankDefinitions] = useState({
        data: [],
        loading: false,
    });
    const onCreate = async (values) => {
        values = {
            ...values,
            reservation_id: reservation.id,
            fall_due: values?.fall_due ? moment(values?.fall_due).format('YYYY-MM-DD') : null
        }
        try {
            let {data: response} = await ReservationPaymentsService.create(values);
            if(response.success){
                notification.success({
                    message: response.message
                });
                await getReservationPayments()
                form.resetFields()
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSelectPaymentMethod = async (paymentMethod) => {
        setSelectedPaymentMethod(paymentMethod)
        form.setFieldsValue({
            safe_bank_id: undefined,
            amount: undefined,
            fall_due: undefined,
            description: undefined,
        })
        await getSafeBankDefinitions(paymentMethod)
    }
    const getSafeBankDefinitions = async (paymentMethod) => {
        setSafeBankDefinitions({ data:[], loading:true })
        try {
            let {data: response} = await SafeBankDefinitionService.getByReservationId(reservation.uuid, paymentMethod);
            setSafeBankDefinitions({
                data: response.data,
                loading: false,
            })
        } catch (e) {
            setSafeBankDefinitions({
                data: [],
                loading: false,
            })
        }
    }
    const getReservationPayments = async () => {
        setPayments({ data: [], loading: true, error: false })
        try {
            let {data: response} = await ReservationPaymentsService.getByReservation(reservation.uuid)
            setPayments({
                data: response.data,
                loading: false,
                error: false,
            })
        }catch (e){
            setPayments({
                data: [],
                loading: false,
                error: true,
            })
        }
    }
    useEffect(() => {
        const getPaymentMethods = async () => {
            setPaymentMethods({ data: [], loading: true })
            try {
                let {data: response} = await PaymentMethodsService.get();
                setPaymentMethods({
                    data: response.data,
                    loading: false,
                })
            } catch (e) {
                setPaymentMethods({
                    data: [],
                    loading: false,
                })
            }
        }
        getPaymentMethods();
        getReservationPayments();
    }, [])
    return (
        <div>
            <Form form={form} layout="vertical" onFinish={onCreate}>
                <Row gutter={[16,4]}>
                    <Col span={8}>
                        <Form.Item
                            label={t("general.payment_type")}
                        >
                            <Select placeholder="Ödeme tipini seçiniz." onSelect={onSelectPaymentMethod} loading={paymentMethods.loading} disabled={paymentMethods.loading}>
                                {paymentMethods.data?.map((payment)=>(
                                    <Option value={payment.id} key={payment.id}>{payment.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t("general.payment_type")}
                            name="safe_bank_id"
                            rules={[{
                                required:true,
                                message:"Ödeme yeri seçilmedi!"
                            }]}
                        >
                            <Select
                                disabled={safeBankDefinitions.loading}
                                loading={safeBankDefinitions.loading}
                                placeholder="Ödeme yeri seçiniz."
                            >
                                {safeBankDefinitions.data?.map((safeBank)=>(
                                    <Option key={safeBank.id} value={safeBank.id}>{safeBank.bank_name} - ({safeBank.title})</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={selectedPaymentMethod !== 4 ? 8:4}>
                        <Form.Item
                            name="amount"
                            label={t("general.amount")}
                            tooltip="Örn: 2460.50"
                            rules={[
                                {
                                    required:true,
                                    message:"Tutar girilmedi!"
                                },
                                {
                                    validator: async (_,value) => {
                                        if(value > reservation?.amount){
                                            return Promise.reject(new Error('Toplam tutardan yüksek tutar girildi!'))
                                        }
                                    }
                                }
                            ]}>
                            <InputNumber style={{width:"100%"}} min={0} placeholder="Tutar"/>
                        </Form.Item>
                    </Col>
                    <Col span={4} hidden={selectedPaymentMethod !== 4}>
                        <Form.Item label={t("general.due_date")} name="fall_due">
                            <DatePicker
                                format="DD.MM.YYYY"
                                placeholder="Vade Tarihi"
                                style={{width:"100%"}}
                                disabledDate={(current) => {
                                    return current && (current > moment(reservation?.check_in).add(1, 'days') || current < moment().startOf('day'))
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={t("general.description")} name="description">
                            <TextArea size="middle" placeholder="Açıklama giriniz."/>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{display:"flex",justifyContent:"flex-end"}}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">{t("general.add")}</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <PaymentsTable
                paymentMethods={paymentMethods.data}
                getSafeBankDefinitions={getSafeBankDefinitions}
                safeBankDefinitions={safeBankDefinitions.data}
                payments={payments.data}
                loading={payments.loading}
                error={payments.error}
                reservation={reservation}
                getReservationPayments={getReservationPayments}
            />
        </div>
    );
};

export default PaymentsTab;