import React from 'react';
import {Breadcrumb, Button, Col, Layout, Row, Table, Form, notification, Space, Tooltip, Popconfirm} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import MainRegionModalForm from "@components/definitions/MainRegionModalForm";
import { MainRegionService } from '@services';
import { useNavigate, useParams} from "react-router-dom";

const {Content} = Layout;

const MainRegion = () => {
    const [createRegionForm] = Form.useForm();
    const navigate = useNavigate();

    const [regions, setRegions] = React.useState({
        data: [],
        meta: {},
        loading: true,
        error: false
    });
    const [isCreateModalVisible, setIsCreateModalVisible] = React.useState(false);

    const getRegions = async (page) => {
        try {
            let {data: response} = await MainRegionService.getPaginate(page ?? 1);
            setRegions(
                {
                    ...regions,
                    data: response.data,
                    meta: response.meta,
                    loading: false,
                }
            );
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setRegions(
                {
                    ...regions,
                    loading: false,
                }
            );

        }
    }

    const onCreate = async (values) => {
        try {
            let {data: response} = await MainRegionService.create(values);
            if(response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setRegions(
                    {
                        ...regions,
                        data: response.data,
                        meta: response.meta,
                        loading: false,
                    }
                );
                setIsCreateModalVisible(false);
                createRegionForm.resetFields();
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }
    const onDelete = async (id) => {
        try {
            let {data: response} = await MainRegionService.delete(id);
            if(response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setRegions(regions.filter(flight => flight.id !== id))
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }
    const handleTableChange = (pagination, filters, sorter) => {
        navigate(`/external-management/definitions/main-regions?page=${pagination.current}`)
        console.log(pagination.current)
        getRegions(pagination.current);
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Bölge Adı',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Ülke',
            dataIndex: 'country',
            key: 'country',
            render: (text, record) => text?.name || <span style={{color:"red"}}>Silinmiş Ülke</span>
        },
        {
            title: 'Alternatif Adlar',
            dataIndex: 'alternative_names',
            key: 'alternative_names',
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (text, record) => (
                <Space direction="horizontal">
                    <Tooltip>
                        <Popconfirm
                            title="Silmek istediğinize emin misiniz?"
                            onConfirm={() => onDelete(record.id)}
                            okText="Evet"
                            cancelText="İptal"
                        >
                            <Button type="danger" htmlType="button">
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ];

    React.useEffect(() => {
        getRegions()
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Ana Bölgeler
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]} style={{
                        marginBottom: '10px'
                    }}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={() => setIsCreateModalVisible(true)}>Ana Bölge Tanımla</Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={regions.data}
                        pagination={
                            {
                                total: regions?.meta?.total,
                                current: regions?.meta?.current_page,
                                pageSize: regions?.meta?.per_page
                            }
                        }
                        onChange={handleTableChange}
                        loading={regions.loading}
                        rowKey={record => record.id}
                    />
                </div>
            </Content>
            <MainRegionModalForm
                title="Yeni Bölge Tanımla"
                form={createRegionForm}
                isVisible={isCreateModalVisible}
                onCancel={() => setIsCreateModalVisible(false)}
                onFinish={onCreate}
            />
        </Wrapper>
    );
};

export default MainRegion;