import React, {useEffect, useState} from 'react';
import {Breadcrumb, Layout, notification, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {ExternalOfferService} from "@services";
import {Link} from "react-router-dom";

const {Content} = Layout;

const ExternalOfferList = (page = 1, per_page = 10) => {
    const [offers, setOffers] = useState({
        data: [],
        meta: {},
        loading: true
    })
    const responsesColumns = [
        {
            title: "Otel",
            dataIndex: "hotel",
            key: "hotel",
            render: (text) => text?.name,
            width: 200
        },
        {
            title: "Whatsapp",
            dataIndex: "whatsapp_number",
            key: "whatsapp_number",
            render: (text) => text,
            width: 200
        },
        {
            title: "Teklif Yanıt Tarihi",
            dataIndex: "created_at",
            key: "created_at"
        }
    ];
    const responsePricesColumns = [
        {
            title: "Oda Tipi",
            dataIndex: "room_name",
            key: "room_name",
            width: 200
        },
        {
            title: "Fiyat",
            dataIndex: "price",
            key: "price",
            render: (text, record) => `${text} ${record.currency}`,
            width: 200
        },
        {
            title: "Giriş/Çıkış Tarihi",
            dataIndex: "date",
            key: "date",
            render: (text, record) => `${record?.check_in} / ${record?.check_out}`
        },
    ];
    const columns = [
        {
            title: "İşlem No",
            dataIndex: "id",
            key: "id",
            width: 240
        },
        {
            title: "Durum",
            dataIndex: "status",
            key: "status",
            render: (text) => {
                switch (text){
                    case "rejected":
                        return <Tag color="red">Reddedildi</Tag>
                    case "accepted":
                        return <Tag color="green">Teklif İletildi</Tag>
                    case "expired":
                        return <Tag color="red">Zaman Aşımı</Tag>
                    default:
                        return <Tag color="yellow">Bekliyor</Tag>
                }
            }
        },
        {
            title: "Ad Soyad",
            dataIndex: "customer",
            key: "customer",
            render: (text) => <Link to={`/external/customers/${text?.id}`}>{text?.full_name}</Link>
        },
        {
            title: "Giriş/Çıkış Tarihi",
            dataIndex: "check_date",
            key: "check_date",
            render: (text, record) => `${record.check_in} / ${record.check_out}`
        },
        {
            title: "Yetişkin Sayısı",
            dataIndex: "adult_number",
            key: "adult_number"
        },
        {
            title: "Çocuk Sayısı",
            dataIndex: "child_number",
            key: "child_number"
        },
        {
            title: "Bütçe",
            dataIndex: "budget",
            key: "budget",
            render: (text, record) => `${record?.min_bugdet} - ${record?.max_budget}`
        },
        {
            title: "Son Geçerlilik Tarihi",
            dataIndex: "expire",
            key: "expire",
            render: (text) => text?.is_expired ? <Tag color="red"> {text?.date}</Tag>: <Tag color="green">{text?.date}</Tag>
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note"
        },
        {
            title: "Teklif Tarihi",
            dataIndex: "created_at",
            key: "created_at"
        }
    ];
    const getOffers = async (page = 1, per_page = 10) => {
        try {
            let {data: response} = await ExternalOfferService.get(page, per_page)
            setOffers({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    useEffect(() => {
        getOffers()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Teklifler
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={offers.data}
                        loading={offers.loading}
                        rowKey="id"
                        scroll={{x: 1440}}
                        pagination={{
                            total: offers.meta.total,
                            pageSize: offers.meta.per_page,
                            current: offers.meta.page,
                            onChange: (page, pageSize) => getOffers(page, pageSize)
                        }}
                        expandable={{
                            expandedRowRender: record => (
                                <Table
                                    columns={responsesColumns}
                                    dataSource={record.responses}
                                    rowKey="id"
                                    pagination={false}
                                    scroll={{x: 1440}}
                                    expandable={{
                                        expandedRowRender: record => (
                                            <Table
                                                columns={responsePricesColumns}
                                                dataSource={record.prices}
                                                rowKey="id"
                                                pagination={false}
                                                scroll={{x: 1440}}
                                            />
                                        ),
                                        rowExpandable: record => record?.prices?.length > 0,
                                    }}
                                />
                            ),
                            rowExpandable: record => record?.responses?.length > 0,
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default ExternalOfferList;