import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, Form, InputNumber, Layout, notification, Row, Select, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faSave} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import { CurrentDeficitService, CurrentDeficitTransactionService } from '@services';
import TextArea from "antd/es/input/TextArea";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const { Content } = Layout;
const { Option } = Select;
const CurrentDeficitTransactions = () => {
    const { t } = useTranslation()
    const columns = [
        {
            title: `${t('general.cari')}`,
            dataIndex: 'cari_detail',
            key: 'cari_detail',
            render: (text, record) => <Link to={`/current-deficit-transactions/${text.id}/detail`}>{text?.cari?.name + " | " + text?.company_title + " - " + text?.bill_title }</Link>
        },
        {
            title: `${t('general.currency')}`,
            dataIndex: 'cari_detail',
            key: 'currency',
            render: (text, record) => `${text?.currency?.currency} (${text?.currency?.symbol})`
        },
        {
            title: `${t('general.debt')} (${t('general.total')})`,
            dataIndex: 'cari_detail',
            key: 'total_income',
            render: (text, record) => `${record?.total_income} ${text?.currency?.symbol}`
        },
        {
            title: `${t('general.receive')} (${t('general.total')})`,
            dataIndex: 'cari_detail',
            key: 'total_expense',
            render: (text, record) => `${record?.total_expense} ${text?.currency?.symbol}`
        },
        {
            title: `${t('general.balance')} (${t('general.total')})`,
            dataIndex: 'cari_detail',
            key: 'balance',
            render: (text, record) => `${record?.balance} ${text?.currency?.symbol}`
        }
    ];
    const [cariTransactionForm] = Form.useForm();
    const [caris, setCaris] = React.useState({
        data: [],
        loading: false,
        searchValue: '',
    });
    const [currentDeficitTransactions, setCurrentDeficitTransactions] = React.useState({
        data: [],
        meta: {},
        loading: true
    });
    const [selectedCari, setSelectedCari] = React.useState({});
    const onFinish = async (values) => {
        try {
            let {data: response} = await CurrentDeficitTransactionService.create(values);
            if(response.success){
                notification.success({
                    message: response.message,
                })
                getCurrentDeficitTransactions()
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.body.message,
                })
            }
        }
    }

    const getCurrentDeficitTransactions = async (page = 1, per_page = 10) => {
        try{
            let {data: response} = await CurrentDeficitTransactionService.get(page, per_page);
            setCurrentDeficitTransactions({
                ...caris,
                data: response.data,
                meta: response.meta,
                loading: false,
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.body.message,
                })
            }
        }
    }

    useEffect(() => {
        if(caris.searchValue === '') return
        setCaris({...caris, loading: true})
        const timer = setTimeout(() => {
            onSearchCari(caris.searchValue)
        }, 600);
        return () => clearTimeout(timer);
    },[caris.searchValue])

    useEffect(() => {
        getCurrentDeficitTransactions()
    },[])

    const onSearchCari = async (value) => {
        try {
            let {data: response} = await CurrentDeficitService.search(value)
            setCaris({
                ...caris,
                data: response.data,
                loading: false,
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.body.message,
                })
            }
        }
    }

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.cari_transactions')}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form layout="vertical" form={cariTransactionForm} onFinish={onFinish}>
                        <Row gutter={[16,4]}>
                            <Col span={6}>
                                <Form.Item
                                    label={t('general.cari')}
                                    name="cari"
                                    rules={[{ required: true, message: 'Lütfen cari seçiniz!' }]}
                                >
                                    <Select
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        loading={caris.loading}
                                        filterOption={false}
                                        value={caris.searchValue}
                                        onSearch={(value) => setCaris({...caris, searchValue: value})}
                                        placeholder="Arama yapmak için yazmaya başlayınız"
                                        notFoundContent={t('general.not_found')}
                                        onSelect={(value) => setSelectedCari(caris.data.find(cari => cari.id === value))}
                                    >
                                        {caris.data.map(cari => (
                                            <Option key={cari.id} value={cari.id}>{cari?.cari?.name} | {cari?.company_title} - {cari?.bill_title}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="type"
                                    label={t('general.type')}
                                    rules={[{required: true, message: 'Lütfen bir işlem tipi seçiniz!'}]}
                                >
                                    <Select
                                        placeholder={t('general.choose')}
                                    >
                                        <Option value="1">{t('general.debt')}</Option>
                                        <Option value="2">{t('general.receive')}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="amount"
                                    label={t('general.amount')}
                                    rules={[{required: true, message: 'Lütfen bir tutar giriniz!'}]}
                                >
                                    <InputNumber
                                        addonBefore={selectedCari?.currency?.symbol}
                                        min={0}
                                        style={{width: "100%"}}
                                        placeholder="Tutar giriniz"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="description"
                                    label={t('general.description')}
                                    rules={[{required: true, message: 'Lütfen bir açıklama giriniz!'}]}
                                >
                                    <TextArea
                                        placeholder="Açıklama giriniz"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" style={{width:"100%"}} icon={<FontAwesomeIcon icon={faSave} style={{marginRight:"8px"}}/>}>
                                    {t('general.save')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        loading={currentDeficitTransactions.loading}
                        columns={columns}
                        dataSource={currentDeficitTransactions.data}
                        rowKey={record => record.id}
                        pagination={{
                            pageSize: currentDeficitTransactions?.meta?.per_page,
                            total: currentDeficitTransactions?.meta?.total,
                            current: currentDeficitTransactions?.meta?.current_page,
                            showSizeChanger: true,
                            onChange: (page, pageSize) => getCurrentDeficitTransactions(page, pageSize),
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default CurrentDeficitTransactions;