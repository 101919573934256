import React, {useEffect} from 'react';
import {Breadcrumb, Button, Form, Layout, notification, Table, Tag, Tooltip} from "antd";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faPlus} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/v2/Wrapper";
import {useTranslation} from "react-i18next";
import {ContractService} from "@services";
import ContractCommissionModalForm
    from "@views/new-version/contract-groups/components/ContractCommissionModalForm";

const {Content} = Layout;

const ContractCommissions = () => {
    const params = useParams()
    const {t} = useTranslation();
    const [isContractCommissionModalVisible, setIsContractCommissionModalVisible] = React.useState(false)
    const [createForm] = Form.useForm()
    const [contracts, setContracts] = React.useState({
        data: [],
        loading: false
    })
    const [selectedContract, setSelectedContract] = React.useState(null)
    const onCreate = async (contractId, values) => {
        values = {
            ...values,
            start_date: values.start_date.format("YYYY-MM-DD"),
            end_date: values.end_date.format("YYYY-MM-DD"),
        }
        console.log(contractId, values)
    }
    const getContracts = async (hotelId, contractGroupId) => {
        setContracts({...contracts, loading: true})
        try {
            let {data: response} = await ContractService.demoGet(hotelId, contractGroupId)
            setContracts({
                data: response.data,
                loading: false
            })
        }catch (e) {
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setContracts({...contracts, loading: false})
        }
    }
    const columns = [
        {
            title: t("general.contract"),
            dataIndex: "title",
            key: "title",
        },
        {
            title: t("general.has_commission"),
            dataIndex: "has_commission",
            key: "has_commission",
            render: (row) => row ? <Tag color="green">{t("general.defined")}</Tag> : <Tag color="red">{t("general.not_defined")}</Tag>
        },
        {
            title: t("general.actions"),
            dataIndex: "actions",
            key: "actions",
            render: (row, record) => (
                <Tooltip title={t("general.create_commission")}>
                    <Button type="primary" htmlType="button" onClick={() => {
                        setIsContractCommissionModalVisible(true)
                        setSelectedContract(record)
                    }}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </Tooltip>
            )
        }
    ];
    const detailsColumns = [
        {
            title: t("general.start_date"),
            dataIndex: "start_date",
            key: "start_date"
        },
        {
            title: t("general.end_date"),
            dataIndex: "end_date",
            key: "end_date"
        },
        {
            title: t("general.commission"),
            dataIndex: "commission",
            key: "commission"
        },
        {
            title: t("general.actions"),
            dataIndex: "actions",
            key: "actions",
        }
    ]
    useEffect(() => {
        getContracts(params.hotelId, params.contractGroupId)
        // eslint-disable-next-line
    },[])
    return (
        <Wrapper title="Sanal Pos İşlemleri">
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/contract-groups`}>
                            {t("general.contract_groups")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.commissions")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={contracts.data}
                        loading={contracts.loading}
                        rowKey="id"
                        pagination={false}
                        expandable={{
                            expandedRowRender: record => (
                                <Table
                                    columns={detailsColumns}
                                    dataSource={record.commissions}
                                    rowKey="id"
                                    pagination={false}
                                    size="small"
                                />
                            ),
                            rowExpandable: record => /*record.has_commission*/ true,
                        }}
                    />
                </div>
            </Content>
            <ContractCommissionModalForm
                isVisible={isContractCommissionModalVisible}
                onCancel={() => {
                    setIsContractCommissionModalVisible(false)
                    createForm.resetFields()
                }}
                onFinish={onCreate}
                form={createForm}
                title={t("general.create_commission")}
                contractId={selectedContract ? selectedContract.id : null}
            />
        </Wrapper>
    );
};

export default ContractCommissions;