import React from 'react';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "@/views/new-version/reservation/components/documents/ActionButton";
import {useTranslation} from "react-i18next";
import ReservationBalanceSendModal from "@components/reservations/ReservationBalanceSendModal";
import {Form, notification} from "antd";
import {ReservationServiceV2} from "@services";

const SendBalanceCompletion = ({ reservation }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm();
    const [isBalanceModalVisible, setIsBalanceModalVisible] = React.useState(false);
    const [isBalanceSending, setIsBalanceSending] = React.useState(false);
    const onSendReservationBalance = async (values) => {
        let formData = new FormData();
        formData.append('mails', JSON.stringify(values.mails));
        values.files.map((file, key) => {
            formData.append(`file_${key}`, file['originFileObj']);
        })
        try{
            let response = await ReservationServiceV2.sendBalanceCompletion(reservation.uuid, formData);
            if(response.status === 201){
                form.resetFields()
                setIsBalanceModalVisible(false)
                notification.success({
                    message: "Mail başarıyla gönderildi."
                });
            }
            setIsBalanceSending(false)
        }catch (e){
            setIsBalanceSending(false)
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    return (
        <React.Fragment>
            <ActionButton
                icon={faPaperPlane}
                title={t("general.balance_completion")}
                loading={isBalanceSending}
                onClick={() => setIsBalanceModalVisible(true)}
            />
            <ReservationBalanceSendModal
                title="Rezervasyon Bakiye Tamamlama Gönder"
                form={form}
                isVisible={isBalanceModalVisible}
                onCancel={() => setIsBalanceModalVisible(false)}
                onFinish={onSendReservationBalance}
                loading={isBalanceSending}
            />
        </React.Fragment>
    );
};

export default SendBalanceCompletion;