import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {CMConceptService} from "@services";
import {useParams} from "react-router-dom";
import CMConceptCreateModalForm from "@components/channel-management/CMConceptCreateModalForm";

const {Content} = Layout;

const CmConceptList = () => {
    const columns = [
        {
            title: 'Konsept ID',
            dataIndex: 'id',
            key: 'id',
            width: 200,
        },
        {
            title: 'Konsept Adı',
            dataIndex: 'name',
            key: 'name',
            width: "50%",
            render: (text) => text
        }
    ];
    const [isLoading, setIsLoading] = React.useState(true);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [concepts, setConcepts] = React.useState({
        data: [],
        meta: {},
        hotel: {}
    });
    const [form] = Form.useForm();
    const params = useParams()

    const getConcepts = async (id, page) => {
        setIsLoading(true)
        try{
            let {data: response} = await CMConceptService.get(id, page);
            setConcepts({
                data: response.data,
                meta: response.meta,
                hotel: response.hotel
            });
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const onCreate = async (values) => {
        values = {...values, hotel_id: params.hotelId}
        try{
            let response = await CMConceptService.create(values);
            setConcepts({
                ...concepts,
                data: [...concepts.data, response.data.data]
            });
            setIsModalVisible(false);
            form.resetFields();
            notification.success({
                message: response.data.message,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    useEffect(() => {
        getConcepts(params.hotelId);
    },[params.hotelId])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/channel-management/hotels">
                        Kanal Yönetimi - Tesisler
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {concepts?.hotel?.name ?? '...'} - Konsept Listesi
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" className="mb-10" onClick={() => setIsModalVisible(true)}>Yeni Konsept Ekle</Button>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={concepts.data}
                                loading={isLoading}
                                pagination={{
                                    pageSize: concepts?.meta?.per_page,
                                    total: concepts?.meta?.total,
                                    current: concepts?.meta?.current_page,
                                }}
                                onChange={(pagination) => getConcepts(params.hotelId, pagination.current)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
            <CMConceptCreateModalForm
                isVisible={isModalVisible}
                onFinish={onCreate}
                onCancel={() => setIsModalVisible(false)}
                title="Yeni Konsept Ekle"
                form={form}
            />
        </Wrapper>
    );
};

export default CmConceptList;