import React, {useEffect} from 'react';
import {Breadcrumb, Layout, Popover, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ReservationService} from "@services";

const {Content} = Layout;

const TempReservations = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const getTempReservations = async (page, pageSize) => {
        try {
            let {data: response} = await ReservationService.getTemp(page, pageSize);
            setData(response);
        }catch (e){
            console.log(e);
        }
    }
    const columns = [
        {
            title: 'Aranma İsteği',
            dataIndex: 'call_me',
            key: 'call_me',
            render: (call_me) => call_me ? <Tag color="green">Evet</Tag> : <Tag color="red">Hayır</Tag>
        },
        {
            title: 'Otel Adı',
            dataIndex: 'hotel',
            key: 'hotel',
        },
        {
            title: 'Oda Adı',
            dataIndex: 'room',
            key: 'room',
            render: (room) => (
                <Popover
                    placement="bottom"
                    title="Diğer Tanım Bilgileri"
                    content={
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            {room?.names?.map((name,index) => (
                                <p key={index}>{name?.name} ({name?.language?.toUpperCase()})</p>
                            ))}
                        </div>
                    }
                    trigger="hover"
                >
                    <span style={{color:"#1890ff",cursor:"pointer"}}>{room?.name ?? "Tanımlı Değil"}</span>
                </Popover>
            )
        },
        {
            title: 'Konsept',
            dataIndex: 'concept',
            key: 'concept',
            render: (concept) => (
                <Popover
                    placement="bottom"
                    title="Diğer Tanım Bilgileri"
                    content={
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            {concept?.names?.map((name,index) => (
                                <p key={index}>{name?.name} ({name?.language?.toUpperCase()})</p>
                            ))}
                        </div>
                    }
                    trigger="hover"
                >
                    <span style={{color:"#1890ff",cursor:"pointer"}}>{concept?.name ?? "Tanımlı Değil"}</span>
                </Popover>
            )
        },
        {
            title: 'Müşteriler',
            dataIndex: 'guests',
            key: 'guests',
            render: (guests) => (
                <Popover placement="bottom" title="Ziyaretçiler" content={
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        {guests?.adult?.length > 0 && (
                            <>
                                <h4>Yetişkinler</h4>
                                {guests?.adult?.map((adult, index) => (
                                    <p key={index}><b>{index + 1}.</b> {adult?.name} {adult?.surname} ({adult?.gender}) - {adult?.birthDate} <br/> <b>Telefon:</b> {adult?.phone}</p>
                                ))}
                            </>
                        )}
                        {guests?.children?.length > 0 && (
                            <>
                                <h4>Çocuklar</h4>
                                {guests?.children?.map((child, index) => (
                                    <p key={index}><b>{index + 1}.</b> {child?.name} {child?.surname} ({child?.gender}) - {child?.birthDate}</p>
                                ))}
                            </>
                        )}
                    </div>
                } trigger="click">
                    <span style={{color:"#1890ff",cursor:"pointer"}}>{guests?.adult?.length + guests?.children?.length} Kişi</span>
                </Popover>
            )
        },
        {
            title: 'Giriş Tarihi',
            dataIndex: 'check_in',
            key: 'check_in',
        },
        {
            title: 'Çıkış Tarihi',
            dataIndex: 'check_out',
            key: 'check_out',
        },
        {
            title: 'Tutar',
            dataIndex: 'total_fee',
            key: 'total_fee',
            render: (total_fee, record) => total_fee + ' ' + record?.currency?.symbol
        },
        {
            title: 'İşlem Tarihi',
            dataIndex: 'created_at',
            key: 'created_at'
        },
        {
            title: 'Ödeme İşlem Durumu',
            dataIndex: 'transactions',
            key: 'transactions'
        }
    ]
    useEffect(() => {
        getTempReservations(page, pageSize).finally(() => setIsLoading(false));
    },[page, pageSize])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Tamamlanmamış Rezervasyonlar
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={data.data}
                        loading={isLoading}
                        pagination={{
                            total: data?.meta?.total,
                            pageSize: data?.meta?.per_page,
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPageSize(pageSize);
                            }
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default TempReservations;