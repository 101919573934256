import React from 'react';
import VoucherDownload from "@/views/new-version/reservation/components/documents/VoucherDownload";
import ReceiptDownload from "@/views/new-version/reservation/components/documents/ReceiptDownload";
import SendVoucher from "@/views/new-version/reservation/components/documents/SendVoucher";
import SendConfirm from "@/views/new-version/reservation/components/documents/SendConfirm";
import SendProforma from "@/views/new-version/reservation/components/documents/SendProforma";
import SendBalanceCompletion from "@/views/new-version/reservation/components/documents/SendBalanceCompletion";
import SendFactSheet from "@/views/new-version/reservation/components/documents/SendFactSheet";
import SendSaleAgreement from "@/views/new-version/reservation/components/documents/SendSaleAgreement";

const DocumentsTab = ({ reservation }) => {
    return (
        <div className="flex flex-wrap gap-4">
            <VoucherDownload
                reservation={reservation}
            />
            <ReceiptDownload
                reservation={reservation}
            />
            <SendVoucher
                reservation={reservation}
            />
            <SendConfirm
                reservation={reservation}
            />
            <SendProforma
                reservation={reservation}
            />
            <SendBalanceCompletion
                reservation={reservation}
            />
            <SendFactSheet
                reservation={reservation}
            />
            <SendSaleAgreement
                reservation={reservation}
            />
        </div>
    );
};

export default DocumentsTab;