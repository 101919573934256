import React, {useEffect} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    InputNumber,
    Layout, notification, Popconfirm,
    Row,
    Select,
    Space,
    Switch, Table,
    Tag
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/v2/Wrapper";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {ContractGroupsService, ContractService, DiscountServiceV2} from "@services";
import {Link, useParams} from "react-router-dom";

const {Content} = Layout;
const {Option} = Select;
const {RangePicker} = DatePicker;

const ActionDiscountList = () => {
    const params = useParams()
    const { t } = useTranslation();
    const [discountForm] = Form.useForm();
    const [contractGroups,setContractGroups] = React.useState([]);
    const [actionDiscounts,setActionDiscounts] = React.useState([]);
    const [rooms,setRooms] = React.useState([]);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t('general.status')}`,
            key: 'status',
            dataIndex: 'status',
            render: (text, record) => {
                if(record.status === 1){
                    return <Tag color="green">{t('general.active')}</Tag>
                }
                if(record.status === 2){
                    return <Tag color="red">{t('general.passive')}</Tag>
                }
            }
        },
        {
            title: `${t('general.room')}`,
            dataIndex: 'contract_name',
            render: (text) => (
                <Space>
                    <div style={{fontSize:"13px"}}>{text || "N/A"}</div>
                </Space>
            )
        },
        {
            title: `${t('general.stay_start_date')}`,
            dataIndex: 'stay_start_date',
            render: (text, record, index) => moment(record.stay_start_date).format('DD.MM.YYYY')
        },
        {
            title: `${t('general.stay_end_date')}`,
            dataIndex: 'stay_end_date',
            render: (text, record, index) => moment(record.stay_end_date).format('DD.MM.YYYY')
        },
        {
            title: `${t('general.availability_start_date')}`,
            dataIndex: 'validity_start_date',
            render: (text, record, index) => moment(record.validity_start_date).format('DD.MM.YYYY')
        },
        {
            title: `${t('general.availability_end_date')}`,
            dataIndex: 'validity_end_date',
            render: (text, record, index) => moment(record.validity_end_date).format('DD.MM.YYYY')
        },
        {
            title: `${t('general.discount')}`,
            render: (text, record) => {
                if(record.discount_value_type === "1"){
                    return `%${record.discount}`
                }
                if(record.discount_value_type === "2"){
                    return `${record.discount} ${t('general.unit')}`
                }
            }
        },
        {
            title: `${t('general.is_cost_include')}`,
            dataIndex: 'is_cost_include',
            render: (text) => text ? <Tag color="green">Evet</Tag> : <Tag color="red">Hayır</Tag>
        },
        {
            title: `${t('general.is_active')}`,
            dataIndex: 'is_active',
            render: (text, record) => <Switch checked={text || false} onChange={() => onStatusChange(record.id, record.discount_name)} />
        },
        {
            title: `${t('general.actions')}`,
            key: 'actions',
            render: (text, record) => (
                <Space align="middle">
                    <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => onDelete(record.id)} okText="Sil" cancelText="İptal">
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]
    const onStatusChange = async (id, discount_name) => {
        try {
            let {data: response} = await DiscountServiceV2.changeDiscountStatus(params.hotelId, {
                id,
                code: discount_name
            });
            if (response.success){
                notification.success({
                    message: response.message
                });
                setActionDiscounts(prev => ({
                    ...prev,
                    data: prev.data.map(item => item.id === id ? {...item, is_active: !item.is_active} : item)
                }))
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getContractGroups = async (hotelId) => {
        if(hotelId === null){
            setContractGroups([])
            setRooms([])
            return discountForm.setFieldsValue({rooms:undefined,contract_group:undefined})
        }
        try {
            let { data: {data:contractGroups} } = await ContractGroupsService.get(params.page,hotelId);
            setContractGroups(contractGroups);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getRooms = async (hotelId, contractGroupId) => {
        if(contractGroupId === null){
            setRooms([])
            return discountForm.setFieldsValue({rooms:undefined})
        }
        try {
            let { data: {data:rooms} } = await ContractService.demoGet(hotelId, contractGroupId);
            setRooms(rooms);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const onSubmit = async (hotelId, values) => {
        values = {
            contract_ids: values.rooms,
            stay_start_date: values.stay_date[0].format('YYYY-MM-DD'),
            stay_end_date: values.stay_date[1].format('YYYY-MM-DD'),
            validity_start_date: values.validity_date[0].format('YYYY-MM-DD'),
            validity_end_date: values.validity_date[1].format('YYYY-MM-DD'),
            discount_value_type: values.discount_value_type,
            discount: values.discount,
            is_cost_include: values.is_cost_include,
        }
        try {
            let {data: response} = await DiscountServiceV2.createActionDiscount(hotelId, values);
            if (response.success){
                notification.success({
                    message: response.message
                });
                discountForm.resetFields();
                getActionDiscounts(hotelId)
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getActionDiscounts = async (hotelId) => {
        try {
            let {data: response} = await DiscountServiceV2.getActionDiscounts(hotelId);
            setActionDiscounts(response.data);
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onDelete = async (id) => {
        try {
            let {data: response} = await DiscountServiceV2.deleteActionDiscount(params.hotelId, id);
            if (response.success){
                notification.success({
                    message: response.message
                });
                getActionDiscounts(params.hotelId)
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    useEffect(() => {
        getActionDiscounts(params.hotelId)
        getContractGroups(params.hotelId)
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/discounts`}>
                            {t("general.discounts")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.action_discounts")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={discountForm} layout="vertical" onFinish={(values) => onSubmit(params.hotelId, values)}>
                        <Row gutter={[16,16]}>
                            <Col span={8}>
                                <Form.Item
                                    name="stay_date"
                                    label={t('general.stay_date_range')}
                                    rules={[
                                        {
                                            required:true,
                                            message:"Konaklama başlangıç ve bitiş tarihi seçilmelidir!"
                                        }
                                    ]}
                                >
                                    <RangePicker
                                        format="DD.MM.YYYY"
                                        placeholder={[`${t("general.start_date")}`, `${t("general.end_date")}`]}
                                        disabledDate={(currentDate) => {
                                            return currentDate.isBefore(moment().subtract(1,'days'))
                                        }}
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="validity_date"
                                    label={t('general.availability_date_range')}
                                    rules={[
                                        {
                                            required:true,
                                            message:"Geçerlilik başlangıç ve bitiş tarihi seçilmelidir!"
                                        }
                                    ]}
                                >
                                    <RangePicker
                                        format="DD.MM.YYYY"
                                        placeholder={[`${t("general.start_date")}`, `${t("general.end_date")}`]}
                                        disabledDate={(currentDate) => {
                                            return currentDate.isBefore(moment().subtract(1,'days'))
                                        }}
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="discount"
                                    label={t('general.discount')}
                                    rules={[
                                        {
                                            required:true,
                                            message:"Maks. gece sayısı seçilmelidir!"
                                        }
                                    ]}
                                >
                                    <InputNumber addonBefore={
                                        <Form.Item
                                            name="discount_value_type"
                                            noStyle={true}
                                            rules={[
                                                {
                                                    required:true,
                                                    message:"İndirim tipi seçilmelidir!"
                                                }
                                            ]}
                                            initialValue="1"
                                        >
                                            <Select>
                                                <Option value="1">{t('general.percent')}</Option>
                                                <Option value="2">{t('general.unit')}</Option>
                                            </Select>
                                        </Form.Item>
                                    } min={1} style={{width:"100%"}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                            <Col span={6}>
                                <Form.Item
                                    name="contract_group"
                                    label={t('general.contract_groups')}
                                    rules={[{required:true,message:"Kontrat grubu seçimi yapılmalıdır!"}]}
                                >
                                    <Select style={{width:"100%"}} onChange={(e) => getRooms(params.hotelId, e)} disabled={!contractGroups.length} placeholder="Seçim yapınız.">
                                        <Option>Seçiniz</Option>
                                        {contractGroups?.map((contractGroup,index)=>(
                                            <Option key={index} value={contractGroup.id}>{contractGroup.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="rooms"
                                    label={t('general.rooms')}
                                    rules={[{required:true,message:"Min. 1 oda seçilmelidir!"}]}>
                                    <Select mode="multiple" style={{width:"100%"}} disabled={!rooms.length} placeholder="Seçim yapınız.">
                                        {rooms?.map((room,index)=>(
                                            <Option key={index} value={room.id}>{room.title}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="is_cost_include"
                                    label={t('general.is_cost_include')}
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Switch defaultChecked />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{width:"100%"}}>
                                        {t('general.save')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        columns={columns}
                        dataSource={actionDiscounts}
                        rowKey="id"
                        pagination={false}
                        scroll={{x: 1000}}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default ActionDiscountList;