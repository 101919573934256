import React from 'react';
import {useTranslation} from "react-i18next";
import {Form, Input, message, Spin, Upload} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FileService} from "@services";

const FILE_SIZE = 1024 * 1024 * 10; // 10MB

const HotelCreatePhotosStep = ({ form }) => {
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = React.useState(false);
    const fileList = Form.useWatch('hotel_photos', form) || [];
    const onRemove = (file) => {
        form.setFieldsValue({
            hotel_photos: fileList.filter((item) => item.uid !== file.uid)
        });
    }
    const onUpload = async (data) => {
        if (data.file?.status === "removed") return;
        if (data.file?.size > FILE_SIZE){
            message.error(t('general.file_size_must_be_less_than', {size: '10MB'}));
            return;
        }
        const formData = new FormData();
        formData.append('file', data.file);
        setIsUploading(true);
        try {
            let {data: response} = await FileService.uploadTempFile(formData);
            if (response.success){
                form.setFieldsValue({
                    hotel_photos: [...fileList, {
                        uid: response.data.uid,
                        name: response.data.name,
                        hashed_name: response.data.hash,
                        url: response.data.url,
                        status: 'done',
                        expires_at: response.data.expires_at
                    }]
                });
            }
            setIsUploading(false);
        }catch (e){
            if (e.response){
                message.error(e.response.data.message);
            }
        }
    }
    return (
        <>
            <Form.Item name="hotel_photos" hidden><Input/></Form.Item>
            <Upload
                beforeUpload={() => false}
                onChange={(data) => onUpload(data)}
                listType="picture-card"
                fileList={fileList}
                itemRender={(originNode, file) => React.cloneElement(originNode, {
                    title: file.status === 'error' ? t('general.file_expire') : file.name,
                })}
                onRemove={(file) => onRemove(file)}
            >
                <div>
                    {isUploading ? (
                        <Spin />
                    ): (<FontAwesomeIcon icon={faPlus} />)}
                    <div style={{ marginTop: 8 }}>
                        {t('general.upload')}
                    </div>
                </div>
            </Upload>
            <span style={{ color: "red" }}>
                <Form.ErrorList errors={form.getFieldError('hotel_photos')} />
            </span>
        </>
    )
};

export default HotelCreatePhotosStep;