import React, { Children, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Breadcrumb, Button, Table, notification, Form, Space, Popconfirm, Row, Grid, Col, Input, InputNumber } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faTrash, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { ConditionService, ChildAgeService } from '@services';
import moment from "moment";
import {useTranslation} from "react-i18next";

const { Content } = Layout;

const Conditions = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isChildAgeLoading, setIsChildAgeLoading] = useState(true);
  const [fields, setFields] = useState([
    { name: "adult", value: 1 },
  ]);

  const [conditions, setConditions] = useState([]);
  const [childAges, setChildAges] = useState([])
  /* create modal form states */
  const [createForm] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  useEffect(() => {
    getConditions(params.conditionGroupId)
    getChildAges()
  }, []);


  const getConditions = async (conditionGroupId) => {
    try {
      let { data: response } = await ConditionService.get(conditionGroupId)
      setConditions(response.data)
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };
  const getChildAges = async () => {
    try {
      let { data: response } = await ChildAgeService.get();
      let temp = [];
      response.data.map((item) => {
        temp.push({
          id: item.id,
          age_type: item.age_type,
          minimum_age: item.minimum_age,
          maximum_age: item.maximum_age,
        });
      });
      temp.map(item => {
        setFields([...fields, { name: `children-${item.id}`, value: 0 }])
      })
      setChildAges(temp);
      setIsChildAgeLoading(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
      console.log(e);
    }
  };
  const onCreate = async (values) => {
    values = values.filter(item => item.new)
    values = values.map(x => {
      return { ...x, condition_group_id: params.conditionGroupId };
    })
    try {
      let { data: response } = await ConditionService.create(params.conditionGroupId, values);
      if (response.success) {
        setIsLoadingTable(true);
        setIsCreateModalVisible(false);
        createForm.resetFields();
        getConditions(params.conditionGroupId);

        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }
      console.log(e);
    }
  };


  const addCondition = (values) => {
    /*
    * Kondüsyon Tablosuna Veri Ekler
    * */
    setConditions([
      ...conditions,
      {
        id: conditions.length > 0 ? conditions[conditions.length - 1].id + 1 : 1,
        ...values,
        new: true
      },
    ]);
  }
  const onDelete = async (conditionId) => {
    try {
      let { data: response } = await ConditionService.delete(params.conditionGroupId, conditionId);

      if (response.success) {
        setIsLoadingTable(true);
        getConditions(params.conditionGroupId);
        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  };
  const removeFromDatabase = (id) => {
    /*
    * Kondüsyon Tablosundaki Verileri Siler. (Veri Tabanı işlemi sağlanmaz.)
    * */
    setConditions([
      ...conditions.filter(condition => condition.id !== id)])
  }
  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/condition-groups">
            Kondüsyon Grupları
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Kondüsyonlar
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background">
          <Form form={createForm} onFinish={addCondition} fields={fields}>
            <Row gutter={16} justify="start">
              <Col>
                <Form.Item name="title" label={t("general.condition_name")}>
                  <Input placeholder={t("general.condition_name")} required />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="adult" label={t("general.adult_count")}>
                  <InputNumber placeholder="Yetişkin Sayısı" required />
                </Form.Item>
              </Col>
              {childAges.map((item, index) => (
                <Col key={index}>
                  <Form.Item name={`children-${item.id}`} label={item.age_type}>
                    <InputNumber placeholder={item.age_type} required />
                  </Form.Item>
                </Col>
              ))}
              <Col>
                <Button type="primary" htmlType='submit'>
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />
                  {t("general.add_condition")}
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={() => onCreate(conditions)}>
                  <FontAwesomeIcon icon={faSave} className="mr-1" />
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
          </Form>
              <table style={{width: "100%"}}>
                <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">#</th>
                  <th className="ant-table-cell">{t("general.condition_name")}</th>
                  <th className="ant-table-cell">{t("general.adult_count")}</th>
                  {childAges.map((item, index) => (
                      <th key={index} className="ant-table-cell" style={{whiteSpace:"nowrap"}}>{item.age_type}</th>
                  ))}
                  <th className="ant-table-cell">{t("general.actions")}</th>
                </tr>
                </thead>
                <tbody className="ant-table-tbody">
                {conditions.map((item, index) => (
                    <tr key={index} className="ant-table-row ant-table-row-level-0">
                      <td className="ant-table-cell">{index + 1}</td>
                      <td className="ant-table-cell">{item.title}</td>
                      <td className="ant-table-cell">{item.adult}</td>
                      {childAges.map((childAge, index) => (
                          <td key={index} className="ant-table-cell">{item[`children-` + childAge.id]}</td>
                      ))}
                      <td className='ant-table-cell'>
                        <Space align="middle">
                          {!item.new ?
                              <Popconfirm title="Silmek istediğine emin misin?" onConfirm={() => onDelete(item.id)}
                                          okText="Sil" cancelText="İptal">
                                <Button type="danger">
                                  <FontAwesomeIcon icon={faTrash}/>
                                </Button>
                              </Popconfirm> :
                              <Popconfirm title="Silmek istediğine emin misin?"
                                          onConfirm={() => removeFromDatabase(item.id)} okText="Sil" cancelText="İptal">
                                <Button type="primary">
                                  <FontAwesomeIcon icon={faTrash}/>
                                </Button>
                              </Popconfirm>
                          }
                        </Space>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
        </div>
      </Content>
    </Wrapper >
  )
};

export default Conditions;