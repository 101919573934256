import React, {useEffect, useState} from 'react';
import {Breadcrumb, Layout, notification, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {ExternalCustomerService} from "@services";
import {Link} from "react-router-dom";

const {Content} = Layout;

const ExternalCustomerList = (page = 1, per_page = 10) => {
    const [customers, setCustomers] = useState({
        data: [],
        meta: {},
        loading: true
    })
    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Ad Soyad",
            dataIndex: "full_name",
            key: "full_name",
            render: (text, record) => <Link to={`/external/customers/${record.id}`}>{text}</Link>
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Telefon",
            dataIndex: "phone_number",
            key: "phone_number",
            render: (text) => text || "Belirtilmemiş"
        },
        {
            title: "Teklif Sayısı",
            dataIndex: "offers_count",
            key: "offers_count"
        },
        {
            title: "Kayıt Tarihi",
            dataIndex: "created_at",
            key: "created_at"
        }
    ];
    const getCustomers = async (page = 1, per_page = 10) => {
        try {
            let {data: response} = await ExternalCustomerService.get(page, per_page)
            setCustomers({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    useEffect(() => {
        getCustomers()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Müşteriler
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={customers.data}
                        loading={customers.loading}
                        pagination={{
                            total: customers.meta.total,
                            pageSize: customers.meta.per_page,
                            current: customers.meta.page,
                            onChange: (page, pageSize) => getCustomers(page, pageSize)
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default ExternalCustomerList;