import React from 'react';
import {Alert, Button, Col, Form, Input, Modal, notification, Row, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import {ContractService} from "@services";

const ContractGroupCopyFormModal = ({ contractGroupId, hotelId }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isVisible, setIsVisible] = React.useState(false);
    const onFinish = async (values, contractGroupId, hotelId) => {
        try {
            let {data: response} = await ContractService.demoCopyContractGroup(hotelId, contractGroupId, values);
            if (response){
                notification.success({
                    message: t("general.success"),
                    description: response.message,
                    duration: 4,
                })
                setIsVisible(false);
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: t("general.error"),
                    description: e.response.data.message
                })
            }
        }
    }
    return (
        <>
            <Tooltip title={t("general.copy")}>
                <Button type="primary" onClick={() => setIsVisible(true)}>
                    <FontAwesomeIcon icon={faCopy} />
                </Button>
            </Tooltip>
            <Modal visible={isVisible} title="Kontrat Grubu Kopyalama" closable={false} onOk={() => form.submit()} onCancel={() => setIsVisible(false)}>
                <Form form={form} layout="vertical" onFinish={(values) => onFinish(values, contractGroupId, hotelId)}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Yeni Kontrat Grubu Adı"
                                name="name"
                                rules={[{ required: true, message: "Bu alan zorunludur" }]}
                            >
                                <Input placeholder="Yeni Kontrat Grubu Adı" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Alert
                    message="Kontrat Grubu Kopyalama Hakkında"
                    description="Kontrat grubu kopyalama işlemi sırasında, seçtiğiniz kontrat grubunun tüm kontratları ve kontrat'a dahil olan fiyat, kondüsyon, kota, minimum konaklama vb. tüm bilgileri yeni kontrat grubuna kopyalanacaktır."
                    type="info"
                    showIcon
                />
            </Modal>
        </>
    );
};

export default ContractGroupCopyFormModal;