import React, { useEffect, useState } from 'react';
import {
    Layout,
    Breadcrumb,
    Button,
    Table,
    notification,
    Space,
    Tag,
    Row,
    Col,
    Input,
    Form, DatePicker, Select, Checkbox, Tooltip
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouse,
    faSearch
} from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/v2/Wrapper';
import { ReservationServiceV2, CountriesService } from '@services';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ReservationsTableActions from "@/views/new-version/reservation/components/ReservationTableActions";
import {useSelector} from "react-redux";
import ReservationModalForm from "@/views/new-version/reservation/components/ReservationModalForm";

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ReservationList = () => {
    const { t } = useTranslation();
    const [reservationEditForm] = Form.useForm();
    const [guestEditForm] = Form.useForm();
    const permissions = useSelector((state) => state.user.user.role.permissions);
    const user = useSelector((state) => state.user.user);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [selectedReservation, setSelectedReservation] = useState({});
    const [isReservationModalFormVisible, setIsReservationModalFormVisible] = useState(false);
    const [reservations, setReservations] = useState({
        data: [],
        isLoading: true,
        meta: {},
    });
    const [countries, setCountries] = useState([]);
    const [searchParams, setSearchParams] = useState({
        hotel_name: "",
        room_name: "",
        agent_name: "",
        uuid: "",
        check_in: "",
        check_out: "",
        guest_name: "",
        reservation_date: "",
        status: "",
        currencies: ["TRY","USD","EUR","GBP"],
        only_checkin: false,
        only_checkout: false,
    });
    const [reservationSearchForm] = Form.useForm();
    const columns = [
        {
            key: "uuid",
            title: t("general.reservation_no"),
            dataIndex: 'uuid',
            render: (row) => (
                <Link to={`/v2/reservations/${row}`} target="_blank">
                    <Button type="primary" htmlType="button" className="font-semibold w-full rounded-lg">
                        {row}
                    </Button>
                </Link>
            )
        },
        {
            key: "status",
            title: t("general.status"),
            dataIndex: 'status',
            render: (row) => <ReservationStatusCell status={row} />
        },
        {
            key: "payment",
            title: t("general.payment"),
            dataIndex: 'payment_status',
            render: (row) => <ReservationPaymentStatusCell status={row} />
        },
        {
            key: "created_by",
            title: t("general.reservation_creator"),
            dataIndex: 'created_by',
        },
        {
            key: "hotel_name",
            title: t("general.hotel"),
            dataIndex: 'hotel_name',
        },
        {
            key: "room_name",
            title: t("general.room_name"),
            dataIndex: 'room_name',
        },
        {
            key: "guest_name",
            title: t("general.full_name"),
            dataIndex: 'guest_name',
        },
        {
            key: "check_in",
            title: t("general.check_in"),
            dataIndex: 'check_in',
            render: (row) => moment(row).format("DD.MM.YYYY")
        },
        {
            key: "check_out",
            title: t("general.check_out"),
            dataIndex: 'check_out',
            render: (row) => moment(row).format("DD.MM.YYYY")
        },
        {
            key: "locked",
            title: t("general.lock_status"),
            dataIndex: 'is_locked',
        },
        {
            key: "amount",
            title: t("general.reservation_amount"),
            dataIndex: 'amount',
            render: (row, record) => row.toLocaleString("tr-TR", { style: "currency", currency: record.currency })
        },
        {
            key: "discounted_amount",
            title: t("general.discount_reservation_amount"),
            dataIndex: 'discounted_amount',
            render: (row, record) => row.toLocaleString("tr-TR", { style: "currency", currency: record.currency })
        },
        {
            key: "reservation_date",
            title: t("general.reservation_date"),
            dataIndex: 'date',
            render: (row) => moment(row).format("DD.MM.YYYY HH:mm:ss"),
        },
        {
            key: 'actions',
            title: t("general.actions"),
            fixed: 'right',
            width: 80,
            render: (text, record) =>
                <ReservationsTableActions
                    data={record}
                    getReservationById={() => getReservationById(record.uuid)}
                    /*onListReservation={() => onListReservation(record.uuid)}
                    sendMailOnCancel={sendMailOnCancel}
                    setSendMailOnCancel={setSendMailOnCancel}
                    onCancelReservation={() => onCancelReservation(record.uuid)}
                    onDeleteReservation={() => onDeleteReservation(record.uuid)}
                    onChangeLockReservation={() => onChangeLockReservation(record.uuid)}
                    onRestoreReservation={() => onRestoreReservation(record.uuid)}
                    restoreCancelReservationStatus={restoreCancelReservationStatus}
                    setRestoreCancelReservationStatus={setRestoreCancelReservationStatus}*/
                    user={user}
                    permissions={permissions}
                />
        }
    ];

    useEffect(() => {
        const getCountries = async () => {
            try {
                let {data: {data: response}} = await CountriesService.get();
                setCountries(response);
            } catch (e) {
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        }
        getCountries();
        getReservations()
    }, []);

    const getReservationById = async (id) => {
        try {
            let { data: response } = await ReservationServiceV2.show(id);
            setSelectedReservation(response.data)
            /*getContracts(response.data.contract_id)*/
            setIsReservationModalFormVisible(true)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getReservations = async (page = 1, pageSize = 10 ) => {
        try {
            let { data: response } = await ReservationServiceV2.get(page, pageSize, searchParams);
            setReservations(prevState => ({
                ...prevState,
                data: response.data,
                meta: response.meta,
                isLoading: false
            }));
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.reservations")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={reservationSearchForm} onFinish={() => getReservations()} layout="vertical">
                        <Row gutter={[16,0]}>
                            <Col span={4}>
                                <Form.Item label={t("general.reservation_creator")}>
                                    <Input placeholder={t("general.reservation_creator")} onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            agent_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.reservation_number")}>
                                    <Input placeholder={t("general.reservation_number")} onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            uuid: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.hotel_name")}>
                                    <Input placeholder={t("general.hotel_name")} onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            hotel_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.room_name")}>
                                    <Input placeholder={t("general.room_name")} onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            room_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.guest_name")}>
                                    <Input placeholder="Ziyaretçi adını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            guest_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.reservation_date")}>
                                    <RangePicker
                                        style={{width:"100%"}}
                                        format="DD.MM.YYYY"
                                        placeholder={[t("general.start_date"), t("general.end_date")]}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                reservation_date: e ? [
                                                    moment(e[0]).format("YYYY-MM-DD"),
                                                    moment(e[1]).format("YYYY-MM-DD")
                                                ] : []
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.check_in")}>
                                    <DatePicker
                                        style={{width:"100%"}}
                                        format="DD.MM.YYYY"
                                        placeholder="Giriş tarihi"
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                check_in: e ? moment(e).format("YYYY-MM-DD"): "",
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.check_out")}>
                                    <DatePicker
                                        style={{width:"100%"}}
                                        format="DD.MM.YYYY"
                                        placeholder="Çıkış tarihi"
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                check_out: e ? moment(e).format("YYYY-MM-DD"): ""
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("general.status")}>
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        placeholder="Rezervasyon durumu seçiniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            status: e ? e : ""
                                        }
                                    })}>
                                        <Option key={1} value={1}>{t("general.confirm_waiting_to_send")}</Option>
                                        <Option key={2} value={2}>{t("general.confirm_pendings")}</Option>
                                        <Option key={3} value={3}>{t("general.confirmed")}</Option>
                                        <Option key={4} value={4}>{t("general.confirm_cancelled")}</Option>
                                        <Option key={6} value={6}>{t("general.reservation_cancelled")}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16,0]}>
                            <Col span={6}>
                                <Form.Item label={t("general.currencies")}>
                                    <Checkbox.Group
                                        defaultValue={['TRY','USD','EUR','GBP']}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                currencies: e
                                            }
                                        })}
                                    >
                                        <Checkbox value="USD">USD</Checkbox>
                                        <Checkbox value="TRY">TRY</Checkbox>
                                        <Checkbox value="EUR">EURO</Checkbox>
                                        <Checkbox value="GBP">GBP</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={t("general.date_type")}
                                >
                                    <Checkbox.Group
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                only_checkin: e.includes("only_checkin"),
                                                only_checkout: e.includes("only_checkout")
                                            }
                                        })}
                                    >
                                        <Checkbox value="only_checkin">{t("general.only_check_in")}</Checkbox>
                                        <Checkbox value="only_checkout">{t("general.only_check_out")}</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16,0]}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button style={{width:"100%"}} type="primary" htmlType="submit" onClick={() => setIsLoadingTable(true)}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div style={{
                        display:"flex",
                        justifyContent:"space-between",
                        marginBottom:"10px"
                    }}>
                        <Space direction="vertical">
                            <Row gutter={[0,8]}>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#6b7280",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.canceled_reservations")}</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#ef4444",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.unpaid_reservations")}</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#1890FF",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.partially_paid_reservations")}</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#22c55e",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.paid_reservations")}</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#be185d",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>{t("general.due_reservations")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Space>
                    </div>
                    <Table
                        columns={columns}
                        scroll={{ x: 2000, y: "100%" }}
                        dataSource={reservations.data}
                        rowKey={record => record.uuid}
                        loading={isLoadingTable}
                        bordered
                        size="small"
                        pagination={{
                            total: reservations.meta?.total,
                            defaultPageSize: reservations.meta?.per_page,
                            onChange: (page, pageSize) => {
                                setIsLoadingTable(true)
                                getReservations(page, pageSize)
                            }
                        }}
                    />
                </div>
            </Content>
            <ReservationModalForm
                title="Rezervasyon Düzenle"
                isVisible={isReservationModalFormVisible}
                onCancel={() => setIsReservationModalFormVisible(false)}
                reservation={selectedReservation}
                countries={countries}
                getReservations={getReservations}
            />
        </Wrapper>
    );
};

const ReservationPaymentStatusCell = ({ status }) => {
    const { t } = useTranslation();
    let color = "red";
    let text = "unknown";
    switch (status) {
        case "unpaid":
            color = "red";
            text = t("general.unpaid");
            break;
        case "partial":
            color = "blue";
            text = t("general.partially_paid");
            break;
        case "paid":
            color = "green";
            text = t("general.paid");
            break;
        default:
            break;
    }
    return (
        <Tooltip title={text}>
            <Tag color={color} className="w-full whitespace-normal truncate-ellipsis text-center">
                {text}
            </Tag>
        </Tooltip>
    )
}

const ReservationStatusCell = ({ status }) => {
    const { t } = useTranslation();
    let color = "red";
    let text = "unknown";
    switch (status) {
        case "pre-reservation":
            color = "blue";
            text = t("general.confirm_waiting_to_send");
            break;
        case "confirm-mail-will-send":
            color = "yellow";
            text = t("general.confirm_waiting_to_send");
            break;
        case "confirm-mail-sent-waiting-for-response":
            color = "orange";
            text = t("general.confirm_pendings");
            break;
        case "confirm-accepted":
            color = "green";
            text = t("general.confirmed");
            break;
        case "confirm-rejected":
            color = "red";
            text = t("general.confirm_cancelled");
            break;
        case "confirm-will-send":
            color = "magenta";
            text = t("general.confirm_will_send");
            break;
        case "reservation-cancelled":
            color = "red";
            text = t("general.reservation_cancelled");
            break;
        default:
            break;
    }
    return (
        <Tooltip title={text}>
            <Tag color={color} className="w-full whitespace-normal truncate-ellipsis text-center">
                {text}
            </Tag>
        </Tooltip>
    )
}

export default ReservationList;