import Wrapper from "@components/Wrapper";
import {
    Breadcrumb,
    Button, Radio,
    Col,
    DatePicker,
    Form,
    Image,
    Input,
    Layout,
    notification,
    Row, Space,
    Tabs, List, Card, Switch, Popconfirm, Select, Checkbox, Upload,
} from "antd";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import HotelSelect from "@components/utils/HotelSelect";
import {WebLanguageService, HotelEventService, ImageService} from "@services"
import Meta from "antd/es/card/Meta";
import {useTranslation} from "react-i18next";

const { Content } = Layout;
const { TabPane } = Tabs;
const { TextArea } = Input;

function HotelEventList(){
    const { t } = useTranslation();
    const [selectedHotel, setSelectedHotel] = React.useState(null)
    const [images, setImages] = React.useState([])
    const [events, setEvents] = React.useState([])
    const [webLanguages, setWebLanguages] = React.useState([]);
    const [eventForm] = Form.useForm();
    const getEvents = async (id) => {
        try {
            let { data: response } = await HotelEventService.getAllByHotel(id);
            setEvents(response.data);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getImages = async () => {
        try {
            let { data: response } = await ImageService.get();
            return response.data;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getWebLanguages = async (hotelId) => {
        try {
            let { data: { data: response } } = await WebLanguageService.getHotelLanguage(hotelId);
            setWebLanguages(prevState => {
                return {
                    ...prevState,
                    data: response,
                    loading: false
                }
            });
        } catch (e) {
            console.log(e);
        }
    };
    const onFinish = async (values) => {
        values = {
            ...values,
            date: moment(values.date).format('YYYY-MM-DD'),
        }
        try {
            let { data: response } = await HotelEventService.create(selectedHotel, values);
            if(response.success){
                getEvents(selectedHotel);
                notification.success({
                    message: response.message,
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onDelete = async (id) => {
        try {
            let { data: response } = await HotelEventService.delete(selectedHotel, id);
            if(response.success){
                getEvents(selectedHotel);
                notification.success({
                    message: response.message,
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        getImages().then((response) => {
            setImages(response);
        });
    },[])
    return(
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.events')}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={eventForm} layout="vertical" onFinish={onFinish}>
                        <Row gutter={[16,8]}>
                            <Col span={8}>
                                <Form.Item label={t('general.hotel')} name="hotel_id">
                                    <HotelSelect onChange={(id) => {getWebLanguages(id);getEvents(id);setSelectedHotel(id)}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Tabs style={{width:"100%"}}>
                                {webLanguages.data && webLanguages.data.map((language, index) => (
                                    <TabPane key={index} tab={language.web_language.name} forceRender={true}>
                                        <Row gutter={[16]}>
                                            <Col span={24}>
                                                <Form.Item name="image_id" label={t('general.event_image')} style={{overflowX:"auto"}} rules={[{
                                                    required: true,
                                                    message: "Etkinlik Resmi Seçiniz"
                                                }]}>
                                                    <Radio.Group>
                                                        <Space direction="horizontal" align="center" size="large" >
                                                            {images?.map(image => (
                                                                <Space key={image.id} align="center" direction="vertical">
                                                                    <Image
                                                                        width={100}
                                                                        src={`http://${image.url}`}
                                                                    />
                                                                    <Radio value={image.id}></Radio>
                                                                </Space>
                                                            ))}
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={['events',language.web_language['iso_639-1'],['title']]} label={t('general.title')} rules={[{
                                                    required: true,
                                                    message: 'Lütfen başlık giriniz'
                                                }]}>
                                                    <Input placeholder="Başlık giriniz"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="date" label={t('general.event_date')} rules={[{
                                                    required: true,
                                                    message: 'Lütfen etkinlik tarihi giriniz'
                                                }]}>
                                                    <DatePicker
                                                        style={{width:"100%"}}
                                                        format="DD.MM.YYYY"
                                                        multiple={true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="every_weekday" label={t('general.every_on_date')} valuePropName="checked" tooltip="Haftanın her aynı günü etkinlik listelenecek.">
                                                    <Checkbox/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={['events',language.web_language['iso_639-1'],['description']]} label={t('general.description')} rules={[{
                                                    required: true,
                                                    message: 'Lütfen açıklama giriniz'
                                                }]}>
                                                    <TextArea placeholder="Açıklama giriniz"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        {t('general.save')}
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Row>
                    </Form>
                    <Row>
                       <Col span={24}>
                           <List
                               grid={{
                                   gutter: 16,
                                   column: 5,
                               }}
                               dataSource={events}
                               renderItem={(item) => (
                                   <List.Item>
                                       <Card
                                           cover={
                                               <Image
                                                   height={150}
                                                   style={{ objectFit: "cover" }}
                                                   alt="example"
                                                   src={`http://${item.image.url}`}
                                                   fallback=""
                                               />
                                           }
                                           actions={[
                                               <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => onDelete(item.id)}>
                                                   <Button type="danger">
                                                       <FontAwesomeIcon icon={faTrash} />
                                                   </Button>
                                               </Popconfirm>
                                           ]}
                                       >
                                           <Meta
                                               title={item.title}
                                               description={!item.description ? 'Açıklama bulunamadı!' : item.description}
                                           />
                                       </Card>
                                   </List.Item>
                               )}
                           />
                       </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    )
}
export default HotelEventList;