import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const ActionButton = ({ icon, title, onClick = () => {}, disabled, loading }) => {
    onClick = disabled ? () => {} : onClick;
    return (
        <div data-disabled={disabled} data-loading={loading} className="data-[disabled=true]:opacity-50 data-[disabled=true]:cursor-not-allowed data-[loading=true]:border-green-500 w-full md:w-auto cursor-pointer text-sm flex flex-col items-center justify-center border-solid border-slate-200 py-4 px-6 rounded-lg hover:bg-slate-100 transition-colors" onClick={onClick}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className="text-xl text-blue-600 animate-spin" />: <FontAwesomeIcon icon={icon} className="text-xl text-blue-600" />}
            <span className="text-blue-500 font-medium text-base">
                {title}
            </span>
        </div>
    );
};

export default ActionButton;