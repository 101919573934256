import React from 'react';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "@/views/new-version/reservation/components/documents/ActionButton";
import {Form, notification} from "antd";
import {useTranslation} from "react-i18next";
import ReservationConfirmSendModal from "@/components/reservations/ReservationConfirmSendModal";
import {ReservationServiceV2} from "@services";
import {convertCurrencyCodeToSymbol} from "@/utils/helper";

const SendConfirm = ({ reservation }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isConfirmModalVisible, setIsConfirmModalVisible] = React.useState(false);
    const [isConfirmSendLoading, setIsConfirmSendLoading] = React.useState(false);
    const onHandleSendConfirm = () => {
        if (!reservation?.is_paid){
            notification.error({
                message: "Ödemesi tamamlanmayan rezervasyonlar için confirme gönderme işlemi yapılamaz.",
            })
            return;
        }

        setIsConfirmModalVisible(true)
    }
    const onSendReservationCost = async (values) => {
        setIsConfirmSendLoading(true)
        try{
            let { data: response } = await ReservationServiceV2.sendConfirm(reservation.uuid, values);
            if(response.success){
                form.resetFields()
                setIsConfirmModalVisible(false)
                notification.success({
                    message: response.message
                });
            }
            setIsConfirmSendLoading(false)
        }catch (e){
            setIsConfirmSendLoading(false)
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    return (
        <React.Fragment>
            <ActionButton
                icon={faPaperPlane}
                title={t("general.send_confirm")}
                onClick={onHandleSendConfirm}
            />
            <ReservationConfirmSendModal
                title="Rezervasyon Confirme Gönder"
                form={form}
                isVisible={isConfirmModalVisible}
                onCancel={() => setIsConfirmModalVisible(false)}
                onFinish={onSendReservationCost}
                cost={reservation.cost}
                currency={{
                    symbol: convertCurrencyCodeToSymbol(reservation.currency)
                }}
                loading={isConfirmSendLoading}
            />
        </React.Fragment>
    );
};

export default SendConfirm;