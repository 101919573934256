import axios from '../../axios';

const ReservationService = {
    async search(hotelId, formData) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/search-availability`, formData);
    },
    async get(page = 1, per_page = 10, filters = {}) {
        return await axios.get(`/v2/dashboard/reservations`, {
            params: {
                page: page,
                per_page: per_page,
                ...filters
            }
        });
    },
    async show(id) {
        return await axios.get(`/v2/dashboard/reservations/${id}`);
    },
    async create(hotelId, formData) {
        return await axios.post(`/v2/dashboard/hotels/${hotelId}/reservations`, formData);
    },

    async getNotes(reservationId) {
        return await axios.get(`/v2/dashboard/reservations/${reservationId}/notes`);
    },


    async downloadVoucher(reservationId, lang) {
        return await axios.get(`/v2/dashboard/reservations/${reservationId}/documents/voucher`, {
            responseType: 'blob',
            params: {
                lang: lang
            }
        });
    },
    async sendVoucher(reservationId, formData) {
        return await axios.post(`/v2/dashboard/reservations/${reservationId}/documents/voucher`, formData);
    },
    async downloadReceipt(reservationId) {
        return await axios.get(`/v2/dashboard/reservations/${reservationId}/documents/receipt`, {
            responseType: 'blob'
        });
    },
    async sendConfirm(reservationId, formData) {
        return await axios.post(`/v2/dashboard/reservations/${reservationId}/documents/confirm`, formData);
    },
    async sendProforma(reservationId, formData) {
        return await axios.post(`/v2/dashboard/reservations/${reservationId}/documents/proforma`, formData);
    },
    async sendBalanceCompletion(reservationId, formData) {
        return await axios.post(`/v2/dashboard/reservations/${reservationId}/documents/balance-completion`, formData);
    }
}

export default ReservationService;