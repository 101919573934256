import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Layout, notification, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {ExternalHotelService} from "@/services";
import {useParams} from "react-router-dom";
import ExternalConceptFormModal from "@/views/external/concepts/components/ExternalConceptFormModal";

const {Content} = Layout;

const ExternalHotelConceptList = () => {
    const [conceptCreateForm] = Form.useForm()
    const [isExternalConceptCreateModalVisible, setIsExternalConceptCreateModalVisible] = useState(false)
    const params = useParams();
    const [hotelConcepts, setHotelConcepts] = useState({
        data: [],
        meta: {},
        loading: true,
    })
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 100,
            key: 'id',
        },
        {
            title: "Konsept Adı",
            dataIndex: 'name',
            key: 'name',
        }
    ];
    const getHotelConcepts = async (hotelId, page = 1) => {
        try {
            let {data: response} = await ExternalHotelService.getConcepts(hotelId, page)
            setHotelConcepts({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    const onCreateHotelConcept = async (values) => {
        try {
            let {data: response} = await ExternalHotelService.createConcept(params.hotelId, values)
            if (response.success){
                notification.success({
                    message: "Success",
                    description: response.message
                })
                getHotelConcepts(params.hotelId)
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getHotelConcepts(params.hotelId)
        // eslint-disable-next-line
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Otel Odaları
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Space direction="horizontal" style={{
                        marginBottom: 16
                    }}>
                        <Button type="primary" htmlType="button" onClick={() => setIsExternalConceptCreateModalVisible(true)}>
                            Konsept Tanımla
                        </Button>
                    </Space>
                    <Table
                        columns={columns}
                        dataSource={hotelConcepts.data}
                        rowKey="id"
                        loading={hotelConcepts.loading}
                        /*pagination={{

                        }}*/
                    />
                </div>
                <ExternalConceptFormModal
                    title="Konsept Tanımla"
                    isVisible={isExternalConceptCreateModalVisible}
                    form={conceptCreateForm}
                    onCancel={() => {
                        setIsExternalConceptCreateModalVisible(false)
                        conceptCreateForm.resetFields();
                    }}
                    onFinish={onCreateHotelConcept}
                />
            </Content>
        </Wrapper>
    );
};

export default ExternalHotelConceptList;