import React, { useEffect, useState } from 'react';
import {Layout, Breadcrumb, notification, Tabs, Skeleton} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/v2/Wrapper';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import GeneralTab from "@/views/new-version/reservation/components/GeneralTab";
import NotesTab from "@/views/new-version/reservation/components/NotesTab";
import DocumentsTab from "@/views/new-version/reservation/components/DocumentsTab";
import PaymentsTab from "@/views/new-version/reservation/components/PaymentsTab";
import PaymentLinksTab from "@/views/new-version/reservation/components/PaymentLinksTab";
import CostTab from "@/views/new-version/reservation/components/CostTab";
import LogsTab from "@/views/new-version/reservation/components/LogsTab";
import {ReservationServiceV2} from "@services";

const { Content } = Layout;
const { TabPane } = Tabs;

const DETAIL_TABS = [
    {
        key: 'general',
        title: 'general.general',
        component: GeneralTab
    },
    {
        key: 'notes',
        title: 'general.notes',
        component: NotesTab
    },
    {
        key: 'documents',
        title: 'general.documents',
        component: DocumentsTab
    },
    {
        key: 'payments',
        title: 'general.payments',
        component: PaymentsTab
    },
    {
        key: 'payment-link',
        title: 'general.payment_link',
        component: PaymentLinksTab
    },
    {
        key: 'cost',
        title: 'general.cost',
        component: CostTab
    },
    {
        key: 'logs',
        title: 'general.logs',
        component: LogsTab
    }
]

const ReservationDetail = () => {
    const { t } = useTranslation()
    const params= useParams();
    const [reservation, setReservation] = useState({
        data: {},
        loading: true,
    })

    const getReservation = async () => {
        setReservation({ ...reservation, loading: true });
        try {
            let { data: response } = await ReservationServiceV2.show(params.reservationId);
            setReservation({
                data: response.data,
                loading: false,
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            setReservation({ ...reservation, loading: false });
        }
    }

    useEffect(() => {
       getReservation()
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/reservations">
                        {t("general.reservations")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.reservation_card")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {params.reservationId}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {reservation.data?.guests?.[0]?.full_name || '---'}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    {reservation.loading && <Skeleton />}
                    {!reservation.loading && (
                        <Tabs defaultActiveKey={DETAIL_TABS[0].key}>
                            {DETAIL_TABS.map(DETAIL_TAB => (
                                <TabPane tab={t(DETAIL_TAB.title)} key={DETAIL_TAB.key}>
                                    <DETAIL_TAB.component
                                        reservation={reservation.data}
                                    />
                                </TabPane>
                            ))}
                        </Tabs>
                    )}
                </div>
            </Content>
        </Wrapper>

    );


};


export default ReservationDetail;