import { useEffect, useState } from 'react';
import {
  Layout,
  Breadcrumb,
  Button,
  Row,
  Col,
  Drawer,
  Upload,
  message,
  Card,
  Image,
  Typography,
  notification,
  Form,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faInbox, faPlus } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { Authentication, ImageService } from '@services';
import PhotoGroupModalForm from "@components/photos/PhotoGroupModalForm";
import {useTranslation} from "react-i18next";

const { Content } = Layout;

const Photos = () => {
  const { t } = useTranslation()
  const [createForm] = Form.useForm();
  const [uploadDrawerVisible, setUploadDrawerVisible] = useState(false);
  const [isPhotoGroupModalVisible, setIsPhotoGroupModalVisible] = useState(false);
  const [images, setImages] = useState({});

  useEffect(() => {
    getImages();
  },[]);

  const getImages = async () => {
    try {
      let { data: response } = await ImageService.get();
      setImages(response);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }
  const onGroupCreate = async (values) => {
    try {
      let { data: response } = await ImageService.createImageGroup(values);
      setImages(response);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }

  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t("general.images")}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mb-20">
          <Col span={24}>
            <Button type="primary" className="float-right" onClick={() => setIsPhotoGroupModalVisible(true)}>
              <FontAwesomeIcon icon={faPlus} />
              {t("general.create_new_album")}
            </Button>
          </Col>
        </Row>

        <Row gutter={[16,0]}>
          {images.data?.map((image) => (
            <Col key={`image-${image.id}`} xs={24} md={8} lg={4}>
              <Card hoverable>
                <Image src={"http://" + image.url} />
                <Typography.Title level={4} style={{ margin: '10px 0 0 0' }}>{image.name}</Typography.Title>
              </Card>
            </Col>
          ))}
        </Row>
      </Content>
      <Drawer title="Fotoğraf Yükle" placement="bottom" size="large" visible={uploadDrawerVisible} onClose={() => setUploadDrawerVisible(false)}>
        <Upload.Dragger
          name="file"
          multiple={true}
          action={`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL}/v1/images`}
          headers={{
            Authorization: `Bearer ${Authentication.getToken()}`
          }}
          beforeUpload={(file) => {
            const isImage = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);

            if (!isImage) {
              message.error(`${file.name} adlı dosya bir resim değildir!`);
            }

            return isImage || Upload.LIST_IGNORE;
          }}
        >
          <p className="ant-upload-drag-icon">
            <FontAwesomeIcon icon={faInbox} />
          </p>
          <p className="ant-upload-text">Tıkla veya sürükle!</p>
        </Upload.Dragger>
      </Drawer>
      <PhotoGroupModalForm
        isVisible={isPhotoGroupModalVisible}
        title={t("general.create_new_album")}
        onCancel={() => setIsPhotoGroupModalVisible(false)}
        form={createForm}
        onFinish={onGroupCreate}
      />
    </Wrapper>
  );
};

export default Photos;