import React, {useEffect} from 'react';
import {
    Breadcrumb,
    Button,
    Col, Form, Input,
    Layout,
    notification,
    Popover,
    Row,
    Select,
    Skeleton,
    Space,
    Table,
    Tag,
    Tree
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Wrapper from "@/components/v2/Wrapper";
import {DiscountServiceV2} from "@services";
import moment from "moment";

const {Content} = Layout;
const {Option} = Select;

const DiscountGroupDetail = () => {
    const [form] = Form.useForm()
    const { t } = useTranslation();
    const [contracts, setContracts] = React.useState([])
    const [selectedContract, setSelectedContract] = React.useState(null)
    const [selectedDiscounts, setSelectedDiscounts] = React.useState([])
    const [discounts, setDiscounts] = React.useState([])
    const [isReady, setIsReady] = React.useState(false)
    const params = useParams()
    const columns = [
        {
            title: t("general.discount_name"),
            dataIndex: 'discount_name',
            key: 'discount_name',
            render: (text) => t(`general.${text.toLowerCase()}`)
        },
        {
            title: t("general.discount"),
            dataIndex: 'discount',
            key: 'discount',
            render: (text, record) => {
                if (record.discount_value_type === "1" || record.discount_type === "1"){
                    return `${text}%`
                }
                if (record.discount_value_type === "2" || record.discount_type === "2"){
                    return `${text}`
                }
            }
        },
        {
            title: t("general.is_cost_include"),
            dataIndex: 'is_cost_include',
            key: 'is_cost_include',
            render: (text) => {
                if (text === 1){
                    return <Tag color="green">Evet</Tag>
                }
                if (text === 0){
                    return <Tag color="red">Hayır</Tag>
                }
            }
        },
        {
            title: t("general.details"),
            dataIndex: 'details',
            key: 'details',
            render: (text, record) => {
                if (record.discount_name === "EARLY_RESERVATION_DISCOUNT"){
                    return <EarlyReservationDiscountDetails data={record} />
                }
                if (record.discount_name === "DATE_DISCOUNT"){
                    return <DateDiscountDetails data={record} />
                }
                if (record.discount_name === "MOBILE_DISCOUNT") {
                    return <MobileDiscountDetails data={record}/>
                }
                if (record.discount_name === "DAY_DISCOUNT") {
                    return <DayDiscountDetails data={record}/>
                }
                if (record.discount_name === "ACTION_DISCOUNT"){
                    return <ActionDiscountDetails data={record} />
                }
            }
        }
    ]
    const getDiscountGroup = async (hotelId, groupId) => {
        try {
            let {data: response} = await DiscountServiceV2.getGroup(hotelId, groupId)
            let initialDiscounts = []
            response.data.discounts.map((discount) => {
                if (!initialDiscounts[discount.index]) {
                    initialDiscounts[discount.index] = { items: [] };
                }
                initialDiscounts[discount.index]["items"].push(discount.uuid);
            });
            form.setFieldsValue({
                discounts: initialDiscounts,
            });
            await getAllDiscounts(response.data.contract_id, params.hotelId)
            setIsReady(true)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getAllDiscounts = async (contractId, hotelId) => {
        try {
            let {data: response} = await DiscountServiceV2.allDiscounts(hotelId, 1, 100, {
                contract_id: contractId
            })
            setDiscounts(response.data)
            setSelectedContract(contractId)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onSave = async (values) => {
        let processedValues = {
            "ids": [],
        }
        values.discounts.map((group) => {
            let groupArray = [];
            group.items.map((item) => {
                let id = item.split("-")[0]
                let code = item.split("-")[1]
                groupArray.push({
                    "id": id,
                    "key": code
                })
            })
            processedValues.ids.push(groupArray)
        })
        try {
            let {data: response} = await DiscountServiceV2.appendDiscountGroup(params.hotelId, params.groupId, processedValues)
            if (response.success){
                notification.success({
                    message: t("general.success")
                })
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const handleRowSelectionChange = (fieldName, selectedRowKeys) => {
        const currentValues = form.getFieldValue('discounts') || [];
        const updatedValues = currentValues.map((group, index) => {
            if (index === fieldName) {
                return {
                    ...group,
                    items: selectedRowKeys,
                };
            }
            return group;
        });

        form.setFieldsValue({ discounts: updatedValues });
    };
    useEffect(() => {
        getDiscountGroup(params.hotelId, params.groupId)
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item>
                        <Link to="/v2">
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/v2/hotels">
                            {t("general.hotels")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/discounts`}>
                            {t("general.discounts")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/v2/hotels/${params.hotelId}/discount-groups`}>
                            {t("general.discount_groups")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Grup Adı
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    {isReady && (
                        <Form form={form} layout="vertical" onFinish={onSave} initialValues={{
                            discounts: []
                        }}>
                            <Form.List
                                name={['discounts']}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => {
                                            const formValues = form.getFieldValue('discounts') || [];
                                            const selectedInOtherGroups = formValues
                                                .filter((_, idx) => idx !== index)
                                                .flatMap((group) => group?.items || []);
                                            const filteredDiscounts = discounts.filter(
                                                (item) =>
                                                    !selectedInOtherGroups.includes(item.uuid) ||
                                                    (formValues[index]?.items || []).includes(item.uuid)
                                            );
                                            return (
                                                <Form.Item
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Row gutter={[16,16]}>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name={[field.name, 'items']}
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                noStyle
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t("general.required_field")
                                                                    },
                                                                    {
                                                                        message: t("general.required_field"),
                                                                        validator: (rule, value) => {
                                                                            if (value.length > 0 && value.length < 2){
                                                                                return Promise.reject(t("general.min_2_discount"))
                                                                            }
                                                                            return Promise.resolve()
                                                                        }
                                                                    }
                                                                ]}
                                                            >
                                                                <Table
                                                                    title={() => (
                                                                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                            <h3>
                                                                                {t("general.combination")} {index + 1}
                                                                            </h3>
                                                                            <Button type="danger" onClick={() => remove(field.name)}>
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                    bordered
                                                                    size="small"
                                                                    columns={columns}
                                                                    dataSource={filteredDiscounts}
                                                                    rowKey="uuid"
                                                                    pagination={false}
                                                                    rowSelection={{
                                                                        type: 'checkbox',
                                                                        onChange: (selectedRowKeys) =>
                                                                            handleRowSelectionChange(index, selectedRowKeys),
                                                                        selectedRowKeys:
                                                                            formValues[index]?.items || [],
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            )
                                        })}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{
                                                    width: '100%',
                                                }}
                                                icon={<FontAwesomeIcon icon={faPlus}/>}
                                            >
                                                Grup Ekle
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                                        {t("general.save")}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    {!isReady && (
                        <Skeleton />
                    )}
                </div>
            </Content>
        </Wrapper>
    );
};

const ActionDiscountDetails = ({data}) => {
    return (
        <Space direction="vertical">
            <div>
                <span>
                    <b>Geçerlilik Tarihi</b>: {moment(data.validity_start_date).format("DD.MM.YYYY")} - {moment(data.validity_end_date).format("DD.MM.YYYY")}
                </span>
            </div>
            <div>
                <span>
                    <b>Konaklama Tarihi</b>: {moment(data.stay_start_date).format("DD.MM.YYYY")} - {moment(data.stay_end_date).format("DD.MM.YYYY")}
                </span>
            </div>
        </Space>
    )
}

const DayDiscountDetails = ({data}) => {
    const { t } = useTranslation();
    const days = JSON.parse(data.days) || []
    const dates = JSON.parse(data.dates) || []
    return (
        <Space direction="vertical">
            <div>
                <span>
                    <b>Geçerlilik Tarihi</b>: {moment(data.validity_start_date).format("DD.MM.YYYY")} - {moment(data.validity_end_date).format("DD.MM.YYYY")}
                </span>
            </div>
            <div>
                {days.length > 0 ? (
                    <span>
                        <b>Günler:</b>&nbsp; {days.map((day, index) => (
                            <Tag key={index} color="blue">
                                {t("general."+ moment().day(day).format("dddd").toLowerCase())}
                            </Tag>
                        ))}
                    </span>
                ): (
                    <span>
                        <b>Tarihler</b>: &nbsp; {dates.map((date, index) => (
                            <Tag key={index} color="blue">
                                {moment(date).format("DD.MM.YYYY")}
                            </Tag>
                        ))}
                    </span>
                )}
            </div>
        </Space>
    )
}

const MobileDiscountDetails = ({data}) => {
    return (
        <Space direction="vertical">
            <div>
                <b>Başlangıç Bitiş Tarihi</b>: {moment(data.start_date).format("DD.MM.YYYY")} - {moment(data.end_date).format("DD.MM.YYYY")}
            </div>
        </Space>
    )
}

const DateDiscountDetails = ({data}) => {
    return (
        <Space direction="vertical">
            <div>
                <b>Başlangıç Bitiş Tarihi</b>: {moment(data.start_date).format("DD.MM.YYYY")} - {moment(data.end_date).format("DD.MM.YYYY")}
            </div>
            <div>
                <b>Min-Max Gece</b>: {data.min_night} - {data.max_night}
            </div>
        </Space>
    )
}

const EarlyReservationDiscountDetails = ({data}) => {
    return (
        <Space direction="vertical">
            <div>
                {data?.checkin_day ? (
                    <span>
                        <b>Girişten Öncesi Gün Sayısı</b>: {data.checkin_day}
                    </span>
                ): (
                    <span>
                        <b>Konaklama Tarihi</b>: {moment(data?.stay_start_date).format("DD.MM.YYYY")} - {moment(data.stay_end_date).format("DD.MM.YYYY")}
                    </span>
                )}
            </div>
            <div>
                <span>
                    <b>Geçerlilik Tarihi</b>: {moment(data.validity_start_date).format("DD.MM.YYYY")} - {moment(data.validity_end_date).format("DD.MM.YYYY")}
                </span>
            </div>
        </Space>
    )
}

export default DiscountGroupDetail;