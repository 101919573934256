import React from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

const ReservationPaymentBalanceCompletionModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} width="80%" onOk={() => props.form.submit()} onCancel={() => props.onCancel()} okText="Kaydet" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={[16,4]}>
                    <Col span={12}>
                        <Form.Item
                            label="Ödeme Tipi"
                        >
                            <Select placeholder="Ödeme tipini seçiniz." onSelect={(selectedMethod) => {props?.getSafeBankDefinitions(selectedMethod);props.form.setFieldsValue({
                                safe_bank_id:undefined
                            })}}>
                                {props?.paymentMethods?.map((payment)=>{
                                    if(payment.id === 4){
                                        return null
                                    }
                                    return (
                                        <Option value={payment.id} key={payment.id}>
                                            {payment.name}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ödeme Yeri" name="safe_bank_id" rules={[{
                            required:true,
                            message:"Ödeme yeri seçilmedi!"
                        }]}>
                            <Select
                                disabled={props?.safeBankDefinitions.length <= 0}
                                placeholder="Ödeme yeri seçiniz."
                            >
                                {props?.safeBankDefinitions?.map((safeBank)=>(
                                    <Option key={safeBank.id} value={safeBank.id}>{safeBank.bank_name} - ({safeBank.title})</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Tutar"
                            name="amount"
                            rules={[{
                                required:true,
                                message:"Ödeme miktarı girilmedi!"
                            }]}
                        >
                            <InputNumber min={0} style={{
                                width:"100%"
                            }} placeholder="Ödeme miktarı giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Açıklama"
                            name="description"
                        >
                            <TextArea placeholder="Lütfen açıklama giriniz."/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ReservationPaymentBalanceCompletionModalForm;