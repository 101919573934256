import React, { useEffect, useState,useMemo } from 'react';
import Wrapper from '@components/Wrapper';
import {
    Layout,
    Breadcrumb,
    Button,
    Collapse,
    notification,
    Form,
    Image,
    Tabs,
    Row,
    Col, Select, DatePicker
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faEdit, faTrash, faPlus, faHotel, faSave, faSearch} from '@fortawesome/free-solid-svg-icons';
import { UserService,AgencyService,RoleService,CompanyService,ConceptService,HotelCategoryService,HotelService, RoomDefinitionService } from '@services';
import UserCreateModalForm from '@components/users/UserCreateModalForm';
import { useSelector } from 'react-redux';
import moment from "moment";
import {RuspoApiService, NationalityService} from "../../services";
import hotelService from "../../services/hotels/Hotel.service";
import ReservationAddModal from "@components/reservations/ReservationAddModal";
import ReservationCreateService from "../../services/reservations/ReservationCreate.service";
import ReservationService from "../../services/reservations/Reservation.service";
import CountriesService from "@services/countries/Countries.service";
import WebLanguageService from '../../services/web-languages/WebLanguage.service';
import CurrenciesService from "../../services/currencies/Currencies.service";
import {useTranslation} from "react-i18next";


const current = new Date();
const { RangePicker } = DatePicker;
const { Content } = Layout;
const { TabPane } = Tabs;
const { Option, OptGroup } = Select;
const { Panel } = Collapse;
const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
const options = [];
for (let i=1; i < 31; i += 1) options.push(i);

const ReservationCreate = () => {
    const { t } = useTranslation();
    const [hotelForm] = Form.useForm();
    const [createForm] = Form.useForm();
    const [showReservationsModal,setShowReservationsModal] = useState(false)
    const [selectedHotelReservation,setSelectedHotelReservation]=useState();
    const [selectedHotelInfo,setSelectedHotelInfo]=useState();
    const [childCount,setChildCount]=useState(0);
    const [hotelLanguages,setHotelLanguages]=useState([]);
    const [hotelCategories, setHotelCategories] = useState([]);
    const [hotelNames, setHotels] = useState([]);
    const [hotelDistricts, setHotelDistricts] = useState([]);
    const [accessibleRooms, setAccessibleRooms] = useState([]);
    const [hotelInfo, setHotelInfo] = useState([]);
    const [accessiblePackages, setAccessiblePackages] = useState([]);
    const [users, setUsers] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [roles, setRoles] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [contractCountries, setContractCountries] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    useEffect(() => {
        getAgencies();
        getRoles();
        getHotels();
        getHotelCategories();
        getNationalities()

    }, []);

    const getNationalities = async () => {
        try {
            let { data: response } = await NationalityService.get();
            setNationalities(response);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getHotels = async ( ) => {
        try {
            let { data: { data: hotels } } = await HotelService.get();
            let hotelDistrict = [];
            hotels.forEach((hotel)=>{
                hotelDistrict.push(hotel.district);
            })
            const hotelDistricts = [...new Set(hotelDistrict)]
            setHotelDistricts(hotelDistricts);
            setHotels(hotels);
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getHotelCategories = async () => {
        try {
            let response = await HotelCategoryService.get();
            setHotelCategories(response.data.data);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };



    const getAgencies = async ( ) => {
        try {
            let { data: { data: agencies } } = await AgencyService.getAgencies();
            setAgencies(agencies);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getRoles = async ( ) => {
        try {
            let { data: { data: roles } } = await RoleService.getRoles();
            setRoles(roles);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onHotelSearch = async (values) => {
        setIsSearchLoading(true)
        values.checkin=moment(values.daterange[0]).format('DD.MM.YYYY');
        values.checkout=moment(values.daterange[1]).format('DD.MM.YYYY');
        try {
            let {data: response} = await hotelService.search(values);
            if(!response.success){
                notification.error({
                    message: response.message
                });
                setIsSearchLoading(false)
                setHotelInfo([]);
                setAccessibleRooms([]);
                return;
            }
            setHotelInfo(response.data);
            setAccessibleRooms(response.data);
            notification.success({
                message: "Arama tamamlandı!"
            });
            setIsSearchLoading(false)
        } catch (e) {
            if (e.response) {
                setIsSearchLoading(false)
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getSession = async (values) =>{
        values.aquapark="0";
        values.nopromo="0";
        values.LIGHT = values.LIGHT === true ? 1:0;
        values.async = values.async === true ? 1:0;
        values.DATEFROM=moment(values.DATEFROM).format('DD.MM.YYYY');
        values.DATETO=moment(values.DATETO).format('DD.MM.YYYY');
        values.AGENCY="request";
        if (values.STARS[0] === 0 || values.STARS ===0){
            values.STARS = ["1","2","3","4","5","8","9"];
        }

            let responseUser = await RuspoApiService.packageSearch(values);

            let session = responseUser.data[0];
            return session;
    }
    const getCountries = async () => {
        try {
            let response = await WebLanguageService.get();
            setContractCountries(response.data.data);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const ruspoDataGet = async (session) =>{
        let responseUserData = await RuspoApiService.ruspoGetData(session,1).then(async response => {
            if (parseInt(response.data.percent) !== 100) {
                await ruspoDataGet(session);
            } else {
                let rawPackages = [];
                Object.keys(response.data).forEach(function(key, index) {
                    rawPackages.push(this[key]);
                },  response.data);

                let index = rawPackages.indexOf("100");

                rawPackages.splice(index,1);

                setAccessiblePackages(rawPackages);

            }
        });
    }

    const handleReservationDetails = (values) => {
        return {
            "hotel_id": values[1].hotelId,
            "room_id": values[2].room.id,
            "period_group_id": values[2].contract.period_id,
            "contract_id": values[2].contract.id,
            "contract_concept_id": values[2].concept.id,
            "currency_id": values[2].currency.currency.id,
            "adult_number": values[1].adultNumber,
            "child_number": values[1].childNumber,
            "check_in": values[1].checkIn,
            "check_out": values[1].checkOut,
            "total_fee": values[2].totalFeeNumber,
            "discounts": values[2].discounts,
            "reservation_informations": values[0],
            "need_insurance": values?.[0]?.need_insurance ?? false,
            "insurance": values?.[0]?.insurance ? {
                ...values?.[0]?.insurance,
                SigortaliDogumTarihi: moment(values?.[0]?.insurance.SigortaliDogumTarihi).format('YYYY-MM-DD'),
            }: null,
            "additional_services": values?.[0]?.additional_services ?? [],
        }
    }

    const onCreateReservation = async (values) => {
        for(let i = 1; i <= accessibleRooms?.adultNumber; i++){
            values = {
                ...values,
                [`adult-birth_date${i}`]: moment(values[`adult-birth_date${i}`]).format('YYYY-MM-DD'),
            }
        }
        for (let i = 1; i <= accessibleRooms?.childNumber; i++) {
            values = {
                ...values,
                [`child-birth_date${i}`]: moment(values[`child-birth_date${i}`]).format('YYYY-MM-DD'),
            }
        }
        let splitValues=[];
        splitValues.push(values);
        splitValues.push(accessibleRooms);
        splitValues.push(selectedHotelReservation)
        let data = handleReservationDetails(splitValues);
        try {
            let {data: response} = await ReservationService.parseAndValidate(data);
            if(response.success){
                notification.success({
                    message: "Rezervasyon oluşturuldu!"
                });
                setShowReservationsModal(false);
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e
                });
            }
        }
    }
    const languageChanged=(value)=>{
        setSelectedOption(value);
    }
  
    return (
        <>
            <Wrapper>
                <Content className="m-25">
                    <Breadcrumb className="mb-4">
                        <Breadcrumb.Item href="/">
                            <FontAwesomeIcon icon={faHouse} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {t("general.create_new_reservation")}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="site-layout-background">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Hotel" key="1">
                                <Form form={hotelForm} layout="vertical" onFinish={onHotelSearch}>
                                    <Row gutter={[16,0]}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="hotel_id"
                                                label={t("general.hotel_name")}
                                                rules={[{
                                                    required: true,
                                                    message: 'Lütfen otel adı seçiniz!'
                                                }]}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    { hotelNames.map((item) => <Option value={item.id} key={`description${item.id}`}>{item.name}</Option>) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name="hotel_category_id"
                                                label={t("general.hotel_category")}
                                                tooltip="Şuanlık otel kategorisi seçimi yapılamamaktadır."
                                            >
                                                <Select disabled={true}>
                                                    { hotelCategories.map((item) => <Option value={item.id} key={`hotelCategory${item.id}`}>{item.name}</Option>) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name="hotel_district"
                                                label={t("general.district")}
                                                tooltip="Şuanlık otel bölgesi seçimi yapılamamaktadır."
                                            >
                                                <Select disabled={true}>
                                                    { hotelDistricts.map((item) => <Option value={item} key={`${item}`}>{item}</Option>) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="nationality"
                                                label={t("general.nationality")}
                                                tooltip="Önce otel seçiniz."
                                                rules={[{
                                                    required: true,
                                                    message: 'Lütfen uyruk seçiniz'
                                                }]}
                                            >
                                                <Select
                                                    onChange={languageChanged}
                                                    showSearch={true}
                                                    filterOption={(input, option) =>
                                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                     {nationalities.map((item) => (
                                                             <Option value={nationalities?.id} key={item?.id}>{item?.label}</Option>
                                                     ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="adults"
                                                label={t("general.adults")}
                                                initialValue={2}
                                                rules={[{
                                                    required: true,
                                                    message: 'Lütfen yetişkin sayısı seçiniz'
                                                }]}
                                            >
                                                <Select>
                                                    {
                                                        [...Array(10)].map((e, i) => {
                                                            if(i === 0)
                                                                return;
                                                            return <Option key={i} value={i}>{i}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="children"
                                                label={t("general.children")}
                                                initialValue={0}
                                                rules={[{
                                                    required: true,
                                                    message: 'Lütfen çocuk sayısı seçiniz'
                                                }]}
                                            >
                                                <Select
                                                    onChange={(e) => setChildCount(e)}
                                                >
                                                    {
                                                        [...Array(5)].map((e, i) => {
                                                            return <Option key={i} value={i}>{i}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {childCount > 0 &&
                                            [...Array(childCount)].map((e, i) => (
                                                <Col span={24/childCount}>
                                                    <Form.Item
                                                        name={['childAge[]', i]}
                                                        label={`${i+1}. Çocuk Yaşı`}
                                                        rules={[{
                                                            required: true,
                                                            message: 'Lütfen yaş seçiniz'
                                                        }]}
                                                        initialValue={1}
                                                    >
                                                        <Select>
                                                            {
                                                                [...Array(12)].map((e, i) => {
                                                                    return <Option key={i} value={i}>{i}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            ))
                                        }
                                        <Col span={12}>
                                            <Form.Item
                                                name="daterange"
                                                label={t("general.check_in_out")}
                                                rules={[{ required: true, message: 'Lütfen tarih seçiniz' }]}
                                            >
                                                <RangePicker
                                                    selected={date}
                                                    format="DD.MM.YYYY"
                                                    placeholder={[`${t("general.starting_date")}`, `${t("general.ending_date")}`]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={24} >
                                            <Button type="primary" htmlType="submit"  className="mt-25" block loading={isSearchLoading}>
                                                <FontAwesomeIcon icon={faSearch} className="mr-5" />
                                                {t("general.search_rooms")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                {accessibleRooms?.agency_info &&
                                    <Row style={{marginTop:"2rem"}}>
                                        <Col span={24}>
                                            <div dangerouslySetInnerHTML={{ __html:accessibleRooms?.agency_info }}></div>
                                        </Col>
                                    </Row>
                                }
                                <Row gutter={[16,16]} style={{marginTop:"2rem"}}>
                                    {accessibleRooms.rooms?.map((room, index) => (
                                            <Col key={index} span={24} style={{border:"1px solid rgba(0,0,0,0.2)",padding:"12px 16px",borderRadius:"16px"}}>
                                                <Row style={{border:"1px solid rgba(0,0,0,0.2)",marginBottom:"12px",padding:"12px 16px",borderRadius:"16px"}}>
                                                    <Col span={12}>
                                                        <Image
                                                            style={{borderRadius:'20px',objectFit:'cover'}}
                                                            width={200}
                                                            height="100%"
                                                            src={room[0].room?.images[0]?.hashed_name ? `http://agencyapi.travelpratic.com/storage/uploads/images/${room[0].room?.hotel_id}/${room[0].room?.id}/${room[0].room?.images[0]?.hashed_name}` : "https://via.placeholder.com/200x200"}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <p>
                                                            <b>{t("general.hotel_name")}:</b> {accessibleRooms?.hotelName} <br />
                                                            <b>{t("general.room_name")}:</b> { room[0].room.definition.active_language.name} <br />
                                                            <b>{t("general.concept")}:</b> { room.map((item,index) => (<i key={index}> {item.concept.concept.active_language.name},</i>))} <br />
                                                            <b>{t("general.check_in")}:</b> {accessibleRooms?.checkIn} <br />
                                                            <b>{t("general.check_out")}:</b> {accessibleRooms?.checkOut} <br />
                                                            <b>{t("general.nights")}:</b> {accessibleRooms?.diffDay} <br />
                                                        </p>
                                                    </Col>
                                                </Row>
                                                {room.map((item,index) => (
                                                    <Row key={index} style={{border:"1px solid rgba(0,0,0,0.2)",padding:"12px 16px",borderRadius:"16px",marginBottom:"1rem"}}>
                                                        <Col span={12}>
                                                            <div style={{width:"100%",height:"100%",display:"flex",alignItems:"center",columnGap:"16px"}}>
                                                                <h3 style={{fontWeight:"600",fontSize:"1.2rem"}}>{item.concept.concept.active_language.name} -</h3>
                                                                <h3 style={{fontWeight:"600",fontSize:"1.2rem"}}>{item.totalFee} { item.currency?.currency?.symbol ?? '₺' }</h3>
                                                            </div>
                                                        </Col>
                                                        <Col span={12}>
                                                            <div style={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                                                {item.requestRoom ?
                                                                    <Button
                                                                        type="danger"
                                                                        htmlType="submit"
                                                                        style={{display:"inline-block"}}
                                                                        onClick={()=>{setShowReservationsModal(true);setSelectedHotelInfo(accessibleRooms);setSelectedHotelReservation(item)}}
                                                                    >
                                                                        {t("general.request_room")}
                                                                    </Button>:
                                                                    <Button
                                                                        type="primary"
                                                                        htmlType="submit"
                                                                        style={{display:"inline-block"}}
                                                                        onClick={()=>{setShowReservationsModal(true);setSelectedHotelInfo(accessibleRooms);setSelectedHotelReservation(item)}}
                                                                    >
                                                                        {t("general.create_reservation")}
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                        ))}
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </Content>
                <ReservationAddModal
                    isVisible={showReservationsModal}
                    selectedReservation={selectedHotelReservation}
                    selectedLanguage={selectedOption}
                    selectedHotelInfo={selectedHotelInfo}
                    form={createForm}
                    onFinish={onCreateReservation}
                    onOk={() => setShowReservationsModal(false)}
                    onCancel={()=> setShowReservationsModal(false)}
                />
            </Wrapper>
        </>
    );
};

export default ReservationCreate;