import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Layout,
  Space,
  DatePicker,
  notification,
  Table,
  Button,
  Tag,
  Form,
  Popconfirm,
  Tooltip,
  Input, Row, Col, Select
} from "antd";
import Wrapper from "@components/Wrapper";
import {faHouse, faCheck, faXmark, faBan, faSearch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HotelService, ReservationService } from "@services";
import ConditionModalForm from "@components/confirm/ConfirmModalForm";
import {Link} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";
const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Option } = Select;


function Confirme() {
  const { t } = useTranslation()
  const [hotelNames, setHotels] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [hotelDistricts, setHotelDistricts] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isConfirmModalFormVisible, setIsConfirmModalFormVisible] = useState(false);
  const [confirmEdit] = Form.useForm()
  const [confirmForm] = Form.useForm()
  const [reservations, setReservations] = useState({
    data: [],
    meta: {},
  });
  const getColumnSearchProps = (type) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }}>
          <Space direction="vertical">
            {type === 'search' && (
                <Input
                    placeholder={t("general.search")}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
            )}
            {type === 'date_range' && (
                <RangePicker
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e ? [e] : [])}
                    onPressEnter={() => confirm()}
                    placeholder={[`${t("general.start_date")}`, `${t("general.end_date")}`]}
                    format="DD.MM.YYYY"
                />
            )}
            {type === 'number_range' && (
                <Row gutter={16}>
                  <Col span={12}>
                    <Input
                        placeholder="Min"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys([e.target.value ? parseInt(e.target.value, 10) : null])}
                        onPressEnter={() => confirm()}
                        style={{ width: 90, marginBottom: 8, display: 'block' }}
                    />
                  </Col>
                  <Col span={12}>
                    <Input
                        placeholder="Max"
                        value={selectedKeys[1]}
                        onChange={e => setSelectedKeys([selectedKeys[0], e.target.value ? parseInt(e.target.value, 10) : null])}
                        onPressEnter={() => confirm()}
                        style={{ width: 90, marginBottom: 8, display: 'block' }}
                    />
                  </Col>
                </Row>
            )}
            {type === 'status' && (
                <Select
                    placeholder={t("general.reservation_status")}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e ? [e] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                >
                  <Option value={2}>{t("general.confirm_pending")}</Option>
                  <Option value={3}>{t("general.confirmed")}</Option>
                  <Option value={4}>{t("general.confirmation_failed")}</Option>
                  <Option value={5}>{t("general.confirmation_will_send")}</Option>
                  <Option value={6}>{t("general.confirmation_cancelled")}</Option>
                </Select>
            )}
            <Space>
              <Button
                  type="primary"
                  onClick={() => confirm()}
                  icon={<FontAwesomeIcon icon={faSearch} />}
                  size="small"
                  style={{
                    width: 90,
                  }}
              >
                {t("general.search")}
              </Button>
              <Button
                  onClick={() => {
                    clearFilters();
                    confirm();
                  }}
                  size="small"
                  style={{
                    width: 90,
                  }}
              >
                {t("general.reset")}
              </Button>
            </Space>
          </Space>
        </div>
    ),
  });
  const columns = [  
    {
      key: "status",
      title: `${t("general.status")}`,
      dataIndex: "status",
      render: (text, record) => {
        let status = `${t("general.confirm_pending")}`
        if(text === 3){
          status = `${t("general.confirmed")}`
          return <Tag color="green">{status}</Tag>;
        }
        if(text === 4){
          status = `${t("general.confirmation_failed")}`
          return <Tag color="red">{status}</Tag>;
        }
        return <Tag color="yellow">{status}</Tag>;
      },
      ...getColumnSearchProps("status")
    },
    {
      key: "reservation_number",
      title: `${t("general.reservation_no")}`,
      dataIndex: "id",
      render: (text, record) => (
        <Link to={`/reservation-card/${record.uuid}`}>
          {record.uuid}
        </Link>
      ),
      ...getColumnSearchProps("search")
    },
    {
      key: "hotel_name",
      title: `${t("general.hotel_name")}`,
      dataIndex: "hotels",
      render: (text) => text.name,
      ...getColumnSearchProps("search")
    },
    {
      key: "full_name",
      title: `${t("general.full_name")}`,
      dataIndex: "full_name",
      render: (text) => text,
      ...getColumnSearchProps("search")
    },
    {
      key: "check_in",
      title: `${t("general.check_dates")}`,
      dataIndex: "check_in",
      render: (text, record) => text + " - " + record.check_out,
      ...getColumnSearchProps("date_range")
    },
    {
      key: "full_name_agent",
      title: `${t("general.agent")}`,
      dataIndex: "users",
      render: (text) => text ? text?.first_name + " " + text?.last_name: "",
      ...getColumnSearchProps("search")
    },
    {
      key: "confirm_note",
      dataIndex: "confirm_note",
      title: `${t("general.confirm_note")}`,
      render: (text) => (
          <div style={{
            overflow: "auto",
            maxHeight: "60px",
          }}>
            {text}
          </div>
      ),
      ...getColumnSearchProps("search")
    },
    {
      key: "actions",
      title: `${t("general.actions")}`,
      render: (text, record) => (
        <Space align="middle">
          {(record.status !== 3 && record.status !== 4) &&
              <>
                <Button type="primary" onClick={() => {
                  setSelectedReservation(record);
                  setIsConfirmModalFormVisible(true)
                }}>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
                <Popconfirm title={t("general.are_you_sure_cancel")} onConfirm={() => onConfirm(record.uuid,4)}>
                  <Button type="danger" >
                    <FontAwesomeIcon icon={faXmark} />
                  </Button>
                </Popconfirm>
              </>
          }
          {record.status === 3 &&
              <Tooltip title={t("general.cancel_reservation")}>
                <Popconfirm
                    title={t("general.are_you_sure_cancel_reservation")}
                    onConfirm={() => onCancelReservation(record.uuid)}
                    okText={t("general.yes")}
                    cancelText={t("general.no")}
                >
                  <Button type="danger">
                    <FontAwesomeIcon icon={faBan}/>
                  </Button>
                </Popconfirm>
              </Tooltip>
          }
        </Space>
      ),
    },
  ];
  useEffect(() => {
    getHotels();
    getReservations();
  }, []);

  const onCancelReservation = async (uuid) => {
    try {
      let { data: response } = await ReservationService.cancelConfirmedReservation(uuid);
      if(response.success){
        notification.success({
          message: response.message,
        });
        getReservations();
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  }

  const getHotels = async () => {
    try {
      let {
        data: { data: hotels },
      } = await HotelService.get();
      let hotelDistrict = [];
      hotels.forEach((hotel) => {
        hotelDistrict.push(hotel.district);
      });
      const hotelDistricts = [...new Set(hotelDistrict)];
      setHotelDistricts(hotelDistricts);
      setHotels(hotels);
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  };

  const getReservations = async (page = 1, pageSize = 10, filters) => {
    setIsLoadingTable(true)
    try {
      let { data: response } = await ReservationService.getConfirms(page, pageSize, filters);
      setReservations({
        data: response.data,
        meta: response.meta,
      });
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
      setIsLoadingTable(false);
    }
  };

  const onConfirm = async (id,status, data) => {
    let values = {
        status: status,
        note: data.note ?? null,
    }
    try {
      let { data: response } = await ReservationService.updateConfirms(id,values);
      if(response.success){
        notification.success({
          message: response.message,
        });
        getReservations();
        setIsConfirmModalFormVisible(false)
        confirmForm.resetFields();
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    filters = {
      ...filters,
      check_date: filters.check_in?.[0]?.map(item => moment(item).format('YYYY-MM-DD')),
    }
    getReservations(pagination.current, pagination.pageSize, filters);
  }

  return (
    <>
      <Wrapper>
        <Content className="m-25">
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHouse} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Confirme</Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background">
            <Table
                columns={columns}
                scroll={{ x: 1300, y: 800 }}
                dataSource={reservations.data}
                rowKey="id"
                loading={isLoadingTable}
                bordered={true}
                onChange={handleTableChange}
                pagination={{
                  pageSize: reservations.meta.per_page,
                  total: reservations.meta.total,
                }}
            />
          </div>
          <ConditionModalForm
              form={confirmForm}
              title="Rezervasyon Confirme İşlemi"
              isVisible={isConfirmModalFormVisible}
              data={selectedReservation}
              onCancel={() => {
                setSelectedReservation(null)
                confirmForm.resetFields();
                setIsConfirmModalFormVisible(false);
              }}
              onFinish={(values, id) => onConfirm(id,3, values)}
          />
        </Content>
      </Wrapper>
    </>
  );
}
export default Confirme;
